import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import StepHeader from 'elements/StepHeader'
import { DropBar } from '../Style'
import StepContent from './StepContent'
import StepStatOverview from './StepContent/StepStatOverview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  background: ${props => (props.selected ? theme.highlightBackground : theme.containerBackground)};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: start;
  border: solid 1px ${theme.borderColor};
`

const StepBanner = styled.div`
  display: inline-block;
  background-color: ${theme.colors.gray10};
  padding: ${theme.padding};
  margin-top: auto;
  width: 100%;

  user-select: none;
  cursor: ${props => (props.hasSent) ? 'default' : 'move'};
`

const ColumnBanner = styled.div`
  display: grid;
  grid-template-columns: calc(${theme.grid.columnGap} + ${theme.grid.firstColumnGap}) repeat(3, ${theme.grid.columnGap}) ${theme.grid.buttonGap};
  padding-bottom: ${theme.padding};
  align-items: end;
  width: calc(${theme.grid.columnGap} * 4 + ${theme.grid.buttonGap} + ${theme.grid.firstColumnGap});
  position: absolute;
  right: 17px;
`

const Label = styled.div`
  font: ${theme.fonts.button};
  color: ${theme.titleColor};
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.05em;
`

const EditStepScheduleWrapper = styled.div`
  cursor: pointer;
  position: relative;

  margin-top: -4px;
`

const EditStepSchedule = styled.button`
  font: ${theme.fonts.button};
  color: ${theme.colors.gray70};
  height: 26px;
  border-radius: 100%;
  border: 1px solid ${theme.colors.gray40};

  &:hover {
    transition: all .15s ease-in;
    border-color: ${theme.colors.blue};
    color: ${theme.colors.blue};
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

class StepBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dragOver: false
    }

    this.dragCounter = 0
    this.wrapperRef = React.createRef()
  }

  willRenderStepStatOverview (stats, stepStats, stepIndex) {
    // by defualt will return `false` in case `stepTotal` or `statTotal` are null
    let willRender = false

    const stepIdx = '' + stepIndex
    const stepAvgData = (stepStats || Immutable.Map({})).get(stepIdx)
    const stepTotal = (stepAvgData || Immutable.Map({})).get('total')

    const statTotal = stats.get('total')

    if (stepTotal && !statTotal) {
      willRender = true
    } else if (stepTotal && statTotal) {
      // stats displayed in <StepStatOverview>
      const stepViewed = Math.round((stepAvgData.get('viewed') / stepTotal) * 100)
      const stepVisited = Math.round((stepAvgData.get('visited') / stepTotal) * 100)
      const stepReplied = Math.round((stepAvgData.get('replied') / stepTotal) * 100)

      // stats displayed in <StepContant>
      const statViewed = Math.round((stats.get('viewed') / statTotal) * 100)
      const statVisited = Math.round((stats.get('visited') / statTotal) * 100)
      const statReplied = Math.round((stats.get('replied') / statTotal) * 100)

      // return false if all the values are the same
      // return true if any of the values are different
      willRender = !(
        stepTotal === statTotal &&
        stepViewed === statViewed &&
        stepVisited === statVisited &&
        stepReplied === statReplied
      )
    }

    return willRender
  }

  render () {
    const {
      subject,
      step,
      testStep,
      selected = false,
      testSelected = false,
      onClick,
      onAddTest,
      onRemove,
      onEditSchedule,
      stepIndex,
      stepStats = Immutable.Map({}),
      stats = Immutable.Map({}),
      testStats = Immutable.Map({}),
      stepIdxStats = Immutable.Map({}),
      onDragStart,
      onDrop,
      readOnly
    } = this.props
    const { dragOver } = this.state

    const wait = step.get('wait_days') === undefined ? null : step.get('wait_days')
    const manual = step.get('manual')
    const isMessage = !manual || manual === 'message'
    const hasSent = (stepIdxStats || Immutable.Map({})).has('total')

    return (
      <>
        <DropBar hasSent={hasSent} visible={dragOver} />
        <Wrapper
          ref={this.wrapperRef}
          selected={selected}
          draggable={!hasSent && !readOnly}
          data-stepbox-index={stepIndex}
          onDragOver={(event) => {
            event.preventDefault()
            event.stopPropagation()
          }}
          onDragStart={onDragStart}
          onDragEnter={(event) => {
            event.preventDefault()

            if (this.dragCounter === 0) {
              this.setState({ dragOver: true })
            }

            this.dragCounter++
          }}
          onDragLeave={(event) => {
            event.preventDefault()

            this.dragCounter--

            if (this.dragCounter === 0) {
              this.setState({ dragOver: false })
            }
          }}
          onDragEnd={() => {
            this.setState({ dragOver: false })
          }}
          onDrop={(event) => {
            event.preventDefault()
            event.persist()
            this.setState({ dragOver: false }, () => {
              this.dragCounter = 0
              onDrop(event)
            })
          }}
        >
          <StepBanner hasSent={hasSent}>
            <StepHeader
              stepCount={stepIndex}
              wait={wait}
              manual={manual}
              onClick={() => { onClick(false) }}
              isPreview={false}
            />
            <ColumnBanner>
              <Label />
              <Label />
              <Label />
              <Label />
              <EditStepScheduleWrapper>
                {!readOnly &&
                  <EditStepSchedule
                    onClick={onEditSchedule}
                    disabled={readOnly}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'cog']}
                      transform={{
                        size: 16,
                        y: 0
                      }}
                    />
                  </EditStepSchedule>}
              </EditStepScheduleWrapper>
            </ColumnBanner>
          </StepBanner>
          <StepContent
            subject={subject}
            step={step}
            stats={stats}
            selected={selected && !testSelected}
            onClick={() => { onClick(false) }}
            onRemove={() => { onRemove(false) }}
            onAddTest={!testStep ? onAddTest : null}
            readOnly={readOnly}
          />
          {testStep &&
            <StepContent
              subject={testStep.get('subject') || subject}
              step={testStep}
              stats={testStats}
              selected={(selected && testSelected)}
              onClick={() => { onClick(true) }}
              onRemove={() => { onRemove(true) }}
              readOnly={readOnly}
            />}

          {this.willRenderStepStatOverview(stats, stepStats, stepIndex) && isMessage &&
            <StepStatOverview
              stepStats={stepStats}
              stepIndex={stepIndex}
            />}
        </Wrapper>
      </>
    )
  }
}

StepBox.propTypes = {
  subject: PropTypes.string,
  step: PropTypes.object,
  testStep: PropTypes.object,
  stats: PropTypes.object,
  testStats: PropTypes.object,
  stepIndex: PropTypes.number,
  stepStats: PropTypes.object,
  stepIdxStats: PropTypes.object,
  selected: PropTypes.bool,
  testSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onAddTest: PropTypes.func,
  onRemove: PropTypes.func,
  onEditSchedule: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  readOnly: PropTypes.bool
}

export default StepBox

/**
*
* TextFieldForm Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { space, width, height } from 'styled-system'
import styled, { css } from 'styled-components'
import Button from 'components/Button'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Field = styled.input`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  width: 100%;
  padding: 1rem;
  border-radius: 3px;
  border-width: 1px;
  border-color: ${theme.colors.gray60};
  border-style: solid;

  ${props => props.disabled && css`
    background-color: #f9f9f9;
  `}
`

const TextArea = styled.textarea`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  width: 100%;
  padding: 1rem;
  border-radius: 3px;
  border-width: 1px;
  border-color: ${theme.colors.gray60};
  border-style: solid;
  min-height: 200px;

  ${props => props.disabled && css`
    background-color: #f9f9f9;
  `}
`

class TextFieldForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed, ignore enter for multiline
    if (!this.props.multiline && e.keyCode === 13 && this.valid()) {
      e.preventDefault()
      return this.props.onSave && this.props.onSave(this.state.value)
    }

    return false
  }

  valid = () => (
    this.props.value !== this.state.value
  )

  render () {
    const {
      label,
      description,
      inputId,
      onSave,
      onChange,
      multiline = false,
      disabled = false,
      disabledButton = false,
      textType = 'text',
      saveLabel = 'Save',
      required = false,
      ...rest
    } = this.props
    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        {...rest}
      >
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {description &&
          <Description htmlFor={inputId}>{description}</Description>}
        {!multiline &&
          <Field
            id={inputId}
            ref={this.fieldRef}
            value={this.state.value}
            type={textType}
            required={required}
            onChange={(e) => {
              const v = e.target.value
              this.setState({ value: e.target.value })
              if (onChange) {
                onChange(v)
              }
            }}
            disabled={disabled}
          />}
        {multiline &&
          <TextArea
            id={inputId}
            value={this.state.value}
            onChange={(e) => {
              const v = e.target.value
              this.setState({ value: v })
              if (onChange) {
                onChange(v)
              }
            }}
            disabled={disabled}
          />}
        {onSave && !disabled &&
          <Button
            full
            primary
            disabled={disabledButton}
            label={saveLabel}
            handleClick={() => {
              if (required && !this.state.value) {
                return
              }

              onSave(this.state.value)
            }}
            mt='1rem'
          />}
      </Wrapper>
    )
  }
}

TextFieldForm.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  inputId: PropTypes.string,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledButton: PropTypes.bool,
  textType: PropTypes.string,
  saveLabel: PropTypes.string,
  required: PropTypes.bool
}

export default TextFieldForm

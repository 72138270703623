import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  subDays,
  addDays,
  addMonths,
  subMonths,
  startOfDay,
  endOfDay
} from 'date-fns'
import Label from 'elements/Label'
import * as dateHelpers from 'containers/Reports/utils/date_helpers'
import { ArrowLeft, ArrowRight } from 'svg/'
import theme from '../../../../../../../../themes/light'

const Wrapper = styled.div`
  padding-top: .5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;

  width: 100%;

  user-select: none;
`

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

/* Items pertaining to the month of the year row */
const MonthBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  padding-top: 0;
`

const CalendarSubjectLabel = styled.div`
  text-transform: uppercase;
  padding-top: .5rem;
  font: ${theme.fonts.xsmall};
  color: ${theme.colors.gray70};
`

const MonthLabel = styled(Label)`
  font: ${theme.fonts.small};
  font-weight: 600;

  color: ${theme.colors.darkBlue};
`

const ArrowContainer = styled.div`
  cursor: pointer;

  svg {
    fill: ${theme.colors.darkBlue};
    color: ${theme.colors.darkBlue};
    stroke: ${theme.colors.darkBlue};
  }
`

/* Items pertaining to the day of the week row */
const DayOfWeekBanner = styled.div`
  display: table-row;
  border-top: solid 1px ${theme.colors.gray40};
  border-bottom: solid 1px ${theme.colors.gray40};
`

const DayOfWeekItem = styled.div`
  display: table-cell;
  text-align: center;
  text-transform: capitalize;
  font-style: italic;
  padding: .25rem;
  width: 14.285%;

  font: ${theme.fonts.small};
  font-weight: 600;

  color: ${theme.colors.darkBlue};
`

/* items related to dates of the month */
const Calendar = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`

const CalendarRow = styled.div`
  display: table-row;
`

const CalendarItem = styled.div`
  display: table-cell;
  text-align: center;
  padding: .25rem;
  cursor: pointer;
  transition: ease-in .1s;

  font: ${theme.fonts.small};

  /* runs when date is the same month */
  ${props => props.sameMonth && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.darkBlue};

    &:hover {
      background-color: ${theme.colors.lightBlue};
      opacity: 0.5;
      color: ${theme.colors.white};
    }
  `};

  /* runs when date is in different month */
  ${props => props.diffMonth && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray40};

    &:hover {
      background-color: ${theme.colors.lightBlue};
      opacity: 0.5;
      color: ${theme.colors.white};
    }
  `};

  /* runs when calendar item is within "start date" & "end date" */
  ${props => props.selectedRange && css`
    background-color: ${theme.colors.gray30};
    color: ${theme.colors.darkBlue};

    &:hover {
      opacity: 0.8;
      color: ${theme.colors.white};
    }
  `};

  /* runs when calendar item is within "start date" and "end date" & in a different month*/
  ${props => props.selectedRange && props.diffMonth && css`
    background-color: ${theme.colors.gray20};
    color: ${theme.colors.gray70};

    &:hover {
      opacity: 0.8;
      color: ${theme.colors.white};
    }
  `};

  /* runs when calendar item is the selected date */
  ${props => props.selected && css`
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};

    &:hover {
      opacity: 0.8;
      color: ${theme.colors.white};
    }
  `};

  /* runs when date is in different month */
  ${props => props.empty && css`
    background-color: ${theme.colors.white};
  `};
`

const CALENDAR_TYPE = { START: 'start', END: 'end' }

class CalendarDatePicker extends React.Component {
  constructor (props) {
    super(props)
    const { startDate, endDate } = props

    // start and end date are cloned into the local state so the `CalendarDatePicker` can
    // modify a seperate date object independent of the date objects stored at the root level
    const previewStartDate = startOfDay(new Date(startDate))
    const previewEndDate = endOfDay(new Date(endDate))

    this.state = {
      previewStartDate,
      previewEndDate
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, _) {
    const prevProps = this.props
    if (prevProps.startDate !== nextProps.startDate) {
      this.setState({ previewStartDate: startOfDay(new Date(nextProps.startDate)) })
    }

    if (prevProps.endDate !== nextProps.endDate) {
      this.setState({ previewEndDate: endOfDay(new Date(nextProps.endDate)) })
    }
  }

  getPreviousMonth = (calendarType) => {
    const { previewStartDate, previewEndDate } = this.state

    if (calendarType === CALENDAR_TYPE.START) {
      const prevMonth = subMonths(previewStartDate, 1)
      this.setState({ previewStartDate: prevMonth })
    } else {
      if (previewStartDate.getMonth() !== previewEndDate.getMonth() ||
        previewStartDate.getFullYear() !== previewEndDate.getFullYear()) {
        const prevMonth = subMonths(previewEndDate, 1)
        this.setState({ previewEndDate: prevMonth })
      }
    }
  }

  getNextMonth = (calendarType) => {
    const { previewStartDate, previewEndDate } = this.state

    if (calendarType === CALENDAR_TYPE.START) {
      if (previewStartDate.getMonth() !== previewEndDate.getMonth() ||
        previewStartDate.getFullYear() !== previewEndDate.getFullYear()) {
        const nextMonth = startOfDay(addMonths(previewStartDate, 1))
        this.setState({ previewStartDate: nextMonth })
      }
    } else {
      const nextMonth = endOfDay(addMonths(previewEndDate, 1))
      this.setState({ previewEndDate: nextMonth })
    }
  }

  generateCalendar = (date, calendarType) => {
    const calendar = [
    // a calendar is an array full of date objects
    // the largest range a month can span is 6 weeks
    // su   mo    tu    we    th    fr    sa
      null, null, null, null, null, null, null, // 1st week
      null, null, null, null, null, null, null, // 2nd week
      null, null, null, null, null, null, null, // 3rd week
      null, null, null, null, null, null, null, // 4th week
      null, null, null, null, null, null, null, // 5th week
      null, null, null, null, null, null, null // 6th week
    ]

    // generate the current month's date objects in the correct position
    const first = dateHelpers.getFirstDayOfMonth(date)
    const firstIndex = first.getDay()
    const last = dateHelpers.getLastDayOfMonth(date)
    const lastIndex = last.getDate()
    for (let i = firstIndex, offset = 1; offset <= lastIndex; i++, offset++) {
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = offset
      calendar[i] = startOfDay(new Date(year, month, day))
    }

    const { previewStartDate, previewEndDate } = this.state
    const adjacentMonths = (startDate, endDate) => (startDate.getMonth() + 1) === endDate.getMonth() && startDate.getYear() === endDate.getYear()
    if (adjacentMonths(previewStartDate, previewEndDate)) {
      if (calendarType === CALENDAR_TYPE.START) {
        // generate left over dates previous to the beginning of the month
        for (let idx = 0; idx < firstIndex; idx++) {
          const offset = firstIndex - idx
          const ithDayBefore = startOfDay(subDays(first, offset))
          calendar[idx] = ithDayBefore
        }
      } else if (calendarType === CALENDAR_TYPE.END) {
        // generate left over dates after the end of the month
        for (let index = lastIndex + firstIndex, offset = 1; index < calendar.length; index++, offset++) {
          const ithDayAfter = startOfDay(addDays(last, offset))
          calendar[index] = ithDayAfter
        }
      }
    } else {
      // generate left over dates previous to the beginning of the month
      for (let idx = 0; idx < firstIndex; idx++) {
        const offset = firstIndex - idx
        const ithDayBefore = startOfDay(subDays(first, offset))
        calendar[idx] = ithDayBefore
      }

      // generate left over dates after the end of the month
      for (let index = lastIndex + firstIndex, offset = 1; index < calendar.length; index++, offset++) {
        const ithDayAfter = startOfDay(addDays(last, offset))
        calendar[index] = ithDayAfter
      }
    }

    return calendar
  }

  renderCalendar = (previewDate, calendarType) => {
    const calendarArray = this.generateCalendar(previewDate, calendarType)

    const renderMonthBanner = () => {
      const monthStr = previewDate.toLocaleDateString('default', { month: 'long' })
      const year = previewDate.getFullYear()
      return (
        <MonthBanner>
          <ArrowContainer onClick={(_) => this.getPreviousMonth(calendarType)}>
            <ArrowLeft />
          </ArrowContainer>

          <MonthLabel>{monthStr} {year}</MonthLabel>

          <ArrowContainer onClick={(_) => this.getNextMonth(calendarType)}>
            <ArrowRight />
          </ArrowContainer>
        </MonthBanner>
      )
    }

    const renderDaysOfWeekBanner = () => {
      return (
        <DayOfWeekBanner>
          <DayOfWeekItem>Su</DayOfWeekItem>
          <DayOfWeekItem>Mo</DayOfWeekItem>
          <DayOfWeekItem>Tu</DayOfWeekItem>
          <DayOfWeekItem>We</DayOfWeekItem>
          <DayOfWeekItem>Th</DayOfWeekItem>
          <DayOfWeekItem>Fr</DayOfWeekItem>
          <DayOfWeekItem>Sa</DayOfWeekItem>
        </DayOfWeekBanner>
      )
    }

    const renderCalendarItem = (date, calendarType, index) => {
      const { startDate, endDate, setDateRange } = this.props
      const { previewStartDate, previewEndDate } = this.state

      // helper functions to compare dates
      const isDifferentMonth = (date1, date2) => date1.getMonth() !== date2.getMonth()
      const isInDateRange = (date, start, end) => date > start && date < end
      const isSameMonth = (date1, date2) => date1.getMonth() === date2.getMonth()
      const isSameDate = (date1, date2) => {
        return !!(date1.getYear() === date2.getYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate())
      }

      if (calendarType === CALENDAR_TYPE.START && !!date) {
        return (
          <CalendarItem
            key={index} onClick={_ => setDateRange(date, previewEndDate)}
            /* These properties change the css and directly affect the `background color` and `text color` */
            selected={isSameDate(date, endDate) || isSameDate(date, startDate)} // checks if the date is the selected `start date` in the `start calendar`
            selectedRange={isInDateRange(date, startDate, endDate)} // checks if the date is within the range of `start date` & `end date`
            sameMonth={isSameMonth(date, previewStartDate)} // checks if the date is in the same month
            diffMonth={isDifferentMonth(date, previewStartDate)} // checks if the date is in a different month
          >
            {date.getDate()}
          </CalendarItem>
        )
      } else if (calendarType === CALENDAR_TYPE.END && !!date) {
        return (
          <CalendarItem
            key={index} onClick={_ => setDateRange(previewStartDate, date)}
            /* These properties change the css and directly affect the `background color` and `text color` */
            selected={isSameDate(date, startDate) || isSameDate(date, endDate)} // checks if the date is the selected `end date` in the `end calendar`
            selectedRange={isInDateRange(date, startDate, endDate)} // checks if the date is within the range of `start date` & `end date`
            sameMonth={isSameMonth(date, previewEndDate)} // checks if the date is in the same month
            diffMonth={isDifferentMonth(date, previewEndDate)} // checks if the date is in a different month
          >
            {date.getDate()}
          </CalendarItem>
        )
      } else if (!date) {
        return <CalendarItem key={index} empty> </CalendarItem>
      }
    }

    return (
      <CalendarContainer>
        {calendarType === CALENDAR_TYPE.START
          ? <CalendarSubjectLabel>Start Date</CalendarSubjectLabel>
          : <CalendarSubjectLabel>End Date</CalendarSubjectLabel>}
        {renderMonthBanner()}
        <Calendar>
          {renderDaysOfWeekBanner()}
          {calendarArray.map((_, index) => {
            if ((index + 1) % 7 === 0) {
              return (
                <CalendarRow key={(index + 1) / 7}>
                  {renderCalendarItem(calendarArray[index - 6], calendarType, index - 6)}
                  {renderCalendarItem(calendarArray[index - 5], calendarType, index - 5)}
                  {renderCalendarItem(calendarArray[index - 4], calendarType, index - 4)}
                  {renderCalendarItem(calendarArray[index - 3], calendarType, index - 3)}
                  {renderCalendarItem(calendarArray[index - 2], calendarType, index - 2)}
                  {renderCalendarItem(calendarArray[index - 1], calendarType, index - 1)}
                  {renderCalendarItem(calendarArray[index], calendarType, index)}
                </CalendarRow>
              )
            }
            return null
          })}
        </Calendar>

      </CalendarContainer>
    )
  }

  render () {
    const { previewStartDate, previewEndDate } = this.state
    return (
      <Wrapper>
        {this.renderCalendar(previewStartDate, CALENDAR_TYPE.START)}
        {this.renderCalendar(previewEndDate, CALENDAR_TYPE.END)}
      </Wrapper>
    )
  }
}

CalendarDatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDateRange: PropTypes.func
}

export default CalendarDatePicker

import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TOP_LEVEL_FIELD_OPTIONS } from 'containers/Integrations/constants'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Label from 'elements/Label'
import Title from 'elements/Title'
import DangerLabel from 'elements/DangerLabel'
import DropDown from 'components/DropDown'
import FieldDropdown from './FieldDropdown'
import theme from '../../../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 2rem;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const MappingRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px solid ${theme.colors.gray60};

  &:last-child {
    border-bottom: 0;
  }
`

const ColumnLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  overflow: hidden;
`

const FormTitle = styled(Label)`
  margin-right: 0.5rem;
  font: ${theme.fonts.button};
  color: ${props => props.required ? theme.colors.orange : theme.titleColor};
  text-transform: uppercase;
`

const DropDownHeader = styled.div`
  width: 50%;
  text-align: left;
`

const MappingDropdown = styled(DropDown)`
  width: 45%;
`

const FieldRows = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  border-bottom: 0.5px solid ${theme.colors.gray60};
`

class FieldMapping extends Component {
  constructor (props) {
    super(props)
    const state = this.getCrmState(props)
    this.state = state
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const state = this.getCrmState(nextProps)
    this.setState(state)
  }

  getCrmState = (props) => {
    const {
      crm,
      mapping
    } = props

    let selectedType = this.state ? this.state.selectedType : null

    if (!selectedType && mapping) {
      selectedType = Object.keys(mapping.toObject())[0]
    }
    if (!selectedType && crm.get('fields')) {
      selectedType = Object.keys(crm.get('fields').toObject())[0]
    }

    let cleanMapping = mapping || crm.get('default_mapping') || Immutable.Map({})

    if (mapping || crm.get('default_mapping')) {
      cleanMapping = cleanMapping.map((fieldMap, type) => {
        const crmFields = crm.getIn(['fields', type])

        if (!crmFields) {
          return Immutable.Map({})
        }

        const crmFieldNames = crmFields.map(field => field.get('name'))

        fieldMap.map((v, f) => {
          if (!crmFieldNames.includes(f)) {
            fieldMap = fieldMap.delete(f)
          }
          return fieldMap
        })

        return fieldMap
      })
    }

    return {
      selectedType,
      mapping: cleanMapping
    }
  }

  render () {
    const {
      crm,
      sequenceFields,
      isOpen,
      onChange,
      onCancel,
      onSave
    } = this.props

    const {
      selectedType,
      mapping
    } = this.state

    if (!selectedType || sequenceFields.get('loading')) {
      return (
        <div />
      )
    }

    const fields = crm.getIn(['fields', selectedType])
    const options = Immutable.List(TOP_LEVEL_FIELD_OPTIONS
      .concat(sequenceFields.get('data').map(field => ({ label: field, value: `$raw.${field}` })).toArray()))

    const typeOptions = Immutable.List(Object.keys(crm.get('fields').toObject()).map(key => ({ value: key, label: key })))

    const rows = fields.map((field) => {
      const name = field.get('name')
      const header = field.get('label')
      const required = field.get('required')
      const value = mapping ? mapping.getIn([selectedType, field.get('name')]) : null
      return (
        <MappingRow
          key={`column_${name}`}
        >
          <FieldDropdown
            options={options}
            value={value}
            clearable
            onOptionChange={(option) => {
              if (option) {
                const m = mapping.setIn([selectedType, field.get('name')], option.value)
                this.setState({
                  mapping: m
                })
                if (onChange) {
                  onChange(m)
                }
              } else {
                const m = mapping.deleteIn([selectedType, field.get('name')])
                this.setState({
                  mapping: m
                })
                if (onChange) {
                  onChange(m)
                }
              }
            }}
          />
          <ColumnLabel>
            <FormTitle
              title={name}
              required={required}
            >
              {header}
              {required && <span>&nbsp;(Required)</span>}
            </FormTitle>
          </ColumnLabel>
        </MappingRow>
      )
    })

    return (
      <Modal
        width='700px'
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper>
          <TitleContainer>
            <Title>
              Field Mapping
            </Title>
            <Label mt='0.25rem'>
              Choose how Interseller syncs data to {crm.get('name')} by mapping Interseller fields to {crm.get('name')} fields
            </Label>
            <DangerLabel mb='0'>
              Editing these values could cause integration errors with contacts. Edit these if you know exactly what you are doing. If you have questions, please contact our support team prior to editing.
            </DangerLabel>
          </TitleContainer>
          {typeOptions.count() > 1 &&
            <MappingDropdown
              height='44px'
              mr='auto'
              mb='1rem'
              label={`${crm.get('name')} Object`}
              options={typeOptions}
              value={selectedType}
              controlled
              clearable={false}
              onOptionChange={(option) => {
                this.setState({
                  selectedType: option.value
                })
              }}
            />}
          <MappingRow>
            <ColumnLabel>
              Interseller Field
            </ColumnLabel>
            <DropDownHeader>
              {`${crm.get('name')} Fields`}
            </DropDownHeader>
          </MappingRow>
          <FieldRows>{rows}</FieldRows>
          {onSave &&
            <Button
              full
              primary
              label='Save Customization'
              handleClick={() => {
                onSave(mapping)
              }}
              mt='1rem'
            />}
        </Wrapper>
      </Modal>
    )
  }
}

FieldMapping.propTypes = {
  sequenceFields: PropTypes.instanceOf(Immutable.Map),
  crm: PropTypes.instanceOf(Immutable.Map),
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default FieldMapping

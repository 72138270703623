import styled from 'styled-components'
import theme from '../../themes/light'

export default styled.ul`
  background: ${theme.menuBackground};
  padding: .5rem;
  margin: 0;
  z-index: 201;
  cursor: default;
  border-radius: 3px;

  a.active li {
    background: ${theme.menuActiveLinkBackground};
  }
`

/* eslint-disable no-unused-expressions */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Column,
  Table,
  AutoSizer,
  SortDirection,
  SortIndicator
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import theme from '../../../../themes/light'

const TableWrapper = styled.div``

const TableContainer = styled.div`
  height: 100%;
  padding-right: 1px;
  padding-bottom: 1px;

  .ReactVirtualized__Table { }

  .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1rem;
  }

  .ReactVirtualized__Table__headerColumn:last-of-type, .ReactVirtualized__Table__rowColumn:last-of-type {
    margin-right: 1rem;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: ${theme.colors.gray10};
    border: 1px solid ${theme.colors.gray30};
    color: ${theme.colors.darkBlue};
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    padding: .35rem 0rem;
    text-align: left;
    color: ${theme.colors.darkBlue};
    font: ${theme.fonts.normal};
    border-bottom: 1px solid ${theme.colors.gray30};

    &:last-child {
      border-bottom: 0;
    }

    span {
      cursor: pointer;
      transition: .15s ease;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .ReactVirtualized__Table__headerTruncatedText {
    display: block;
  }

  .ReactVirtualized__Table__Grid {
    outline: none;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray30};
    border-top: 0;
  }
`

class SpreadSheet extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      sortBy: 'date',
      sortDirection: SortDirection.ASC
    }
  }

  sort = ({ sortBy, sortDirection }) => {
    // - If the previously clicked column header is being sorted again,
    //   send back the reverse ordered list
    // - Otherwise, sort the newly clicked header in ascending order
    if (sortBy === this.state.sortBy) {
      const { sortDirection } = this.state
      const { tableData } = this.props
      const { tableRows } = tableData
      if (sortDirection === SortDirection.ASC) {
        this.setState({ tableRows: tableRows.reverse(), sortDirection: SortDirection.DESC })
      } else {
        this.setState({ tableRows: tableRows.reverse(), sortDirection: SortDirection.ASC })
      }
      return
    } else {
      this.setState({ sortDirection: SortDirection.ASC })
    }

    const { tableData } = this.props
    let { tableRows } = tableData
    if (sortBy) {
      const updatedTableRows = tableRows.sort((a, b) => {
        if (sortBy === 'date') {
          a = new Date(a.unformattedDate)
          b = new Date(b.unformattedDate)
        } else if (typeof a[sortBy] === 'string') { // ignore case if "string"
          a = a[sortBy].toLowerCase()
          b = b[sortBy].toLowerCase()
        } else {
          a = a[sortBy]
          b = b[sortBy]
        }

        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        } else { // otherwise values must be equal
          return 0
        }
      });

      (sortDirection === SortDirection.DESC)
        ? updatedTableRows.reverse()
        : updatedTableRows

      // store the sorted columns and which column the table was sorted by
      this.setState({ sortBy })
      tableRows = updatedTableRows
    }
  }

  renderSequenceColumn = (column, columnSize, index) => {
    const cellRenderer = ({ dataKey, rowData }) => {
      const { router } = this.props
      return (
        <span
          title='View Sequence'
          onClick={() => {
            const { sequenceID } = rowData
            const route = `/sequence/${sequenceID}/contacts`
            router.push(route)
          }}
        >
          {rowData[dataKey]}
        </span>
      )
    }

    return (
      <Column
        key={index}
        label={column}
        dataKey={column}
        width={columnSize}
        cellRenderer={cellRenderer}
        headerRenderer={this.headerRenderer}
      />
    )
  }

  renderContactColumn = (column, columnSize, index) => {
    const cellRenderer = ({ dataKey, rowData }) => {
      const { router } = this.props
      const { deleted } = rowData
      return (
        <>
          {deleted &&
            <span
              title='Contact Deleted or Moved from Sequence'
              style={{ textDecoration: 'none', cursor: 'default' }}
            >
              {`${rowData[dataKey]} (deleted)`}
            </span>}
          {!deleted &&
            <span
              title='View Contact in Sequence'
              onClick={() => {
                const { sequenceID, contactID } = rowData
                const route = `/sequence/${sequenceID}/contacts/${contactID}/detail`
                router.push(route)
              }}
            >
              {rowData[dataKey]}
            </span>}
        </>
      )
    }

    return (
      <Column
        key={index}
        label={column}
        dataKey={column}
        width={columnSize}
        cellRenderer={cellRenderer}
        headerRenderer={this.headerRenderer}
      />
    )
  }

  renderDefaultColumn = (column, columnSize, index) => {
    return (
      <Column
        key={index}
        label={column}
        dataKey={column}
        width={columnSize}
        headerRenderer={this.headerRenderer}
      />
    )
  }

  renderEmptySpreadSheet = () => {
    return (<></>)
  }

  headerRenderer = ({ dataKey, sortBy, sortDirection }) => {
    return (
      <div>
        {// title of header
          dataKey
        }

        {// indicator displaying which way a column is sorted
          sortBy === dataKey &&
            <SortIndicator sortDirection={sortDirection} />
        }
      </div>
    )
  }

  renderSpreadSheet = () => {
    const { sortBy, sortDirection } = this.state
    const { tableData } = this.props
    const { tableColumns, tableRows } = tableData
    let { columnWidths } = tableData

    if (!columnWidths) {
      columnWidths = tableColumns.map((column, index) => {
        if (tableColumns.length === index + 1) {
          return 'flex'
        }

        return 'min'
      })
    }

    let columnWidthSum = 0
    let columnHasFlex = false
    columnWidths = columnWidths.map(columnWidth => {
      if (columnWidth === 'min') {
        columnWidth = 240
      }

      if (Number.isInteger(columnWidth)) {
        columnWidthSum = columnWidthSum + columnWidth
      }

      if (columnWidth === 'flex') {
        columnHasFlex = true
      }

      return columnWidth
    })

    return (
      <TableWrapper>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  width={width}
                  height={Math.min(36 * (tableRows.length + 1), height)}
                  headerHeight={36}
                  rowHeight={36}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  {tableColumns.map((column, index) => {
                  // default column size
                    const columnWidth = columnWidths[index]

                    let columnSize = Math.min(columnWidth, width / tableColumns.length)
                    if (!columnHasFlex) {
                      columnSize = width / tableColumns.length
                    }
                    if (columnWidth === 'flex') {
                      columnSize = Math.max(width - columnWidthSum, width / tableColumns.length)
                    }
                    if (columnSize < 0) {
                      columnSize = 0
                    }

                    switch (column) {
                      case 'sequence':
                        return this.renderSequenceColumn(column, columnSize, index)
                      case 'name':
                        return this.renderContactColumn(column, columnSize, index)
                      default:
                        return this.renderDefaultColumn(column, columnSize, index)
                    }
                  })}
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </TableWrapper>
    )
  }

  render () {
    const { tableData } = this.props
    if (tableData) {
      const { tableColumns, tableRows } = tableData
      if (!!tableColumns && !!tableRows) {
        return this.renderSpreadSheet()
      }
    }

    return this.renderEmptySpreadSheet()
  }
}

SpreadSheet.propTypes = {
  router: PropTypes.object,
  tableData: PropTypes.object
}

export default SpreadSheet

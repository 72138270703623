import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { isToday } from 'date-fns'
import Label from 'elements/Label'
import { stripHtml, truncate, timeInFutureShort } from 'utils/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../../../../themes/light'

const Wrapper = styled.li`
  font: ${theme.fonts.normal};
  padding: ${theme.padding};
  background: ${theme.colors.white};
  width: 100%;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
`

const Container = styled.div`
  border-left: 2px dashed ${theme.borderColor};
  margin-top: -17px;
  margin-left: -3px;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const ActivityTitle = styled.h3`
  flex: 0;
  font: ${theme.fonts.label};
  color: ${theme.colors.gray60};
  text-align: left;
  text-transform: uppercase;
  margin: 0;
`

const DateLabel = styled.span`
  font: ${theme.fonts.label};
  color: ${theme.labelColor};
  text-align: right;
  text-transform: uppercase;
  margin: 0;
`

const IconContainer = styled.div`
  position: absolute;
  background-color: ${theme.colors.gray60};
  left: 8.5px;
  top: -5px;
  width: 27px;
  height: 27px;
  border-radius: 9999px;
  border: solid 1px white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BodyContainer = styled.div`
  background-color: ${theme.colors.gray10};
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  margin-top: 0.5rem;
  line-height: 0.8;
`

const BodyContainerYellow = styled(BodyContainer)`
  background-color: ${theme.colors.lightYellow};
`

const BodyHeader = styled(Label)`
  font: ${theme.fonts.button};
  color: ${theme.titleColor};
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  text-align: left;
`

const BodyLabel = styled(Label)`
  font: ${theme.fonts.xsmall};
  color: ${theme.titleColor};
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const DoubleBodyLabel = styled(BodyLabel)`
  margin-top: 0;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

class MessageItem extends PureComponent {
  bodyForStep = (step) => {
    switch (step.get('type')) {
      case 'message': {
        const message = step.get('object')
        const subject = message.get('subject')
        let body = message.get('markdown')
        body = stripHtml(body)
        body = truncate(body, 65)
        return (
          <BodyContainer>
            {subject && <BodyHeader>{subject}</BodyHeader>}
            <BodyLabel>{body}</BodyLabel>
          </BodyContainer>
        )
      }
      case 'task': {
        const task = step.get('object')
        let body = task.get('markdown')
        body = stripHtml(body)
        body = truncate(body, 130)
        return (
          <BodyContainerYellow>
            <DoubleBodyLabel>{body}</DoubleBodyLabel>
          </BodyContainerYellow>
        )
      }
      default:
        return null
    }
  }

  dateForStep = (step) => {
    const scheduledAt = step.get('scheduled_for')
    if (scheduledAt) {
      return timeInFutureShort(scheduledAt)
    }
    return 'If no reply'
  }

  getDateLabel = (step) => {
    const date = step.get('scheduled_for')

    let time = 'If no reply'
    if (date) {
      time = timeInFutureShort(date)
    }

    if (date && isToday(date) && step.get('type') === 'message') {
      return (
        <Tooltip
          title='This is only an approximation on when this message will send and can be affected by the volume of emails in the queue'
          position='top'
          trigger='mouseenter'
          arrow
        >
          <DateLabel>
            {time}
            <FontAwesomeIcon
              icon={['fas', 'info-circle']}
              transform={{
                size: 14,
                x: 3,
                y: 1
              }}
            />
          </DateLabel>
        </Tooltip>
      )
    }

    return (<DateLabel>{time}</DateLabel>)
  }

  render () {
    const {
      step,
      onClick
    } = this.props

    const body = this.bodyForStep(step)
    const type = step.get('type')

    return (
      <Wrapper onClick={onClick}>
        <IconContainer>
          <FontAwesomeIcon
            icon={['far', 'clock']}
            color='#FFF'
            transform={{
              size: 14
            }}
          />
        </IconContainer>
        <Container>
          <HeaderWrapper>
            <ActivityTitle>Scheduled {type}</ActivityTitle>
            {this.getDateLabel(step)}
          </HeaderWrapper>
          {body && body}
        </Container>
      </Wrapper>
    )
  }
}

MessageItem.propTypes = {
  step: PropTypes.object,
  onClick: PropTypes.func
}

export default MessageItem

/* eslint-disable */
import { createGlobalStyle } from 'styled-components';
import theme from 'themes/light'

const GlobalStyles = createGlobalStyle`
  html {
    overflow: auto;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    -webkit-app-region: drag;
  }
  body {
    font-family: soleil, sans-serif;
  }
  #root {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  p,
  label {
    font-family: soleil, sans-serif;
    line-height: 1.5em;
  }
  a {
    color: #5469b2;
  }

  /* Used by custom slider tooltip */

  .rc-slider-tooltip-inner {
    font: ${theme.fonts.normal};
    color: white;
    background-color: #333333;
    height: fit-content;
    max-width: 240px;

    border-radius: 4px;
    padding: .4rem .8rem;
    text-align: center;
  }

  .rc-slider-tooltip-arrow {
    border-top-color: #333333 !important;
  }

  .hero-bear {
    position: relative;
    -webkit-animation: floatBear 6s infinite normal;
    animation: floatBear 6s infinite normal;
  }

  @-webkit-keyframes floatBear {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(1rem);
              transform: translateY(1rem);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floatBear {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(1rem);
              transform: translateY(1rem);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  /* Used by custom slider tooltip */
  .popper {
    z-index: 100;
  }

  .popper .popper__arrow {
    display: none;
  }
`;

export default GlobalStyles

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/Modal'
import DropDown from 'components/DropDown'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  width: 42rem;
  height: fit-content;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

function AddToSequence ({
  actions,
  isOpen = false,
  onCancel,
  sequences,
  onConfirm,
  description
}) {
  const [sequence, setSequence] = useState(null)

  useEffect(() => {
    actions.fetchSequences()
  }, [])

  const options = sequences.map((s, i) => (
    {
      label: s.get('title'),
      value: s.get('_id'),
      index: i,
      sequence: s
    }
  ))

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onCancel}
    >
      <Wrapper>
        <InputContainer>
          <Title align='center'>Add To Sequence</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            {description}
          </Label>
          {options.count() > 0 &&
            <DropDown
              placeholder='Select or search sequence'
              inputId='sequence'
              onOptionChange={(option) => {
                setSequence(option ? option.sequence : null)
              }}
              options={options}
              mt='1.5rem'
              autoFocus
            />}
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={onCancel}
            mr='0.5rem'
          />
          <Button
            primary
            disabled={sequence === null}
            label='confirm'
            onClick={() => {
              onConfirm(sequence)
            }}
          />
        </ButtonContainer>
      </Wrapper>
    </Modal>
  )
}

AddToSequence.propTypes = {
  actions: PropTypes.object,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  sequences: PropTypes.object,
  onConfirm: PropTypes.func,
  description: PropTypes.string
}

export default AddToSequence

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { Tooltip } from 'react-tippy'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : theme.background)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => (props.p ? props.p : '0.125rem 0.4rem')};
  margin: auto 0;
  height: fit-content;
  width: fit-content;
  border: ${props => (props.border ? props.border : 0)};
  border-style: solid;
  border-color: ${props => (props.color ? props.color : theme.labelColor)}
  ${props => (props.minWidth ? `min-width: ${props.minWidth}` : '')};

  ${space}
  ${width}
  ${textAlign}
`

const BadgeText = styled.span`
  color: ${props => (props.color ? props.color : theme.labelColor)};
  font: ${theme.fonts.button};
  text-align: center;
  margin: auto;
  line-height: 21px;
`

const Badge = ({
  children,
  color,
  backgroundColor,
  tooltip,
  ...rest
}) => {
  const content = (
    <Wrapper
      backgroundColor={backgroundColor}
      color={color}
      {...rest}
    >
      <BadgeText color={color}>
        {children}
      </BadgeText>
    </Wrapper>
  )

  if (tooltip) {
    return (
      <Tooltip
        // options
        title={tooltip}
        position='top'
        trigger='mouseenter'
        arrow
      >
        {content}
      </Tooltip>
    )
  }
  return content
}

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  tooltip: PropTypes.string
}

export default Badge

/**
*
* ZapTemplate
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 1rem 0;
  padding: 1rem;

  border: solid 1px ${theme.borderColor};
  border-radius: 3px;

  cursor: pointer;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font: ${theme.fonts.xsmall};
  margin: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.borderColor};
  padding: 8px;
  border-radius: 3px;
`

const AppIcon = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-size: 100%;
  width: 32px;
  height: 32px;
`

const IconContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`

const DescriptionContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

class ZapTemplate extends PureComponent {
  render () {
    const { zap } = this.props

    const firstApp = zap.getIn(['steps', 0])
    const secondApp = zap.getIn(['steps', 1])

    return (
      <Wrapper onClick={() => {
        window.open(zap.get('url'))
      }}
      >
        <IconContainer>
          <IconWrapper>
            <AppIcon src={firstApp.getIn(['images', 'url_64x64'])} />
          </IconWrapper>
          <span>→</span>
          <IconWrapper>
            <AppIcon src={secondApp.getIn(['images', 'url_64x64'])} />
          </IconWrapper>
        </IconContainer>
        <DescriptionContainer>
          <Label>
            {zap.get('title')}
          </Label>
        </DescriptionContainer>
      </Wrapper>
    )
  }
}

ZapTemplate.propTypes = {
  zap: PropTypes.object
}

export default ZapTemplate

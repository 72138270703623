import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import { isAfter, format, isSameDay, subDays, parse } from 'date-fns'
import { getTimezoneNames } from 'utils/dates'
import Button from 'components/Button'
import DropDownFieldForm from 'components/DropDownFieldForm'
import ScheduleFieldForm from 'components/ScheduleFieldForm'
import SliderFieldForm from 'components/SliderFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import DateField from 'components/DateField'
import Subtitle from 'elements/Subtitle'
import Bin from 'svg/Bin'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const InnerContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const Label = styled.h3`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.p`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  margin: 0;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Holidays = styled.div`
  padding-left: 74px;
  padding-top: 10px;
`

const Holiday = styled.div`
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  letter-spacing: 0.05em;
`

const CustomHoliday = styled.div`
  color: ${theme.labelColor};
  font: ${theme.fonts.normal};

  border: 1px solid ${theme.colors.gray30};
  padding: 1rem 1.25rem;
  margin-top: 1rem;

  display: flex;
  justify-content: space-between;
`

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BinContainer = styled.div`
  margin-top: -3px;
  opacity: 1;
  cursor: pointer;

  svg {
    g {
      stroke: #ff6666;
    }
  }

  &:hover {
    opacity: .5;
    transition: opacity .15s ease-out;
  }

  &:active {
    opacity: .8
    transition: opacity .15s east-out;
    cursor: pointer;
  }
`

class Schedule extends Component {
  constructor (props) {
    super(props)
    const {
      session
    } = this.props

    const limitDay = session.get('limit_day')
    const limitFrequency = session.get('limit_frequency')
    this.state = {
      limitDay,
      limitFrequency
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      session
    } = newProps

    const limitDay = session.get('limit_day')
    const limitFrequency = session.get('limit_frequency')

    this.setState({
      limitDay,
      limitFrequency
    })
  }

  render () {
    const {
      session,
      onSettingUpdate,
      config
    } = this.props

    const {
      limitDay,
      limitFrequency
    } = this.state

    const timezone = session.get('timezone')
    const timezones = Immutable.List(getTimezoneNames())
    const schedule = session.get('schedule')
    const excludeHolidays = session.get('exclude_holidays')
    const customHolidays = session.get('custom_holidays')

    let emailDayTrackColor = theme.primaryColor
    let emailHourTrackColor = theme.primaryColor

    if (limitDay > 401) {
      emailDayTrackColor = theme.colors.red
    } else if (limitDay > 201) {
      emailDayTrackColor = theme.colors.yellow
    } else {
      emailDayTrackColor = theme.colors.green
    }

    if (limitFrequency <= 30) {
      emailHourTrackColor = theme.colors.red
    } else if (limitFrequency < 60) {
      emailHourTrackColor = theme.colors.yellow
    } else {
      emailHourTrackColor = theme.colors.green
    }

    const marks = {
      30: '',
      60: '',
      100: '',
      150: '',
      300: ''
    }

    const holidays = config.getIn(['data', 'holidays']) || Immutable.List([])

    const upcomingHolidays = holidays.filter(h => {
      const date = parse(h.get('date'))
      const now = new Date()
      return isSameDay(date, now) || isAfter(date, now)
    })

    const holidayRows = upcomingHolidays.map(h => {
      return (
        <Holiday key={h.get('name')}>
          {h.get('name')} &mdash; {format(h.get('date'), 'dddd, MMMM D, YYYY')}
        </Holiday>
      )
    })

    const upcomingCustomHolidays = customHolidays
      .filter(d => {
        const date = parse(d)
        const now = new Date()
        return isSameDay(date, now) || isAfter(date, now)
      })
      .sortBy(date => date)

    const customHolidayRows = upcomingCustomHolidays.map(date => {
      return (
        <CustomHoliday key={format(date, 'YYYY-MM-DD')}>
          {format(date, 'dddd, MMMM D, YYYY')}
          <BinContainer>
            <Bin
              onClick={() => {
                onSettingUpdate({
                  custom_holidays: customHolidays.filterNot(d => d === date)
                })
              }}
            />
          </BinContainer>
        </CustomHoliday>
      )
    })

    return (
      <Wrapper>
        <Subtitle>Schedule</Subtitle>
        <FieldContainer>
          <Label>Volume</Label>
          <Description>Set the frequency of emails to be sent during the schedule</Description>
          <InnerContainer>
            <SliderFieldForm
              label='Maximum emails to send per day'
              value={limitDay}
              inputId='limit_day'
              min={20}
              max={1000}
              step={20}
              trackColor={emailDayTrackColor}
              tipFormatter={(value) => {
                if (value > 1000) {
                  return 'This is an excessive amount and may result in being reported as spam'
                } else if (value > 500) {
                  return 'This is a reasonable amount only if you have reply rates above 10%'
                }
                return 'This is a safe frequency'
              }}
              onSliderChange={(value) => {
                this.setState({
                  limitDay: value
                })
              }}
            />
          </InnerContainer>
          <InnerContainer>
            <SliderFieldForm
              label='Seconds to wait between each email'
              value={limitFrequency}
              inputId='limit_frequency'
              min={10}
              max={300}
              step={null}
              marks={marks}
              trackColor={emailHourTrackColor}
              tipFormatter={(value) => {
                if (value <= 30) {
                  return 'This should only be used if you have reply rates over 10%'
                }

                return 'This is a safe frequency'
              }}
              onSliderChange={(value) => {
                this.setState({
                  limitFrequency: value
                })
              }}
            />
          </InnerContainer>
          <Button
            full
            primary
            label='Save'
            handleClick={() => {
              onSettingUpdate({
                limit_day: this.state.limitDay,
                limit_frequency: this.state.limitFrequency
              })
            }}
            mt='1rem'
          />
        </FieldContainer>
        <FieldContainer>
          <DropDownFieldForm
            label='Timezone'
            description='Set the default timezone for your schedule'
            options={timezones}
            value={timezone}
            searchable
            inputId='timezone'
            onSave={(value) => {
              onSettingUpdate({ timezone: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <ScheduleFieldForm
            label='Schedule'
            description='Set when your emails get sent out'
            schedule={schedule}
            onSave={(value) => {
              onSettingUpdate({ schedule: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Exclude U.S. Holidays'
            description='Prevent messages from being sent during U.S. public holidays'
            inputId='exclude_holidays'
            value={excludeHolidays}
            onSave={(value) => {
              onSettingUpdate({ exclude_holidays: value })
            }}
          />
          {holidayRows.count() > 0 &&
            <Holidays>
              {holidayRows}
            </Holidays>}
        </FieldContainer>
        <FieldContainer>
          <Label>Custom Holidays</Label>
          <Description>Choose your own custom dates to prevent messages from being sent out</Description>
          <FlexWrapper>
            <DateField
              flex='1'
              placeholder='Select a date'
              inputId='selected_holiday_date'
              isValidDate={current => (current.isAfter(subDays(new Date(), 1)))}
              onValueChange={(value) => {
                this.setState({
                  selected_holiday_date: value
                })
              }}
              value={this.state.selected_holiday_date ? new Date(this.state.selected_holiday_date) : ''}
              dateFormat='dddd, MMMM D, YYYY'
              timeFormat={false}
              small
            />
            <Button
              primary
              label='Add Date'
              disabled={!this.state.selected_holiday_date}
              handleClick={() => {
                const date = format(this.state.selected_holiday_date, 'YYYY-MM-DD')

                onSettingUpdate({
                  custom_holidays: customHolidays.push(date)
                })
                this.setState({
                  selected_holiday_date: null
                })
              }}
              ml='1rem'
            />
          </FlexWrapper>
          <>
            {customHolidayRows}
          </>
        </FieldContainer>
      </Wrapper>
    )
  }
}

Schedule.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func,
  config: PropTypes.any
}

export default Schedule

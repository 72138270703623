import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Cross, Search } from 'svg'
import { fadeInRule } from 'utils/animations'
import theme from '../../../../../../themes/light'

const InputContainer = styled.div`
  display: flex;
  border: 1.5px solid ${theme.colors.gray60};
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  margin: 0 1rem;

  &:focus {
    border: 5px solid ${theme.colors.blue};
    box-shadow: 0 0 4px ${theme.colors.lightBlue};
  }

  animation: ${fadeInRule};
`

const SearchBox = styled.input`
  outline: none;
  border: none;

  color: ${theme.colors.darkBlue};
  background-color: ${theme.colors.white};

  font: ${theme.fonts.normal};

  padding: .75rem 0.5rem;
  height: 30px;
  width: 100%;

  ::placeholder {
    color: #aaa;
  }
`

const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 1rem;

  width: 100%;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;
  /* padding: .4rem .6rem; */
  padding: .4rem 1rem;
  margin-bottom: .5rem;

  border-radius: 50px;
  border: 1px solid #588BE2;
  background-color: #E9F1FF;
  font: ${theme.fonts.label};
  color: ${theme.colors.darkBlue};
`

const SelectedItemCloseContainer = styled.div`
  cursor: pointer;
  padding-left: .55rem;

  svg {
    position: relative;
    top: -1px;
    width: 7px;
    height: 7px;
    stroke: ${theme.colors.gray50};
    fill: ${theme.colors.gray50};
    margin: auto;
  }
`

const SelectedItemText = styled.div`
  flex: 1;
`

function FilterDropdown ({
  field,
  list,
  onSelected,
  selectedFilters,
  placeholder,
  showPanel = false
}) {
  const searchBoxRef = useRef()

  const [value, setValue] = useState('')
  // const [showSuggestions, setShowSuggestions] = useState(false)
  // const [suggestionBoxPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (searchBoxRef?.current) {
      searchBoxRef.current.focus()
    }
  }, [showPanel])

  const onFocusSearchBox = e => {
    // setShowSuggestions(true)
    // calculateSearchBoxPosition()
  }

  // const handleSelectSuggestion = (suggestion) => {
  //   const newVal = selectedFilters.add(suggestion?.value)
  //   onSelected(newVal)
  //   setShowSuggestions(false)
  // }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const onRemoveFilter = removeVal => {
    const newSelectedFilters = selectedFilters.filterNot(x => x === removeVal)

    onSelected(newSelectedFilters)
  }

  const renderSelectedFilters = () => {
    return selectedFilters.map(m => {
      return (
        <SelectedItem key={m}>
          <SelectedItemText>{m}</SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => onRemoveFilter(m)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      const newVal = selectedFilters.add(e.target.value)
      onSelected(newVal)
      setValue('')
    }
  }

  // const renderSuggestions = () => {
  //   const people = list.getIn(['data', 'people'])
  //   const suggestions = people
  //     ?.map(person => person.get(field))
  //     ?.toSet()
  //     .map(title => {
  //       const count = people.filter(person => person.get(field) === title).size
  //       return {
  //         value: title,
  //         count
  //       }
  //     })
  //     .sort((a, b) => b.count - a.count)

  //   if (!value) {
  //     return suggestions
  //       .map(m => {
  //         let res
  //         if (m?.value && !selectedFilters.includes(m?.value)) {
  //           res = (
  //             <Suggestion
  //               key={m.value}
  //               onClick={() => handleSelectSuggestion(m)}
  //             >
  //               <SuggestionLabel>
  //                 {m.value}
  //                 <SuggestionCount>{m.count}</SuggestionCount>
  //               </SuggestionLabel>
  //             </Suggestion>
  //           )
  //         }
  //         return res
  //       })
  //   } else {
  //     return suggestions
  //       .map(m => {
  //         let res
  //         if (m?.value?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
  //           !selectedFilters.includes(m)
  //         ) {
  //           res = (
  //             <Suggestion
  //               key={m.value}
  //               onClick={() => handleSelectSuggestion(m)}
  //             >
  //               <SuggestionLabel>
  //                 {m.value}
  //                 <SuggestionCount>{m.count}</SuggestionCount>
  //               </SuggestionLabel>
  //             </Suggestion>
  //           )
  //         }
  //         return res
  //       })
  //   }
  // }

  return (
    <>
      <SelectedContainer>
        {renderSelectedFilters()}
      </SelectedContainer>
      {showPanel &&
        <InputContainer>
          <Search />
          <SearchBox
            onChange={handleChange}
            onFocus={onFocusSearchBox}
            value={value}
            ref={searchBoxRef}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
          />
        </InputContainer>}
      {/* {showSuggestions &&
        <DropDownContainer position={suggestionBoxPosition}>
          <SuggestionBox>{renderSuggestions()}</SuggestionBox>
        </DropDownContainer>} */}
    </>
  )
}

FilterDropdown.propTypes = {
  field: PropTypes.string,
  masterList: PropTypes.array,
  list: PropTypes.object,
  onSelected: PropTypes.func,
  selectedFilters: PropTypes.object,
  placeholder: PropTypes.string,
  showPanel: PropTypes.bool
}

export default FilterDropdown

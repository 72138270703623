import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Label from 'elements/Label'
import withSession from 'hocs/session'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  padding: ${theme.padding};
`

const FieldLabel = styled.div`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const FieldContainer = styled.div`
  padding: 1rem;
  text-align: left;
`

class Archive extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      sequence,
      onArchive
    } = this.props

    const title = sequence.get('title')

    return (
      <Wrapper>
        <FieldContainer>
          <FieldLabel>Archive</FieldLabel>
          <Label>
            Archive your sequence, <strong>{title}</strong>,
            to remove it from your active sequence list.
            Archiving your sequence will stop your sequence
            from sending messages and be available for for
            deduplication, tracking, and reporting purposes.
            You can recover your sequence from your archives
            at any time.
          </Label>
        </FieldContainer>
        <FieldContainer>
          <Button
            label='archive'
            onClick={onArchive}
            width='11.5rem'
            mr='auto'
            mb='1rem'
            destructive
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

Archive.propTypes = {
  sequence: PropTypes.object,
  onArchive: PropTypes.func
}

export default withSession(Archive)

import { SLATE_EDITORS } from '../slate/constants'
import sanitizeHtml from 'sanitize-html'

// this will take break tags that are inside paragraph tags and
// parse them into new lines, here's an example:
// input: <p>line 1<br>line 2<br><br>line 3</p>
// output: <p>line 1</p><p>line2</p><p></p><p>line 3</p>
function _parseBreaksInTags (html) {
  return html
    .replace(/<p>(?:.|\n)*?<\/p>/ig, function (match) {
      return match.replace(/(<br\s?\/?>\n?)/ig, '</p><p>')
    })
    .replace(/<li>(?:.|\n)*?<\/li>/ig, function (match) {
      return match.replace(/(<br\s?\/?>\n?)/ig, ' ')
    })
}

/**
 * Re-inserts <span> tags around custom fields and emojis
 * @param {body & subject of an email as html} html
 */
function _parseInlinesToHtml (editor, html, wrapEmojis) {
  // return if html is null or undefined -- idk why it is sometimes undefined
  // this hack currently fixes all know bugs
  // FUTURE TODO: figure out why `html` is undefined when loading an A/B test
  if (!html || html.length === 0) {
    return '<p></p>'
  }

  // ensure there is only 1 surrounding paragraph tag in the subject
  if (editor === SLATE_EDITORS.SUBJECT && html) {
    // remove all <br> tags from editor on load
    const brRegex = /<br\s?\/?>/ig
    html = html.replace(brRegex, '')

    // remove all non-outermost paragraph tags
    const pRegex = /<p>|<\/p>/ig
    html = html.replace(pRegex, '')
    html = `<p>${html}</p>`

    // replace all new-lines with spaces
    html = html.replace('\n', ' ')
  }

  /*
   * - need to manually replace <em> tags inside custom fields.
   * - <em> tags can sometimes show up in custom fields due to the HTML
   *   converter thinking that the underscores inside a custom field are italics.
   *
   * - EX:  improper custom field conversion: {{custom<em>field</em>1}}
   *        corrected custom field conversion: {{custom_field_1}}
   */
  const tagIdxs = []
  let i = 0
  while (true) {
    const openIdx = html.indexOf('{{', i)
    i = openIdx + 1

    const closeIdx = html.indexOf('}}', i)
    i = closeIdx + 1

    // `indexOf()` returns -1 if there is no instance of a substring
    // if either openIdx or close Idx is -1, there isn't a matching pair of `{{` & `}}`
    if (openIdx === -1 || closeIdx === -1) {
      break
    } else {
      tagIdxs.push([openIdx, closeIdx])
    }
  }

  for (let i = 0; i < tagIdxs.length; i++) {
    const pair = tagIdxs[i]
    const openIdx = pair[0]
    const closeIdx = pair[1]

    const customField = html.substring(openIdx, closeIdx)
    const emRegex = /<em>|<\/em>/gi
    const result = customField.replace(emRegex, '_')
    html = html.substring(0, openIdx) + result + html.substring(closeIdx, html.length)
  }

  // regex to parse emojis
  if (wrapEmojis) {
    const emojiRegex = /((?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]))/g;  // eslint-disable-line
    html = html.replace(emojiRegex, '<span code="$1"></span>') // slate serializer will inject the code attr into the span tag
  }

  return html
}

function _wrapHtml (text) {
  if (!text) {
    return text
  }

  // This will wrap all HTML code with code blocks so that
  // Slate does not convert this into it's own elements
  const regex = /<(?:\/?(div|table|span|p|ul|ol|li))(?:[^>]+id='([a-zA-Z-]*)')?[^>]*>/ig
  let ptr = 0
  let current = null
  let count = 0
  const total = (text.match(regex) || []).length
  text = text.replace(regex, (match, tag, id) => {
    // NOTE: when div tag has `id='signature'`, that div tag is treated as the email step's signature
    // in this case, we do nothing and return the original string
    if (tag === 'div' && id === 'signature') {
      return match
    }

    const isEnd = tag.startsWith('/')
    count = count + 1

    if (!current && !isEnd) {
      current = tag
      ptr = ptr + 1
      return `<code>${match}`
    }

    if (isEnd) {
      ptr = ptr - 1
    } else {
      ptr = ptr + 1
    }

    if (!ptr) {
      current = null
      return `${match}</code>`
    }

    if (count === total && ptr) {
      current = null
      return `${match}</code>`
    }

    return match
  })

  // this helps compact new lines in code blocks so that showdown
  // doesn't compile this as separate lines
  text = text.replace(/<code>(\n|.)*?<\/code>/ig, function (match) {
    return match.replace(/(.)\n(.)/ig, function (m, prevChar, nextChar) {
      let separator = ' '
      if (prevChar === '>' && nextChar === '<') {
        separator = ''
      }

      return `${prevChar}${separator}${nextChar}`
    })
  })

  return text
}

const sanitize = function (html) {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    // allowedTags: false,
    allowedAttributes: {
      '*': ['id', 'title', 'style', 'width', 'height'],
      a: ['href'],
      img: ['src', 'hspace', 'alt', 'align'],
      table: ['border', 'cellspacing', 'cellpadding'],
      td: ['colspan', 'rowspan', 'valign', 'align'],
      div: ['dir', 'class'],
      font: ['color', 'size', 'face'],
      br: ['clear']
    },
    allowedClasses: {
      div: ['gmail_signature']
    },
    allowedSchemes: ['http', 'https', 'mailto', 'tel']
  })
}

export {
  _parseBreaksInTags,
  _parseInlinesToHtml,
  _wrapHtml,
  sanitize
}

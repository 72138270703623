import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { Tooltip } from 'react-tippy'
import theme from '../../../../../themes/light'

// Extended the button to show a toast message after clicking
const ToastButton = styled(Button)`
  &:hover {
    opacity: .8;
  }

  &:focus {
    opacity: 1;
  }
`

const OverlayMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${theme.colors.lightYellow};

  padding: 0 1rem 1rem 1rem;

  >* {
    margin-left: 0.5rem;
  }
`

function OverlayMenu (props) {
  const {
    linkedInProfileUrl,
    showCopyToClipboard,
    copyToClipboard
  } = props

  const [isToastMessageActive, setIsToastMessageActive] = useState(false)

  return (
    <OverlayMenuWrapper>
      {showCopyToClipboard && !linkedInProfileUrl &&
        <ToastButton
          label={isToastMessageActive ? 'Copied!' : 'Copy to Clipboard'}
          primary={false}
          small
          handleClick={(event) => {
            // NOTE: events in react are synthetic unless told to persist
            //       this line is needed in order for event to stay alive while inside promise
            // NOTE: the `;` is required before calling self-invoking function
            event.persist();

            (async (event) => {
              await setIsToastMessageActive(true)
              await copyToClipboard()
              await window.setTimeout(() => {
                event.target.blur()
                setIsToastMessageActive(false)
              }, 2000)
            })(event)
          }}
        />}

      {showCopyToClipboard && linkedInProfileUrl &&
        <>
          <Tooltip
            title={'This will copy the content of this step to your clipboard and open the contact\'s LinkedIn profile'}
            position='top'
            trigger='mouseenter'
            disabled={isToastMessageActive}
            arrow
          >
            <ToastButton
              label={isToastMessageActive ? 'Copied! Opening LinkedIn...' : 'Copy & Open in LinkedIn'}
              primary={false}
              small
              handleClick={event => {
                // NOTE: events in react are synthetic unless told to persist
                //       this line is needed in order for event to stay alive while inside promise
                // NOTE: the `;` is required before calling self-invoking function
                event.persist();

                (async (event) => {
                  await setIsToastMessageActive(true)
                  await copyToClipboard()
                  await window.setTimeout(() => {
                    event.target.blur()
                    setIsToastMessageActive(false)
                    window.open(linkedInProfileUrl + '?rightRail=composer', '_blank')
                  }, 1000)
                })(event)
              }}
            />
          </Tooltip>
        </>}
    </OverlayMenuWrapper>
  )
}

OverlayMenu.propTypes = {
  copyToClipboard: PropTypes.func,
  showCopyToClipboard: PropTypes.bool,
  linkedInProfileUrl: PropTypes.string
}

export default OverlayMenu

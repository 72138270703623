import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import { getTimeDiff } from 'utils/dates'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.white};
  padding-bottom: 8rem;
`

const WorkHistoryList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  list-style-type: none;
  line-height: 1.4em;
  padding: 0 0 2rem 0;
  margin: 0;

  >:last-child div {
    border-left: none!important;
  }
`

const WorkItem = styled.li`
  font: ${theme.fonts.normal};
  padding: ${theme.padding};
  background: ${theme.colors.white};
  width: 100%;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0;
  position: relative;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

const CompanyName = styled.div`
  font: ${theme.fonts.formtitle};
  color: ${props => (props.color ? props.color : theme.colors.gray70)};
  text-align: left;
  margin: 0;
`

const SubTitle = styled.div`
  font: ${theme.fonts.xsmall};
  color: ${theme.labelColor};
  text-align: left;
  margin: 0;
`

const DateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  font: ${theme.fonts.xsmall};
  color: ${theme.labelColor};
  margin: 0;
`

const BulletPointWrapper = styled.div`
  padding-top: 7.5px;
  margin: 0 0.5rem;
`

const BulletPoint = styled.div`
  width: 3px;
  height: 3px;

  border-radius: 100%;
  background-color: ${theme.colors.gray70};
`

function ContactWorkHistory ({
  contact
}) {
  const workHistory = contact.get('linkedin_positions') || Immutable.List([])

  const workHistoryItems = workHistory
    .map((item, i) => {
      const compKey = `${item.get('company_name')} - ${i}}`
      const startDate = item.get('start_date')
      const endDate = item.get('end_date')

      return (
        <WorkItem key={compKey}>
          <Container>
            <CompanyName>{item.get('company_name')}</CompanyName>
            <SubTitle>{item.get('title')}</SubTitle>
            {startDate &&
              <DateWrapper>
                {`${startDate} - ${endDate || 'Present'}`}
                <BulletPointWrapper>
                  <BulletPoint />
                </BulletPointWrapper>
                {getTimeDiff(startDate, endDate)}
              </DateWrapper>}
          </Container>
        </WorkItem>
      )
    })

  return (
    <Wrapper>
      <WorkHistoryList>
        {workHistoryItems}
      </WorkHistoryList>
    </Wrapper>
  )
}

ContactWorkHistory.propTypes = {
  contact: PropTypes.object
}

export default ContactWorkHistory

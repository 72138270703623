import React, { Component } from 'react'
import Dimensions from 'react-dimensions'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { format } from 'date-fns'
import Popper from 'components/Popper'
import Button from 'components/Button'
import Loading from 'components/Loading'
import ComponentError from 'elements/ComponentError'
import DropdownArrow from 'elements/DropdownArrow'
import StyledTooltip from 'elements/StyledTooltip'
import { Menu, MenuItem } from 'elements/Menu'
import withError from 'hocs/withError'
import { generateSeedColors } from 'utils/colors'
import { pluralize } from 'utils/strings'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const TitleContainer = styled.div`
  background: ${theme.colors.gray10};
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TitlePopperMenu = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const Title = styled.h3`
  color: ${theme.titleColor};
  font: ${theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
`

const CaretContainer = styled.div`
  width: 100%;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 0.5rem;
`

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

  background-color: ${theme.colors.white};

  padding: 2rem;
  width: 100%;
`

const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: ${theme.colors.gray20};

  // border: 1px solid ${theme.colors.gray60};
  border-radius: 3px;

  width: 100%;
  height: 5rem;
`

const ProgressBarItem = styled.div`
  position: relative;
  background-color: ${props => props.itemColor};
  width: ${props => `max(calc(100% * ${props.percent}), 1px)`};
  margin: 0 1px 0 0;
  height: 100%;
`

const ProgressBarLabel = styled.div`
  font-weight: 300;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  margin-top: 1rem;
`

const ProgressBarNumber = styled(NumberFormat)`
  font-size: 20px;
  color: ${theme.colors.black};
`

const ProgressBarTotalLabel = styled.div`
  font-size: 15px;
  color: ${theme.colors.gray60};
`

const ProgressBarResetDate = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: ${theme.colors.gray60};
`

const ButtonContainer = styled.div``

class LookupProgressBar extends Component {
  getRemainingLookups = () => {
    const { requests } = this.props
    const totalLookups = requests.getIn(['data', 'limit'])
    const noUsedLookups = requests.getIn(['data', 'totals', 'total'])

    return (totalLookups - noUsedLookups)
  }

  getColorsByLookupData = (lookupData) => {
    const userKeyList = lookupData.map(v => v.key)
    const colors = generateSeedColors(userKeyList)
    colors[colors.length - 1] = theme.colors.gray30

    return colors
  }

  aggregateLookupData = (userData, userMap, userKeys) => {
    // getting look up data for all users
    let lookupData = userKeys.map((key, index) => {
      const userReport = userData.get(key)
      const user = userMap[key]
      const userName = user ? user.get('full_name') : 'User'

      const lookupCount = userReport.getIn(['total']) || 0
      const label = `${userName} used ${lookupCount} ${pluralize('lookup', 'lookups', lookupCount)}`

      return { index, lookupCount, label, key }
    })

    // order `lookupData` by the number of used look ups per user
    lookupData = lookupData
      .sort((a, b) => (a.lookupCount > b.lookupCount) ? -1 : 1)
      .filter(object => (object.lookupCount > 0))
      .map((v, index) => ({ ...v, index }))

    // make the last value in `lookupData` the remaining number of lookups
    const remaining = this.getRemainingLookups()
    lookupData.push({
      index: lookupData.count,
      lookupCount: remaining,
      key: 'remaining',
      label: `${remaining} lookups remaining`
    })

    return lookupData
  }

  /**
   * Calculate where the tooltip should be pinned inside a progress bar item
   *
   * @param {HTMLElement} currentTarget: The current progress bar item being hovered over
   * @param {HTMLElement} node: node is a reference to the tooltip object
   */
  positionTooltip = (postObject, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => {
    // bouding box of the progress item being hovered over
    const boundingBox = currentTarget.getBoundingClientRect().toJSON()

    const centeredTooltipPositonX = (boundingBox.left + boundingBox.width / 2) - (node.clientWidth / 2)
    const tooltipPositionY = boundingBox.top - node.clientHeight

    return { left: centeredTooltipPositonX, top: tooltipPositionY }
  }

  renderProgressBar = (lookupData, colors) => {
    const { requests } = this.props

    const lookupResetRaw = requests.getIn(['data', 'resets_on'])
    const lookupReset = format(new Date(lookupResetRaw), 'MMM. D')

    const remainingLookups = this.getRemainingLookups()
    const totalLookups = requests.getIn(['data', 'limit'])
    const usedLookups = totalLookups - remainingLookups
    const sizeOfTeam = lookupData.length

    const progressBarItems = lookupData.map((userLookupData, idx) => {
      const itemColor = colors[idx]

      const { lookupCount, label } = userLookupData
      const percent = (lookupCount / totalLookups).toFixed(2)

      return (
        <ProgressBarItem
          key={`pbi_${idx}`}
          itemColor={itemColor}
          sizeOfTeam={sizeOfTeam}
          percent={percent}
          data-tip={label}
        />
      )
    })

    return (
      <ProgressBarWrapper>
        <ProgressBar>
          {progressBarItems}
        </ProgressBar>

        <ProgressBarLabel>
          <ProgressBarTotalLabel>
            <ProgressBarNumber value={usedLookups} displayType='text' thousandSeparator=',' />
            &nbsp;lookups used out of&nbsp;
            <ProgressBarNumber value={totalLookups} displayType='text' thousandSeparator=',' />
            &nbsp;total
          </ProgressBarTotalLabel>
        </ProgressBarLabel>

        <ProgressBarResetDate>
          {`usage resets monthly, next on ${lookupReset}`}
        </ProgressBarResetDate>
        {/* Using react-tippy prevented the items in the ProgressBar to scale correctly
          * Using react-tooltip because it's easier to configure attributes on the tooltip */}
        <StyledTooltip
          overridePosition={this.positionTooltip}
        />
      </ProgressBarWrapper>
    )
  }

  render () {
    const {
      subscription,
      requests,
      members,
      isOwner,
      onAddLookups,
      onBillingCycleChange
    } = this.props

    if (subscription.get('loading') || requests.get('loading') || members.get('loading')) {
      return (
        <Wrapper>
          <TitleContainer>
            <Title>Email Lookups</Title>
          </TitleContainer>
          <Loading padding='5rem' />
        </Wrapper>
      )
    }

    const userData = requests.getIn(['data', 'users'])
    const userKeys = userData ? Object.keys(userData.toObject()) : []
    const userMap = members.get('data').reduce((acc, user) => {
      acc[user.get('id')] = user
      return acc
    }, {})

    // organize lookup data and get progress bar colors
    const lookupData = this.aggregateLookupData(userData, userMap, userKeys)
    const colors = this.getColorsByLookupData(lookupData)

    const phraseMap = {
      0: 'Current Billing Cycle',
      1: 'Last Billing Cycle',
      2: 'Two Billing Cycles Ago',
      3: 'Three Billing Cycles Ago'
    }
    const billingCycles = [0, 1, 2, 3].map(x => {
      return (
        <MenuItem
          key={x}
          onClick={() => {
            onBillingCycleChange(x)
          }}
        >
          {phraseMap[x]}
        </MenuItem>
      )
    })

    const currentCyclesAgo = requests.getIn(['data', 'cycles_ago'])

    const title = (currentCyclesAgo === 0)
      ? 'Email Lookups'
      : phraseMap[currentCyclesAgo]

    return (
      <Wrapper>
        <TitleContainer>
          <TitlePopperMenu>
            <Title> {title} </Title>
            <Popper
              position='bottom-start'
              maxHeight='18rem'
              trigger={
                <CaretContainer data-tip='View previous billing cycles'>
                  <DropdownArrow />
                </CaretContainer>
              }
            >
              {close => (
                <Menu style={{ minWidth: '12rem' }} onClick={close}>
                  {billingCycles}
                </Menu>
              )}
            </Popper>
          </TitlePopperMenu>
          {isOwner &&
            <ButtonContainer>
              <Button
                primary
                label='Add Lookups'
                small
                ml='0.5rem'
                handleClick={onAddLookups}
              />
            </ButtonContainer>}
        </TitleContainer>

        {this.renderProgressBar(lookupData, colors)}
      </Wrapper>
    )
  }
}

LookupProgressBar.propTypes = {
  subscription: PropTypes.object,
  members: PropTypes.object,
  requests: PropTypes.object,
  isOwner: PropTypes.bool,
  onAddLookups: PropTypes.func,
  onBillingCycleChange: PropTypes.func
}

const Fallback = (
  <Wrapper style={{ justifyContent: 'start' }}>
    <Title>
      Email Lookups
    </Title>
    <ComponentError />
  </Wrapper>
)

export default withError(Fallback, Dimensions()(LookupProgressBar))

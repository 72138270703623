/**
*
* Dropdown Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import theme from '../../themes/light'

const SliderWithTooltip = createSliderWithTooltip(Slider)

class CustomSlider extends Component {
  onSliderChange = (value) => {
    const {
      onSliderChange
    } = this.props
    onSliderChange(value)
  }

  render () {
    const {
      trackColor,
      value,
      ...rest
    } = this.props

    return (
      <SliderWithTooltip
        value={value}
        onChange={this.onSliderChange}
        trackStyle={{ backgroundColor: trackColor || theme.primaryColor, height: 8 }}
        handleStyle={[{
          borderWidth: '1px',
          borderColor: theme.colors.gray42,
          height: 20,
          width: 20,
          marginLeft: -2,
          marginTop: -6,
          backgroundColor: theme.colors.white
        }]}
        tipProps={{ overlayClassName: 'tooltip-background' }}
        railStyle={{ backgroundColor: theme.colors.gray42, height: 8 }}
        {...rest}
      />
    )
  }
}

CustomSlider.propTypes = {
  value: PropTypes.any,
  onSliderChange: PropTypes.func,
  trackColor: PropTypes.any
}

export default CustomSlider

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  Header,
  TabContainer
} from '../ToolbarModal'
import Title from 'elements/Title'
import Divider from 'elements/Divider'
import Input from 'components/Input'
import Button from 'components/Button'
import Label from 'elements/Label'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'
import theme from '../../../../../themes/light'

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const InvalidLabel = styled(Label)`
  color: ${theme.colors.red};
  font: ${theme.fonts.normal};
  text-align: center;
`

const InputContainer = styled.div``

class UnsubscribeModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      unsubscribeText: '',
      hasSubmitted: false,
      emptyTextError: false
    }

    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
    window.addEventListener('keydown', this.handleEnterKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
    window.removeEventListener('keydown', this.handleEnterKey, false)
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showUnsubscribeMenu')
    }
  }

  handleEnterKey = event => {
    const ENTER_KEY = 13
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ENTER_KEY) {
      Promise
        .resolve()
        .then(() => this.handleInsertUnsubscribeLink(event))
        .then(() => toggleToolbarMenuVisibility('showUnsubscribeMenu'))
    }
  }

  handleInsertUnsubscribeLink = () => {
    const { unsubscribeText } = this.state
    const { activeEditorName, editorRef } = this.props
    if (!unsubscribeText) {
      this.setState({
        emptyTextError: true,
        hasSubmitted: true
      })
    } else if (activeEditorName === SLATE_EDITORS.BODY) {
      editorRef.current.wrapUnsubscribeLink(unsubscribeText)
    }
  }

  render () {
    const { unsubscribeText, emptyTextError, hasSubmitted } = this.state
    const { toggleToolbarMenuVisibility } = this.props

    return (
      <Wrapper>
        <Curtain
          onClick={() => toggleToolbarMenuVisibility('showUnsubscribeMenu')}
          visible
        />
        <Window>
          <TabContainer>
            <Header>
              <Title>Add Unsubscribe Link</Title>
              {emptyTextError && hasSubmitted
                ? <InvalidLabel mt='0.5rem' mb='0.5rem'>No text provided, please provide valid text</InvalidLabel>
                : <Label mt='0.5rem' mb='0.5rem' align='center'>Add an unsubscribe link to any part of your message that is unique for each contact</Label>}
            </Header>
            <InputContainer>
              <InputWrapper>
                <Input
                  label='Text'
                  type='text'
                  value={unsubscribeText}
                  onValueChange={value => {
                    this.setState({ unsubscribeText: value })
                  }}
                />
              </InputWrapper>
            </InputContainer>
          </TabContainer>

          <Divider />

          <ButtonContainer>
            <Button
              label='cancel'
              handleClick={() => toggleToolbarMenuVisibility('showUnsubscribeMenu')}
              mr='0.5rem'
            />
            <Button
              primary
              label='add unsubscribe link'
              handleClick={() => {
                Promise
                  .resolve()
                  .then(() => this.handleInsertUnsubscribeLink())
                  .then(() => toggleToolbarMenuVisibility('showUnsubscribeMenu'))
              }}
            />
          </ButtonContainer>
        </Window>
      </Wrapper>
    )
  }
}

UnsubscribeModal.propTypes = {
  toggleToolbarMenuVisibility: PropTypes.func,
  editorRef: PropTypes.object,
  activeEditorName: PropTypes.string
}

export default UnsubscribeModal

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Button from 'components/Button'
import Label from 'elements/Label'
import { format, differenceInDays, subDays } from 'date-fns'
import SequenceDropdown from './components/SequenceDropdown'
import ReportDropDown from './components/ReportDropdown'
import DateRange from './components/DateRange'
import LegacyDropdown from './components/LegacyDropdown'
import theme from '../../../../themes/light'

const Container = styled.div`
  max-width: 300px;
`

const Wrapper = styled.div`
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  border: solid 1px ${theme.colors.gray30};
  border-radius: 3px;
  font: ${theme.fonts.normal};

  margin-bottom: 1rem;
`

const ConfigWrapper = styled.div`
  background-color: ${theme.colors.white};
  padding: 1.5rem 1.75rem;
  padding-bottom: 2rem;

  > :last-child {
    padding-bottom: 0;
  }
`

const ConfigMenuListItem = styled.div`
  width: 15rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`

const ToggleListItem = styled.div`
  margin-top: 0.5rem;
  width: 15rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ToggleSwitch = styled(SwitchFieldForm)`
  margin: 0;
`

const MenuLabel = styled(Label)`
  font: ${theme.fonts.formtitle};
  font-size: 13px;

  margin-bottom: 0.25rem;

  color: ${theme.colors.darkBlue};
  text-transform: uppercase;
`

const WarningWrapper = styled(ConfigWrapper)`
  padding: 1.25rem 1.5rem;
  padding-top: calc(1.25rem + 5px);
`

const Warning = styled.div`
  display: flex;
  align-items: baseline;
  color: ${theme.colors.darkGray};
  font-size: 15px;
  font-weight: 300;
`

const WarningText = styled.div`
  position: relative;
  top: -3px;
  margin-left: 0.5rem;
  flex: 1;
`

class ReportMenu extends React.Component {
  calcualteContrastDates = () => {
    const { startDate, endDate } = this.props

    // # of days inbetween `startDate` & `endDate`
    const dayDiff = differenceInDays(endDate, startDate)

    // the dates that mark the `start` and `end` of the range
    const start = subDays(startDate, dayDiff)
    const end = subDays(startDate, 1)

    return { start, end }
  }

  /**
   * Description: These conditions must be true in order for
   *              the sequence selector to be allowed to rendered
   *
   * Conditions:
   * 1. The `users` props must exist
   * 2. The `sequences` props must exist
   * 3. The `users` or `sequences` data must be finished loading
   */
  showSequenceSelectorPreconditions = () => {
    const { users, sequences } = this.props
    if (!users ||
      !sequences ||
      (!!users && users.get('loading')) ||
      (!!sequences && sequences.get('loading'))) {
      return false
    }
    return true
  }

  /**
   * Description: These conditions must be true in order for
   *              the date range selector to be allowed to rendered
   *
   * Conditions:
   * 1. `startDate` and `endDate` must exist
   * 2. `setDateRange` must be a function that can be called when
   *     new dates are selected
   */
  showDateRangeSelectorPreconditions = () => {
    const { startDate, endDate, setDateRange } = this.props
    if (!startDate || !endDate || !setDateRange) {
      return false
    }
    return true
  }

  render () {
    const {
      actions,
      sequences,
      users,
      router,
      state,
      session,

      setDateRange,
      dateRangeLabel,
      startDate,
      endDate,

      setSequenceIds,
      setUserIds,
      setFolderNames,
      setGroups,
      setFilter,
      toggleSelectAllSequencesUsers,
      selectedSequenceIds,
      selectedUserIds,
      allSequencesUsersSelected,
      selectedFolderNames,
      selectedGroups,

      toggleLeaderboardContrastData,
      showLeaderboardContrastData,
      toggleLeaderboardGroupsData,
      showLeaderboardGroupsData,

      showReportsSelector = false,
      showDateRangeSelector = false,
      showSequenceSelector = false,
      showPreviousPeriodToggle = false,
      showExportButton = false,
      showLegacySelector = false,
      showGroupsToggle = false,
      showSequenceFolder = false,

      fetchReports,
      exportReport,

      warningMessage,
      iconColor = theme.colors.blue,

      showPersonalizedMessagePerformance,
      showPersonalizedContactPerformance,
      showPersonalizedMessagePerformanceToggle,
      showPersonalizedContactPerformanceToggle,
      togglePersonalizedMessagePerformanceData,
      togglePersonalizedContactPerformanceData
    } = this.props

    const { start, end } = this.calcualteContrastDates()

    const featureFlags = session.get('feature_flags') || session.get('whitelist')
    const isManager = state.get('is_manager')
    const isOwner = state.get('is_owner')

    return (
      <Container>
        <Wrapper>
          <ConfigWrapper>
            {showReportsSelector &&
              <ConfigMenuListItem>
                <MenuLabel>report</MenuLabel>
                <ReportDropDown
                  router={router}
                  fetchReports={fetchReports}
                  state={state}
                />
              </ConfigMenuListItem>}

            {this.showDateRangeSelectorPreconditions() && showDateRangeSelector &&
              <ConfigMenuListItem>
                <MenuLabel>{dateRangeLabel || 'Date Range'}</MenuLabel>
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  setDateRange={setDateRange}
                />
              </ConfigMenuListItem>}

            {this.showSequenceSelectorPreconditions() && showSequenceSelector &&
              <ConfigMenuListItem>
                <MenuLabel>sequences</MenuLabel>
                <SequenceDropdown
                  session={session}
                  actions={actions}
                  sequences={sequences}
                  users={users}
                  state={state}
                  startDate={startDate}
                  endDate={endDate}
                  setSequenceIds={setSequenceIds}
                  setUserIds={setUserIds}
                  setFolderNames={setFolderNames}
                  setGroups={setGroups}
                  toggleSelectAllSequencesUsers={toggleSelectAllSequencesUsers}
                  allSequencesUsersSelected={allSequencesUsersSelected}
                  selectedSequenceIds={selectedSequenceIds}
                  selectedUserIds={selectedUserIds}
                  selectedFolderNames={selectedFolderNames}
                  selectedGroups={selectedGroups}
                  showSequenceFolder={showSequenceFolder}
                />
              </ConfigMenuListItem>}

            {this.showSequenceSelectorPreconditions() && showLegacySelector &&
              <ConfigMenuListItem>
                <MenuLabel>filter</MenuLabel>
                <LegacyDropdown
                  session={session}
                  users={users}
                  sequences={sequences}
                  state={state}
                  selectedSequenceIds={selectedSequenceIds}
                  selectedUserIds={selectedUserIds}
                  setFilter={setFilter}
                />
              </ConfigMenuListItem>}

            {showExportButton &&
              <ConfigMenuListItem>
                <Button
                  primary
                  onClick={exportReport}
                  label='Export'
                />
              </ConfigMenuListItem>}
          </ConfigWrapper>
        </Wrapper>

        {showGroupsToggle && featureFlags.includes('show_group_ui') && (isManager || isOwner) &&
          <Wrapper>
            <ConfigWrapper>
              <ToggleListItem>
                <ToggleSwitch
                  condensed
                  value={showLeaderboardGroupsData}
                  onSave={toggleLeaderboardGroupsData}
                  label={!showLeaderboardGroupsData
                    ? 'Display stats by group'
                    : 'Displaying stats by group'}
                />
              </ToggleListItem>
            </ConfigWrapper>
          </Wrapper>}

        {showPersonalizedMessagePerformanceToggle &&
          <Wrapper>
            <ConfigWrapper>
              <ToggleListItem>
                <ToggleSwitch
                  condensed
                  value={showPersonalizedMessagePerformance}
                  onSave={togglePersonalizedMessagePerformanceData}
                  label={!showPersonalizedMessagePerformance
                    ? 'Display personalized message stats'
                    : 'Displaying personalized message stats'}
                />
              </ToggleListItem>
            </ConfigWrapper>
          </Wrapper>}

        {showPersonalizedContactPerformanceToggle &&
          <Wrapper>
            <ConfigWrapper>
              <ToggleListItem>
                <ToggleSwitch
                  condensed
                  value={showPersonalizedContactPerformance}
                  onSave={togglePersonalizedContactPerformanceData}
                  label={!showPersonalizedContactPerformance
                    ? 'Display personalized contact stats'
                    : 'Displaying personalized contact stats'}
                />
              </ToggleListItem>
            </ConfigWrapper>
          </Wrapper>}

        {showPreviousPeriodToggle &&
          <Wrapper>
            <ConfigWrapper>
              <ToggleListItem>
                <ToggleSwitch
                  condensed
                  value={showLeaderboardContrastData}
                  onSave={toggleLeaderboardContrastData}
                  label={(!showLeaderboardContrastData)
                    ? 'Compare to Previous Period'
                    : `Comparing from ${format(start, 'MMM D, YYYY')} to ${format(end, 'MMM D, YYYY')}`}
                />
              </ToggleListItem>
            </ConfigWrapper>
          </Wrapper>}

        {showPersonalizedContactPerformanceToggle && showPersonalizedContactPerformance &&
          <Wrapper>
            <WarningWrapper>
              <Warning>
                <FontAwesomeIcon
                  icon={['fas', 'info-circle']}
                  color={iconColor}
                />
                <WarningText>Tasks that have been completed will also count as customized.</WarningText>
              </Warning>
            </WarningWrapper>
          </Wrapper>}

        {warningMessage &&
          <Wrapper>
            <WarningWrapper>
              <Warning>
                <FontAwesomeIcon
                  icon={['fas', 'info-circle']}
                  color={iconColor}
                />
                <WarningText>{warningMessage}</WarningText>
              </Warning>
            </WarningWrapper>
          </Wrapper>}
      </Container>
    )
  }
}

ReportMenu.propTypes = {
  router: PropTypes.object,
  actions: PropTypes.object,
  sequences: PropTypes.object,
  users: PropTypes.object,
  state: PropTypes.object,
  session: PropTypes.object,

  setDateRange: PropTypes.func,
  dateRangeLabel: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,

  setUserIds: PropTypes.func,
  setSequenceIds: PropTypes.func,
  setFolderNames: PropTypes.func,
  setGroups: PropTypes.func,
  setFilter: PropTypes.func,
  toggleSelectAllSequencesUsers: PropTypes.func,
  allSequencesUsersSelected: PropTypes.bool,
  selectedSequenceIds: PropTypes.instanceOf(Immutable.Set),
  selectedUserIds: PropTypes.instanceOf(Immutable.Set),
  selectedFolderNames: PropTypes.instanceOf(Immutable.Set),
  selectedGroups: PropTypes.instanceOf(Immutable.Set),

  showReportsSelector: PropTypes.bool,
  showDateRangeSelector: PropTypes.bool,
  showSequenceSelector: PropTypes.bool,
  showLegacySelector: PropTypes.bool,
  showSequenceFolder: PropTypes.bool,
  showPreviousPeriodToggle: PropTypes.bool,
  showExportButton: PropTypes.bool,
  showGroupsToggle: PropTypes.bool,

  toggleLeaderboardContrastData: PropTypes.func,
  showLeaderboardContrastData: PropTypes.bool,
  toggleLeaderboardGroupsData: PropTypes.func,
  showLeaderboardGroupsData: PropTypes.bool,

  showPersonalizedToggle: PropTypes.bool,

  fetchReports: PropTypes.func,
  exportReport: PropTypes.func,

  warningMessage: PropTypes.any,
  iconColor: PropTypes.string,

  showPersonalizedMessagePerformance: PropTypes.bool,
  showPersonalizedContactPerformance: PropTypes.bool,
  showPersonalizedMessagePerformanceToggle: PropTypes.bool,
  showPersonalizedContactPerformanceToggle: PropTypes.bool,
  togglePersonalizedMessagePerformanceData: PropTypes.func,
  togglePersonalizedContactPerformanceData: PropTypes.func
}

export default ReportMenu

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import Label from 'elements/Label'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: ${props => (props.full ? 'column' : 'row')};
  align-items:  ${props => (props.full ? 'start' : 'center')};
  justify-content: space-between;

  padding-top: 1rem;
  padding-bottom: 1rem;

  ${space}
  ${width}
  ${textAlign}
`

const FormTitle = styled(Label)`
  flex: 0;
  text-transform: uppercase;
  font: ${theme.fonts.button};
  margin-right: 0.5rem;
`

class FormLabel extends Component {
  render () {
    const {
      label,
      full = false
    } = this.props

    return (
      <Wrapper full={full}>
        <FormTitle>{label}</FormTitle>
        {this.props.children}
      </Wrapper>
    )
  }
}

FormLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  full: PropTypes.bool
}

export default FormLabel

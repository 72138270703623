import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fadeInRule } from 'utils/animations'
import theme from '../../themes/light'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(242,244,249,0.7);
  z-index: 10;
  overflow: auto;

  display: ${props => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  animation: ${fadeInRule};
`

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  margin: auto;
  position: relative;
  outline: none !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: auto; */

  animation: ${fadeInRule};
  box-shadow: ${props => (props.shadow ? theme.shadow : '')};
`

class Modal extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      open: props.isOpen
    }
  }

  UNSAFE_componentWillMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isOpen !== this.state.open) {
      this.setState({
        open: nextProps.isOpen
      })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleContentClick = (e) => {
    const { open } = this.state
    if (open) {
      e.stopPropagation()
      return false
    }
    return true
  }

  closable = true

  handleClickOutside = () => {
    if (this.props.persistent) {
      return
    }

    if (!this.closable) {
      return
    }

    if (this.state.open) {
      this.close()
    }
  }

  handleKeyDown = (e) => {
    // If 'escape' is pressed and open
    if (this.state.open && e.keyCode === 27) {
      e.preventDefault()
      this.close()
      return true
    }
    return false
  }

  close = () => {
    this.setState({ open: false })
    if (this.props.onModalClose) {
      this.props.onModalClose()
    }
  }

  onMouseDown = (e) => {
    this.closable = false
    e.stopPropagation()
  }

  onMouseUp = (e) => {
    const self = this

    setTimeout(function () {
      self.closable = true
    })
    e.stopPropagation()
  }

  render () {
    const {
      content,
      children,
      width = '600px',
      maxHeight,
      shadow = true,
      left,
      right,
      minWidth,
      maxWidth,
      className,
      marginTop,
      ...rest
    } = this.props

    return (
      <Backdrop
        onClick={this.handleClickOutside}
        onKeyDown={this.handleKeyDown}
        onMouseUp={this.onMouseUp}
        tabIndex='0'
        className={className}
        {...this.state}
      >
        {this.state.open &&
          <Wrapper
            minWidth={minWidth}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            marginTop={marginTop}
            width={width}
            className={className}
            shadow={shadow}
            closeModal={this.close}
            onClick={this.handleContentClick}
            onMouseDown={this.onMouseDown}
            {...rest}
          >
            {children && React.cloneElement(children, {
              closeModal: this.close
            })}
          </Wrapper>}
      </Backdrop>
    )
  }
}

Modal.defaultProps = {
  isOpen: false
}

Modal.propTypes = {
  height: PropTypes.any,
  maxHeight: PropTypes.any,
  width: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  marginTop: PropTypes.any,
  position: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  dismissOnClick: PropTypes.bool,
  isOpen: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  onModalClose: PropTypes.func,
  persistent: PropTypes.bool
}

export default Modal

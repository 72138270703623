import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'
import withSession from 'hocs/session'
import Label from 'elements/Label'
import theme from '../../../../../../../themes/light'

const Wrapper = styled.div`
  display: block;
  padding-top: 0.5rem;
  width: 100%;
`

const Title = styled(Label)`
  color: ${theme.titleColor};
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
`

const RowContainer = styled.div`
  display: block;
  padding-top: 0.25rem;
  width: 100%;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`

const UserLabel = styled(Label)`
  float: left;
`

const DateLabel = styled(Label)`
  float: right;
`

class SequenceRow extends PureComponent {
  render () {
    const {
      contact,
      dateField,
      onClick,
      session
    } = this.props

    const title = contact.getIn(['_campaign', 'title'])
    const name = contact.getIn(['_user', 'full_name']) || contact.getIn(['_user', 'email'])
    const date = contact.get(dateField)
    const currentUser = contact.getIn(['_user', '_id']) === session.get('_id')
    const deleted = contact.getIn('deleted_at') || contact.getIn(['_campaign', 'deleted_at'])
    const shared = contact.getIn(['_campaign', 'shared'])
    const viewable = !deleted && (currentUser || shared)

    return (
      <Wrapper>
        <RowContainer>
          <Title
            style={{
              cursor: viewable ? 'pointer' : 'default'
            }}
            onClick={() => {
              if (viewable) {
                onClick()
              }
            }}
          >
            {title}
          </Title>
        </RowContainer>
        <RowContainer>
          {!currentUser && <UserLabel>{name}</UserLabel>}
          <DateLabel>
            {`${format(new Date(date), 'MMM D, YYYY')}`}
          </DateLabel>
        </RowContainer>
      </Wrapper>
    )
  }
}

SequenceRow.propTypes = {
  contact: PropTypes.instanceOf(Immutable.Map),
  onClick: PropTypes.func,
  session: PropTypes.object,
  dateField: PropTypes.string
}

export default withSession(SequenceRow)

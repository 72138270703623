import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { space, width, height } from 'styled-system'
import styled, { css } from 'styled-components'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  font: ${theme.fonts.normal};
  color: ${theme.colors.darkBlue};
  text-align: left;

  ${props => props.border && css`
    border: solid 1px ${theme.borderColor};
  `}

  ${space}
  ${width}
  ${height}
`

const TableContainer = styled.table`
  width: 100%;
`

const Cell = styled.tr`
  th,
  td {
    position: relative;
    padding: 1rem;
  }

  th {
    text-transform: uppercase;
    font:  ${theme.fonts.small};
    font-weight: 500;
    color: ${theme.colors.gray70};
  }
`

function Table ({
  cols,
  rows,
  ...rest
}) {
  return (
    <Wrapper {...rest}>
      <TableContainer>
        <thead>
          <Cell>
            {cols.map((col, index) => (
              <th key={'header-' + index}>
                {col.header}
              </th>
            ))}
          </Cell>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <Cell key={'row-' + rowIndex}>
              {cols.map((col, index) => (
                <td key={'item-' + index}>
                  {col.render(row, rowIndex)}
                </td>
              ))}
            </Cell>
          ))}
        </tbody>
      </TableContainer>
    </Wrapper>
  )
}

Table.propTypes = {
  cols: PropTypes.array,
  rows: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Immutable.List)
  ])
}

export default Table

import styled from 'styled-components'
import theme from '../../themes/light'

export default styled.div`
  background: ${theme.background};
  -webkit-app-region: drag;
  width: 100%;
  max-width: 68rem;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
`

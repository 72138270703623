import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'recharts'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'
import * as reportHelpers from 'containers/Reports/utils/report_helpers'
import SpreadSheet from 'containers/Reports/components/SpreadSheet'
import Graph from 'containers/Reports/components/Graph'
import UserHUD from 'containers/Reports/components/Graph/components/UserHUD'
import DateSelector from 'containers/Reports/components/Graph/components/DateSelector'
import { ReportContainer, EmptyContainer, GraphContainer } from 'containers/Reports/styles'
import { Satellite } from 'svg'
import theme from '../../../../../themes/light'

class MessagedReport extends React.Component {
  formatTableData = () => {
    const { messagedReports } = this.props
    const totals = messagedReports.getIn(['data', 'totals', 'campaigns'])
    const users = messagedReports.getIn(['data', 'populate', 'users'])
    const sequences = messagedReports.getIn(['data', 'populate', 'campaigns'])

    const tableColumns = ['sequence', 'owner', 'messages sent']
    const tableRows = []
    const columnWidths = ['flex', 'min', 140]
    totals
      .entrySeq()
      .forEach(record => {
        const sequenceId = record[0]
        const count = record[1]

        const sequence = sequences.get(sequenceId)
        const sequenceTitle = sequences.getIn([sequenceId, 'title'])
        const user = users.getIn([sequence.get('_user'), 'full_name'])

        tableRows.push({
          sequence: sequenceTitle,
          'messages sent': count,
          sequenceID: sequenceId,
          owner: user
        })
      })

    return { tableColumns, tableRows, columnWidths }
  }

  render () {
    const {
      router,
      messagedReports,
      selectedDateRange,
      setSelectedDateType
    } = this.props

    if (!!messagedReports && messagedReports.get('loading')) {
      return <Loading width='100%' />
    }

    let graphValues = null
    let userTotals = null
    let totalUnits = 0
    let tableData = null
    if (messagedReports &&
      !messagedReports.get('loading') &&
      messagedReports.get('data') &&
      messagedReports.get('data').size > 0) {
      ({ graphValues, userTotals, totalUnits } = reportHelpers.formatBarGraphData(messagedReports))
      tableData = this.formatTableData()
    }

    if (!graphValues || graphValues.length < 0) {
      return (
        <ReportContainer>
          <EmptyContainer>
            <EmptyState
              height='100%'
              mt='0'
              p='3rem'
              icon={<Satellite />}
              title='Nothing To Report'
              description="Looks like we don't have any data for the timeframe and filter you have selected"
            />
          </EmptyContainer>
        </ReportContainer>
      )
    }

    return (
      <ReportContainer>
        <GraphContainer>
          <DateSelector
            dayDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.DAY)}
            weekDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.WEEK)}
            selectedDateRange={selectedDateRange}
          />

          <Graph
            graphData={graphValues}
            unitLabel='Emails Sent'
            unitLabelPlural='Email Sent'
            selectedDateRange={selectedDateRange}
          >
            <Bar dataKey='value' fill={theme.colors.blue} />
          </Graph>

          <UserHUD
            unitLabel='Emails Sent'
            unitLabelPlural='Email Sent'
            userData={userTotals}
            totalUnits={totalUnits}
          />
        </GraphContainer>

        <SpreadSheet
          router={router}
          tableData={tableData}
        />
      </ReportContainer>
    )
  }
}

MessagedReport.propTypes = {
  messagedReports: PropTypes.object,
  selectedDateRange: PropTypes.string,
  setSelectedDateType: PropTypes.func,
  router: PropTypes.object
}

export default MessagedReport

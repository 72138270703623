import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Sticky from 'react-stickynode'
import Button from 'components/Button'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import VirtualTable from 'components/VirtualTable'
import ConfirmModal from 'components/ConfirmModal'
import { pluralize } from 'utils/strings'
import { contactErrorToString } from 'utils/keyMapping'
import Toolbar from './Toolbar'
import ContactRowRenderer from './ContactRowRenderer'
import HeaderRowRenderer from './HeaderRowRenderer'
import EditContactSidebar from '../EditContactSidebar'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.containerBackground};
`

const TableWrapper = styled.div`
  background: ${theme.colors.white};
  color: #FFF;
  transition: background-color 0.3s ease, color .3s ease;
  position: relative;
  height: 100%;
  // width: 100%;
  flex: 1;
`

const ContactTableWrapper = styled.div`
  background-color: ${theme.background};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow-x: auto;
`

const LoadingContainer = styled.div`
  background-color: ${theme.colors.white};
  height: 100%;
  width: 100%;
`

class ContactTasks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedContacts: [],
      deselectedContacts: [],
      allSelected: false,
      errorFilter: null,
      showRemoveModal: false,
      showClearModal: false,
      showContactTaskSidebar: false,
      activeSidebarIndex: -1
    }
  }

  UNSAFE_componentWillMount () {
    const { actions, location } = this.props
    document.title = 'Interseller | Contacts Need Attention'
    actions.fetchContactErrorsStats()
    const { error } = location.query
    this.fetchContacts(error)
  }

  getHeaderRowRenderer = (rowProps) => {
    const {
      allSelected,
      deselectedContacts
    } = this.state

    return (
      <HeaderRowRenderer
        selected={allSelected && !deselectedContacts.length}
        onSelected={() => {
          if (allSelected && deselectedContacts.length) {
            this.setState({
              deselectedContacts: []
            })
          } else {
            this.setState({
              allSelected: !allSelected,
              selectedContacts: [],
              deselectedContacts: []
            })
          }
        }}
        {...rowProps}
      />
    )
  }

  getRowRenderer = (rowProps) => {
    const {
      index
    } = rowProps
    const {
      contactErrors,
      actions,
      router
    } = this.props
    const {
      selectedContacts,
      allSelected,
      deselectedContacts,
      errorFilter
    } = this.state

    const contact = contactErrors.getIn(['data', index])
    const contactId = contact.get('_id')
    const sequence = contact.get('_campaign')

    const steps = sequence.get('steps') || []
    const totalSteps = steps.size

    return (
      <ContactRowRenderer
        sequence={sequence}
        totalSteps={totalSteps}
        errorFilter={errorFilter}
        selected={(allSelected && deselectedContacts.indexOf(contactId) < 0) || selectedContacts.indexOf(contactId) >= 0}
        isVisible={this.state.showContactTaskSidebar}
        onSelected={() => {
          const selected = allSelected
            ? deselectedContacts
            : selectedContacts

          const i = selected.indexOf(contactId)
          if (i >= 0) {
            selected.splice(i, 1)
          } else {
            selected.push(contactId)
          }

          if (allSelected) {
            this.setState({
              deselectedContacts: selected
            })
          } else {
            this.setState({
              selectedContacts: selected
            })
          }
        }}
        onClick={() => {
          this.setState({
            showContactTaskSidebar: true,
            activeSidebarIndex: index
          })
        }}
        onClear={() => {
          actions.updateContactErrors({
            contactIds: [contactId],
            error: errorFilter,
            data: { error: null }
          })
        }}
        onRemove={() => {
          actions.removeContact(contactId)
        }}
        onClickSequence={() => {
          router.push(`/sequence/${sequence.get('id')}/contacts/${contactId}/detail`)
        }}
        {...rowProps}
      />
    )
  }

  fetchContacts = (error) => {
    const { actions, location } = this.props
    const query = location.query || {}

    if (error) {
      query.error = error
    } else {
      delete query.error
    }

    this.setState({
      errorFilter: error
    })

    this.props.router.push({
      ...this.props.location,
      query
    })

    actions.fetchContactErrors({
      sort: 'email',
      skip: 0,
      limit: 50,
      error
    })
  }

  render () {
    const {
      contactErrors,
      contactErrorsStats,
      actions
    } = this.props

    const {
      errorFilter,
      selectedContacts,
      allSelected,
      deselectedContacts,
      showRemoveModal,
      showClearModal,
      activeSidebarIndex
    } = this.state

    if (contactErrorsStats.get('loading')) {
      return (
        <Wrapper>
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        </Wrapper>
      )
    }

    const loading = contactErrors.get('loading')
    const hasMore = contactErrors.get('hasMore')
    const contactsCount = contactErrors
      .get('data')
      .count()

    const totalContacts = contactErrorsStats
      .get('data')
      .keySeq()
      .toArray()
      .reduce((acc, s) => (acc + contactErrorsStats.getIn(['data', s])), 0)

    let title = `Contacts Need Attention (${totalContacts})`
    if (errorFilter) {
      const filterCount = contactErrorsStats.getIn(['data', errorFilter])
      if (filterCount > 0) {
        title = `${contactErrorToString(errorFilter)} (${filterCount})`
      } else {
        title = contactErrorToString(errorFilter)
      }
    }

    let count = selectedContacts.length
    let word = pluralize('contact', 'contacts', selectedContacts.length)

    if (allSelected) {
      if (deselectedContacts.length) {
        count = 'selected'
      } else {
        count = 'all'
      }
      word = 'contacts'
    }

    const selectedContact = contactErrors.getIn(['data', activeSidebarIndex])

    let selectedSequence = null
    if (selectedContact) {
      selectedSequence = selectedContact.get('_campaign')
    }

    return (
      <Wrapper>
        <TableWrapper>
          <Sticky innerZ={3}>
            <Toolbar
              title={title}
              stats={contactErrorsStats.get('data')}
              selectedContacts={selectedContacts}
              allSelected={allSelected}
              filter={errorFilter}
              onClear={() => {
                this.setState({
                  allSelected: false,
                  selectedContacts: []
                })
              }}
              onRemove={() => {
                this.setState({
                  showRemoveModal: true
                })
              }}
              onClearError={() => {
                this.setState({
                  showClearModal: true
                })
              }}
              onFilterContacts={(filter) => {
                this.fetchContacts(filter)
              }}
            />
          </Sticky>
          <ContactTableWrapper>
            {loading && contactsCount === 0 &&
              <LoadingContainer>
                <Loading />
              </LoadingContainer>}
            {!loading && contactsCount === 0 &&
              <LoadingContainer>
                <EmptyState
                  title='No Contacts Need Attention'
                  description='When a contact needs attention from you to continue their sequence you will find them here.'
                >
                  {errorFilter &&
                    <Button
                      label='Remove filter'
                      handleClick={() => {
                        this.fetchContacts(null)
                      }}
                    />}
                </EmptyState>
              </LoadingContainer>}
            {contactsCount > 0 &&
              <VirtualTable
                data={contactErrors.get('data')}
                rowHeight={73}
                rowCount={contactsCount}
                headerRowRenderer={this.getHeaderRowRenderer}
                rowRenderer={this.getRowRenderer}
                onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                  const LOADING_DELTA = 1200
                  if (hasMore && !loading &&
                  scrollTop > (scrollHeight - clientHeight - LOADING_DELTA)) {
                    actions.fetchContactErrors({
                      sort: 'email',
                      skip: contactsCount,
                      limit: 50,
                      error: errorFilter
                    })
                  }
                }}
              />}
            {hasMore && contactsCount > 0 &&
              <LoadingContainer>
                <Loading padding='2rem' />
              </LoadingContainer>}
          </ContactTableWrapper>
        </TableWrapper>
        <ConfirmModal
          isOpen={showRemoveModal}
          onCancel={() => {
            this.setState({
              showRemoveModal: false
            })
          }}
          onConfirm={() => {
            actions.removeContactErrors({
              contactIds: allSelected ? deselectedContacts : selectedContacts,
              all: allSelected,
              error: errorFilter
            })
            this.setState({
              showRemoveModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: []
            })
          }}
          title='Remove Contacts'
          description={`Are you sure you want to remove ${count} ${word} from their sequence?`}
        />
        <ConfirmModal
          isOpen={showClearModal}
          onCancel={() => {
            this.setState({
              showClearModal: false
            })
          }}
          onConfirm={() => {
            actions.updateContactErrors({
              contactIds: allSelected ? deselectedContacts : selectedContacts,
              all: allSelected,
              error: errorFilter,
              data: { error: null }
            })
            this.setState({
              showClearModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: []
            })
          }}
          title='Clear Contact Errors'
          description={`Are you sure you want to clear the errors from ${count} ${word}?`}
        />

        {!loading && selectedContact && this.state.showContactTaskSidebar &&
          <EditContactSidebar
            contact={selectedContact}
            sequence={selectedSequence}
            router={this.props.router}
            onClose={() => {
              this.setState({
                showContactTaskSidebar: false,
                activeSidebarIndex: -1
              })
            }}
            onIgnore={() => {
              const contactId = selectedContact.get('_id')
              this.props.actions.updateContactErrors({
                contactIds: [contactId],
                error: errorFilter,
                data: { error: null }
              })
            }}
            onRemove={() => {
              const contactId = selectedContact.get('_id')
              this.props.actions.removeContact(contactId)
            }}
            onSave={(params) => {
              const contactId = selectedContact.get('_id')
              const sequenceId = selectedContact.get('_campaign')
              this.props.actions.updateContact(contactId, params, { sequenceId })
            }}
          />}
      </Wrapper>
    )
  }
}

ContactTasks.propTypes = {
  contactErrors: PropTypes.object,
  contactErrorsStats: PropTypes.object,
  actions: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object
}

export default ContactTasks

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fadeInRule } from 'utils/animations'

const Wrapper = styled.div`
  background-color: rgba(
    242,
    244,
    249,
    ${props => props.visible ? '0.7' : '0'}
  );
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  animation: ${fadeInRule};
`

class Curtain extends React.Component {
  render () {
    const {
      visible,
      onClick
    } = this.props
    return (
      <Wrapper
        visible={visible}
        onClick={onClick}
      />
    )
  }
}

Curtain.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func
}

export default Curtain

/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DropDown from 'components/DropDown'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Label from 'elements/Label'
import SwitchFieldForm from 'components/SwitchFieldForm'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  padding: ${theme.padding};
`

const FieldLabel = styled.div`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const FieldContainer = styled.div`
  padding: 1rem;
  text-align: left;
`

const LoadingWrapper = styled.div`
  display: inline-block;
  width: 24px;
  padding: 10px 0;
`

class Transfer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      copyManualMessages: false
    }
  }

  render () {
    const {
      sequence,
      onTransfer,
      members,
      newSequence,
      isManager,
      group
    } = this.props

    const {
      copyManualMessages,
      userId
    } = this.state

    const nameCounts = {}
    members.get('data').map((user) => {
      const name = user.get('full_name') || user.get('email')
      nameCounts[name] = (nameCounts[name] || 0) + 1
    })

    const sequenceOwnerId = sequence.getIn(['_user', '_id'])

    const teamMembers = members.get('data')
      .map((user, i) => {
        let name = user.get('full_name') || user.get('email')
        if (nameCounts[name] > 1) {
          name = `${name} (${user.get('email')})`
        }
        return {
          label: name,
          value: user.get('id'),
          index: i,
          group: user.get('group')
        }
      })

    const userOptions = teamMembers
      .filter(user => user)
      .filter(user => sequenceOwnerId !== user.id)
      .filter(user => (!group && isManager) || user.group === group)

    return (
      <Wrapper>
        <FieldContainer>
          <FieldLabel>Transfer</FieldLabel>
          <Label mb='1.5rem'>
            Transferring will copy this sequence, <strong>{sequence.get('title')}</strong>, to the user you choose but only take contacts from this sequence that have <strong>not been messaged</strong>. <a href='https://help.interseller.io/article/150-transferring-sequences' rel='noopener noreferrer' target='_blank'>Learn more</a>
          </Label>

          {members.get('loading') && <LoadingWrapper><Loading size={24} padding={0} /></LoadingWrapper>}

          {!members.get('loading') &&
            (
              <>
                <DropDown
                  label='Transfer To'
                  inputId='userId'
                  onOptionChange={(option) => {
                    if (option && option.value !== sequenceOwnerId) {
                      this.setState({
                        userId: option.value
                      })
                    } else {
                      this.setState({
                        userId: null
                      })
                    }
                  }}
                  options={userOptions}
                  clearable
                  mb='1rem'
                />
                <SwitchFieldForm
                  label='Transfer Personalized Messages'
                  description='Personalized messages will not be transferred. Switch this on to transfer personalized messages that have not yet been sent.'
                  descriptionOn='Personalized messages that have not yet been sent will be transferred to the new sequence.'
                  condensed
                  small
                  inputId='copyManualMessages'
                  value={copyManualMessages}
                  mt='1.5rem'
                  mb='1.5rem'
                  onSave={(value) => {
                    this.setState({
                      copyManualMessages: value
                    })
                  }}
                />
              </>)}

          {!members.get('loading') && !newSequence.get('loading') &&
            <Button
              label='transfer'
              disabled={!userId}
              onClick={() => {
                onTransfer(userId, {
                  copy_manual_messages: copyManualMessages
                })
              }}
              width='11.5rem'
              mr='auto'
              mb='1rem'
              destructive
            />}

          {!members.get('loading') && newSequence.get('loading') && <LoadingWrapper><Loading size={24} padding={0} /></LoadingWrapper>}
        </FieldContainer>
      </Wrapper>
    )
  }
}

Transfer.propTypes = {
  sequence: PropTypes.object,
  onTransfer: PropTypes.func,
  members: PropTypes.object,
  newSequence: PropTypes.object,
  isManager: PropTypes.bool,
  group: PropTypes.string
}

export default Transfer

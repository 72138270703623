import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import withSession from 'hocs/session'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectSession, selectState } from 'containers/App/selectors'
import { selectSequences } from 'containers/Dashboard/selectors'
import { fetchSequences } from 'containers/Dashboard/actions'
import { pluralize } from 'utils/strings'
import Immutable from 'immutable'
import ConfirmModal from 'components/ConfirmModal'
import {
  fetchTalentPool,
  fetchMoreTalents,
  updateTalentPool,
  createTalent,
  addTalentToSequence,
  deleteCandidate
} from './actions'
import { selectTalentPool } from './selectors'
import SidebarFilter from './components/SidebarFilter'
import TalentPoolTable from './components/TalentPoolTable'
import CreateTalentModal from './components/CreateTalent'
import AddToSequence from './components/AddToSequence'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  background-color: ${theme.colors.gray20};
`

const ContentDivider = styled.div`
  margin-top: 2rem;
  padding: 0 1.5rem;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

let editedRows = Immutable.Set()

class TalentPool extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isEditMode: false,
      showAddTalentModal: false,
      showAddToSequenceModal: false,
      showDeleteConfirmModal: false,
      showSaveConfirmModal: false,
      selectedTalents: null,
      selectedIds: Immutable.Set(),

      // FILTERS
      selectedNames: Immutable.Set(),
      selectedJobTitles: Immutable.Set(),
      selectedLocations: Immutable.Set(),
      selectedTags: Immutable.Set(),
      selectedLastMessagedStartDate: null,
      selectedLastMessagedEndDate: null,
      selectedLastRepliedStartDate: null,
      selectedLastRepliedEndDate: null
    }

    this.wrapperRef = React.createRef()
  }

  componentDidMount () {
    document.title = 'Interseller | Talent Pool'

    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)

    if (this.wrapperRef?.current) {
      this.wrapperRef.current.addEventListener('mousewheel', this.preventAutoNavigateOnHorizontalScroll, false)
    }
  }

  componentWillUnmount () {
    this.wrapperRef?.current.removeEventListener('mousewheel', this.preventAutoNavigateOnHorizontalScroll, false)
  }

  routerWillLeave = (nextLocation) => {
    if (this.state.isEditMode) {
      this.setState({
        showSaveConfirmModal: true
      })
      return false
    }

    return true
  }

  preventAutoNavigateOnHorizontalScroll = (event) => {
    event.stopPropagation()

    if (this.wrapperRef?.current) {
      const wrapper = this.wrapperRef.current
      const max = wrapper.scrollWidth - wrapper.offsetWidth

      if (wrapper.scrollLeft + event.deltaX < 0 || wrapper.scrollLeft + event.deltaX > max) {
        event.preventDefault()
        wrapper.scrollLeft = Math.max(0, Math.min(max, wrapper.scrollLeft + event.deltaX))
      }
    }
  }

  handleSave = () => {
    this.setState({
      isEditMode: false
    })

    this.props.actions.updateTalentPool(editedRows)

    editedRows = Immutable.Set()
  }

  onAddTalent = () => {
    this.setState({
      showAddTalentModal: true
    })
  }

  onDeleteTalent = () => {
    this.setState({
      showDeleteConfirmModal: true
    })
  }

  onAddToCampaign = (ids) => {
    this.setState({
      showAddToSequenceModal: true
    })
  }

  onAddToSequence = (sequence) => {
    this.props.actions.addTalentToSequence({
      peopleIds: this.state.selectedIds,
      campaignId: sequence?.get('_id') || null,
      campaignName: sequence?.get('title') || ''
    })

    this.setState({
      showAddToSequenceModal: false
    })
  }

  onDeleteCandidate = () => {
    const message = `Deleted ${pluralize('candidate', 'candidates', this.state.selectedIds?.size)}! 🚀`

    this.props.actions.deleteCandidate(this.state.selectedIds, message)
    this.setState({
      showDeleteConfirmModal: false
    })
  }

  onClearFilters = () => {
    this.setState({
      selectedNames: Immutable.Set(),
      selectedJobTitles: Immutable.Set(),
      selectedLocations: Immutable.Set(),
      selectedTags: Immutable.Set(),
      selectedLastMessagedStartDate: null,
      selectedLastMessagedEndDate: null,
      selectedLastRepliedStartDate: null,
      selectedLastRepliedEndDate: null
    })
  }

  render () {
    const isLoading = this.props.talentPool.get('loading')
    const hasFilters = !!Object.keys(this.props.location.query).length
    const isEnabled = this.props.state.get('talent_pool_enabled')
    const isTalentPoolPopulating = this.props.talentPool?.get('talent_pool_currently_populating')

    // No talents yet state
    const talentPoolHasNoEntries = !this.props.talentPool?.getIn(['data', 'total']) && !hasFilters && !isLoading

    return (
      <Wrapper ref={this.wrapperRef}>
        <ContentDivider />
        <ContentWrapper>
          {isEnabled && !talentPoolHasNoEntries &&
            <SidebarFilter
              actions={this.props.actions}
              location={this.props.location}
              talentPool={this.props.talentPool}
              router={this.props.router}
              session={this.props.session}
              state={this.props.state}
              selectedNames={this.state.selectedNames}
              setSelectedNames={(selectedNames) => {
                this.setState({
                  selectedNames
                })
              }}
              selectedJobTitles={this.state.selectedJobTitles}
              setSelectedJobTitles={(selectedJobTitles) => {
                this.setState({
                  selectedJobTitles
                })
              }}
              selectedLocations={this.state.selectedLocations}
              setSelectedLocations={(selectedLocations) => {
                this.setState({
                  selectedLocations
                })
              }}
              selectedTags={this.state.selectedTags}
              setSelectedTags={(selectedTags) => {
                this.setState({
                  selectedTags
                })
              }}
              selectedLastMessagedStartDate={this.state.selectedLastMessagedStartDate}
              setLastMessagedStartDate={(selectedLastMessagedStartDate) => {
                this.setState({
                  selectedLastMessagedStartDate
                })
              }}
              selectedLastMessagedEndDate={this.state.selectedLastMessagedEndDate}
              setLastMessagedEndDate={(selectedLastMessagedEndDate) => {
                this.setState({
                  selectedLastMessagedEndDate
                })
              }}
              selectedLastRepliedStartDate={this.state.selectedLastRepliedStartDate}
              setLastRepliedStartDate={(selectedLastRepliedStartDate) => {
                this.setState({
                  selectedLastRepliedStartDate
                })
              }}
              selectedLastRepliedEndDate={this.state.selectedLastRepliedEndDate}
              setLastRepliedEndDate={(selectedLastRepliedEndDate) => {
                this.setState({
                  selectedLastRepliedEndDate
                })
              }}
              onClearFilters={this.onClearFilters}
            />}
          <TalentPoolTable
            actions={this.props.actions}
            talentPool={this.props.talentPool}
            isLoading={isLoading}
            onAddTalent={this.onAddTalent}
            onAddToCampaign={this.onAddToCampaign}
            onDeleteTalent={this.onDeleteTalent}
            selectedTalents={this.state.selectedTalents}
            setSelectedTalents={(selectedTalents) => {
              this.setState({
                selectedTalents
              })
            }}
            selectedIds={this.state.selectedIds}
            setSelectedIds={(selectedIds) => {
              this.setState({
                selectedIds
              })
            }}
            hasFilters={hasFilters}
            onClearFilters={this.onClearFilters}
            isEnabled={isEnabled}
            talentPoolHasNoEntries={talentPoolHasNoEntries}
            isEditMode={this.state.isEditMode}
            setIsEditMode={(isEditMode) => {
              this.setState({
                isEditMode
              })
            }}
            editedRows={editedRows}
            handleSave={this.handleSave}
            isTalentPoolPopulating={isTalentPoolPopulating}
          />
        </ContentWrapper>

        <CreateTalentModal
          isOpen={this.state.showAddTalentModal}
          onCancel={() => {
            this.setState({
              showAddTalentModal: false
            })
          }}
        />
        <AddToSequence
          actions={this.props.actions}
          isOpen={this.state.showAddToSequenceModal}
          onCancel={() => {
            this.setState({
              showAddToSequenceModal: false
            })
          }}
          sequences={this.props.sequences}
          onConfirm={this.onAddToSequence}
          description={`Select the sequence to add ${this.selectedIds?.size} ${pluralize('candidate', 'candidates', this.selectedIds?.size)} to`}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteConfirmModal}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false
            })
          }}
          title='Delete Confirmation'
          description={`Are you sure you want to delete ${this.selectedIds?.size} ${pluralize('candidate', 'candidates', this.selectedIds?.size)} ?`}
          onConfirm={this.onDeleteCandidate}
        />
        <ConfirmModal
          isOpen={this.state.showSaveConfirmModal}
          onCancel={() => {
            this.setState({
              showSaveConfirmModal: false
            })
          }}
          title='Unsaved Changes'
          description='Do you want to save changes to your talent pool?'
          onConfirm={() => {
            this.handleSave()
            this.setState({
              showSaveConfirmModal: false
            })
          }}
        />
      </Wrapper>
    )
  }
}

TalentPool.propTypes = {
  actions: PropTypes.object,
  router: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  location: PropTypes.object,
  talentPool: PropTypes.object,
  sequences: PropTypes.object,
  route: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  session: selectSession(),
  state: selectState(),
  talentPool: selectTalentPool(),
  sequences: selectSequences()
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({
      fetchTalentPool,
      fetchMoreTalents,
      updateTalentPool,
      createTalent,
      fetchSequences,
      addTalentToSequence,
      deleteCandidate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSession(withRouter(TalentPool)))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import R from 'utils/ramda'
import styled from 'styled-components'
import { isValidEmail } from 'utils/strings'
import { ACCOUNT_NAMES } from 'containers/App/constants'
import TextFieldForm from 'components/TextFieldForm'
import RichTextFieldForm from 'components/RichTextFieldForm'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Button from 'components/Button'
import Label from 'elements/Label'
import Subtitle from 'elements/Subtitle'
import RevokeAccountModal from './components/RevokeAccountModal'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const DescriptionLabel = styled(Label)`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const FieldContainerLabel = styled(Label)`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const DangerButton = styled(Button)`
  background-color: ${theme.colors.red};
  border-color: ${theme.colors.red};
`

class General extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...this.buildStateFromProps(props),
      showDeleteAccountConfirmationModal: false
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.fetchSessionAddresses()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const state = this.buildStateFromProps(nextProps)
    this.setState(state)
  }

  buildStateFromProps = (props) => {
    const {
      session
    } = props
    return {
      signature: session.get('signature')
    }
  }

  render () {
    const {
      session,
      onSettingUpdate,
      addresses,
      actions
    } = this.props

    const {
      signature,
      showDeleteAccountConfirmationModal
    } = this.state

    const accountEmailType = ACCOUNT_NAMES[session?.get('account_type')] || 'Email'
    const bcc = session.get('bcc')
    const isNylas = session.get('nylas_id')
    const fullName = isValidEmail(session.get('full_name')) ? '' : session.get('full_name')

    // Send as variables
    const addressValue = session.getIn(['gmail_send_as', 'email'])
    let gmailSignature = session.getIn(['gmail_send_as', 'signature'])

    // Build options for alias dropdown
    let addressOptions = Immutable.List([])
    let addressMap = Immutable.Map({})
    if (addresses) {
      addressOptions = addresses.map((option, i) => (
        {
          label: option.get('email'),
          value: option.get('email'),
          index: i
        }
      ))
      const byEmail = R.groupBy(option => (option.get('email')))
      addressMap = byEmail(addresses)

      if (!gmailSignature && addresses.count() > 0) {
        gmailSignature = addresses.getIn([0, 'signature'])
      }
    }

    // If we found a gmailSignature in the session or in the alias show the button
    let importSignatureMessage
    if (importSignatureMessage) {
      importSignatureMessage = (
        <span>
          <br />
          <Link
            onClick={() => {
              this.setState({ signature: '' }, () => { this.setState({ signature: gmailSignature }) })
            }}
          >
            import signature from Gmail.
          </Link>
        </span>
      )
    }

    return (
      <Wrapper>
        <Subtitle>Email</Subtitle>
        {isNylas &&
          <FieldContainer>
            <TextFieldForm
              label='Name'
              description={
                <span>
                  Set the name for your account that is displayed when sending emails.
                </span>
          }
              value={fullName}
              inputId='full_name'
              onSave={(value) => {
                onSettingUpdate({ full_name: value })
              }}
            />
          </FieldContainer>}
        <FieldContainer>
          <TextFieldForm
            label='BCC'
            description={
              <span>
                Set the BCC email address of your integration you'd like to record activity too. It is not recommended to BCC another teammate.
              </span>
            }
            value={bcc}
            inputId='bcc'
            onSave={(value) => {
              onSettingUpdate({ bcc: value })
            }}
          />
        </FieldContainer>
        {addressOptions.count() > 0 &&
          <FieldContainer>
            <DropDownFieldForm
              label='Send as'
              description='Set the Gmail alias that your emails get sent as. If this setting is left blank, we will use your default Gmail address.'
              helpUrl='https://help.interseller.io/article/29-changing-the-send-as-email-for-your-account'
              options={addressOptions}
              value={addressValue}
              clearable
              inputId='gmail_send_as'
              onSave={(value) => {
                const gmailAlias = value ? addressMap.get(value).first().toJS() : null
                onSettingUpdate({ gmail_send_as: gmailAlias })
              }}
            />
          </FieldContainer>}
        <FieldContainer>
          <RichTextFieldForm
            label='Signature'
            description={
              <span>
                This adds a signature to all messages that are sent through Interseller. {importSignatureMessage}
              </span>
            }
            value={signature}
            inputId='signature'
            isBrandedDomain={session.get('branded_domain_enabled') || false}
            onSave={(value) => {
              onSettingUpdate({ signature: value })
            }}
          />
        </FieldContainer>

        <FieldContainer>
          <FieldContainerLabel>Disconnect {accountEmailType} Account</FieldContainerLabel>
          <DescriptionLabel> Disconnecting your {accountEmailType} account will remove our ability to send and sync emails with your inbox. Use this if you no longer plan on using Interseller. </DescriptionLabel>
          <DangerButton
            mt='.75rem'
            primary
            label='disconnect'
            handleClick={(e) => {
              this.setState({
                showDeleteAccountConfirmationModal: true
              })
            }}
          />
        </FieldContainer>
        <RevokeAccountModal
          session={session}
          actions={actions}
          isOpen={showDeleteAccountConfirmationModal}
          onModalClose={(e) => {
            this.setState({
              showDeleteAccountConfirmationModal: false
            })
          }}
        />
      </Wrapper>
    )
  }
}

General.propTypes = {
  session: PropTypes.object,
  actions: PropTypes.object,
  addresses: PropTypes.instanceOf(Immutable.List),
  onSettingUpdate: PropTypes.func
}

General.defaultProps = {
  addresses: Immutable.List([])
}

export default General

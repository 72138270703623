import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from 'elements/Label'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  transition: border 0.2s linear;
  border: 1px solid ${
    props => props.showPanel
      ? theme.colors.talentPoolBlue
      : theme.colors.talentPoolBorderGray
  };

  cursor: pointer;
  margin-top: -1px;
  margin-bottom: ${props => props.showPanel ? '1px' : 0};
  z-index: ${props => props.showPanel ? 1 : 0};

  &:first-child {
    margin-top: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  transition: background-color 0.2s linear;
  background-color: ${
    props => props.showPanel || props.hasFilters
      ? theme.colors.white
      : theme.colors.talentPoolHeaderBackground
  };
`

const LabelWrapper = styled(Label)`
  font: ${theme.fonts.searchtitle};
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: ${theme.colors.blueText};
  user-select: none;
`

const Panel = styled.div`
  transition: padding-bottom 0.2s linear;
  padding-bottom: ${props => props.showPanel || props.hasFilters ? '1rem' : 0};
`

function CollapsiblePanel ({
  label,
  isOpen = false,
  openedPanel,
  setOpenedPanel,
  field,
  filters,
  ...props
}) {
  const [showPanel, setShowPanel] = useState(isOpen)

  const handleOpen = e => {
    setOpenedPanel(field)
    setShowPanel(!showPanel)
  }

  useEffect(() => {
    if (showPanel && openedPanel !== field) {
      setShowPanel(false)
    }
  }, [openedPanel])

  return (
    <Wrapper showPanel={showPanel}>
      <HeaderWrapper
        onClick={handleOpen}
        showPanel={showPanel}
        hasFilters={filters?.size || filters?.length}
      >
        {label && <LabelWrapper>{label}</LabelWrapper>}
        <div style={{ textAlign: 'right' }}>
          <FontAwesomeIcon
            icon={showPanel ? ['far', 'angle-up'] : ['far', 'angle-down']}
            color='#588BE2'
          />
        </div>
      </HeaderWrapper>
      <Panel showPanel={showPanel} hasFilters={filters?.size || filters?.length}>
        {props.children && React.cloneElement(props.children, {
          showPanel,
          selectedFilters: filters,
          field
        })}
      </Panel>
    </Wrapper>
  )
}

CollapsiblePanel.propTypes = {
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.object,
  openedPanel: PropTypes.string,
  setOpenedPanel: PropTypes.func,
  field: PropTypes.string,
  // Immutable.Set or [new Date]
  filters: PropTypes.any
}

export default CollapsiblePanel

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import NumberFormat from 'react-number-format'
import {
  Column,
  AutoSizer,
  Table,
  SortIndicator
} from 'react-virtualized'
import Loading from 'components/Loading'
import { FullContainer } from 'containers/Reports/styles'
import { SEQUENCES_REPORTS } from 'containers/Reports/constants'
import theme from '../../../../../themes/light'

const TableContainer = styled.div`
  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1rem;
  }

  .ReactVirtualized__Table__headerRow {
    border: 1px solid ${theme.colors.gray30};
    background-color: ${theme.colors.gray10};
    color: ${theme.colors.darkBlue};
    font-size: 15px;
    text-transform: capitalize;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    background-color: white;
    padding: 0;
    color: ${theme.colors.darkBlue};

    border: 1px solid ${theme.colors.gray30};
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid ${theme.colors.gray30};
    }
  }
`

const ColumnGroup = styled.div`
  color: ${theme.colors.darkGray};
  font: ${theme.fonts.normal};
`

const RowGroup = styled.div`
  display: flex;
  align-items: center;
`

const UserCell = styled.div`
  display: flex;
  color: ${theme.colors.darkGray};
`

const UserDetails = styled.div`
  flex: 1;
  width: 0;
  font: ${theme.fonts.normal};
  margin-left: 0.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const UserDetailName = styled.div`
  font: ${theme.fonts.formtitle};
  color: ${theme.colors.darkBlue};
`

class SequencesReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  formatTableData = () => {
    const { users, sequencesReports } = this.props

    let { sortBy, sortDirection } = this.state
    const tableRows = []
    const columnNames = SEQUENCES_REPORTS

    users
      .get('data')
      .filter(user => {
        return sequencesReports.hasIn(['data', 'reports', user.get('id')])
      })
      .forEach(user => {
        const report = sequencesReports.getIn(['data', 'reports', user.get('id')]) || Immutable.Map()

        // `newRow` represents each row that get's added to the table
        const newRow = { user, userName: user.get('full_name') }
        columnNames.forEach(columnName => {
          newRow[`report_${columnName}`] = report.getIn([columnName])
        })

        tableRows.push(newRow)
      })

    if (sortBy) {
      if (sortBy === 'user') {
        sortBy = 'userName'
      }

      let sort = Immutable.List(tableRows)
      sort = sort.sortBy(row => row[sortBy])

      if (sortDirection === 'DESC') {
        sort = sort.reverse()
      }

      return sort.toArray()
    }

    return tableRows
  }

  _numberCellRenderer = ({ dataKey, rowData }) => {
    const reportData = rowData[dataKey] || 0

    return (
      <ColumnGroup>
        <RowGroup>
          <NumberFormat
            value={reportData}
            displayType='text'
            thousandSeparator=','
            style={{ fontWeight: '300', fontSize: '18px' }}
          />
        </RowGroup>
      </ColumnGroup>
    )
  }

  _userCellRenderer = ({ cellData }) => {
    return (
      <UserCell>
        <Avatar
          key={`user_${cellData.get('id')}`}
          src={cellData.get('photo_url' || '/bear_avatar.png')}
          size={44}
          round
        />
        <UserDetails>
          <UserDetailName>{cellData.get('full_name')}</UserDetailName>
          <div>{cellData.get('email')}</div>
        </UserDetails>
      </UserCell>
    )
  }

  _headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => {
    return (
      <div>
        {label}
        {sortBy === dataKey &&
          <SortIndicator sortDirection={sortDirection} />}
      </div>
    )
  }

  sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortBy,
      sortDirection
    })
  }

  render () {
    const { sequencesReports } = this.props
    const { sortBy, sortDirection } = this.state

    if (!sequencesReports || sequencesReports.get('loading')) {
      return <Loading width='100%' />
    }

    const columnWidth = 125
    const tableRows = this.formatTableData()

    return (
      <FullContainer>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={70}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    key='user'
                    dataKey='user'
                    label='User'
                    width={columnWidth}
                    flexGrow={4}
                    cellRenderer={this._userCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Active'
                    dataKey='report_campaigns_active'
                    label='Active'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='SentMessageLast7Days'
                    dataKey='report_sent_message_last_7_days'
                    label='Messaged (Last 7 Days)'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='SentMessageLast30Days'
                    dataKey='report_sent_message_last_30_days'
                    label='Messaged (Last 30 Days)'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Total'
                    dataKey='report_campaigns_total'
                    label='Total'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </FullContainer>
    )
  }
}

SequencesReport.propTypes = {
  actions: PropTypes.object,
  users: PropTypes.object,
  sequencesReports: PropTypes.object
}

export default SequencesReport

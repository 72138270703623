import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { pluralize } from 'utils/strings'
import theme from '../../../../../themes/light'

import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  Header,
  TabContainer
} from '../../Toolbar/ToolbarModal'
import { EMAIL_STATUS } from '../../../utils/rules-engine'

import Title from 'elements/Title'
import Label from 'elements/Label'
import Divider from 'elements/Divider'
import Button from 'components/Button'

const EditorCoachWrapper = styled.div`
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const EditorCoachLight = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;

  border: 0px;
  border-radius: 100%;
  padding: .5rem;
  margin-top: 1px;

  font: ${theme.colors.title};
  font-size: 13px;

  color: white;
  background-color: ${(props) => {
    if (props.statusColor === EMAIL_STATUS.GREEN) {
      return theme.colors.green
    } else if (props.statusColor === EMAIL_STATUS.AMBER) {
      return theme.colors.yellow
    } else if (props.statusColor === EMAIL_STATUS.RED) {
      return theme.colors.red
    }
  }};

  cursor: pointer;

  &:hover {
    box-shadow: ${theme.shadow};
  }
`

const EditorCoachModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  padding: .25rem;
`

const EditorCoachModalBulletPointWrapper = styled.div`
  padding-top: 8px;
  margin-right: 0.5rem;
`

const EditorCoachModalBulletPoint = styled.div`
  width: 11px;
  height: 11px;

  border-radius: 100%;

  background-color: ${props => {
    if (props.statusColor === EMAIL_STATUS.GREEN) {
      return theme.colors.green
    } else if (props.statusColor === EMAIL_STATUS.AMBER) {
      return theme.colors.yellow
    } else if (props.statusColor === EMAIL_STATUS.RED) {
      return theme.colors.red
    }
  }};
`

const ErrorLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ErrorLabel = styled(Label)`
  color: ${props => props.title
    ? theme.colors.darkGray
    : theme.colors.gray70
  };

  font-size: ${props => props.title
    ? '17px'
    : '15px'
  };

  font-weight: ${props => props.title
    ? 600
    : 300
  };
`

function EditorCoach ({ brokenRules }) {
  const [showEditorCoach, setShowEditorCoach] = useState(false)

  const editorCoachColor = brokenRules.reduce((acc, curr) => (
    (acc > curr.color)
      ? acc
      : curr.color
  ), EMAIL_STATUS.GREEN)

  const errorCount = brokenRules
    .filter(rule => rule.details !== null)
    .length

  const tooltipMessage = (errorCount === 0)
    ? 'There are no issues with your email'
    : `${errorCount} ${pluralize('suggestion', 'suggestions', errorCount)} to help improve your email &mdash; click to view ${pluralize('suggestion', 'suggestions', errorCount)}`

  const renderRulesModal = () => {
    return (
      <Wrapper>
        <Curtain
          visible
          onClick={() => {
            setShowEditorCoach(false)
          }}
        />
        <Window>
          <TabContainer>
            <Header>
              <Title> Email Suggestions </Title>
              <Label mt='0.5rem' pr='3rem' pl='3rem' align='center'>
                Here are some suggestions that we think could help improve your chances of landing in your contact's inbox
              </Label>
            </Header>

            {brokenRules
              .filter(rule => rule.details !== null)
              .map(({ rule, details, color }, index) => {
                // captialize first letter of each rule
                rule = rule
                  .charAt(0).toUpperCase() + rule.slice(1)
                  .replace(/_/ig, ' ')

                return (
                  <EditorCoachModalRow key={`ecm-${index}`}>
                    <EditorCoachModalBulletPointWrapper>
                      <EditorCoachModalBulletPoint statusColor={color} />
                    </EditorCoachModalBulletPointWrapper>
                    <ErrorLabelContainer>
                      <ErrorLabel title>{rule.replace('_', ' ')}</ErrorLabel>
                      <ErrorLabel>{details}</ErrorLabel>
                    </ErrorLabelContainer>
                  </EditorCoachModalRow>
                )
              })}

            {brokenRules
              .filter(rule => rule.details === null)
              .map(({ rule, color }, index) => {
                // captialize first letter of each rule
                rule = rule
                  .charAt(0).toUpperCase() + rule.slice(1)
                  .replace(/_/ig, ' ')

                return (
                  <EditorCoachModalRow key={`ecm-${index}`}>
                    <EditorCoachModalBulletPointWrapper>
                      <EditorCoachModalBulletPoint statusColor={color} />
                    </EditorCoachModalBulletPointWrapper>
                    <ErrorLabelContainer>
                      <ErrorLabel title>{rule}</ErrorLabel>
                    </ErrorLabelContainer>
                  </EditorCoachModalRow>
                )
              })}
          </TabContainer>

          <Divider />

          <ButtonContainer>
            <Button
              label='close'
              handleClick={() => {
                setShowEditorCoach(false)
              }}
            />
          </ButtonContainer>
        </Window>
      </Wrapper>
    )
  }

  return (
    <EditorCoachWrapper>
      <Tooltip
        title={tooltipMessage}
        position='top'
        trigger='mouseenter'
        arrow
      >
        <EditorCoachLight
          statusColor={editorCoachColor}
          onClick={() => {
            setShowEditorCoach(true)
          }}
        >
          {errorCount}
        </EditorCoachLight>
      </Tooltip>

      {showEditorCoach && renderRulesModal()}
    </EditorCoachWrapper>
  )
}

EditorCoach.propTypes = {
  brokenRules: PropTypes.array
}

export default EditorCoach

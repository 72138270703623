import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  endOfDay,
  startOfDay
} from 'date-fns'
import { toUTC } from 'utils/dates'
import CollapsiblePanel from './components/CollapsiblePanel'
import FilterDropdown from './components/FilterDropdown'
import FilterTextField from './components/FilterTextField'
import DateRange from './components/DateRange'
import theme from '../../../../themes/light'

const Container = styled.div`
  max-width: 350px;
  min-width: 350px;
  padding-left: 1.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  font: ${theme.fonts.normal};

  margin-bottom: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px ${theme.colors.gray30};
  border-bottom: 0px;
  border-radius: 3px 3px 0 0;

  background-color: ${theme.colors.white};
  padding: 1.4rem 1rem;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.blueText};

  margin-bottom: 0;
`

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const ClearButton = styled.div`
  cursor: pointer;

  color: #7A859F;
  font-size: 13px;
  font-weight: 600;

  margin-right: 5px;
`

const FilterCount = styled.div`
  border: 1px solid #588BE2;
  border-radius: 50px;
  padding: 0 7px;
  line-height: 18px;

  background-color: #E9F1FF;

  color: #588BE2;
  font-weight: 600;
  font-size: 11px;
`

const FilterWrapper = styled.div`
  background-color: ${theme.colors.white};
  overflow: auto;
  width: 100%;

  > :last-child {
    padding-bottom: 0;
  }
`

const lastMessaged = 'Last Messaged'
const lastReplied = 'Last Replied'
// const lastAdded = 'Last Added'

function SidebarFilter ({
  actions,
  filters,
  location,
  talentPool,
  router,
  onSetFilters,
  onClearFilters,
  selectedNames,
  setSelectedNames,
  selectedJobTitles,
  setSelectedJobTitles,
  selectedLocations,
  setSelectedLocations,
  selectedTags,
  setSelectedTags,
  selectedLastMessagedStartDate,
  setLastMessagedStartDate,
  selectedLastMessagedEndDate,
  setLastMessagedEndDate,
  selectedLastRepliedStartDate,
  setLastRepliedStartDate,
  selectedLastRepliedEndDate,
  setLastRepliedEndDate
}) {
  const [openedPanel, setOpenedPanel] = useState(null)

  const setDateRangeMap = {
    [lastMessaged]: {
      start: setLastMessagedStartDate,
      end: setLastMessagedEndDate
    },
    [lastReplied]: {
      start: setLastRepliedStartDate,
      end: setLastRepliedEndDate
    }
    // [lastAdded]: {
    //   start: setLastAddedStartDate,
    //   end: setLastAddedEndDate
    // }
  }

  const selectedLastMessaged = (selectedLastMessagedStartDate && selectedLastMessagedEndDate)
    ? [toUTC(selectedLastMessagedStartDate).toISOString(), toUTC(selectedLastMessagedEndDate).toISOString()]
    : null
  const selectedLastReplied = (selectedLastRepliedStartDate && selectedLastRepliedEndDate)
    ? [toUTC(selectedLastRepliedStartDate).toISOString(), toUTC(selectedLastRepliedEndDate).toISOString()]
    : null

  useEffect(() => {
    setRouteParameters()
    fetchTalentPool()
  }, [
    selectedNames,
    selectedLocations,
    selectedJobTitles,
    selectedTags,
    selectedLastMessagedEndDate,
    selectedLastMessagedStartDate,
    selectedLastRepliedStartDate,
    selectedLastRepliedEndDate
  ])

  const setRouteParameters = () => {
    const name = selectedNames.toArray()
    const locations = selectedLocations.toArray()
    const jobTitles = selectedJobTitles.toArray()
    const tag = selectedTags.toArray()

    const lastMessagedStartDate = selectedLastMessagedStartDate?.toISOString() || null
    const lastMessagedEndDate = selectedLastMessagedEndDate?.toISOString() || null
    const lastRepliedStartDate = selectedLastRepliedStartDate?.toISOString() || null
    const lastRepliedEndDate = selectedLastRepliedEndDate?.toISOString() || null

    const nameString = name.length ? name.join(',') : null
    const locationString = locations.length ? locations.join(',') : null
    const jobTitleString = jobTitles.length ? jobTitles.join(',') : null
    const tagString = tag.length ? tag.join(',') : null
    const lastMessaged = lastMessagedStartDate && lastMessagedEndDate ? [lastMessagedStartDate, lastMessagedEndDate] : null
    const lastReplied = lastRepliedStartDate && lastRepliedEndDate ? [lastRepliedStartDate, lastRepliedEndDate] : null

    const query = {
      name: nameString,
      location: locationString,
      title: jobTitleString,
      tag: tagString,
      last_messaged_at: lastMessaged,
      replied_at: lastReplied
    }

    if (!query.name || query.name.length <= 0) {
      delete query.name
    }

    if (!query.location || query.location.length <= 0) {
      delete query.location
    }

    if (!query.title || query.title.length <= 0) {
      delete query.title
    }

    if (!query.tag || query.tag.length <= 0) {
      delete query.tag
    }

    if (!query.last_messaged_at) {
      delete query.last_messaged_at
    }

    if (!query.replied_at) {
      delete query.replied_at
    }

    router.push({ ...location, query })

    fetchTalentPool()
  }

  const fetchTalentPool = () => {
    const params = {
      name: selectedNames.toArray(),
      location: selectedLocations.toArray(),
      title: selectedJobTitles.toArray(),
      notes: selectedTags.toArray(),
      last_messaged_at: selectedLastMessaged,
      replied_at: selectedLastReplied
    }

    actions.fetchTalentPool(params)
  }

  const setDateRange = (newStartDate, newEndDate, type) => {
    if (!newStartDate || !newEndDate || (newStartDate > newEndDate)) {
      return
    }

    if (type && setDateRangeMap[type]) {
      const setStart = setDateRangeMap[type].start
      const setEnd = setDateRangeMap[type].end
      setStart(startOfDay(newStartDate))
      setEnd(endOfDay(newEndDate))
    }
  }

  let allFilters = selectedNames.concat(selectedLocations, selectedTags, selectedJobTitles)
  allFilters = selectedLastMessaged ? allFilters.concat([selectedLastMessaged]) : allFilters
  allFilters = selectedLastReplied ? allFilters.concat([selectedLastReplied]) : allFilters

  const selectedFilterCount = allFilters.size

  return (
    <Container>
      <Wrapper>
        <Header>
          <div>Filters</div>
          {selectedFilterCount > 0 &&
            <HeaderActions>
              <ClearButton onClick={onClearFilters}>Clear</ClearButton>
              <FilterCount>{selectedFilterCount}</FilterCount>
            </HeaderActions>}
        </Header>
        <FilterWrapper>
          <CollapsiblePanel
            label='Name'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='names'
            filters={selectedNames}
          >
            <FilterTextField
              onSelected={setSelectedNames}
              list={talentPool}
              placeholder='Search names'
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            label='Job Title'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='role'
            filters={selectedJobTitles}
          >
            <FilterDropdown
              onSelected={setSelectedJobTitles}
              list={talentPool}
              placeholder='Search titles'
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            label='Location'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='location'
            filters={selectedLocations}
          >
            <FilterDropdown
              onSelected={setSelectedLocations}
              list={talentPool}
              placeholder='Search locations'
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            label='Tags'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='notes'
            filters={selectedTags}
          >
            <FilterTextField
              onSelected={setSelectedTags}
              list={talentPool}
              placeholder='Search tags'
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            label='Last Messaged'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='lastMessaged'
            filters={selectedLastMessaged}
          >
            <DateRange
              startDate={selectedLastMessagedStartDate}
              endDate={selectedLastMessagedEndDate}
              setDateRange={(start, end) => setDateRange(start, end, lastMessaged)}
              onRemoveSelectedFilter={() => {
                setLastMessagedStartDate(null)
                setLastMessagedEndDate(null)
              }}
            />
          </CollapsiblePanel>
          <CollapsiblePanel
            label='Last Replied'
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            field='lastReplied'
            filters={selectedLastReplied}
          >
            <DateRange
              startDate={selectedLastRepliedStartDate}
              endDate={selectedLastRepliedEndDate}
              setDateRange={(start, end) => setDateRange(start, end, lastReplied)}
              onRemoveSelectedFilter={() => {
                setLastRepliedStartDate(null)
                setLastRepliedEndDate(null)
              }}
            />
          </CollapsiblePanel>
        </FilterWrapper>
      </Wrapper>
    </Container>
  )
}

SidebarFilter.propTypes = {
  actions: PropTypes.object,
  filters: PropTypes.object,
  location: PropTypes.object,
  talentPool: PropTypes.object,
  router: PropTypes.object,
  onSetFilters: PropTypes.func,
  onClearFilters: PropTypes.func,

  // FILTERS
  selectedNames: PropTypes.object,
  setSelectedNames: PropTypes.func,
  selectedJobTitles: PropTypes.object,
  setSelectedJobTitles: PropTypes.func,
  selectedTags: PropTypes.object,
  setSelectedTags: PropTypes.func,
  selectedLocations: PropTypes.object,
  setSelectedLocations: PropTypes.func,
  selectedLastMessagedStartDate: PropTypes.object,
  setLastMessagedStartDate: PropTypes.func,
  selectedLastMessagedEndDate: PropTypes.object,
  setLastMessagedEndDate: PropTypes.func,
  selectedLastRepliedStartDate: PropTypes.object,
  setLastRepliedStartDate: PropTypes.func,
  selectedLastRepliedEndDate: PropTypes.object,
  setLastRepliedEndDate: PropTypes.func
}

const comp = React.memo(SidebarFilter)

export default comp

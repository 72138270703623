import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import theme from '../../themes/light'

export default styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.gray20};


  ${space}
  ${width}
  ${textAlign}
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { space } from 'styled-system'
import Popper from 'components/Popper'
import Button from 'components/Button'
import Label from 'elements/Label'
import { Menu, MenuHeader, MenuItem } from 'elements/Menu'
import { fadeInRightRule } from 'utils/animations'
import { getGroupsFromMembers, getGroupLabel } from 'utils/groups'
import IconButton from 'components/IconButton'
import ToolbarButton from 'components/ToolbarButton'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  color: ${theme.titleColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.gray10};
  width: ${props => props.width};
  padding: ${theme.padding};
  height: 68px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  animation: ${fadeInRightRule};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LabelWrapper = styled.div`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInRightRule};
`

const Filter = styled.div`
  display: inline-block;
  border: 1px solid ${theme.colors.gray40};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 9999px;
  padding: 6px 1rem;
  margin-right: 0.5rem;
`

const ButtonWrapper = styled.div`
  ${space}
`

class SequenceToolbar extends Component {
  getTooltipTitle = () => {
    const { filterUserId, filterGroupId, bookmarkedId } = this.props
    let title = 'Set this as the default view'

    if (
      bookmarkedId.userId === filterUserId &&
      bookmarkedId.groupId === filterGroupId
    ) {
      title = 'This is the current default view'
    }

    return title
  }

  getIconButtonType = () => {
    const { filterUserId, filterGroupId, bookmarkedId } = this.props
    let type = ['far', 'bookmark']

    if (
      bookmarkedId.userId === filterUserId &&
      bookmarkedId.groupId === filterGroupId
    ) {
      type = ['fas', 'bookmark']
    }

    return type
  }

  getIconColor = () => {
    const { filterUserId, filterGroupId, bookmarkedId } = this.props
    let color = theme.colors.gray70

    if (
      bookmarkedId.userId === filterUserId &&
      bookmarkedId.groupId === filterGroupId
    ) {
      color = theme.colors.blue
    }

    return color
  }

  render () {
    const {
      title,
      selectedSequences,
      allSelected,
      onAddSequence,
      onDelete,
      onUpdate,
      onRestore,
      onExportCsv,
      showArchived,
      onArchiveToggled,
      members,
      session,
      state,
      onFilterSequences,
      onClearFolder,
      filterFolder,
      onAddToFolder,
      onRemoveFromFolder,
      setBookmarkedId
    } = this.props

    let selectedButtons

    if (selectedSequences.length > 0 || allSelected) {
      if (!showArchived) {
        selectedButtons = [
          <ToolbarButton
            key='add_folder'
            tooltip='Add Sequences to Folder'
            icon={['far', 'folder-open']}
            onClick={onAddToFolder}
          />,
          <ToolbarButton
            key='remove_folder'
            tooltip='Remove Sequences from Folder'
            icon={['far', 'folder-times']}
            onClick={onRemoveFromFolder}
          />,
          <ToolbarButton
            key='archive_sequences'
            tooltip='Archive sequences'
            icon={['far', 'archive']}
            onClick={onDelete}
          />,
          <Popper
            key='extra'
            position='bottom-start'
            trigger={
              <IconButton
                type={['far', 'ellipsis-v']}
                translateY={-1}
                translateX={-1}
                iconColor={theme.colors.gray70}
              />
            }
          >
            {close => (
              <Menu style={{ minWidth: '10rem' }} onClick={close}>
                <MenuItem
                  key='activate'
                  onClick={() => {
                    onUpdate({ active: true, paused_until: null })
                  }}
                >
                  Activate sequences
                </MenuItem>
                <MenuItem
                  key='deactivate'
                  onClick={() => {
                    onUpdate({ active: false })
                  }}
                >
                  Deactivate sequences
                </MenuItem>
                <MenuItem
                  key='share'
                  onClick={() => {
                    onUpdate({ shared: 'rw' })
                  }}
                >
                  Share sequences
                </MenuItem>
                <MenuItem
                  key='unshare'
                  onClick={() => {
                    onUpdate({ shared: null })
                  }}
                >
                  Unshare sequences
                </MenuItem>
                <MenuItem
                  key='export'
                  onClick={() => {
                    onExportCsv()
                  }}
                >
                  Export Contacts to CSV
                </MenuItem>
              </Menu>
            )}
          </Popper>
        ]
      } else {
        selectedButtons = [
          <Button
            key='restore_sequences'
            ml='0.5rem'
            label='Restore'
            small
            primary
            onClick={() => {
              onRestore()
            }}
          />
        ]
      }
    }

    const isOwner = state.get('is_owner') || false
    const isManager = state.get('is_manager') || false
    const hasGroup = !!session.get('group')

    const allSequencesOption = (
      <MenuItem
        key='all'
        onClick={() => {
          onFilterSequences(null)
        }}
      >
        All Sequences
      </MenuItem>
    )

    const mySequences = (
      <MenuItem
        key='mine'
        onClick={() => {
          onFilterSequences({
            userId: session.get('_id')
          })
        }}
      >
        My Sequences
      </MenuItem>
    )

    const sortedMembers = members.sort((a, b) => {
      return a.get('full_name').localeCompare(b.get('full_name'))
    }).filter((member) => {
      return member.get('id') !== session.get('_id')
    })

    const groups = getGroupsFromMembers(members)

    const groupsMenuOptions = groups
      .map(group => {
        return (
          <MenuItem
            key={group}
            onClick={() => {
              onFilterSequences({
                group
              })
            }}
          >
            {`${getGroupLabel(group)} Sequences`}
          </MenuItem>
        )
      })

    const menuOptions = sortedMembers
      .map((user) => {
        return (
          <MenuItem
            key={`user_${user.get('id')}`}
            onClick={() => {
              onFilterSequences({
                userId: user.get('id')
              })
            }}
          >
            {`${user.get('full_name')}'s Sequences`}
          </MenuItem>
        )
      })
      .insert(0, mySequences)

    const sortByGroupMenuOptions = new Set()
      .add(groupsMenuOptions)

    return (
      <Wrapper>
        <Container>
          {!showArchived && !filterFolder &&
            <Tooltip
              title={this.getTooltipTitle()}
              position='top'
              trigger='mouseenter'
              arrow
            >
              <IconButton
                type={this.getIconButtonType()}
                buttonColor='transparent'
                borderColor='transparent'
                iconColor={this.getIconColor()}
                onClick={setBookmarkedId}
              />
            </Tooltip>}
          {filterFolder &&
            <ButtonWrapper mr='0.5rem'>
              <Tooltip
                style={{ display: 'block' }}
                key='filter'
                title='Go back / exit folder'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  type={['fal', 'angle-left']}
                  borderColor={theme.colors.gray40}
                  iconColor={theme.colors.darkBlue}
                  onClick={() => {
                    onClearFolder()
                  }}
                  iconSize='lg'
                  translateX='-1'
                />
              </Tooltip>
            </ButtonWrapper>}
          <Filter>
            {title}
          </Filter>
          <Popper
            portal
            position='bottom'
            maxHeight='18rem'
            marginRight='0.5rem'
            trigger={
              <Tooltip
                style={{ display: 'block' }}
                key='filter'
                title='Filter sequences'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  type={['far', 'user']}
                  borderColor={theme.colors.gray40}
                  iconColor={theme.colors.darkBlue}
                />
              </Tooltip>
            }
          >
            {close => (
              <>
                <Menu style={{ minWidth: '12rem' }} onClick={close}>
                  {allSequencesOption}
                </Menu>

                {(isOwner || isManager) && !hasGroup && !!groups.size &&
                  <Menu style={{ minWidth: '12rem' }} onClick={close}>
                    <MenuHeader>
                      Filter Sequences By Group
                    </MenuHeader>
                    {sortByGroupMenuOptions}
                  </Menu>}
                <Menu style={{ minWidth: '12rem' }} onClick={close}>
                  <MenuHeader>
                    Filter Sequences By User
                  </MenuHeader>
                  {menuOptions}
                </Menu>
              </>
            )}
          </Popper>
          <ButtonWrapper ml='0.5rem'>
            <Tooltip
              style={{ display: 'block' }}
              key='filter'
              title={showArchived ? 'Hide archived sequences' : 'View archived sequences'}
              position='top'
              trigger='mouseenter'
              arrow
            >
              <IconButton
                type={['far', 'archive']}
                buttonColor={showArchived ? theme.colors.blue : 'transparent'}
                borderColor={showArchived ? theme.colors.blue : theme.colors.gray40}
                iconColor={showArchived ? theme.colors.white : theme.colors.darkBlue}
                onClick={onArchiveToggled}
              />
            </Tooltip>
          </ButtonWrapper>
        </Container>
        {selectedButtons &&
          <ButtonContainer>
            {selectedSequences.length > 0 &&
              <LabelWrapper>
                <Label fontWeight='600' color='darkBlue'>{selectedSequences.length} selected</Label>
              </LabelWrapper>}

            {selectedButtons}
          </ButtonContainer>}
        {!selectedButtons &&
          <ButtonContainer>
            <Button
              label='Add Sequence'
              small
              primary
              handleClick={onAddSequence}
            />
          </ButtonContainer>}
      </Wrapper>
    )
  }
}

SequenceToolbar.propTypes = {
  title: PropTypes.string,
  selectedSequences: PropTypes.array,
  allSelected: PropTypes.bool,
  onAddSequence: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onRestore: PropTypes.func,
  onArchiveToggled: PropTypes.func,
  onFilterSequences: PropTypes.func,
  onClearFolder: PropTypes.func,
  onExportCsv: PropTypes.func,
  showArchived: PropTypes.bool,
  members: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  filterUserId: PropTypes.string,
  filterGroupId: PropTypes.string,
  onAddToFolder: PropTypes.func,
  onRemoveFromFolder: PropTypes.func,
  filterFolder: PropTypes.string,
  bookmarkedId: PropTypes.object,
  setBookmarkedId: PropTypes.func
}

export default SequenceToolbar

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import { replyTypesToOptions } from 'containers/Sequence/constants'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class ReplyTypeModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showContactInfo: true
    }
  }

  render () {
    const {
      onCancel,
      onConfirm,
      isOpen,
      contact
    } = this.props

    const {
      replyType = 'EMAIL'
    } = this.state

    const listOptions = replyTypesToOptions()
    const name = contact && contact.get('display_name')
    const contactId = contact && contact.get('id')

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper>
          <InputContainer>
            <Title align='center'>Set Replied</Title>
            <Label mt='0.5rem' mb='2rem' align='center'>
              Set how {name} responded to you
            </Label>
            <DropDownFieldForm
              options={listOptions}
              value={replyType}
              clearable
              inputId='list'
              onOptionChange={(option) => {
                this.setState({
                  replyType: option || null
                })
              }}
            />
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label='Set Replied'
              onClick={() => {
                onConfirm(contactId, replyType)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

ReplyTypeModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  contact: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
}

ReplyTypeModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default ReplyTypeModal

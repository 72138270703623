import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { fromJS } from 'immutable'
import { isBefore, isToday, addDays } from 'date-fns'
import Label from 'elements/Label'
import Loading from 'components/Loading'
import api from 'utils/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../themes/light'

const INTEGRATION_NOTIFICATION_DISMISSED_ON = 'INTEGRATION_NOTIFICATION_DISMISSED_ON'
const CHROME_NOTIFICATION_DISMISSED_ON = 'CHROME_NOTIFICATION_DISMISSED_ON'
const DISMISSED_LOOKUP_IDS = 'DISMISSED_LOOKUP_IDS'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`

const IconContainer = styled.div`
  background-color: ${props => props.color || theme.color.gray30};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 64px;
  padding-bottom: 3px;
`

const ToastLabel = styled(Label)`
  flex: 1 0 auto;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 86%;
  padding-right: 2.5rem;

  padding-bottom: calc(1rem + 3px);

  a {
    text-decoration: underline;
  }
`

const CloseButtonContainer = styled.button`
  border-style: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  height: 100%;
  margin-top: -3px;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

const CloseButton = ({ closeToast, onClose }) => (
  <CloseButtonContainer
    onClick={() => {
      closeToast()
      if (onClose) {
        onClose()
      }
    }}
  >
    <svg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Campaign-List' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g id='Campaign-list---Notifications' transform='translate(-1331.000000, -97.000000)' stroke='#C7CBD2' strokeWidth='1.5'>
          <g id='notification' transform='translate(1130.000000, 80.000000)'>
            <g id='button-close' transform='translate(202.000000, 18.000000)'>
              <path d='M7.6,0.4 L0.4,7.6' id='Stroke-3' />
              <path d='M7.6,7.6 L0.4,0.4' id='Stroke-5' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </CloseButtonContainer>
)

const SuccessToast = (message, onClick) => (
  <Container onClick={onClick}>
    <IconContainer color='#1FC866'>
      <FontAwesomeIcon
        icon={['fal', 'check-circle']}
        color='white'
      />
    </IconContainer>
    <ToastLabel p='1rem'>{message}</ToastLabel>
  </Container>
)

const ErrorToast = (message, onClick) => (
  <Container onClick={onClick}>
    <IconContainer color='#E4542B'>
      <FontAwesomeIcon
        icon={['fal', 'times-circle']}
        color='white'
      />
    </IconContainer>
    <ToastLabel p='1rem'>{message}</ToastLabel>
  </Container>
)

const WarningToast = (message, onClick) => (
  <Container onClick={onClick}>
    <IconContainer color='#F6CE1E'>
      <FontAwesomeIcon
        icon={['fal', 'times-circle']}
        color='white'
      />
    </IconContainer>
    <ToastLabel p='1rem'>{message}</ToastLabel>
  </Container>
)

const InProgressToast = message => (
  <Container>
    <IconContainer color='#F6CE1E'><Loading size={20} padding={0} singleColor='#FFF' /></IconContainer>
    <ToastLabel p='1rem'>{message}</ToastLabel>
  </Container>
)

const ReconnectToast = (message, onClick) => (
  <Container onClick={onClick}>
    <IconContainer color='#E4542B'>
      <FontAwesomeIcon
        icon={['fal', 'times-circle']}
        color='white'
      />
    </IconContainer>
    <ToastLabel p='1rem'>
      {message}<br /><br /><a>Click here to reconnect &rarr;</a>
    </ToastLabel>
  </Container>
)

export function success (message) {
  toast(SuccessToast(message), {
    closeButton: <CloseButton />,
    progressStyle: {
      background: '#1FC866'
    }
  })
}

export function error (message) {
  toast(ErrorToast(message), {
    closeButton: <CloseButton />,
    progressStyle: {
      background: '#E4542B'
    }
  })
}

let currentLoadingDelay
let currentLoading

export function loading (message) {
  currentLoadingDelay = setTimeout(() => {
    currentLoading = toast(InProgressToast(message), {
      closeButton: <CloseButton />,
      autoClose: false,
      closeOnClick: true
    })
  }, 500)
}

export function done () {
  if (currentLoadingDelay) {
    clearTimeout(currentLoadingDelay)
  }

  if (currentLoading) {
    toast.dismiss(currentLoading)
  }

  currentLoadingDelay = null
  currentLoading = null
}

export function warning (message) {
  toast(WarningToast(message), {
    closeButton: <CloseButton />,
    progressStyle: {
      background: '#F6CE1E'
    }
  })
}

export function persistentError (message, onClick) {
  toast(ErrorToast(message, onClick), {
    closeButton: <CloseButton />,
    autoClose: false,
    closeOnClick: false
  })
}

export function persistentWarning (message, onClick) {
  toast(WarningToast(message, onClick), {
    closeButton: <CloseButton />,
    autoClose: false,
    closeOnClick: false
  })
}

export function reconnect (message, onClick) {
  toast(ReconnectToast(message, onClick), {
    closeButton: <CloseButton />,
    autoClose: false,
    closeOnClick: false
  })
}

// Chrome toastId used for deduplicating and dismissing on install
let chromeToastId

const ChromeToast = (
  <Container onClick={() => {
    const extensionId = 'coggaiakecmfpkamlplbpacajahgeofh'
    const chromeUrl = `https://chrome.google.com/webstore/detail/${extensionId}`
    localStorage.setItem(CHROME_NOTIFICATION_DISMISSED_ON, new Date().toISOString())
    const installedCallback = () => {
      toast.dismiss(chromeToastId)
      // Call anayltics and update identity as chrome installed
    }
    /* eslint-disable no-use-before-define */
    if (window.chrome && chrome.webstore) {
      chrome.webstore.install(chromeUrl, installedCallback, () => {
        window.open(chromeUrl)
      })
    } else {
      window.open(chromeUrl)
      installedCallback()
    }
  }}
  >
    <IconContainer color='#4D83E1'>
      <FontAwesomeIcon
        icon={['fab', 'chrome']}
        color='white'
      />
    </IconContainer>
    <ToastLabel p='1rem'>
      Source from anywhere with our powerful chrome extension
      <br />
      <a>Add Chrome Extension</a>
    </ToastLabel>
  </Container>
)

export function chrome () {
  const dismissed = localStorage.getItem(CHROME_NOTIFICATION_DISMISSED_ON)
  if (!toast.isActive(chromeToastId) &&
    (!dismissed || isBefore(addDays(new Date(dismissed), 3), new Date()))) {
    chromeToastId = toast(ChromeToast, {
      closeButton:
  <CloseButton
    onClose={() => {
      localStorage.setItem(CHROME_NOTIFICATION_DISMISSED_ON, new Date().toISOString())
    }}
  />,
      progressStyle: {
        background: 'transparent'
      },
      autoClose: false,
      closeOnClick: false
    })
  }
}

let integrationToastId

export function integration (onClick) {
  const dismissed = localStorage.getItem(INTEGRATION_NOTIFICATION_DISMISSED_ON)

  if (dismissed || toast.isActive(integrationToastId)) {
    return
  }

  const integrationToast = (
    <Container onClick={() => {
      localStorage.setItem(INTEGRATION_NOTIFICATION_DISMISSED_ON, new Date().toISOString())
      onClick()
    }}
    >
      <IconContainer color='#4D83E1'>
        <FontAwesomeIcon
          icon={['fal', 'times-circle']}
          color='white'
        />
      </IconContainer>
      <ToastLabel p='1rem'>
        Connect your CRM or ATS to start syncing contacts and email activity. <a>Connect Integration &rarr;</a>
      </ToastLabel>
    </Container>
  )

  const closeButton = (
    <CloseButton
      onClose={() => {
        localStorage.setItem(INTEGRATION_NOTIFICATION_DISMISSED_ON, new Date().toISOString())
      }}
    />
  )

  integrationToastId = toast(integrationToast, {
    closeButton,
    progressStyle: {
      background: 'transparent'
    },
    autoClose: false,
    closeOnClick: false
  })
}

const lookupMap = {}
let ignoreDismiss = false

function dismissedLookupIds () {
  const lookupIds = localStorage.getItem(DISMISSED_LOOKUP_IDS)
  if (!lookupIds) {
    return []
  }
  const list = JSON.parse(lookupIds)
  return list
}

function addDismissedLookupId (lookupId) {
  if (lookupId) {
    const lookupIds = dismissedLookupIds()
    lookupIds.unshift(lookupId)
    localStorage.setItem(DISMISSED_LOOKUP_IDS, JSON.stringify(lookupIds))
  }
}

export function findLookups (sequenceId, lookups) {
  let inProgress = false
  ignoreDismiss = false
  lookups.forEach((lookup) => {
    const updatedAt = lookup.get('updated_at')
    const count = lookup.get('count')
    const completed = lookup.get('completed')
    const errorCode = lookup.get('error')
    const name = lookup.get('name')
    const lookupId = lookup.get('_id')
    const dismissedIds = dismissedLookupIds()
    const type = lookup.get('type')?.toLowerCase()
    if (dismissedIds.includes(lookupId)) {
      return
    }
    const existingToast = lookupMap[lookupId]
    let render
    let options
    if (isToday(updatedAt)) {
      if (errorCode) {
        let message
        switch (errorCode) {
          case 'SUBSCRIPTION_EXPIRED':
            message = 'Cancelled subscription expired'
            break
          case 'NO_CREDITS':
            message = 'Out of credits, please contact support'
            break
          case 'CANCELLED':
            message = 'Cancelled'
            break
          default:
            message = 'Unexpected Error'
            break
        }
        const notification = (
          <span>
            Import for <strong>{name}</strong>
            <br />
            {message}
          </span>
        )
        render = ErrorToast(notification)
        options = {
          closeButton: <CloseButton />,
          autoClose: false,
          closeOnClick: true
        }
      } else if (completed < count) {
        inProgress = true
        let message

        if (type === 'phone') {
          message = (
            <span>
              Looking up <strong>phone numbers.</strong>
              <br />
              {completed}/{count}
            </span>
          )
        } else {
          message = (
            <span>
              Importing <strong>{name}</strong>
              <br />
              {completed}/{count}
            </span>
          )
        }

        render = InProgressToast(message)
        options = {
          closeButton: <CloseButton />,
          autoClose: false,
          closeOnClick: true,
          onClose: () => {
            delete lookupMap[lookupId]
          }
        }
      } else {
        let message

        if (type === 'phone') {
          message = (
            <span>
              Phone number lookups finished
              <br />
              <a>Download results</a>
            </span>
          )
        } else {
          message = (
            <span>
              Import for <strong>{name}</strong> finished
              <br />
              <a>Download results</a>
            </span>
          )
        }

        render = SuccessToast(message, () => {
          api.get(`/lookups/${lookupId}/csv`)
            .then((response) => {
              const data = fromJS(response.data)
              const url = data.get('url')
              window.location = url
            })
            .catch((err) => {
              console.error(err)
            })
        })
        options = {
          closeButton: <CloseButton />,
          autoClose: false,
          closeOnClick: true
        }
      }

      if (render && options) {
        if (existingToast) {
          toast.update(existingToast, { render, ...options })
        } else {
          lookupMap[lookupId] = toast(render, {
            onClose: () => {
              delete lookupMap[lookupId]
              if (!ignoreDismiss) {
                addDismissedLookupId(lookupId)
              }
            },
            ...options
          })
        }
      }
    }
  })
  return inProgress
}

export function clearLookupToasts () {
  // Ignore dismisses does not store dismissed to cookie to not show again
  ignoreDismiss = true
  Object.values(lookupMap).forEach((toastId) => {
    toast.dismiss(toastId)
  })
}

CloseButton.propTypes = {
  closeToast: PropTypes.func,
  onClose: PropTypes.func
}

import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LogoImage from 'elements/LogoImage'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5rem;
`

const ListItem = styled.div`
  position: relative;
  flex-grow: 1;
  width: 45%;
  height: 68px;
  display: flex;
  align-items: center;
  margin: 0.5rem;
  padding: 1rem;
  border: solid 1px ${theme.background};

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

const Title = styled.div`
  padding-left: 1rem;
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  font-weight: 600;
  text-transform: uppercase;
  flex: 1;
`

const BetaLabel = styled.div`
  background-color: ${theme.colors.orange};
  border-color: ${theme.colors.orange};
  border-radius: 3px;
  color: ${theme.colors.white};
  font: ${theme.fonts.button};
  padding: 0.125rem 0.4rem 0.2rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
  height: fit-content;
  width: fit-content;
  right: 1.5rem;
`

class CrmList extends PureComponent {
  render () {
    const {
      crms,
      onClick
    } = this.props

    const items = crms
      .filterNot(crm => crm.get('hidden'))
      .map((crm, i) => {
        const title = crm.get('name')
        const type = crm.get('type')
        const beta = crm.get('beta')
        return (
          <ListItem onClick={() => onClick(crm)} key={`crm_${type}`} data-index={i}>
            <LogoImage type={type} width='48px' />
            <Title>{title}</Title>
            {beta && <BetaLabel>Beta</BetaLabel>}
          </ListItem>
        )
      })

    return (
      <Wrapper>
        {items}
      </Wrapper>
    )
  }
}

CrmList.propTypes = {
  crms: PropTypes.instanceOf(Immutable.List),
  onClick: PropTypes.func
}

export default CrmList

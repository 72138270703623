/* eslint-disable react/no-find-dom-node, array-callback-return */
import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import Curtain from 'components/SlateEditor/components/Toolbar/ToolbarModal/Curtain'
import { Cross } from 'svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getGroupsFromMembers, getGroupLabel } from 'utils/groups'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div``

const InputBoxContainer = styled.div``

const SearchBox = styled.input`
  outline: none;
  border: 1px solid ${theme.colors.gray60};
  border-radius: 2px;

  color: ${theme.colors.darkBlue};
  background-color: ${theme.colors.white};

  font: ${theme.fonts.normal};

  padding: .75rem 1rem;
  height: 46px;
  width: 100%;

  ::placeholder {
    color: #aaa;
  }

  &:focus {
    border: 1px solid ${theme.colors.blue};
    box-shadow: 0 0 4px ${theme.colors.lightBlue};
  }
`

const DropDownContainer = styled.div`
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;

  position: absolute;
  left: ${props => props.position.x}px;
  top: ${props => props.position.y}px;
  z-index: 100;

  max-height: 20rem;
  min-width: 480px;

  border: 1px solid ${theme.colors.gray20};
  border-radius: 1.5px;
  box-shadow: ${theme.shadow};
`

const SequenceUserBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid .3px ${theme.colors.gray30};

  padding: .75rem;
  width: 100%;
`

const SequenceUserBannerItem = styled.div`
  font: ${theme.fonts.normal};
  margin-right: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid;
  border-radius: 9999px;

  padding: 0.4rem 1rem;

  ${props => props.selected && css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.blue};
    border-color: ${theme.colors.blue};
  `};

  ${props => !props.selected && css`
    border-color: ${theme.colors.gray40};
    color: ${theme.colors.darkGray};
    cursor: pointer;
    transition: .15s ease-in;

    &:hover {
      border-color: ${theme.colors.gray30};
      background-color: ${theme.colors.gray30};
    }
  `};

  svg {
    top: 1px;
    position: relative;
    padding-right: .25rem;
  }
`

const SequenceUserBannerTitle = styled.div`
  padding-left: 0.25rem;
  font-size: 12px;
  text-transform: uppercase;
`

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  width: 100%;
  padding: .4rem .6rem;
  margin-bottom: .5rem;

  border-radius: 3px;
  border: 1px solid ${theme.colors.gray30};
  background-color: ${theme.colors.gray20};
  font: ${theme.fonts.label};
  color: ${theme.colors.darkBlue};
`

const SelectedItemCloseContainer = styled.div`
  cursor: pointer;
  padding-left: .55rem;

  svg {
    position: relative;
    top: -1px;
    width: 7px;
    height: 7px;
    stroke: ${theme.colors.gray50};
    fill: ${theme.colors.gray50};
    margin: auto;
  }
`

const SelectedItemText = styled.div`
  flex: 1;
`

const SelectedItemIconContainer = styled.div`
  flex: 0;

  margin-right: 0.5rem;

  svg {
    position: relative;
  }
`

const SuggestionBox = styled.div`
  background-color: ${theme.colors.white};
  color: ${theme.colors.grayBlue};

  overflow-y: auto;
  width: 100%;

  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Suggestion = styled.div`
  color: ${theme.colors.darkBlue};
  background-color: ${theme.colors.white};

  width: 100%;
  z-index: 100;
  cursor: pointer;
  font: ${theme.fonts.nomral};
  padding: .5rem 0rem .5rem .75rem;

  &:hover {
    background-color: ${theme.colors.blueGray};
  }
`

const DROPDOWN_SELECTION = {
  SEQUENCES: 'sequences',
  USERS: 'users',
  GROUPS: 'groups',
  FOLDERS: 'folders'
}

class SequenceDropdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allSequenceUsers: false, // flag that will selects all sequences and users
      currentDropdownSelection: DROPDOWN_SELECTION.SEQUENCES,

      searchQuery: '',
      showSuggestions: false,

      suggestionBoxPosition: { x: 0, y: 0 }
    }

    // creating ref to `SearchBox`
    this.searchBoxRef = React.createRef()

    // bind functions to `this`
    this.onFocusSearchBox = this.onFocusSearchBox.bind(this)
    this.onChangeSearchBox = this.onChangeSearchBox.bind(this)
    this.calculateSearchBoxPosition = this.calculateSearchBoxPosition.bind(this)
  }

  componentDidMount () {
    this.calculateSearchBoxPosition()
  }

  calculateSearchBoxPosition = () => {
    const searchBox = ReactDOM.findDOMNode(this.searchBoxRef.current)
    const { bottom, left } = searchBox.getBoundingClientRect()
    this.setState({ suggestionBoxPosition: { x: left, y: (bottom + 10) } })
  }

  setDropdownSelection = dropdownSelection => {
    const { currentDropdownSelection } = this.state
    if (!dropdownSelection ||
      currentDropdownSelection === dropdownSelection ||
      !Object.values(DROPDOWN_SELECTION).includes(dropdownSelection)) {
      return
    }

    this.setState(
      { currentDropdownSelection: dropdownSelection },
      this.calculateSearchBoxPosition()
    )
  }

  onFocusSearchBox = event => {
    this.setState({ showSuggestions: true })
    this.calculateSearchBoxPosition()
  }

  onChangeSearchBox = event => {
    this.setState({ searchQuery: event.target.value })
  }

  onSelectSequence = selectedSequenceId => {
    const { setSequenceIds } = this.props
    let { selectedSequenceIds } = this.props

    // will only add sequence index if the index is unique
    selectedSequenceIds = selectedSequenceIds.add(selectedSequenceId)

    // udpate the state to have the newly selected items
    // fetch the sequence report of campaign ids after the state updates
    setSequenceIds(selectedSequenceIds, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onSelectUser = selectedUserId => {
    const { setUserIds } = this.props
    let { selectedUserIds } = this.props

    // will only add user index if the index is unique
    selectedUserIds = selectedUserIds.add(selectedUserId)

    // udpate the state to have the newly selected users
    // recalculate the positions of the dropdown
    setUserIds(selectedUserIds, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onSelectGroup = selectedGroup => {
    const { setGroups } = this.props
    let { selectedGroups } = this.props

    selectedGroups = selectedGroups.add(selectedGroup)

    setGroups(selectedGroups, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onSelectFolder = selectedFolder => {
    const { setFolderNames } = this.props
    let { selectedFolderNames } = this.props

    selectedFolderNames = selectedFolderNames.toSet()
    selectedFolderNames = selectedFolderNames.add(selectedFolder)
    selectedFolderNames = Immutable.Set(selectedFolderNames)

    setFolderNames(selectedFolderNames, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onRemoveSequence = removeSequenceId => {
    const { setSequenceIds } = this.props
    let { selectedSequenceIds } = this.props

    selectedSequenceIds = selectedSequenceIds.filterNot(sequenceId => sequenceId === removeSequenceId)

    setSequenceIds(selectedSequenceIds, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onRemoveUser = removeUserId => {
    const { setUserIds } = this.props
    let { selectedUserIds } = this.props

    // remove user from local copy of `selectedUserIndicies`
    selectedUserIds = selectedUserIds.filterNot(userId => userId === removeUserId)

    // get an updated list of user IDs, update the state, and regenerate the report
    setUserIds(selectedUserIds, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onRemoveFolder = toBeRemovedFolderName => {
    const { setFolderNames } = this.props
    let { selectedFolderNames } = this.props

    selectedFolderNames = selectedFolderNames.toSet()
    selectedFolderNames = selectedFolderNames.delete(toBeRemovedFolderName)
    selectedFolderNames = Immutable.Set(selectedFolderNames)

    setFolderNames(selectedFolderNames, () => {
      this.calculateSearchBoxPosition()
    })
  }

  onRemoveGroup = removeGroup => {
    const { setGroups } = this.props
    let { selectedGroups } = this.props

    selectedGroups = selectedGroups.filterNot(group => group === removeGroup)

    setGroups(selectedGroups, () => {
      this.calculateSearchBoxPosition()
    })
  }

  renderSequences = () => {
    const { sequences, selectedSequenceIds } = this.props
    const { searchQuery } = this.state

    if (!searchQuery) {
      return sequences
        .get('data')
        .sortBy(seq => seq.get('title'))
        .entrySeq()
        .map((seq) => {
          const title = seq[1].get('title')
          const sequenceId = seq[1].get('id')
          if (!selectedSequenceIds.includes(sequenceId)) {
            return (
              <Suggestion
                key={sequenceId}
                onClick={(_) => this.onSelectSequence(sequenceId)}
              >
                {title}
              </Suggestion>
            )
          }
        })
    } else {
      return sequences
        .get('data')
        .sortBy(seq => seq.get('title'))
        .entrySeq()
        .map((seq) => {
          const title = seq[1].get('title')
          const sequenceId = seq[1].get('id')
          if (title.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1) {
            if (!selectedSequenceIds.includes(sequenceId)) {
              return (
                <Suggestion
                  key={sequenceId}
                  onClick={(_) => this.onSelectSequence(sequenceId)}
                >
                  {title}
                </Suggestion>
              )
            }
          }
        })
    }
  }

  renderUsers = () => {
    const { users, selectedUserIds, session } = this.props
    const { searchQuery } = this.state

    if (!searchQuery) {
      return users
        .get('data')
        .filter(user => !session.get('group') || session.get('group') === user.get('group'))
        .sortBy(user => user.get('full_name'))
        .entrySeq()
        .map((user) => {
          const name = user[1].get('full_name')
          const userId = user[1].get('id')
          if (!selectedUserIds.includes(userId)) {
            return (
              <Suggestion
                key={userId}
                onClick={(_) => this.onSelectUser(userId)}
              >
                {name}
              </Suggestion>
            )
          }
        })
    } else {
      return users
        .get('data')
        .sortBy(user => user.get('full_name'))
        .entrySeq()
        .map((user) => {
          const name = user[1].get('full_name')
          const userId = user[1].get('id')
          if (name.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1) {
            if (!selectedUserIds.includes(userId)) {
              return (
                <Suggestion
                  key={userId}
                  onClick={(_) => this.onSelectUser(userId)}
                >
                  {name}
                </Suggestion>
              )
            }
          }
        })
    }
  }

  renderGroups = () => {
    const { users, selectedGroups } = this.props
    const { searchQuery } = this.state

    const groups = getGroupsFromMembers(users)

    let renderedGroups

    if (!searchQuery) {
      renderedGroups = groups
        .sortBy(group => group)
        .map(group => {
          const name = getGroupLabel(group)
          if (!selectedGroups.includes(group)) {
            return (
              <Suggestion
                key={group}
                onClick={(_) => this.onSelectGroup(group)}
              >
                {name}
              </Suggestion>
            )
          }
        })
    } else {
      renderedGroups = groups
        .sortBy(group => group)
        .map(group => {
          const name = getGroupLabel(group)
          if (group.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1) {
            if (!selectedGroups.includes(group)) {
              return (
                <Suggestion
                  key={group}
                  onClick={(_) => this.onSelectGroup(group)}
                >
                  {name}
                </Suggestion>
              )
            }
          }
        })
    }

    return renderedGroups
  }

  renderFolders = () => {
    const { sequences, selectedFolderNames } = this.props
    const { searchQuery } = this.state

    const folderNameSet = new Set()
    sequences
      .get('data')
      .forEach(seq => {
        const folderName = seq.get('group')
        if (folderName) {
          folderNameSet.add(seq.get('group'))
        }
      })

    if (!searchQuery) {
      return Array.from(folderNameSet).sort().map((groupName, index) => {
        if (!selectedFolderNames.has(groupName)) {
          return (
            <Suggestion
              key={index}
              onClick={(_) => this.onSelectFolder(groupName)}
            >
              {groupName}
            </Suggestion>
          )
        }
      })
    } else {
      return Array.from(folderNameSet).sort().map((groupName, index) => {
        if (!selectedFolderNames.has(groupName) &&
            groupName.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1) {
          return (
            <Suggestion
              key={index}
              onClick={(_) => this.onSelectFolder(groupName)}
            >
              {groupName}
            </Suggestion>
          )
        }
      })
    }
  }

  renderSelectedSequences = () => {
    const { sequences, selectedSequenceIds } = this.props
    return selectedSequenceIds.map((sequenceId) => {
      const sequence = sequences.get('data')
        .find(s => s.get('id') === sequenceId) || Immutable.Map()

      return (
        <SelectedItem key={sequenceId}>
          <SelectedItemIconContainer>
            <FontAwesomeIcon icon={['far', 'mail-bulk']} />
          </SelectedItemIconContainer>
          <SelectedItemText>{sequence.get('title')}</SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => this.onRemoveSequence(sequenceId)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  renderSelectedUsers = () => {
    const { users, selectedUserIds } = this.props
    return selectedUserIds.map((userId) => {
      const user = users.get('data')
        .find(u => u.get('id') === userId)

      return (
        <SelectedItem key={userId}>
          <SelectedItemIconContainer>
            <FontAwesomeIcon icon={['far', 'user']} />
          </SelectedItemIconContainer>
          <SelectedItemText>
            {user.get('full_name')}
          </SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => this.onRemoveUser(userId)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  renderSelectedFolders = () => {
    const { selectedFolderNames } = this.props
    return selectedFolderNames.map((folderName, index) => {
      return (
        <SelectedItem key={index}>
          <SelectedItemIconContainer>
            <FontAwesomeIcon icon={['far', 'folder-open']} />
          </SelectedItemIconContainer>
          <SelectedItemText>{folderName}</SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => this.onRemoveFolder(folderName)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  renderSelectedGroups = () => {
    const { selectedGroups } = this.props

    return selectedGroups.map((group, index) => {
      const name = !isNaN(group) ? `Group ${group}` : group
      return (
        <SelectedItem key={index}>
          <SelectedItemIconContainer>
            <FontAwesomeIcon icon={['far', 'users']} />
          </SelectedItemIconContainer>
          <SelectedItemText>{name}</SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => this.onRemoveGroup(group)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  renderSuggestionBox = () => {
    const { currentDropdownSelection } = this.state
    switch (currentDropdownSelection) {
      case DROPDOWN_SELECTION.SEQUENCES:
        return <SuggestionBox>{this.renderSequences()}</SuggestionBox>
      case DROPDOWN_SELECTION.USERS:
        return <SuggestionBox>{this.renderUsers()}</SuggestionBox>
      case DROPDOWN_SELECTION.GROUPS:
        return <SuggestionBox>{this.renderGroups()}</SuggestionBox>
      case DROPDOWN_SELECTION.FOLDERS:
        return <SuggestionBox>{this.renderFolders()}</SuggestionBox>
    }
  }

  render () {
    const {
      selectedSequenceIds,
      selectedUserIds,
      selectedFolderNames,
      selectedGroups,
      showSequenceFolder,
      session,
      state
    } = this.props

    const {
      showSuggestions,
      suggestionBoxPosition,
      currentDropdownSelection
    } = this.state

    const isOwner = state.get('is_owner')
    const isManager = state.get('is_manager')
    const hasGroup = !!session.get('group')

    return (
      <Wrapper>
        <InputBoxContainer>
          <SelectedContainer>
            {this.renderSelectedSequences()}
            {this.renderSelectedUsers()}
            {this.renderSelectedFolders()}
            {this.renderSelectedGroups()}
            {state.get('is_manager') &&
                selectedSequenceIds.size === 0 &&
                selectedUserIds.size === 0 &&
                selectedFolderNames.size === 0 &&
                selectedGroups.size === 0 &&
                  <SelectedItem>
                    <SelectedItemText>All Sequences & Users</SelectedItemText>
                  </SelectedItem>}
            {!state.get('is_manager') &&
                selectedSequenceIds.size === 0 &&
                selectedUserIds.size === 0 &&
                selectedFolderNames.size === 0 &&
                selectedGroups.size === 8 &&
                  <SelectedItem>
                    <SelectedItemText>All Sequences</SelectedItemText>
                  </SelectedItem>}
          </SelectedContainer>
          <SearchBox
            ref={this.searchBoxRef}
            value={this.state.searchQuery}
            onChange={this.onChangeSearchBox}
            onFocus={this.onFocusSearchBox}
            onBlur={this.onBlurSearchBox}
            placeholder='Filter by sequence or user...'
          />
        </InputBoxContainer>

        {showSuggestions &&
          <>
            <Curtain onClick={() => {
              this.setState({ showSuggestions: false })
              this.calculateSearchBoxPosition()
            }}
            />
            <DropDownContainer position={suggestionBoxPosition}>
              <SequenceUserBanner>
                <SequenceUserBannerItem
                  selected={currentDropdownSelection === DROPDOWN_SELECTION.SEQUENCES}
                  onClick={() => this.setDropdownSelection(DROPDOWN_SELECTION.SEQUENCES)}
                >
                  <FontAwesomeIcon icon={['far', 'mail-bulk']} />
                  <SequenceUserBannerTitle>Sequences</SequenceUserBannerTitle>
                </SequenceUserBannerItem>

                {isManager &&
                  <SequenceUserBannerItem
                    selected={currentDropdownSelection === DROPDOWN_SELECTION.USERS}
                    onClick={() => this.setDropdownSelection(DROPDOWN_SELECTION.USERS)}
                  >
                    <FontAwesomeIcon icon={['far', 'user']} />
                    <SequenceUserBannerTitle>Users</SequenceUserBannerTitle>
                  </SequenceUserBannerItem>}

                {(isManager || isOwner) && !hasGroup &&
                  <SequenceUserBannerItem
                    selected={currentDropdownSelection === DROPDOWN_SELECTION.GROUPS}
                    onClick={() => this.setDropdownSelection(DROPDOWN_SELECTION.GROUPS)}
                  >
                    <FontAwesomeIcon icon={['far', 'users']} />
                    <SequenceUserBannerTitle>Groups</SequenceUserBannerTitle>
                  </SequenceUserBannerItem>}

                {showSequenceFolder &&
                  <SequenceUserBannerItem
                    selected={currentDropdownSelection === DROPDOWN_SELECTION.FOLDERS}
                    onClick={() => this.setDropdownSelection(DROPDOWN_SELECTION.FOLDERS)}
                  >
                    <FontAwesomeIcon icon={['far', 'folder-open']} />
                    <SequenceUserBannerTitle>Folders</SequenceUserBannerTitle>
                  </SequenceUserBannerItem>}

              </SequenceUserBanner>

              {this.renderSuggestionBox()}
            </DropDownContainer>
          </>}

      </Wrapper>
    )
  }
}

SequenceDropdown.propTypes = {
  actions: PropTypes.object,
  sequences: PropTypes.object,
  users: PropTypes.object,
  state: PropTypes.object,
  session: PropTypes.object,
  setUserIds: PropTypes.func,
  setSequenceIds: PropTypes.func,
  setFolderNames: PropTypes.func,
  setGroups: PropTypes.func,
  allUsersSelected: PropTypes.bool,
  showSequenceFolder: PropTypes.bool,
  selectedSequenceIds: PropTypes.instanceOf(Immutable.Set),
  selectedUserIds: PropTypes.instanceOf(Immutable.Set),
  selectedFolderNames: PropTypes.instanceOf(Immutable.Set),
  selectedGroups: PropTypes.instanceOf(Immutable.Set)
}

export default SequenceDropdown

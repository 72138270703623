import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'elements/Badge'
import theme from '../../themes/light'

const Wrapper = styled(Link)`
  color: ${theme.colors.gray70};
  text-align: center;
  cursor: pointer;
  margin: 1rem;
  margin-top: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${theme.fonts.button};
  letter-spacing: .05em;
  border-bottom: 2px solid transparent;
  white-space: nowrap;

  &.${props => props.activeClassName} {
    color: ${theme.colors.blue};
    cursor: default;
    transition: none;

    padding-bottom: 0.5rem;
    border-bottom: 2px solid ${theme.colors.blue};

    &:hover,
    &:focus {
      opacity: 1;
      transition: none;
    }
  };

  opacity: 1;
  transition: opacity .15s ease-in;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: 1;
    transition: opacity .15s ease-out;
  }

  &:last-child {
    margin-right: 2px;
  }

  ${space}
  ${width}
  ${textAlign}
`

const NavBadge = styled(Badge)`
  display: inline-block;
  line-height: 14px;
  margin-left: 6px;
  padding: 0 6px;
  text-align: center;
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: 4px;
`

class NavigationLink extends Component {
  render () {
    const {
      to,
      label,
      badge = null,
      icon,
      ...rest
    } = this.props

    return (
      <Wrapper to={to} activeClassName='active' {...rest}>
        {label && <span>{label}</span>}
        {badge !== null &&
          <NavBadge
            color={theme.colors.white}
            backgroundColor={badge === 0
              ? theme.colors.gray70
              : theme.colors.blue}
          >
            {badge}
          </NavBadge>}
        {icon ? <Icon icon={icon} size='sm' /> : null}
      </Wrapper>
    )
  }
}

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.number,
  icon: PropTypes.array
}

export default NavigationLink

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class ConfirmModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13) {
      e.preventDefault()
      return this.props.onConfirm()
    }
    return false
  }

  render () {
    const {
      title,
      description,
      loading,
      onCancel,
      onConfirm,
      isOpen,
      persistent,
      confirmLabel = 'confirm',
      cancelLabel = 'cancel'
    } = this.props

    if (loading) {
      return <Loading />
    }

    return (
      <Modal
        width='fit-content'
        maxWidth='40rem'
        isOpen={isOpen}
        persistent={persistent}
        onModalClose={onCancel}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='0.5rem' align='center'>
              {description}
              {this.props.children}
            </Label>
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label={cancelLabel}
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label={confirmLabel}
              onClick={onConfirm}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  loading: PropTypes.bool,
  children: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  persistent: PropTypes.bool
}

ConfirmModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default ConfirmModal

import { createSelector } from 'reselect'

const selectSequenceDomain = () => state => state.get('sequence')

const selectSequence = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('sequence')
)

const selectSequenceState = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('sequenceState')
)

const selectContacts = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contacts')
)

const selectContactsHasMore = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contacts', 'hasMore']) || false
)

const selectStats = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('stats')
)

const selectStepStats = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['stepStats', 'data'])
)

const selectCsvDownloadLink = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['csvDownloadLink', 'data'])
)

const selectContact = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('selectedContact')
)

const selectNewContacts = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('newContacts')
)

const selectContactActivity = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactActivity', 'data'])
)

const selectContactActivityLoading = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactActivity', 'loading'])
)

const selectContactIntegration = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactIntegration', 'data'])
)

const selectContactMessages = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactMessages', 'data'])
)

const selectSentMessages = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['sentMessages', 'data'])
)

const selectContactMessagesLoading = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactMessages', 'loading']) || sequenceState.getIn(['sentMessages', 'loading'])
)

const selectContactInaccuracyLoading = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contactInaccuracy', 'loading'])
)

const selectSequencePreview = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('previewSteps')
)

const selectCreateCsv = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('createCsv')
)

const selectImportCsv = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('importCsv')
)

// Combines the loading state of several requests
const selectLoading = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.getIn(['contacts', 'loading'])
)

const selectTemplates = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('templates')
)

const selectContactCompany = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contactCompany')
)

const selectLookups = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('lookups')
)

const selectPreviewContacts = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('previewContacts')
)

const selectCreateFile = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('createFile')
)

const selectSimilarContacts = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('similarContacts')
)

const selectCrm = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('crm')
)

const selectContactReply = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contactReply')
)

const selectNewSequence = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('newSequence')
)

const selectContactErrors = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contactErrors')
)

const selectClearedContactErrors = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('noClearedContactErrors')
)

const selectSelectedContactCount = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('selectedContactCount')
)

const selectOgp = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('ogp')
)

const selectAddresses = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('addresses')
)

const selectContactSteps = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contactSteps')
)

const selectContactPhone = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('contactPhone')
)

const selectImportCrmStep = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('importCrmStep')
)

const selectShowWarningModal = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('showWarningModal')
)

const selectSequenceSignature = () => createSelector(
  selectSequenceDomain(),
  sequenceState => sequenceState.get('sequenceSignature')
)

export {
  selectSequence,
  selectSequenceState,
  selectContacts,
  selectStats,
  selectStepStats,
  selectCsvDownloadLink,
  selectLoading,
  selectContact,
  selectContactActivity,
  selectContactActivityLoading,
  selectContactIntegration,
  selectContactMessages,
  selectContactMessagesLoading,
  selectContactInaccuracyLoading,
  selectContactCompany,
  selectNewContacts,
  selectSequencePreview,
  selectContactsHasMore,
  selectCreateCsv,
  selectImportCsv,
  selectTemplates,
  selectLookups,
  selectPreviewContacts,
  selectCreateFile,
  selectSimilarContacts,
  selectCrm,
  selectContactReply,
  selectNewSequence,
  selectSentMessages,
  selectContactErrors,
  selectClearedContactErrors,
  selectSelectedContactCount,
  selectOgp,
  selectAddresses,
  selectContactSteps,
  selectContactPhone,
  selectImportCrmStep,
  selectShowWarningModal,
  selectSequenceSignature
}

import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  TOP_LEVEL_FIELD_OPTIONS,
  CSV_IMPORT_FIELD_OPTIONS,
  TOP_LEVEL_FIELD_VALUES
} from 'containers/Sequence/constants'
import Button from 'components/Button'
import Loading from 'components/Loading'
import DropDown from 'components/DropDown'
import EmptyState from 'components/EmptyState'
import ConfirmModal from 'components/ConfirmModal'
import Label from 'elements/Label'
import { Satellite } from 'svg'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${theme.padding};
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const MappingRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px #a7b2c5 solid;
`

const ColumnLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DropDownHeader = styled.div`
  width: 40%;
  text-align: left;
`

const FormTitle = styled(Label)`
  margin-right: 0.5rem;
  font: ${theme.fonts.button};
  color: ${theme.titleColor};
  text-transform: uppercase;
`

const FormValue = styled(Label)`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 0.5rem;
  max-height: 44px;
`

const MappingDropdown = styled(DropDown)`
  width: 40%;
`

const LinkButton = styled.a`
  color: ${theme.colors.blue};
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${theme.fonts.button};
  letter-spacing: .05em;
  color: ${theme.colors.blue};
  cursor: default;
  transition: none;

  &:hover,
  &:focus {
    opacity: 1;
    transition: none;
  }
`

// we're stripping to only alphanumeric so we only need
// alphanumeric keys to match by
const columnAliases = {
  emailaddress: 'email',
  full: 'name',
  fullname: 'name',
  firstname: 'first_name',
  first: 'first_name',
  lastname: 'last_name',
  last: 'last_name',
  website: 'url',
  domain: 'url',
  companyname: 'company',
  currentcompany: 'company',
  mobile: 'phone_nmber',
  mobilephone: 'phone_number',
  mobilephonenumber: 'phone_number',
  phone: 'phone_number',
  phonenumber: 'phone_number',
  sourceurl: 'profile_url',
  city: 'location_city',
  state: 'location_state',
  country: 'location_country',
  locationcity: 'location_city',
  locationstate: 'location_state',
  locationcountry: 'location_country',
  linkedin: 'profile_url',
  linkedinurl: 'profile_url'
}

class ImportMapper extends Component {
  constructor (props) {
    super(props)
    this.state = this.buildDefaults(props)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.props !== newProps) {
      const params = this.buildDefaults(newProps)
      this.setState(params)
    }
  }

  buildDefaults = (props) => {
    const {
      importCsv,
      sequence
    } = props

    const csv = importCsv.get('data')
    if (csv) {
      const contactFields = TOP_LEVEL_FIELD_VALUES
      const customFields = (sequence.get('fields') || [])

      return csv.get('columns').reduce((acc, c) => {
        let value = null

        // normalize the header to disallow characters we do not use or
        // allow in our actual field names
        const header = c.get('header')
          .toLowerCase()
          .replace(/[\t\r\n _-]/ig, '_')
        const alphanumericHeader = header.replace('_', '')

        // header matches custom field directly
        if (customFields.includes(header)) {
          value = header
        // header matches top-level contact field directly
        } else if (contactFields.includes(header)) {
          value = header
        // header matches the alphanumeric top-level field
        } else if (contactFields.includes(alphanumericHeader)) {
          value = alphanumericHeader
        // matches an alias
        } else if (columnAliases[alphanumericHeader]) {
          value = columnAliases[alphanumericHeader]
        }

        if (value === 'first_name' || value === 'last_name') {
          if (acc.name !== undefined) {
            return acc
          }
        }

        if (value === 'name') {
          if (acc.first_name !== undefined && acc.last_name !== undefined) {
            return acc
          }
        }

        if (value === 'location') {
          if (acc.location_city !== undefined && acc.location_state !== undefined && acc.location_city !== undefined) {
            return acc
          }
        }

        if (value === 'location_city' || value === 'location_state' || value === 'location_country') {
          if (acc.location !== undefined) {
            return acc
          }
        }

        if (value && acc[value] === undefined) {
          acc[value] = c.get('index')
        }

        return acc
      }, {})
    }

    return {}
  }

  next () {
    const {
      importCsv,
      onMappingSelect
    } = this.props

    const csv = importCsv.get('data')
    const csvId = csv.get('_id')
    const {
      ...params
    } = this.state

    onMappingSelect(csvId, params)
    this.setState({
      warningMessage: null
    })
  }

  onSubmit () {
    let warningMessage = null

    if (this.state.first_name && this.state.last_name === undefined) {
      warningMessage = 'It looks like you mapped a first name field but not a last name field. Are you sure you want to continue?'
    } else if (this.state.last_name && this.state.first_name === undefined) {
      warningMessage = 'It looks like you mapped a last name field but not a first name field. Are you sure you want to continue?'
    } else if (this.state.name === undefined && this.state.first_name === undefined && this.state.last_name === undefined) {
      warningMessage = 'You have not mapped a name field yet. Are you sure you want to continue?'
    }

    if (warningMessage) {
      this.setState({
        warningMessage
      })
    } else {
      this.next()
    }
  }

  render () {
    const {
      sequence,
      importCsv,
      onAddField
    } = this.props

    const {
      warningMessage
    } = this.state

    const loading = importCsv.get('loading')
    if (loading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const csv = importCsv.get('data')

    if (!csv) {
      return (
        <Wrapper>
          <EmptyState
            icon={<Satellite />}
            title='CSV Failed To Upload'
            description='Please check to make sure that your CSV is properly formatted.'
          />
        </Wrapper>
      )
    }

    const columns = csv.get('columns')
    const fields = sequence.get('fields') || []
    const valid = this.state.email !== undefined

    const options = Immutable.List(TOP_LEVEL_FIELD_OPTIONS.concat(CSV_IMPORT_FIELD_OPTIONS).concat(fields.map(field => ({ label: field, value: field })).toArray()))

    const indexLookup = Object.keys(this.state).reduce((acc, key) => {
      const value = this.state[key]
      acc[value] = key
      return acc
    }, {})

    const rows = columns.map((column) => {
      const header = column.get('header')
      const index = column.get('index')
      const samples = column.get('samples')
        .filter(s => s.length > 0).join(', ')
      const value = indexLookup[index]
      return (
        <MappingRow
          key={`column_${header}_${index}`}
        >
          <ColumnLabel>
            <FormTitle>{header}</FormTitle>
            <FormValue ml='1rem'>{samples}</FormValue>
          </ColumnLabel>
          <MappingDropdown
            height='30px'
            options={options}
            value={value}
            controlled
            onOptionChange={(option) => {
              if (option) {
                const p = {}
                p[option.value] = index

                if (option.value === 'name') {
                  p.first_name = undefined
                  p.last_name = undefined
                } else if (option.value === 'first_name' || option.value === 'last_name') {
                  p.name = undefined
                }

                if (option.value === 'location') {
                  p.location_city = null
                  p.location_state = null
                  p.location_country = null
                } else if (option.value === 'location_city' || option.value === 'location_state' || option.value === 'location_country') {
                  p.location = null
                }

                this.setState(p)
              } else {
                const v = indexLookup[index]
                const p = this.state
                delete p[v]
                this.setState(p)
              }
            }}
          />
        </MappingRow>
      )
    })

    return (
      <Wrapper>
        <TitleContainer>
          <Label>
            ⓘ Map the CSV columns to fields in Interseller. Rows without an email address
            will be skipped.
          </Label>
          <Button
            disabled={!valid}
            primary
            label={valid ? 'Import Contacts' : 'Email Is Required'}
            handleClick={() => {
              this.onSubmit()
            }}
          />
        </TitleContainer>
        <MappingRow>
          <ColumnLabel>
            CSV Column With Sample
          </ColumnLabel>
          <DropDownHeader>
            Sequence Field
            <LinkButton
              onClick={() => { onAddField() }}
              style={{ position: 'absolute', right: '48px', marginTop: '-3px' }}
            >
              Add Field
            </LinkButton>
          </DropDownHeader>
        </MappingRow>
        {rows}
        <ConfirmModal
          title='Are you sure?'
          description={warningMessage}
          onConfirm={() => {
            this.next()
          }}
          onCancel={() => {
            this.setState({
              warningMessage: null
            })
          }}
          cancelLabel='Edit Mapping'
          confirmLabel='Continue'
          persistent
          isOpen={!!warningMessage}
        />
      </Wrapper>
    )
  }
}

ImportMapper.propTypes = {
  sequence: PropTypes.instanceOf(Immutable.Map),
  importCsv: PropTypes.instanceOf(Immutable.Map),
  onMappingSelect: PropTypes.func,
  onAddField: PropTypes.func
}

export default ImportMapper

import React, { Component } from 'react'
import Immutable from 'immutable'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { format } from 'date-fns'
import { space, width, textAlign } from 'styled-system'
import withSession from 'hocs/session'
import Popover from 'components/Popover'
import Popper from 'components/Popper'
import { Menu, MenuItem } from 'elements/Menu'
import { STATUS_TYPE, SENTIMENT_TYPE, ERROR_TYPE, DELAY_TYPE, REPLY_TYPE } from 'containers/Sequence/constants'
import { CRM_NAMES } from 'containers/Integrations/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { timeFromNow, pluralize } from 'utils/strings'
import { contactErrorToString } from 'utils/keyMapping'
import theme from '../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-right: ${props => (props.options ? '1.75rem' : theme.padding)};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${theme.fonts.button};
  text-transform: uppercase;
  color: ${theme.colors.blue};

  ${props => ([STATUS_TYPE.MESSAGED, STATUS_TYPE.REACHED].includes(props.status)) && css`
    color: ${theme.colors.blue};
  `};

  ${props => ([STATUS_TYPE.ERROR, STATUS_TYPE.BOUNCED, STATUS_TYPE.UNSUBSCRIBED].includes(props.status)) && css`
    color: ${theme.colors.red};
  `};

  ${props => ([STATUS_TYPE.REPLIED, STATUS_TYPE.BOOKED].includes(props.status)) && css`
    color: ${theme.colors.green};
  `};

  ${props => ([STATUS_TYPE.OPENED, STATUS_TYPE.CLICKED].includes(props.status)) && css`
    color: ${theme.colors.blue};
  `};

  ${props => ([STATUS_TYPE.PENDING, STATUS_TYPE.PAUSED, STATUS_TYPE.MANUAL, STATUS_TYPE.MANUAL_TASK].includes(props.status)) && css`
    color: ${theme.colors.yellow};
  `};

  opacity: 1;
  transition: opacity .15s ease-in;
  transition: transform 0.25s ease-out;

  ${props => props.options.length > 0 && css`
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: .8;
      transition: opacity .15s ease-out;
    }
  `};

  ${space}
  ${width}
  ${textAlign}
`

const StatusHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  font-weight: 700;
  line-height: 24px;
`

const StatusDetail = styled.span`
  color: ${theme.labelColor};
`

const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-top: 7px;
`

const InfoIconContainer = styled.div`
  display: inline-block;
  margin-left: 3px;
`

class StatusText extends Component {
  setRepliedOption = () => (
    <MenuItem
      key='set_replied'
      onClick={() => {
        this.props.onRepliedContactUpdate(false)
      }}
    >
      Set Replied
    </MenuItem>
  )

  setBookedOption = () => (
    <MenuItem
      key='set_booked'
      onClick={() => {
        this.props.onRepliedContactUpdate(false, true)
      }}
    >
      Set Booked
    </MenuItem>
  )

  openInGmailOption = () => (
    <MenuItem
      key='open_in_gmail'
      onClick={() => {
        this.props.onOpenInGmail(this.props?.contact?.get('id'))
      }}
    >
      Open In Gmail
    </MenuItem>
  )

  reportContactInaccuracyOption = () => (
    <MenuItem
      key='report_bad_email'
      onClick={() => {
        this.props.onReportContactInaccuracy(this.props?.contact)
      }}
    >
      Report Incorrect Email
    </MenuItem>
  )

  setRemoveReplyOption = (label, booked) => (
    <MenuItem
      key='remove_reply'
      onClick={() => {
        this.props.onRepliedContactUpdate(true, booked)
      }}
    >
      {label}
    </MenuItem>
  )

  setResumeEmails = () => (
    <MenuItem
      key='resume_emails'
      onClick={() => {
        this.props.onUpdateContact({ delay_until: null, domain_paused: false })
      }}
    >
      Resume Emails
    </MenuItem>
  )

  assignSentimentOption = () => (
    <MenuItem
      key='assign_sentiment'
      onClick={() => {
        this.props.onAssignSentiment()
      }}
    >
      Assign Sentiment
    </MenuItem>
  )

  setPauseEmails = () => (
    <MenuItem
      key='pause_email'
      onClick={() => {
        this.props.onPauseContact()
      }}
    >
      Pause Contact
    </MenuItem>
  )

  setJumpToTask = () => (
    <MenuItem
      key='jump_to_task'
      onClick={() => {
        this.props.onJumpToTask()
      }}
    >
      Jump To Task
    </MenuItem>
  )

  clearError = () => {
    const { contact } = this.props
    const errorCount = this.getContactErrorCount(contact)
    return (
      <MenuItem
        key='clear_error'
        onClick={() => {
          this.props.onClearError()
        }}
      >
        {`Clear ${pluralize('Error', 'Errors', errorCount)}`}
      </MenuItem>
    )
  }

  cloneContact = () => (
    <MenuItem
      key='clone_contact'
      onClick={() => {
        this.props.onCloneContact()
      }}
    >
      Fix Email
    </MenuItem>
  )

  unsubscribe = () => (
    <MenuItem
      key='unsubscribe'
      onClick={() => {
        this.props.onUnsubscribeContact()
      }}
    >
      Unsubscribe
    </MenuItem>
  )

  block = (contact) => (
    <MenuItem
      key='block'
      onClick={() => {
        this.props.onBlockContact()
      }}
    >
      Block {contact.get('email_domain') || 'Domain'}
    </MenuItem>
  )

  customizeMessagesOption = () => (
    <MenuItem
      key='customize'
      onClick={() => {
        this.props.onCustomizeMessages()
      }}
    >
      Customize Messages
    </MenuItem>
  )

  getMenuOptionsForError = (error) => {
    switch (error) {
      case ERROR_TYPE.UNSUBSCRIBED:
        return []
      default:
        return [this.clearError()]
    }
  }

  getContactErrorCount = contact => {
    if (!contact) {
      return 0
    }

    const contactErrors = contact
      .get('all_errors')
      .entrySeq()
      .map(item => item[1].get('error'))

    const errorCount = new Set(contactErrors).size
    return errorCount
  }

  menuOptionsForStatus = (status, contact) => {
    const { sequenceUserId, session } = this.props

    switch (status) {
      case STATUS_TYPE.BOUNCED:
        return [
          this.cloneContact(),
          this.setRepliedOption()
        ]
      case STATUS_TYPE.UNSUBSCRIBED:
        return []
      case STATUS_TYPE.REPLIED: {
        const sessionId = session?.get('id')
        const isGoogleAccount = session?.get('account_type') === 'google'
        const requestHashValid = contact?.get('request_hash_valid')

        return [
          ...(isGoogleAccount && sequenceUserId === sessionId ? [this.openInGmailOption()] : []),
          this.setRemoveReplyOption('Remove Reply', false),
          this.assignSentimentOption(),
          this.setBookedOption(),
          this.unsubscribe(),
          this.block(contact),
          ...(requestHashValid ? [this.reportContactInaccuracyOption()] : [])
        ]
      }
      case STATUS_TYPE.BOOKED:
        return [
          this.setRemoveReplyOption('Remove Booked', true),
          this.assignSentimentOption(),
          this.unsubscribe()
        ]
      case STATUS_TYPE.ERROR:
        return this.getMenuOptionsForError(contact.get('error'))
      case STATUS_TYPE.PAUSED:
        return [
          this.setResumeEmails(),
          this.setRepliedOption(),
          this.unsubscribe(),
          this.block(contact)
        ]
      case STATUS_TYPE.MANUAL:
        return [
          this.customizeMessagesOption(),
          this.setRepliedOption(),
          this.setBookedOption(),
          this.setPauseEmails(),
          this.unsubscribe(),
          this.block(contact)
        ]
      case STATUS_TYPE.MANUAL_TASK:
        return [
          this.setJumpToTask(),
          this.setRepliedOption(),
          this.setBookedOption(),
          this.setPauseEmails(),
          this.unsubscribe(),
          this.block(contact)
        ]
      case STATUS_TYPE.MESSAGED:
      case STATUS_TYPE.REACHED:
      case STATUS_TYPE.OPENED:
      case STATUS_TYPE.CLICKED:
      default:
        return [
          this.setRepliedOption(),
          this.setBookedOption(),
          this.setPauseEmails(),
          this.unsubscribe(),
          this.block(contact)
        ]
    }
  }

  colorForStatus = (status) => {
    switch (status) {
      case STATUS_TYPE.REPLIED:
      case STATUS_TYPE.BOOKED:
        return theme.colors.green
      case STATUS_TYPE.ERROR:
      case STATUS_TYPE.BOUNCED:
      case STATUS_TYPE.UNSUBSCRIBED:
        return theme.colors.red
      case STATUS_TYPE.PENDING:
      case STATUS_TYPE.MANUAL:
      case STATUS_TYPE.MANUAL_TASK:
      case STATUS_TYPE.PAUSED:
        return theme.colors.yellow
      default:
        return theme.colors.blue
    }
  }

  titleForStatus = (status, contact, crm) => {
    const crmData = (crm ? crm.get('data') : null) || Immutable.Map({})

    switch (status) {
      case STATUS_TYPE.REACHED:
        return 'TASK DONE'
      case STATUS_TYPE.MANUAL_TASK:
      case STATUS_TYPE.MANUAL:
        return 'PENDING'
      case STATUS_TYPE.PENDING:
        return 'VALIDATING'
      case STATUS_TYPE.OPENED: {
        const viewCount = contact.get('view_count')
        return `${viewCount} ${pluralize('open', 'opens', viewCount)}`
      }
      case STATUS_TYPE.CLICKED: {
        const visitCount = contact.get('visit_count')
        return `${visitCount} ${pluralize('click', 'clicks', visitCount)}`
      }
      case STATUS_TYPE.ERROR: {
        const errorCount = this.getContactErrorCount(contact)
        if (errorCount > 1) {
          return `${errorCount} errors`
        }

        const error = contact.get('error')
        if (error === ERROR_TYPE.UNSUBSCRIBED ||
          error === ERROR_TYPE.INVALID) {
          return error
        }

        if (error === ERROR_TYPE.BLOCKED_CONTACT ||
          error === ERROR_TYPE.BLACKLISTED ||
          error === ERROR_TYPE.TEAM_BLOCKED_CONTACT ||
          error === ERROR_TYPE.TEAM_BLACKLISTED) {
          return 'BLOCKLIST'
        }

        if (error === ERROR_TYPE.CRM_VALIDATION) {
          return `${crmData.get('name') || 'INTEGRATION'} ERROR`
        }

        if (error === ERROR_TYPE.REPLIED_CAMPAIGN ||
          error === ERROR_TYPE.REPLIED_DOMAIN ||
          error === ERROR_TYPE.REPLIED_INDIRECTLY ||
          error === ERROR_TYPE.REPLIED_TEAM) {
          return 'FOUND REPLY'
        }

        if (error === ERROR_TYPE.MISSING_VARIABLE) {
          return 'MISSING INFO'
        }

        if (error === ERROR_TYPE.DUPLICATE_CRM ||
          error === ERROR_TYPE.DUPLICATE_CAMPAIGN ||
          error === ERROR_TYPE.DUPLICATE_TEAM ||
          error === ERROR_TYPE.DUPLICATE_DOMAIN) {
          return 'DUPLICATE'
        }

        if (error === ERROR_TYPE.GDPR_WARNING) {
          return 'GDPR'
        }

        return error
      }
      default:
        return status || 'Added'
    }
  }

  tooltipForStatus = (status, contact) => {
    if (status === STATUS_TYPE.ERROR) {
      // list errors if user has multiple errors
      const errorCount = this.getContactErrorCount(contact)
      const { crm } = this.props
      if (errorCount > 1) {
        const contactErrors = new Set(
          contact
            .get('all_errors')
            .entrySeq()
            .map(item => item[1].get('error'))
        )

        return [...contactErrors].map((err) => {
          const crmName = crm.getIn(['data', 'name'])
          return contactErrorToString(err, crmName)
        }).join(', ')
      }

      // describe error if contact has one error
      const error = contact.get('error')
      if (error) {
        return contact.get('error_message')
      }
    } else if (status === STATUS_TYPE.PAUSED) {
      if (contact.get('domain_paused')) {
        return `Contact will automatically resume once contacts at ${contact.get('domain')} reach the end of their sequence`
      } else if (contact.get('delay_until_reason')) {
        switch (contact.get('delay_until_reason')) {
          case DELAY_TYPE.EMAIL_ERROR:
            return `A temporary error occurred when sending an email to ${contact.get('email')} and we're pausing to see if it resolves itself`
          case DELAY_TYPE.OUT_OF_OFFICE_EMAIL:
            return 'Contact was paused automatically for a week because we received a vacation responder from the contact'
          case DELAY_TYPE.OUT_OF_OFFICE_DATE:
            return 'Contact was paused automatically because we received a vacation responder with a return date from the contact'
          case DELAY_TYPE.DOMAIN_BOUNCES:
            return `Contact was paused because we received too many bounces from ${contact.get('domain')}`
          case DELAY_TYPE.RESUBSCRIBED:
            return 'Contact was paused because they were resubscribed'
        }
      }
    }
    return null
  }

  repliedDetail = (contact) => {
    const details = []

    const repliedType = contact.get('replied_type')
    const sentiment = contact.get('sentiment')
    if (
      sentiment &&
      SENTIMENT_TYPE[sentiment] &&
      (!repliedType || repliedType === 'EMAIL')
    ) {
      details.push(SENTIMENT_TYPE[sentiment])
    } else if (repliedType && repliedType !== 'EMAIL') {
      details.push(`via ${REPLY_TYPE[repliedType]}`)
    }

    details.push(timeFromNow(contact.get('replied_at')))

    return details.join(', ')
  }

  bookedDetail = (contact) => {
    const details = []

    const sentiment = contact.get('sentiment')
    if (sentiment && SENTIMENT_TYPE[sentiment]) {
      details.push(SENTIMENT_TYPE[sentiment])
    }

    details.push(timeFromNow(contact.get('booked_at')))
    return details.join(', ')
  }

  detailForStatus = (status, contact, session, crm) => {
    const crmData = (crm ? crm.get('data') : null) || Immutable.Map({})

    switch (status) {
      case STATUS_TYPE.ERROR: {
        const error = contact.get('error')
        const crmType = crmData.get('type') || 'CRM'
        const crmName = crmData.get('name') || CRM_NAMES[crmType] || crmType

        // custom error message if there is more than 1 error
        const errorCount = this.getContactErrorCount(contact)
        if (errorCount > 1) {
          return 'contact needs attention'
        }

        switch (error) {
          case ERROR_TYPE.INVALID:
            return 'invalid email address'
          case ERROR_TYPE.DUPLICATE_CRM:
            return <span>Exists in <strong>{crmName}</strong></span>
          case ERROR_TYPE.DUPLICATE_CAMPAIGN:
            return 'in different sequence'
          case ERROR_TYPE.BLACKLISTED:
          case ERROR_TYPE.BLOCKED_CONTACT:
            return 'your safety settings'
          case ERROR_TYPE.TEAM_BLACKLISTED:
          case ERROR_TYPE.TEAM_BLOCKED_CONTACT:
            return 'team safety settings'
          case ERROR_TYPE.DUPLICATE_TEAM:
            return 'in teammate\'s sequence'
          case ERROR_TYPE.DUPLICATE_DOMAIN:
            return 'is teammate\'s account'
          case ERROR_TYPE.REPLIED_CAMPAIGN:
            return 'in another sequence'
          case ERROR_TYPE.REPLIED_DOMAIN:
            return 'from different contact'
          case ERROR_TYPE.REPLIED_INDIRECTLY:
            return 'replied outside sequence'
          case ERROR_TYPE.REPLIED_TEAM:
            return 'replied to teammate'
          case ERROR_TYPE.MISSING_VARIABLE:
            return 'add missing field'
          case ERROR_TYPE.GDPR_WARNING:
            return 'contact located in GDPR country'
          default:
            return timeFromNow(contact.get('updated_at'))
        }
      }
      case STATUS_TYPE.MANUAL:
        return 'Needs Personalization'
      case STATUS_TYPE.MANUAL_TASK:
        return 'Pending Task'
      case STATUS_TYPE.OPENED:
        return timeFromNow(contact.get('viewed_at'))
      case STATUS_TYPE.CLICKED:
        return timeFromNow(contact.get('visited_at'))
      case STATUS_TYPE.REPLIED:
        return this.repliedDetail(contact)
      case STATUS_TYPE.PAUSED: {
        if (contact.get('delayed_indefinitely')) {
          return 'Indefinitely'
        } else if (contact.get('delay_until')) {
          return `until ${format(new Date(contact.get('delay_until')), 'MMM Do')}`
        } else if (contact.get('domain_paused')) {
          return 'Account Throttled'
        }

        return 'Indefinitely'
      }
      case STATUS_TYPE.BOOKED:
        return this.bookedDetail(contact)
      case STATUS_TYPE.MESSAGED:
      case STATUS_TYPE.BOUNCED:
        return timeFromNow(contact.get('last_messaged_at') || contact.get('last_action_at'))
      case STATUS_TYPE.PENDING:
        return timeFromNow(contact.get('created_at'))
      case STATUS_TYPE.REACHED:
        return timeFromNow(contact.get('last_completed_task_at'))
      default:
        return timeFromNow(contact.get('last_action_at'))
    }
  }

  render () {
    const {
      contact,
      crm,
      session,
      position = 'left',
      align = 'left',
      portal = false,
      readOnly,
      ...rest
    } = this.props

    // Forcing paused status since it exists as delayed on the server now
    const state = contact.get('state')
    const isError = state === STATUS_TYPE.ERROR
    const inQueue = contact.get('in_task_queue')

    let status = contact.get('state')
    if (!isError) {
      if (contact.get('delayed')) {
        status = STATUS_TYPE.PAUSED
      } else if (contact.get('needs_manual_attention') === 'task' && inQueue) {
        status = STATUS_TYPE.MANUAL_TASK
      } else if (contact.get('needs_manual_attention') === 'message' && inQueue) {
        status = STATUS_TYPE.MANUAL
      }
    }

    const options = this.menuOptionsForStatus(status, contact)
    const caretColor = options.length > 0 ? this.colorForStatus(status) : 'transparent'
    const text = this.titleForStatus(status, contact, crm)
    const detail = this.detailForStatus(status, contact, session, crm)
    const tooltip = this.tooltipForStatus(status, contact)

    const content = (hasTooltip) => {
      return (
        <div>
          <StatusHeader>
            <div
              style={{
                width: (options.length > 0 ? 'auto' : '100%'),
                textAlign: align,
                marginLeft: (align === 'right' ? 'auto' : 0)
              }}
            >
              {text}
            </div>
            {!readOnly &&
              options.length > 0 &&
                <CaretContainer>
                  <FontAwesomeIcon
                    icon={['fal', 'angle-down']}
                    color={caretColor}
                    transform={{
                      size: 28
                    }}
                  />
                </CaretContainer>}
          </StatusHeader>
          <StatusDetail style={{ textAlign: align }}>
            {detail}
            {(hasTooltip) &&
              <InfoIconContainer>
                <FontAwesomeIcon
                  icon={['fas', 'info-circle']}
                  color={theme.labelColor}
                  transform={{
                    size: 14
                  }}
                />
              </InfoIconContainer>}
          </StatusDetail>
        </div>
      )
    }

    const statusElement = (
      <Wrapper
        status={status}
        options={options}
        {...rest}
      >
        {!tooltip && content(false)}
        {tooltip &&
          <Tooltip
            // options
            title={tooltip}
            position='top'
            trigger='mouseenter'
            arrow
          >
            {content(true)}
          </Tooltip>}
      </Wrapper>
    )

    if (readOnly) {
      return statusElement
    }

    if (options.length > 0) {
      if (portal) {
        return (
          <Popper
            position='bottom-start'
            portal
            trigger={statusElement}
          >
            {close => (
              <Menu style={{ minWidth: '10rem' }} onClick={close}>
                {options}
              </Menu>
            )}
          </Popper>
        )
      }
      return (
        <Popover
          ref={(element) => { this.popover = element }}
          top='0.5rem'
          minWidth='10rem'
          position={position}
          portal
          content={
            <Menu>
              {options}
            </Menu>
          }
        >
          {statusElement}
        </Popover>
      )
    }
    return statusElement
  }
}

StatusText.propTypes = {
  sequenceUserId: PropTypes.string,
  contact: PropTypes.object,
  onCustomizeMessages: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onClearError: PropTypes.func,
  onRepliedContactUpdate: PropTypes.func,
  onOpenInGmail: PropTypes.func,
  onReportContactInaccuracy: PropTypes.func,
  onPauseContact: PropTypes.func,
  onCloneContact: PropTypes.func,
  onUnsubscribeContact: PropTypes.func,
  onAssignSentiment: PropTypes.func,
  onBlockContact: PropTypes.func,
  position: PropTypes.string,
  align: PropTypes.string,
  session: PropTypes.object,
  portal: PropTypes.bool,
  readOnly: PropTypes.bool,
  crm: PropTypes.object,
  onJumpToTask: PropTypes.func
}

export default withSession(StatusText)

import React from 'react'
import ReactDOM from 'react-dom'
import Rollbar from 'rollbar'
import { Provider, ErrorBoundary } from '@rollbar/react' // Provider imports 'rollbar'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import 'react-toggle/style.css'
import 'react-tippy/dist/tippy.css'
import 'rc-slider/assets/index.css'
import 'react-datetime/css/react-datetime.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { makeSelectLocationState } from 'containers/App/selectors'
import configureStore from './stores/store'
import Root from './containers/Root'
import './assets/reset.css'
import GlobalStyles from './global-styles'
import './fontawesome'

const rollbarConfig = {
  accessToken: window.SERVER_DATA.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: window.SERVER_DATA.environment,
  codeVersion: window.SERVER_DATA.commitSha,
  enabled: !!window.SERVER_DATA.rollbarToken
}
const rollbar = new Rollbar(rollbarConfig)
window.rollbar = rollbar

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {}
const routerHistory = browserHistory
const store = configureStore(initialState, routerHistory)

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(routerHistory, store, {
  selectLocationState: makeSelectLocationState()
})

const rootEl = document.getElementById('root')

const render = (Component) => {
  ReactDOM.render(
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Component history={history} store={store} />
      </ErrorBoundary>
      <GlobalStyles />
    </Provider>,
    rootEl // eslint-disable-line comma-dangle
  )
}

// Start the app
render(Root)

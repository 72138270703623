import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'
import { space, width, textAlign } from 'styled-system'
import Button from 'components/Button'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Modal from 'components/Modal'
import ChargePreview from './ChargePreview'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
`

const Title = styled.h3`
  color: ${theme.titleColor};
  font: ${theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`

const Label = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.label};
  text-align: left;
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  ${space}
  ${width}
  ${textAlign}
`

const FieldContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`

const ChargesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 140px;
`

class ChangeSeats extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      subscription
    } = nextProps
    const {
      seats
    } = this.state

    if (!seats) {
      const newSeats = subscription.getIn(['data', 'quantity'])
      this.setState({
        seats: newSeats
      })
    }
  }

  render () {
    const {
      actions,
      subscription,
      preview,
      isOpen,
      onSave,
      onModalClose
    } = this.props
    const {
      seats
    } = this.state

    const chargeToday = preview.getIn(['data', 'now'])
    const chargeLater = preview.getIn(['data', 'later'])
    const interval = subscription.getIn(['data', 'plan', 'interval'])
    const laterAt = preview.getIn(['data', 'later_at'])
    const loading = preview.get('loading') || !preview.get('data')
    const date = format(new Date(laterAt * 1000), 'MMM Do')

    let chargeLabel = 'Prorated Charge'
    if (chargeToday < 0) {
      chargeLabel = 'Credits Added to Account'
    }

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={() => {
          this.setState({
            seats: null
          })
          onModalClose()
          actions.resetUpdatePreview()
        }}
      >
        <Wrapper>
          <Title>Adjust Total Seats</Title>
          <FieldContainer>
            <Input
              label='Total Seats'
              inputId='seats'
              type='number'
              width='50%'
              mr='2rem'
              small
              value={seats}
              min={1}
              onValueChange={(value) => {
                this.setState({
                  seats: value
                })
                actions.fetchUpdatePreview({ seatCount: value })
              }}
            />
            {!loading &&
              <ChargesContainer>
                <Label>
                  {chargeLabel}
                </Label>
                <ChargePreview
                  amount={Math.abs(chargeToday)}
                />
                <Label pt='1rem'>
                  Next Charge On <strong>{date}</strong>
                </Label>
                <ChargePreview
                  amount={chargeLater}
                  label={`/${interval || 'month'}`}
                />
              </ChargesContainer>}
            {loading &&
              <ChargesContainer>
                <Loading />
              </ChargesContainer>}
          </FieldContainer>
          <Button
            primary
            label='Save Changes'
            full
            mt='1rem'
            handleClick={() => {
              onSave(parseInt(this.state.seats, 10))
              actions.resetUpdatePreview()
              this.setState({})
            }}
          />
        </Wrapper>
      </Modal>
    )
  }
}

ChangeSeats.propTypes = {
  actions: PropTypes.object,
  preview: PropTypes.object,
  subscription: PropTypes.object,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onModalClose: PropTypes.func
}

export default ChangeSeats

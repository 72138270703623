import Immutable from 'immutable'

export const FETCH_CONTACTS_REQUEST = 'Sequence/FETCH_CONTACTS_REQUEST'
export const FETCH_CONTACTS_SUCCESS = 'Sequence/FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'Sequence/FETCH_CONTACTS_FAILURE'

export const FETCH_SEQUENCE_REQUEST = 'Sequence/FETCH_SEQUENCE_REQUEST'
export const FETCH_SEQUENCE_SUCCESS = 'Sequence/FETCH_SEQUENCE_SUCCESS'
export const FETCH_SEQUENCE_FAILURE = 'Sequence/FETCH_SEQUENCE_FAILURE'

export const DELETE_SEQUENCE_REQUEST = 'Sequence/DELETE_SEQUENCE_REQUEST'
export const DELETE_SEQUENCE_SUCCESS = 'Sequence/DELETE_SEQUENCE_SUCCESS'
export const DELETE_SEQUENCE_FAILURE = 'Sequence/DELETE_SEQUENCE_FAILURE'

export const FETCH_SEQUENCE_STATE_REQUEST = 'Sequence/FETCH_SEQUENCE_STATE_REQUEST'
export const FETCH_SEQUENCE_STATE_SUCCESS = 'Sequence/FETCH_SEQUENCE_STATE_SUCCESS'
export const FETCH_SEQUENCE_STATE_FAILURE = 'Sequence/FETCH_SEQUENCE_STATE_FAILURE'

export const FETCH_SEQUENCE_STATS_REQUEST = 'Sequence/FETCH_SEQUENCE_STATS_REQUEST'
export const FETCH_SEQUENCE_STATS_SUCCESS = 'Sequence/FETCH_SEQUENCE_STATS_SUCCESS'
export const FETCH_SEQUENCE_STATS_FAILURE = 'Sequence/FETCH_SEQUENCE_STATS_FAILURE'

export const FETCH_STEP_STATS_REQUEST = 'Sequence/FETCH_STEP_STATS_REQUEST'
export const FETCH_STEP_STATS_SUCCESS = 'Sequence/FETCH_STEP_STATS_SUCCESS'
export const FETCH_STEP_STATS_FAILURE = 'Sequence/FETCH_STEP_STATS_FAILURE'

export const FETCH_EXPORT_CSV_REQUEST = 'Sequence/FETCH_EXPORT_CSV_REQUEST'
export const FETCH_EXPORT_CSV_SUCCESS = 'Sequence/FETCH_EXPORT_CSV_SUCCESS'
export const FETCH_EXPORT_CSV_FAILURE = 'Sequence/FETCH_EXPORT_CSV_FAILURE'

// Create Contact
export const CREATE_CONTACT_REQUEST = 'Sequence/CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS = 'Sequence/CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'Sequence/CREATE_CONTACT_FAILURE'
export const CREATE_CONTACT_RESET = 'Sequence/CREATE_CONTACT_RESET'

// Fetch contact
export const FETCH_CONTACT_REQUEST = 'Sequence/FETCH_CONTACT_REQUEST'
export const FETCH_CONTACT_SUCCESS = 'Sequence/FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILURE = 'Sequence/FETCH_CONTACT_FAILURE'

// Update contact
export const UPDATE_CONTACT_REQUEST = 'Sequence/UPDATE_CONTACT_REQUEST'
export const UPDATE_CONTACT_SUCCESS = 'Sequence/UPDATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_FAILURE = 'Sequence/UPDATE_CONTACT_FAILURE'

// Clone Contact (Update email on bounce)
export const CLONE_CONTACT_REQUEST = 'Sequence/CLONE_CONTACT_REQUEST'
export const CLONE_CONTACT_SUCCESS = 'Sequence/CLONE_CONTACT_SUCCESS'
export const CLONE_CONTACT_FAILURE = 'Sequence/CLONE_CONTACT_FAILURE'

// Replied contact
export const REPLIED_CONTACT_REQUEST = 'Sequence/REPLIED_CONTACT_REQUEST'
export const REPLIED_CONTACT_SUCCESS = 'Sequence/REPLIED_CONTACT_SUCCESS'
export const REPLIED_CONTACT_FAILURE = 'Sequence/REPLIED_CONTACT_FAILURE'

// Unsubscribe contact
export const UNSUBSCRIBE_CONTACT_REQUEST = 'Sequence/UNSUBSCRIBE_CONTACT_REQUEST'
export const UNSUBSCRIBE_CONTACT_SUCCESS = 'Sequence/UNSUBSCRIBE_CONTACT_SUCCESS'
export const UNSUBSCRIBE_CONTACT_FAILURE = 'Sequence/UNSUBSCRIBE_CONTACT_FAILURE'

// Block contact
export const BLOCK_CONTACT_REQUEST = 'Sequence/BLOCK_CONTACT_REQUEST'
export const BLOCK_CONTACT_SUCCESS = 'Sequence/BLOCK_CONTACT_SUCCESS'
export const BLOCK_CONTACT_FAILURE = 'Sequence/BLOCK_CONTACT_FAILURE'

// Fetch contact actions
export const FETCH_CONTACT_ACTIONS_REQUEST = 'Sequence/FETCH_CONTACT_ACTIONS_REQUEST'
export const FETCH_CONTACT_ACTIONS_SUCCESS = 'Sequence/FETCH_CONTACT_ACTIONS_SUCCESS'
export const FETCH_CONTACT_ACTIONS_FAILURE = 'Sequence/FETCH_CONTACT_ACTIONS_FAILURE'
export const FETCH_CONTACT_ACTIONS_RESET = 'Sequence/FETCH_CONTACT_ACTIONS_RESET'

// Fetch contact actions
export const FETCH_CONTACT_COMPANY_REQUEST = 'Sequence/FETCH_CONTACT_COMPANY_REQUEST'
export const FETCH_CONTACT_COMPANY_SUCCESS = 'Sequence/FETCH_CONTACT_COMPANY_SUCCESS'
export const FETCH_CONTACT_COMPANY_FAILURE = 'Sequence/FETCH_CONTACT_COMPANY_FAILURE'

// Fetch contact integration
export const FETCH_CONTACT_INTEGRATION_REQUEST = 'Sequence/FETCH_CONTACT_INTEGRATION_REQUEST'
export const FETCH_CONTACT_INTEGRATION_SUCCESS = 'Sequence/FETCH_CONTACT_INTEGRATION_SUCCESS'
export const FETCH_CONTACT_INTEGRATION_FAILURE = 'Sequence/FETCH_CONTACT_INTEGRATION_FAILURE'

// Fetch contact messages
export const FETCH_CONTACT_MESSAGES_REQUEST = 'Sequence/FETCH_CONTACT_MESSAGES_REQUEST'
export const FETCH_CONTACT_MESSAGES_SUCCESS = 'Sequence/FETCH_CONTACT_MESSAGES_SUCCESS'
export const FETCH_CONTACT_MESSAGES_FAILURE = 'Sequence/FETCH_CONTACT_MESSAGES_FAILURE'
export const FETCH_CONTACT_MESSAGES_RESET = 'Sequence/FETCH_CONTACT_MESSAGES_RESET'

// Report inaccurate contact information
export const REPORT_CONTACT_INACCURACY_REQUEST = 'Sequence/REPORT_CONTACT_INACCURACY_REQUEST'
export const REPORT_CONTACT_INACCURACY_SUCCESS = 'Sequence/REPORT_CONTACT_INACCURACY_SUCCESS'
export const REPORT_CONTACT_INACCURACY_FAILURE = 'Sequence/REPORT_CONTACT_INACCURACY_FAILURE'

// Fetch preview for contact
export const FETCH_SEQUENCE_PREVIEW_REQUEST = 'Sequence/FETCH_SEQUENCE_PREVIEW_REQUEST'
export const FETCH_SEQUENCE_PREVIEW_SUCCESS = 'Sequence/FETCH_SEQUENCE_PREVIEW_SUCCESS'
export const FETCH_SEQUENCE_PREVIEW_FAILURE = 'Sequence/FETCH_SEQUENCE_PREVIEW_FAILURE'

// Update sequence
export const UPDATE_SEQUENCE_REQUEST = 'Sequence/UPDATE_SEQUENCE_REQUEST'
export const UPDATE_SEQUENCE_SUCCESS = 'Sequence/UPDATE_SEQUENCE_SUCCESS'
export const UPDATE_SEQUENCE_FAILURE = 'Sequence/UPDATE_SEQUENCE_FAILURE'
export const UPDATE_SEQUENCE_RESET = 'Sequence/UPDATE_SEQUENCE_RESET'

// Restore sequence
export const RESTORE_SEQUENCE_REQUEST = 'Sequence/RESTORE_SEQUENCE_REQUEST'
export const RESTORE_SEQUENCE_SUCCESS = 'Sequence/RESTORE_SEQUENCE_SUCCESS'
export const RESTORE_SEQUENCE_FAILURE = 'Sequence/RESTORE_SEQUENCE_FAILURE'

// Update sequence
export const UPDATE_CUSTOM_FIELDS_REQUEST = 'Sequence/UPDATE_CUSTOM_FIELDS_REQUEST'
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'Sequence/UPDATE_CUSTOM_FIELDS_SUCCESS'
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'Sequence/UPDATE_CUSTOM_FIELDS_FAILURE'

// Customize contact step
export const UPDATE_CONTACT_STEP_REQUEST = 'Sequence/UPDATE_CONTACT_STEP_REQUEST'
export const UPDATE_CONTACT_STEP_SUCCESS = 'Sequence/UPDATE_CONTACT_STEP_SUCCESS'
export const UPDATE_CONTACT_STEP_FAILURE = 'Sequence/UPDATE_CONTACT_STEP_FAILURE'

// Remove contact step
export const REMOVE_CONTACT_STEP_REQUEST = 'Sequence/REMOVE_CONTACT_STEP_REQUEST'
export const REMOVE_CONTACT_STEP_SUCCESS = 'Sequence/REMOVE_CONTACT_STEP_SUCCESS'
export const REMOVE_CONTACT_STEP_FAILURE = 'Sequence/REMOVE_CONTACT_STEP_FAILURE'

// Send test email
export const SEND_TEST_EMAIL_REQUEST = 'Sequence/SEND_TEST_EMAIL_REQUEST'

// Mass Contact Updates
export const DELETE_CONTACTS_REQUEST = 'Sequence/DELETE_CONTACTS_REQUEST'
export const MOVE_CONTACTS_REQUEST = 'Sequence/MOVE_CONTACTS_REQUEST'
export const COPY_CONTACTS_REQUEST = 'Sequence/COPY_CONTACTS_REQUEST'
export const REMOVE_SEQUENCE_CONTACTS = 'Sequence/REMOVE_SEQUENCE_CONTACTS'

export const UPDATE_CONTACTS_REQUEST = 'Sequence/UPDATE_CONTACTS_REQUEST'
export const UPDATE_CONTACTS_SUCCESS = 'Sequence/UPDATE_CONTACTS_SUCCESS'

// Import CSV
export const CREATE_CSV_REQUEST = 'Sequence/CREATE_CSV_REQUEST'
export const CREATE_CSV_SUCCESS = 'Sequence/CREATE_CSV_SUCCESS'
export const CREATE_CSV_FAILURE = 'Sequence/CREATE_CSV_FAILURE'
export const CREATE_CSV_RESET = 'Sequence/CREATE_CSV_RESET'

// Update CSV
export const UPDATE_CSV_REQUEST = 'Sequence/UPDATE_CSV_REQUEST'
export const UPDATE_CSV_SUCCESS = 'Sequence/UPDATE_CSV_SUCCESS'
export const UPDATE_CSV_FAILURE = 'Sequence/UPDATE_CSV_FAILURE'

// Import CSV to Sequence
export const IMPORT_CSV_REQUEST = 'Sequence/IMPORT_CSV_REQUEST'
export const IMPORT_CSV_SUCCESS = 'Sequence/IMPORT_CSV_SUCCESS'
export const IMPORT_CSV_FAILURE = 'Sequence/IMPORT_CSV_FAILURE'

// Fetch templates
export const FETCH_TEMPLATES_REQUEST = 'Sequence/FETCH_TEMPLATES_REQUEST'
export const FETCH_TEMPLATES_SUCCESS = 'Sequence/FETCH_TEMPLATES_SUCCESS'
export const FETCH_TEMPLATES_FAILURE = 'Sequence/FETCH_TEMPLATES_FAILURE'

// Create templates
export const CREATE_TEMPLATE_REQUEST = 'Sequence/CREATE_TEMPLATE_REQUEST'
export const CREATE_TEMPLATE_SUCCESS = 'Sequence/CREATE_TEMPLATE_SUCCESS'
export const CREATE_TEMPLATE_FAILURE = 'Sequence/CREATE_TEMPLATE_FAILURE'

// Delete templates
export const DELETE_TEMPLATE_REQUEST = 'Sequence/DELETE_TEMPLATE_REQUEST'
export const DELETE_TEMPLATE_SUCCESS = 'Sequence/DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_FAILURE = 'Sequence/DELETE_TEMPLATE_FAILURE'

// Check for existing sequence lookups
export const FETCH_LOOKUP_STATUS_REQUEST = 'Sequence/FETCH_LOOKUP_STATUS_REQUEST'
export const FETCH_LOOKUP_STATUS_SUCCESS = 'Sequence/FETCH_LOOKUP_STATUS_SUCCESS'
export const FETCH_LOOKUP_STATUS_FAILURE = 'Sequence/FETCH_LOOKUP_STATUS_FAILURE'

// Check for similar contacts
export const FETCH_SIMILAR_CONTACTS_REQUEST = 'Sequence/FETCH_SIMILAR_CONTACTS_REQUEST'
export const FETCH_SIMILAR_CONTACTS_SUCCESS = 'Sequence/FETCH_SIMILAR_CONTACTS_SUCCESS'
export const FETCH_SIMILAR_CONTACTS_FAILURE = 'Sequence/FETCH_SIMILAR_CONTACTS_FAILURE'

// Upload Files
export const CREATE_FILE_REQUEST = 'Sequence/CREATE_FILE_REQUEST'
export const CREATE_FILE_SUCCESS = 'Sequence/CREATE_FILE_SUCCESS'
export const CREATE_FILE_FAILURE = 'Sequence/CREATE_FILE_FAILURE'
export const CREATE_FILE_RESET = 'Sequence/CREATE_FILE_RESET'

// Preview Contacts List
export const FETCH_PREVIEW_CONTACTS_REQUEST = 'Sequence/FETCH_PREVIEW_CONTACTS_REQUEST'
export const FETCH_PREVIEW_CONTACTS_SUCCESS = 'Sequence/FETCH_PREVIEW_CONTACTS_SUCCESS'
export const FETCH_PREVIEW_CONTACTS_FAILURE = 'Sequence/FETCH_PREVIEW_CONTACTS_FAILURE'

// Send Step
export const FORCE_SEND_STEP_REQUEST = 'Sequence/FORCE_SEND_STEP_REQUEST'

// Fetch CRM
export const FETCH_CRM_REQUEST = 'Sequence/FETCH_CRM_REQUEST'
export const FETCH_CRM_SUCCESS = 'Sequence/FETCH_CRM_SUCCESS'
export const FETCH_CRM_FAILURE = 'Sequence/FETCH_CRM_FAILURE'

// Refresh CRM
export const REFRESH_CRM_REQUEST = 'Sequence/REFRESH_CRM_REQUEST'

// Fetch Contact Reply
export const FETCH_CONTACT_REPLY_REQUEST = 'Sequence/FETCH_CONTACT_REPLY_REQUEST'
export const FETCH_CONTACT_REPLY_SUCCESS = 'Sequence/FETCH_CONTACT_REPLY_SUCCESS'
export const FETCH_CONTACT_REPLY_FAILURE = 'Sequence/FETCH_CONTACT_REPLY_FAILURE'

// Transfer Sequence
export const TRANSFER_SEQUENCE_REQUEST = 'Sequence/TRANSFER_SEQUENCE_REQUEST'
export const TRANSFER_SEQUENCE_SUCCESS = 'Sequence/TRANSFER_SEQUENCE_SUCCESS'
export const TRANSFER_SEQUENCE_FAILURE = 'Sequence/TRANSFER_SEQUENCE_FAILURE'
export const NEW_SEQUENCE_RESET = 'Sequence/NEW_SEQUENCE_RESET'

// fetch sent messages
export const FETCH_SENT_MESSAGES_REQUEST = 'Sequence/FETCH_SENT_MESSAGES_REQUEST'
export const FETCH_SENT_MESSAGES_SUCCESS = 'Sequence/FETCH_SENT_MESSAGES_SUCCESS'
export const FETCH_SENT_MESSAGES_FAILURE = 'Sequence/FETCH_SENT_MESSAGES_FAILURE'
export const FETCH_SENT_MESSAGES_RESET = 'Sequence/FETCH_SENT_MESSAGES_RESET'

// Filter constants
export const STATS_TOTAL = 'Stats/SEQUENCE_TOTAL'
export const STATS_ONGOING = 'Stats/ONGOING_TOTAL'
export const STATS_MESSAGED = 'Stats/MESSAGED_TOTAL'
export const STATS_OPEN = 'Stats/SEQUENCE_OPEN'
export const STATS_CLICKED = 'Stats/SEQUENCE_CLICKED'
export const STATS_REPLIED = 'Stats/SEQUENCE_REPLIED'
export const STATS_INTERESTED = 'Stats/STATS_INTERESTED'
export const STATS_BOOKED = 'Stats/SEQUENCE_BOOKED'
export const STATS_BOUNCED = 'Stats/SEQUENCE_BOUNCED'
export const STATS_ATTENTION = 'Stats/SEQUENCE_ATTENTION'

// Fetch errors on a group of contacts
export const FETCH_CONTACT_ERRORS_REQUEST = 'Sequence/FETCH_CONTACT_ERRORS_REQUEST'
export const FETCH_CONTACT_ERRORS_SUCCESS = 'Sequence/FETCH_CONTACT_ERRORS_SUCCESS'
export const FETCH_CONTACT_ERRORS_FAILURE = 'Sequence/FETCH_CONTACT_ERRORS_FAILURE'
export const FETCH_CONTACT_ERRORS_RESET = 'Sequence/FETCH_CONTACT_ERRORS_RESET'

// Clear errors on a group of contacts
export const UPDATE_CONTACT_ERRORS_REQUEST = 'Sequence/UPDATE_CONTACT_ERRORS_REQUEST'
export const UPDATE_CONTACT_ERRORS_SUCCESS = 'Sequence/UPDATE_CONTACT_ERRORS_SUCCESS'
export const UPDATE_CONTACT_ERRORS_FAILURE = 'Sequence/UPDATE_CONTACT_ERRORS_FAILURE'

// Fetch the selected count based on the queries
export const FETCH_SELECTED_CONTACT_COUNT_REQUEST = 'Sequence/FETCH_SELECTED_CONTACT_COUNT_REQUEST'
export const FETCH_SELECTED_CONTACT_COUNT_SUCCESS = 'Sequence/FETCH_SELECTED_CONTACT_COUNT_SUCCESS'
export const FETCH_SELECTED_CONTACT_COUNT_FAILURE = 'Sequence/FETCH_SELECTED_CONTACT_COUNT_FAILURE'

export const FETCH_OGP_REQUEST = 'Sequence/FETCH_OGP_REQUEST'
export const FETCH_OGP_SUCCESS = 'Sequence/FETCH_OGP_SUCCESS'
export const FETCH_OGP_FAILURE = 'Sequence/FETCH_OGP_FAILURE'

// Fetch addresses
export const FETCH_ADDRESSES_REQUEST = 'Sequence/FETCH_ADDRESSES_REQUEST'
export const FETCH_ADDRESSES_SUCCESS = 'Sequence/FETCH_ADDRESSES_SUCCESS'
export const FETCH_ADDRESSES_FAILURE = 'Sequence/FETCH_ADDRESSES_FAILURE'

// fetch contact steps
export const FETCH_CONTACT_STEPS_REQUEST = 'Sequence/FETCH_CONTACT_STEPS_REQUEST'
export const FETCH_CONTACT_STEPS_SUCCESS = 'Sequence/FETCH_CONTACT_STEPS_SUCCESS'
export const FETCH_CONTACT_STEPS_FAILURE = 'Sequence/FETCH_CONTACT_STEPS_FAILURE'
export const FETCH_CONTACT_STEPS_RESET = 'Sequence/FETCH_CONTACT_STEPS_RESET'

// fetch contact phone number
export const FETCH_CONTACT_PHONE_REQUEST = 'Sequence/FETCH_CONTACT_PHONE_REQUEST'
export const FETCH_CONTACT_PHONE_SUCCESS = 'Sequence/FETCH_CONTACT_PHONE_SUCCESS'
export const FETCH_CONTACT_PHONE_FAILURE = 'Sequence/FETCH_CONTACT_PHONE_FAILURE'

export const IMPORT_CRM_CONTACTS_REQUEST = 'Sequence/IMPORT_CRM_CONTACTS_REQUEST'
export const IMPORT_CRM_CONTACTS_SUCCESS = 'Sequence/IMPORT_CRM_CONTACTS_SUCCESS'
export const IMPORT_CRM_CONTACTS_FAILURE = 'Sequence/IMPORT_CRM_CONTACTS_FAILURE'

export const IMPORT_CRM_OPTION_SELECTED = 'Sequence/IMPORT_CRM_OPTION_SELECTED'
export const IMPORT_CRM_CONTACTS_RESET = 'Sequence/IMPORT_CRM_CONTACTS_RESET'

export const SHOW_WARNING_MODAL = 'Sequence/SHOW_WARNING_MODAL'
export const HIDE_WARNING_MODAL = 'Sequence/HIDE_WARNING_MODAL'

// resync to crm
export const RESYNC_TO_CRM_REQUEST = 'Sequence/RESYNC_TO_CRM_REQUEST'
export const RESYNC_TO_CRM_SUCCESS = 'Sequence/RESYNC_TO_CRM_SUCCESS'
export const RESYNC_TO_CRM_FAILURE = 'Sequence/RESYNC_TO_CRM_FAILURE'

// bulk phone number lookups
export const PHONE_NUMBER_LOOKUPS_REQUEST = 'Sequence/PHONE_NUMBER_LOOKUPS_REQUEST'
export const PHONE_NUMBER_LOOKUPS_SUCCESS = 'Sequence/PHONE_NUMBER_LOOKUPS_SUCCESS'
export const PHONE_NUMBER_LOOKUPS_FAILURE = 'Sequence/PHONE_NUMBER_LOOKUPS_FAILURE'

// bulk phone number lookups
export const FETCH_SEQUENCE_SIGNATURE_REQUEST = 'Sequence/FETCH_SEQUENCE_SIGNATURE_REQUEST'
export const FETCH_SEQUENCE_SIGNATURE_SUCCESS = 'Sequence/FETCH_SEQUENCE_SIGNATURE_SUCCESS'
export const FETCH_SEQUENCE_SIGNATURE_FAILURE = 'Sequence/FETCH_SEQUENCE_SIGNATURE_FAILURE'

// Field blocklist
export const FIELD_BLOCKLIST = [
  'linkedin_company_address',
  'linkedin_company_id',
  'linkedin_company_industry',
  'linkedin_company_location',
  'linkedin_company_size',
  'linkedin_company_url',
  'linkedin_company_year',
  'linkedin_profile_summary'
]

export const TOP_LEVEL_FIELD_VALUES = [
  'name',
  'first_name',
  'last_name',
  'email',
  'title',
  'location',
  'phone_number',
  'company',
  'url',
  'profile_url',
  'error',
  'source_url',
  'location_city',
  'location_state',
  'location_country'
]

export const ERROR_TYPE = {
  INVALID: 'INVALID',
  MISSING_VARIABLE: 'MISSING_VARIABLE',
  BLACKLISTED: 'BLACKLISTED', // deprecated
  BLOCKED_CONTACT: 'BLOCKED_CONTACT',
  TEAM_BLACKLISTED: 'TEAM_BLACKLISTED', // deprecated
  TEAM_BLOCKED_CONTACT: 'TEAM_BLOCKED_CONTACT',
  CRM_VALIDATION: 'CRM_VALIDATION',
  DUPLICATE_CRM: 'DUPLICATE_CRM',
  DUPLICATE_CAMPAIGN: 'DUPLICATE_CAMPAIGN',
  DUPLICATE_TEAM: 'DUPLICATE_TEAM',
  DUPLICATE_DOMAIN: 'DUPLICATE_DOMAIN',
  REPLIED_INDIRECTLY: 'REPLIED_INDIRECTLY',
  REPLIED_CAMPAIGN: 'REPLIED_CAMPAIGN',
  REPLIED_DOMAIN: 'REPLIED_DOMAIN',
  REPLIED_TEAM: 'REPLIED_TEAM',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
  BOUNCED: 'BOUNCED',
  GDPR_WARNING: 'GDPR_SUBJECT'
}

export const STATUS_TYPE = {
  MESSAGED: 'messaged',
  REACHED: 'reached',
  ERROR: 'error',
  REPLIED: 'replied',
  BOUNCED: 'bounced',
  BOOKED: 'booked',
  OPENED: 'opened',
  CLICKED: 'clicked',
  PAUSED: 'paused',
  UNSUBSCRIBED: 'unsubscribed',
  MANUAL: 'manual',
  MANUAL_TASK: 'task',
  PENDING: 'pending'
}

export const SENTIMENT_TYPE = {
  INTERESTED: 'Interested',
  NOT_INTERESTED: 'Not Interested',
  BAD_FIT: 'Bad Fit',
  WRONG_CONTACT: 'Wrong Contact',
  TIMING: 'Bad Timing'
}

export function sentimentTypesToOptions () {
  const options = []

  // eslint-disable-next-line array-callback-return
  Object.keys(SENTIMENT_TYPE).map((key) => {
    options.push({
      value: key,
      label: SENTIMENT_TYPE[key]
    })
  })

  return Immutable.List(options)
};

export const REPLY_TYPE = {
  EMAIL: 'Email',
  LINKEDIN: 'LinkedIn',
  CALL: 'Call',
  SMS: 'Text Message'
}

export function replyTypesToOptions () {
  const options = []

  // eslint-disable-next-line array-callback-return
  Object.keys(REPLY_TYPE).map((key) => {
    options.push({
      value: key,
      label: REPLY_TYPE[key]
    })
  })

  return Immutable.List(options)
}

export const DELAY_TYPE = {
  EMAIL_ERROR: 'EMAIL_ERROR',
  OUT_OF_OFFICE_EMAIL: 'OUT_OF_OFFICE_EMAIL',
  OUT_OF_OFFICE_DATE: 'OUT_OF_OFFICE_DATE',
  MANUAL: 'MANUAL',
  DOMAIN_BOUNCES: 'DOMAIN_BOUNCES',
  RESUBSCRIBED: 'RESUBSCRIBED'
}

export const TOP_LEVEL_FIELD_OPTIONS = [
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'name',
    label: 'Name'
  },
  {
    value: 'first_name',
    label: 'First Name'
  },
  {
    value: 'last_name',
    label: 'Last Name'
  },
  {
    value: 'company',
    label: 'Company Name'
  },
  {
    value: 'title',
    label: 'Title'
  },
  {
    value: 'phone_number',
    label: 'Phone Number'
  },
  {
    value: 'url',
    label: 'Website'
  },
  {
    value: 'profile_url',
    label: 'Profile URL'
  },
  {
    value: 'location',
    label: 'Location'
  },
  {
    value: 'college',
    label: 'Education - College'
  },
  {
    value: 'degree',
    label: 'Education - Degree'
  }
]

export const CSV_IMPORT_FIELD_OPTIONS = [
  {
    value: 'location_city',
    label: 'Location - City'
  },
  {
    value: 'location_state',
    label: 'Location - State'
  },
  {
    value: 'location_country',
    label: 'Location - Country'
  }
]

export const DAYS = [
  {
    label: 'Sunday',
    startingHour: 0,
    index: 0
  },
  {
    label: 'Monday',
    startingHour: 24,
    index: 1
  },
  {
    label: 'Tuesday',
    startingHour: 48,
    index: 2
  },
  {
    label: 'Wednesday',
    startingHour: 72,
    index: 3
  },
  {
    label: 'Thursday',
    startingHour: 96,
    index: 4
  },
  {
    label: 'Friday',
    startingHour: 120,
    index: 5
  },
  {
    label: 'Saturday',
    startingHour: 144,
    index: 6
  }
]

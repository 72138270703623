import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../themes/light'

const ListItem = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: block;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  &:hover {
    background: ${theme.highlightBackground};
  }
`

const MenuLinkWrapper = styled.a`
  padding: .5rem;
  width: 100%;
  height: 100%;

  color: ${props => props.color ? props.color : theme.menuItemColor};
  font: ${theme.fonts.normal};
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  line-height: 16px;
`

const MenuLink = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 0;
  margin: 0;
`

const MenuItem = (props) => {
  return (
    <ListItem>
      <MenuLinkWrapper onClick={props.onClick}>
        <MenuLink>
          {props.children}
        </MenuLink>
      </MenuLinkWrapper>
    </ListItem>
  )
}

MenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any
}

export default MenuItem

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  position: relative;
  background-color: ${props =>
    props?.selectedStep?.get('manual') === 'task'
    ? theme.colors.lightYellow
    : theme.colors.white};
  overflow: hidden;
  text-align: left;
`

function SignaturePreview ({
  selectedStep,
  signature
}) {
  const signatureRef = useRef()

  const handleSignatureResize = () => {
    const height = signatureRef?.current?.contentWindow?.document?.body?.scrollHeight
      ? signatureRef.current.contentWindow.document.body.scrollHeight + 50
      : 150
    signatureRef.current.style.height = height + 'px'
  }

  const updateSignatureLinkTargets = (html) => {
    const dom = new window.DOMParser().parseFromString(html, 'text/html')
    const linkTags = [...dom.querySelectorAll('a')]
    linkTags.forEach((link) => {
      link.setAttribute('target', '_blank')
    })

    return dom.body.innerHTML
  }

  const signatureStyle = `
    <style>
      html {
        margin: 0;
        padding: 0;
      }
      body {
        padding: 0;
        margin-top: 0;
        margin: 4px;
        font-family: Arial,sans-serif;
        line-height: 1.5;
        font-size: 15px;
      }
      p {
        margin: 0;
        padding: 0;
      }
    </style>
  `

  const signatureHtml = updateSignatureLinkTargets(signature)

  return (
    <Wrapper selectedStep={selectedStep}>
      <iframe
        srcDoc={`${signatureStyle}${signatureHtml}`}
        ref={signatureRef}
        frameBorder='0'
        width='100%'
        onLoad={e => {
          handleSignatureResize()
        }}
        style={{
          backgroundColor: selectedStep?.get('manual') === 'task' ? '#FEFBEB' : '#fff',
          opacity: 0.5,
          padding: '0 1rem 1rem 1rem',
          transform: 'translateY(-3px)'
        }}
      />

    </Wrapper>
  )
}

SignaturePreview.propTypes = {
  selectedStep: PropTypes.object,
  signature: PropTypes.string
}

export default SignaturePreview

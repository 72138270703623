/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from 'elements/Label'
import { pluralize } from 'utils/strings'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
`

const MainPrice = styled.div`
  display: flex;
  flex-direction: row;
`

const Currency = styled(Label)`
  font: ${theme.fonts.header};
  margin-top: 5px;
  margin-right: 2px;
`

const Price = styled.span`
  color: ${theme.titleColor};
  font: ${theme.fonts.price};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
`

const Period = styled(Label)`
  font: ${theme.fonts.formtitle};
  margin-bottom: 5px;
`

const PriceNote = styled(Label)`
  font: ${theme.fonts.xsmall};
`

const TeamLabel = styled.a`
  font: ${theme.fonts.xsmall};
  color: ${theme.labelColor};
  text-decoration: underline;
  cursor: pointer;
`

class PriceLabel extends Component {
  render () {
    const {
      seats,
      pricing,
      selectedPlan
    } = this.props

    const plan = pricing.get(selectedPlan)
    let couponNote

    const adjust = pricing.getIn(['coupon', 'amount_off']) || 0

    if (pricing.getIn(['coupon', 'id'])) {
      const coupon = pricing.get('coupon')
      const code = coupon.get('id')
      const percentOff = coupon.get('percent_off')
      const amountOff = coupon.get('amount_off')
      const duration = coupon.get('duration')
      const months = coupon.get('duration_in_months')
      let durationText
      if (duration === 'forever') {
        durationText = 'off forever'
      } else if (months) {
        durationText = `off for ${months} ${pluralize('month', 'months', months)}`
      }
      if (amountOff) {
        couponNote = (
          <span>
            *Coupon code applied: <strong>{code}</strong> - ${amountOff / 100} {durationText}
          </span>
        )
      } else if (percentOff) {
        couponNote = (
          <span>
            *Coupon code applied: <strong>{code}</strong> - {percentOff}% {durationText}
          </span>
        )
      }
    }

    return (
      <Wrapper>
        <MainPrice>
          <Currency>$</Currency>
          <Price>{((plan.get('amount') * seats) - adjust) / 100}</Price>
          <Period>{`/${plan.get('interval')}${couponNote ? '*' : ''}`}</Period>
        </MainPrice>
        {/* <SegmentedControl
          options={options}
          onOptionChange={(index) => {
            onPlanChange(options[index].value);
          }}
          mt="0.5rem"
          mb="0.5rem"
        /> */}
        <PriceNote mb='0.5rem'>
          for <TeamLabel
            onClick={() => {
              let route = ''
              const currentLocation = this.props.router.getCurrentLocation()
              if (currentLocation.pathname.startsWith('/paywall/')) {
                route = '/paywall'
              }

              this.props.router.push(`${route}/team`)
            }}>
            {seats} {pluralize('seat', 'seats', seats)}
          </TeamLabel>, billed monthly (includes 250 email lookup credits)
        </PriceNote>
        {couponNote &&
          <PriceNote>{couponNote}</PriceNote>}
      </Wrapper>
    )
  }
}

PriceLabel.propTypes = {
  pricing: PropTypes.object,
  selectedPlan: PropTypes.string,
  seats: PropTypes.number,
  onPlanChange: PropTypes.func,
  router: PropTypes.object
}

export default PriceLabel

import { keyframes, css } from 'styled-components'
import theme from '../themes/light'

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const fadeInRightRule = css`
  ${fadeInRight} .2s linear 1
`

const greenGlow = keyframes`
  to {
    border-color: ${theme.colors.green};
    box-shadow: ${`0 0 8px ${theme.colors.green}`}
  }
`

export const greenGlowRule = css`
  ${greenGlow} 1.5s infinite alternate;
`

const yellowGlow = keyframes`
  to {
    border-color: ${theme.colors.yellow};
    box-shadow: ${`0 0 8px ${theme.colors.yellow}`}
  }
`

export const yellowGlowRule = css`
  ${yellowGlow} 1.5s infinite alternate;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const fadeInRule = css`
  ${fadeIn} .2s linear 1;
`

const rotateCss = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const rotateRule = css`
  ${rotateCss} .8s linear infinite;
`

const circleAnimation = keyframes`
  0% { stroke-dasharray: 0 100; }
`

export const circleAnimationRule = css`
  ${circleAnimation} .300s ease-out forwards;
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Modal from 'components/Modal'
import Button from 'components/Button'
import DropDownSelectInputField from 'components/DropDownSelectInputField'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 2rem 4rem;
  width: 100%;
`

const DropdownContainer = styled.div`
  padding: 0 4rem 2rem 4rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class AssignGroupModal extends Component {
  render () {
    const {
      isOpen,
      onCancel,
      onConfirm,
      groups,
      selectedGroup,
      setGroup,
      member
    } = this.props

    const name = member && (member.get('full_name') || member.get('email'))

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper>
          <TitleContainer>
            <Title align='center'>Assign Group</Title>
            <Label mt='0.5rem' align='center'>Assign {name} to a group. Users assigned a group are restricted to seeing only their own group's sequences and contacts</Label>
          </TitleContainer>
          <DropdownContainer>
            <DropDownSelectInputField
              options={groups}
              clearable
              customCreateLabel='Create new group...'
              value={selectedGroup}
              onOptionChange={(option) => {
                const newValue = option && option.value ? option.value : null
                setGroup(newValue)
              }}
            />
          </DropdownContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label='confirm'
              onClick={onConfirm}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

AssignGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  groups: PropTypes.instanceOf(Immutable.List),
  setGroup: PropTypes.func,
  selectedGroup: PropTypes.number,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  member: PropTypes.object
}

export default AssignGroupModal

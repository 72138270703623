import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class RemoveStep extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      stepIndex,
      closeModal,
      onConfirm
    } = this.props

    return (
      <Wrapper>
        <InputContainer>
          <Title align='center'>Remove Sent Step</Title>
          <Label mt='1.5rem' mb='1rem' align='center'>
            Removing step {stepIndex + 1} will not reset contacts that have already been messaged from receiving step {stepIndex + 1} &mdash; <a href='https://help.interseller.io/article/167-removing-already-sent-steps' target='_new'>Learn more</a>
          </Label>
          <Label mt='1.5rem' align='center'>
            Are you sure you want to remove this step?
          </Label>
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={() => {
              closeModal()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label='Remove'
            onClick={() => {
              onConfirm()
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

RemoveStep.propTypes = {
  stepIndex: PropTypes.any,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func
}

export default RemoveStep

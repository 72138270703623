import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import { FIELD_BLOCKLIST, TOP_LEVEL_FIELD_VALUES } from 'containers/Sequence/constants'
import Input from 'components/Input'
import CompanyInput from 'components/CompanyInput'
import EmailInput from 'components/EmailInput'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  width: 100%;
  padding: ${showingCustomFields => (showingCustomFields) ? '0 2rem 2rem 2rem' : '0 2rem 0 2rem'};
`

const ShowCustomFields = styled.div`
  width: 100%;
  padding-top: 2rem;
`

const SelectionLabel = styled.p`
  color: ${theme.colors.lightBlue};
  cursor: pointer;
  text-align: center;
  font: ${theme.fonts.xsmall};

  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #FAFAFA;
  margin: 0;
  padding: 1.5rem;

  &:hover{
    color: ${theme.colors.darkBlue};
    text-decoration: underline;
    cursor: pointer;
  }
`

class AddContact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showCustomFields: false
    }
  }

  renderCustomFields = () => {
    const {
      raw,
      customFields,
      defaultValues = Immutable.Map({}),
      onValueChange
    } = this.props

    const filteredCustomFields = customFields.filter(
      f => !FIELD_BLOCKLIST.includes(f) &&
               !TOP_LEVEL_FIELD_VALUES.includes(f)
    )

    const mappedCustomFields = filteredCustomFields.map((field) => {
      let value = ''

      if (defaultValues.get(field) !== undefined) {
        value = defaultValues.get(field)
      }

      return {
        field,
        value
      }
    })

    if (filteredCustomFields &&
          filteredCustomFields._tail &&
          filteredCustomFields._tail.array &&
          filteredCustomFields._tail.array.length > 0) {
      if (!this.state.showCustomFields) {
        return (
          <ShowCustomFields>
            <SelectionLabel
              onClick={() => {
                this.setState({
                  showCustomFields: true
                })
                this.renderCustomFields(customFields)
              }}
            >
              show more fields
            </SelectionLabel>
          </ShowCustomFields>
        )
      } else {
        return mappedCustomFields.map(
          ({ field, value }) => {
            const fieldLabel = field.split('_').join(' ')
            return (
              <Input
                label={fieldLabel}
                inputId={field}
                type='text'
                mt='1.5rem'
                key={field}
                placeholder={value}
                onValueChange={(value) => {
                  const fieldObj = { [field]: value }
                  onValueChange({
                    raw: { ...raw, ...fieldObj }
                  })
                }}
              />
            )
          }
        )
      }
    }
  }

  render () {
    const {
      onValueChange,
      name,
      email,
      company
    } = this.props

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer
          showingCustomFields={!this.state.showCustomFields}
        >
          <Input
            label='Name'
            value={name}
            inputId='name'
            onValueChange={(value) => {
              onValueChange({
                name: value
              })
            }}
            mt='1.5rem'
          />
          <CompanyInput
            label='Company'
            value={company}
            inputId='website'
            onValueChange={(value) => {
              onValueChange({
                company: value
              })
            }}
            onCompanySelect={(c) => {
              onValueChange({
                website: c.domain,
                company: c.name
              })
            }}
            domainSelection={false}
            mt='1.5rem'
          />
          <EmailInput
            label='Email'
            value={email}
            inputId='email'
            onValueChange={(value) => {
              onValueChange({
                email: value
              })
            }}
            mt='1.5rem'
          />

          <Input
            label='Website'
            inputId='website'
            type='text'
            mt='1.5rem'
            onValueChange={(value) => {
              onValueChange({
                url: value
              })
            }}
          />

          <Input
            label='Location'
            inputId='location'
            type='text'
            mt='1.5rem'
            onValueChange={(value) => {
              onValueChange({
                location: value
              })
            }}
          />

          <Input
            label='Title'
            inputId='title'
            type='text'
            mt='1.5rem'
            onValueChange={(value) => {
              onValueChange({
                title: value
              })
            }}
          />

          <Input
            label='Phone Number'
            inputId='phone_number'
            type='tel'
            mt='1.5rem'
            onValueChange={(value) => {
              onValueChange({
                phone_number: value
              })
            }}
          />

          {this.renderCustomFields()}

        </InputContainer>
      </Wrapper>
    )
  }
}

AddContact.propTypes = {
  onValueChange: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  customFields: PropTypes.object,
  defaultValues: PropTypes.instanceOf(Immutable.Map),
  raw: PropTypes.object
}

export default AddContact

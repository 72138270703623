import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  borders,
  color,
  borderColor,
  boxShadow,
  borderRadius,
  space,
  width
} from 'styled-system'
import theme from '../../themes/light'

const Wrapper = styled.div`
  overflow-y: scroll;
  max-height: ${props => props.maxHeight};
  border-radius: ${theme['popover.border.radius']};
  border-width: ${theme['popover.border.width']};
  border-style: ${theme['popover.border.style']};
  border-color: ${theme['popover.border.color']};
  box-shadow: ${theme['popover.shadow']};
  margin-top: 0.5rem;
  ${color}
  ${borders}
  ${borderColor}
  ${borderRadius}
  ${boxShadow}
  ${space}
  ${width}
`

class PositionerWrapper extends PureComponent {
  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.update()
    }
  }

  render () {
    return (
      <Wrapper {...this.props}>
        {this.props.children}
      </Wrapper>
    )
  }
}

PositionerWrapper.propTypes = {
  isOpen: PropTypes.bool,
  update: PropTypes.func,
  children: PropTypes.any
}

export default PositionerWrapper

import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import R from 'utils/ramda'
import TimeRangePicker from 'components/TimeRangePicker'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import DaySelector from './DaySelector'
import theme from '../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const ClearLink = styled(Label)`
  color: ${theme.colors.blue};
  cursor: pointer;
  text-decoration: underline;
`

class EditSchedule extends PureComponent {
  constructor (props) {
    super(props)
    const {
      day,
      schedule
    } = props
    const hours = this.filteredHours(schedule, day)
    const ranges = this.hoursToRanges(hours)
    this.state = {
      hours,
      ranges,
      selectedDays: [day.index]
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      day,
      schedule
    } = nextProps
    const hours = this.filteredHours(schedule, day)
    const ranges = this.hoursToRanges(hours)
    this.setState({
      hours,
      ranges,
      selectedDays: [day.index]
    })
  }

  filteredHours = (schedule, day) => {
    if (day && schedule) {
      const dayIndex = day.index
      return schedule.filter(hour => (
        hour >= dayIndex * 24 && hour < (dayIndex + 1) * 24
      )).sort()
    }
    return Immutable.List([])
  }

  hoursToRanges = (hours) => {
    let currentRange = 0
    let lastHour = 0
    return hours.reduce((acc, h) => {
      const hour = h % 24
      let current = acc[currentRange]
      if (!current) {
        current = {
          startTime: hour,
          endTime: hour + 1
        }
      } else if (lastHour + 1 !== hour) {
        current.endTime = lastHour + 1
        acc[currentRange] = current
        current = {
          startTime: hour,
          endTime: hour + 1
        }
        currentRange += 1
      } else {
        current.endTime = hour + 1
      }
      lastHour = hour
      acc[currentRange] = current
      return acc
    }, [])
  }

  rangesToHours = (ranges, dayIndex) => {
    let hours = []
    const hourOffset = dayIndex * 24
    ranges.forEach((r) => {
      hours = hours.concat(R.range(r.startTime, r.endTime).map(h => (h + hourOffset)))
    })
    return hours
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13) {
      e.preventDefault()
      return this.props.onEditSchedule(this.state)
    }
    return false
  }

  render () {
    const {
      day,
      schedule
    } = this.props
    const {
      ranges,
      selectedDays
    } = this.state

    if (this.props.loading || !day || !ranges) {
      return <Loading />
    }

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer>
          <Title align='center'>Editing Schedule</Title>
          <Label mt='0.5rem' mb='1rem' align='center'>
            Select the time ranges to send messages in your sequence
          </Label>
          <TimeRangePicker
            blocks={ranges}
            timeupdate={(blocks) => {
              if (!R.equals(blocks, this.state.ranges)) {
                this.setState({
                  ranges: blocks
                })
              }
            }}
          />
          {ranges.length > 0 &&
            <ClearLink
              height='2rem'
              mt='0.5rem'
              mb='0.5rem'
              align='center'
              onClick={() => {
                this.setState({
                  ranges: []
                })
              }}
            >
              Clear time range
            </ClearLink>}
          {ranges.length <= 0 &&
            <Label height='2rem' mt='0.5rem' mb='0.5rem' align='center'>
              Click the range above to create a new time block
            </Label>}

          <Divider mt='2rem' mb='2rem' />

          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Save time range for additional days
          </Label>
          <DaySelector
            selectedDays={selectedDays}
            onChange={(days) => {
              this.setState({
                selectedDays: days
              })
            }}
          />
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={() => {
              this.setState({})
              this.props.closeModal()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label='save'
            onClick={() => {
              const hours = selectedDays.reduce((acc, i) => (
                acc.filter(hour => (
                  hour < i * 24 || hour > ((i + 1) * 24) - 1
                )).concat(this.rangesToHours(this.state.ranges, i)).sort()
              ), schedule)
              this.props.onEditSchedule(hours)
              this.setState({})
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

EditSchedule.propTypes = {
  schedule: PropTypes.object,
  day: PropTypes.object,
  loading: PropTypes.bool,
  onEditSchedule: PropTypes.func,
  closeModal: PropTypes.func
}

EditSchedule.defaultProps = {
  loading: false
}

export default EditSchedule

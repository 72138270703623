import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TOP_LEVEL_FIELD_VALUES } from 'containers/Sequence/constants'
import { MenuHeader, MenuItem } from 'elements/Menu'
import { Curtain } from '../ToolbarModal'
import theme from '../../../../../themes/light'

const Wrapper = styled.div``

const HorizontalDivider = styled.div`
  padding: 0.5rem 0rem;
`

const DropDownMenu = styled.div`
  z-index: 20;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  overflow-y: auto;
  width: 200px;
  max-height: 250px;
  text-align: left;
  margin-top: 1rem;
  padding: 0.5rem;
  box-shadow: ${theme.shadow};
`

const CustomField = styled(MenuItem)`
  white-space: pre-wrap;
  line-height: 20px;
  padding: 0.3rem 0.5rem;
  z-index: 20;
  cursor: pointer;
`

const ListHeader = styled(MenuHeader)``

class CustomFieldMenu extends React.Component {
  constructor (props) {
    super(props)
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showCustomFieldMenu')
    }
  }

  renderContactFields = () => {
    const { editorRef } = this.props
    return TOP_LEVEL_FIELD_VALUES.map((field, index) => {
      return (
        <CustomField
          key={`tlf-${index}`}
          onClick={() => {
            const contactVariable = `{{ ${field} }}`
            editorRef.current.insertCustomField(contactVariable)
          }}
        >
          {field.split('_').join(' ')}
        </CustomField>
      )
    })
  }

  renderCustomFields = () => {
    const { customFields, editorRef } = this.props
    return customFields.map((field, index) => {
      return (
        <CustomField
          key={`cf-${index}`}
          onClick={() => {
            const customField = field.variableName
            editorRef.current.insertCustomField(customField)
          }}
        >
          {field.searchName}
        </CustomField>
      )
    })
  }

  renderCalendarFields = () => {
    const dateFields = [
      { labelName: 'Today', varValue: '{{ dayofweek }}' },
      { labelName: 'Tomorrow', varValue: '{{ tomorrow }}' },
      { labelName: 'Next Business Day', varValue: '{{ nextbusinessday }}' },
      { labelName: 'Two Business Days', varValue: '{{ twobusinessdays }}' }
    ]
    const { editorRef } = this.props
    return dateFields.map((field, index) => {
      return (
        <CustomField
          key={`df-${index}`}
          onClick={() => {
            const calendarVar = field.varValue
            editorRef.current.insertCustomField(calendarVar)
          }}
        >
          {field.labelName}
        </CustomField>
      )
    })
  }

  render () {
    const {
      customFields,
      toggleToolbarMenuVisibility
    } = this.props

    const hasCustomFields = customFields.length > 0

    return (
      <Wrapper>
        <Curtain
          onClick={() => toggleToolbarMenuVisibility('showCustomFieldMenu')}
        />

        <DropDownMenu>
          <ListHeader>Contact Fields</ListHeader>
          {this.renderContactFields()}

          {hasCustomFields &&
            <>
              <HorizontalDivider />
              <ListHeader>Custom Fields</ListHeader>
              {this.renderCustomFields()}
            </>}

          <>
            <HorizontalDivider />
            <ListHeader>Day of the Week Variables</ListHeader>
            {this.renderCalendarFields()}
          </>

        </DropDownMenu>
      </Wrapper>
    )
  }
}

CustomFieldMenu.propTypes = {
  customFields: PropTypes.array,
  toggleToolbarMenuVisibility: PropTypes.func,
  editorRef: PropTypes.object
}

export default CustomFieldMenu

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, height } from 'styled-system'
import Loading from 'components/Loading'
import SlateEditor from 'components/SlateEditor'
import Modal from 'components/Modal'
import Templates from 'components/Templates'
import ConfirmModal from 'components/ConfirmModal'
import ContactSequenceDropdown from '../ContactSequenceDropdown'
import EditContactSidebar from '../EditContactSidebar'
import TaskList from '../TaskList'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: start;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 360px);
  flex-direction: column;
  align-items: start;
  justify-content: start;

  ${space}
  ${height}
`

const EditorContainer = styled.div`
  width: 100%;
  flex: 1;
`

const EditorWrapper = styled.div`
  border: 1px solid ${theme.borderColor};
`

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptySection = styled.div`
  height: 100%;
  width: 100%;
  background: ${theme.containerBackground};
  border: 1px solid ${theme.borderColor};
  padding: 5rem;
`

class EmailTasks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showTemplateModal: false,
      newThread: false,
      activeTask: null,
      activeTaskIndex: 0,
      selectedMemberId: null,
      selectedSequenceId: null,
      showDeleteConfirmModal: false,
      actionTask: null,
      showEditContact: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    actions.fetchTeamMembers()
    actions.fetchSequences()
    actions.fetchTasks()

    document.title = 'Interseller | Email Tasks'
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const { contactMessages, tasks } = newProps
    const { activeTask, activeTaskIndex } = this.state

    if (contactMessages !== this.props.contactMessages && activeTask) {
      const contactMessage = contactMessages
        .get('data')
        .find(message => message.get('step') === activeTask.get('next_step'))

      if (contactMessage) {
        this.setState({
          newThread: !!contactMessage.get('subject'),
          contactMessage
        })
      }
    }

    if ((!activeTask || tasks !== this.props.tasks) && tasks.get('data').count() > 0) {
      if (activeTaskIndex !== null && activeTaskIndex < tasks.get('data').count() - 1) {
        this.onTaskSelected(tasks.getIn(['data', activeTaskIndex]), activeTaskIndex)
      } else {
        this.onTaskSelected(tasks.getIn(['data', 0]), 0)
      }
    }

    if (tasks.get('data').count() === 0 && activeTask) {
      this.setState({ activeTask: null, contactMessage: null })
    }

    if (!this.state.selectedMemberId && !this.state.selectedSequenceId && newProps.session) {
      this.setState({
        selectedMemberId: newProps.session.get('id')
      })
    }
  }

  onTaskSelected = (task, index) => {
    const { actions } = this.props
    const contactId = task.get('id')
    actions.fetchContactMessages(contactId)

    this.setState({
      activeTask: task,
      activeTaskIndex: index,
      showEditContact: false
    })
  }

  handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'd') {
      e.stopPropagation()
      e.preventDefault()
      this.deleteContact(this.state.activeTask)
      return false
    } else if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      this.saveContactMessage()
      return false
    }
  }

  saveContactMessage = () => {
    const { actions } = this.props
    const { activeTask, newThread } = this.state

    const subject = newThread
      ? this.editor.getMarkdown(SLATE_EDITORS.SUBJECT)
      : null
    const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

    actions.updateContactMessage(
      activeTask.get('id'),
      activeTask.get('next_step'),
      subject,
      markdown
    )
  }

  deleteContact = (task) => {
    this.setState({
      showDeleteConfirmModal: true,
      actionTask: task
    })
  }

  editContact = () => {
    this.setState({
      showEditContact: true
    })
  }

  render () {
    const {
      actions,
      templates,
      tasks,
      contactMessages,
      contactCompany,
      selectedContact,
      session,
      members,
      sequences
    } = this.props
    const {
      activeTask,
      showTemplateModal,
      newThread,
      contactMessage,
      showDeleteConfirmModal,
      actionTask,
      selectedMemberId,
      selectedSequenceId
    } = this.state

    const loading = contactMessages.get('loading') || tasks.get('loading')

    const contactSequence = sequences
      .find(s => s.get('_id') === selectedContact.getIn(['data', '_campaign']))

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <FlexWrapper>
          <Container>
            <ContactSequenceDropdown
              session={session}
              members={members}
              sequences={sequences}
              actions={actions}
              selectedMemberId={selectedMemberId}
              selectedSequenceId={selectedSequenceId}
              onOptionChange={(option) => {
                const value = option ? JSON.parse(option.value) : {}

                this.setState({
                  activeTask: null,
                  contactMessage: null,
                  selectedMemberId: value.userId,
                  selectedSequenceId: value.sequenceId
                })
                actions.fetchContactMessagesReset()
                actions.fetchTasks(value.userId, value.sequenceId)
              }}
            />
            <TaskList
              actions={actions}
              tasks={tasks}
              taskType='Email'
              handleTaskSelected={this.onTaskSelected}
              activeTask={activeTask}
              contactCompany={contactCompany}
              selectedContact={selectedContact}
              deleteContact={this.deleteContact}
              editContact={this.editContact}
            />
          </Container>

          {loading &&
            <Container pl='1rem' height='100%'>
              <EmptyWrapper>
                <Loading />
              </EmptyWrapper>
            </Container>}

          {!loading &&
            <Container pl='1rem' height='100%'>
              {!contactMessage && <EmptySection />}

              {contactMessage &&
                <EditorContainer>
                  <EditorWrapper>
                    <SlateEditor
                      key={contactMessage.get('id')}
                      ref={(element) => { this.editor = element }}
                      onSave={this.saveContactMessage}
                      isBrandedDomain={session.get('branded_domain_enabled')}
                      onShowTemplates={() => {
                        actions.fetchTemplates()
                        this.setState({
                          showTemplateModal: true
                        })
                      }}
                      subject={contactMessage.get('subject') || contactMessage.get('previous_subject')}
                      body={contactMessage.get('markdown')}
                      newThread={newThread}
                      subjectDisabled={!newThread}
                      hideEditorCoach
                      hideVariables
                      mt={0}
                      saveActions={[
                        {
                          title: 'Pause Task/Contact',
                          fn: () => {
                            return actions.setPauseContactTask(activeTask)
                          }
                        }
                      ]}
                    />
                  </EditorWrapper>
                </EditorContainer>}
            </Container>}
          <Modal
            isOpen={showTemplateModal}
            width='700px'
            onModalClose={() => {
              this.setState({
                showTemplateModal: false
              })
            }}
          >
            <Templates
              templates={templates}
              onTemplateSelected={(template) => {
                if (template) {
                  const markdown = template.get('markdown')
                  const subject = template.get('subject')
                  let updatedMessage = contactMessage.set('markdown', markdown)
                  if (subject) {
                    updatedMessage = updatedMessage.set('subject', subject)
                  }
                  this.setState({
                    contactMessage: updatedMessage,
                    showTemplateModal: false
                  })
                }
              }}
              onDeleteTemplate={(template) => {
                actions.deleteTemplate(template)
              }}
            />
          </Modal>
          <ConfirmModal
            isOpen={showDeleteConfirmModal}
            onCancel={() => {
              this.setState({
                showDeleteConfirmModal: null,
                actionTask: null
              })
            }}
            onConfirm={() => {
              actions.removeContact(actionTask.get('id'))
              this.setState({
                showDeleteConfirmModal: null,
                actionTask: null
              })
            }}
            title='Are you sure?'
            description={
              `Are you sure you want to remove ${actionTask ? actionTask.get('name') : ''} from your ${actionTask ? actionTask.getIn(['_campaign', 'title']) : ''} sequence?`
            }
          />
        </FlexWrapper>
        {this.state.showEditContact &&
          <EditContactSidebar
            contact={selectedContact.get('data')}
            sequence={contactSequence}
            loading={selectedContact.get('loading')}
            router={this.props.router}
            onClose={() => {
              this.setState({
                showEditContact: false
              })
            }}
            onRemove={() => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.removeContact(contactId)
            }}
            onSave={(params) => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.updateSequenceContact(contactId, params)
              this.setState({
                showEditContact: false
              })
            }}
          />}
      </Wrapper>
    )
  }
}

EmailTasks.propTypes = {
  tasks: PropTypes.object,
  contactMessages: PropTypes.object,
  contactCompany: PropTypes.object,
  actions: PropTypes.object,
  templates: PropTypes.object,
  selectedContact: PropTypes.object,
  session: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  router: PropTypes.object
}

export default EmailTasks

/**
*
* FolderRow
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { pluralize } from 'utils/strings'
import PercentageCircle from 'elements/PercentageCircle'
import Checkbox from 'elements/Checkbox'
import { FolderIcon } from 'svg'
import {
  Wrapper,
  SequenceColumn,
  SequenceTitle,
  SequenceLabel,
  CheckboxColumn,
  ContactErrorsBadge,
  ContactTasksBadge,
  StatColumn,
  ContactNumberColumn,
  IconColumn,
  AvatarPhotoColumn,
  BadgeColumn,
  UserColumn
} from '../Rows'
import theme from '../../../../../themes/light'

const FolderAvatar = styled(Avatar)`
  margin-left: -24px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    z-index: 100;
  }
`

class FolderRow extends Component {
  render () {
    const {
      stats,
      folderName,
      sequences,
      onClick,
      selected,
      onSelected
    } = this.props

    const users = Immutable.Set(sequences.map((s) => {
      return s.get('_user')
    })).toList().sort((a, b) => {
      return a.get('full_name') > b.get('full_name') ? 1 : -1
    })

    const avatars = users.slice(0, 3).map((user) => {
      return (
        <FolderAvatar
          key={`folder_avatar_${user.get('id')}`}
          src={user.get('photo_url') || '/bear_avatar.png'}
          name={user.get('full_name')}
          maxInitials={2}
          size={38}
          textSizeRatio={2}
          round
          colors={theme.avatarColors}
        />
      )
    })

    const names = users.toList().slice(0, 3).map((user) => {
      if (user.get('full_name')) {
        return user.get('full_name').split(' ')[0]
      }

      return user.get('email').split('@')[0]
    })

    const remaining = users.count() - 3
    let owners
    if (remaining > 0) {
      owners = `and ${remaining} ${pluralize('other', 'others', remaining)}`
    }

    const sequenceCount = sequences.count()
    const activeCount = sequences
      .filter((s) => {
        return s.get('active')
      })
      .count()

    let folderCount = `${sequenceCount} ${pluralize('sequence', 'sequences', sequenceCount)}`
    if (activeCount > 0) {
      folderCount = `${activeCount} active ${pluralize('sequence', 'sequences', activeCount)}, ${sequenceCount} total`
    }

    // calculate stats for the folder
    const contactsCount = stats.get('contacts_count')
    const messagedContactsCount = stats.get('messaged_contacts_count')
    const viewedContactsCount = stats.get('viewed_contacts_count')
    const repliedContactsCount = stats.get('replied_contacts_count')
    const erroredContactsCount = stats.get('errored_contacts_count')

    let openRate
    let replyRate
    if (contactsCount > 0) {
      if (viewedContactsCount) {
        openRate = Math.round((viewedContactsCount / messagedContactsCount) * 100)
      }
      if (repliedContactsCount) {
        replyRate = Math.round((repliedContactsCount / messagedContactsCount) * 100)
      }
    }

    const tasksCount = stats.get('manual')

    return (
      <Wrapper selected={selected} onClick={onClick}>
        <CheckboxColumn>
          <Checkbox
            selected={selected}
            handleClick={onSelected}
          />
        </CheckboxColumn>
        <IconColumn>
          <FolderIcon />
        </IconColumn>
        <SequenceColumn>
          <SequenceTitle>{folderName}</SequenceTitle>
          <SequenceLabel>{folderCount}</SequenceLabel>
        </SequenceColumn>
        <AvatarPhotoColumn>{avatars}</AvatarPhotoColumn>
        <UserColumn>
          {names.join(', ')} {owners}
        </UserColumn>
        <BadgeColumn />
        <ContactNumberColumn>
          {!stats.get('loading') && <NumberFormat value={contactsCount} displayType='text' thousandSeparator=',' />}
          {erroredContactsCount > 0 &&
            <ContactErrorsBadge
              color='#fff'
              tooltip={`${erroredContactsCount} ${pluralize('contact needs', 'contacts need', erroredContactsCount)} attention`}
            >
              {erroredContactsCount}
            </ContactErrorsBadge>}
          {tasksCount > 0 &&
            <ContactTasksBadge
              color='#FFF'
              tooltip={`${tasksCount} ${pluralize('task', 'tasks', tasksCount)} pending`}
            >
              {tasksCount}
            </ContactTasksBadge>}
        </ContactNumberColumn>
        <StatColumn>
          {openRate &&
            <PercentageCircle percent={openRate} />}
        </StatColumn>
        <StatColumn>
          {replyRate &&
            <PercentageCircle percent={replyRate} minGreen={10} minYellow={5} />}
        </StatColumn>
      </Wrapper>
    )
  }
}

FolderRow.propTypes = {
  stats: PropTypes.object,
  folderName: PropTypes.string,
  sequences: PropTypes.instanceOf(Immutable.List),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  onSelected: PropTypes.func
}

export default FolderRow

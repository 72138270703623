/**
*
* ContactFilter
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format, isValid } from 'date-fns'
import Popper from 'components/Popper'
import DateField from 'components/DateField'
import DropDown from 'components/DropDown'
import Input from 'components/Input'
import { titlecase } from 'utils/strings'
import { toUnixTimestamp } from 'utils/dates'
import { contactErrorsToOptions, contactErrorToString } from 'utils/keyMapping'
import { sentimentTypesToOptions, SENTIMENT_TYPE } from 'containers/Sequence/constants'
import theme from '../../../../../../themes/light'

const PopperWrapper = styled.div`
  .popper {
    overflow: visible;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  border: 1px solid ${theme.colors.gray40};
  font-size: 14px;
  line-height: 22px;
  border-radius: 9999px;
  padding: 6px 1rem;
  margin-right: 0.5rem;
  cursor: ${props => props.static ? 'auto' : 'pointer'};
`

const Action = styled.span`
  font-weight: 600;
`

const PopTip = styled.form`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${theme.colors.gray30};
  min-width: 16rem;
`

const Options = styled.div`
  padding: 1rem 1.25rem;
`

const Label = styled.label`
  display: block;
  font-size: 15px;
  margin-bottom: 5px;

  input {
    top: 1px;
    position: relative;
    margin-right: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const NestedValue = styled.div`
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;

  >div {
    margin-top: 0;
  }
`

const RemoveButton = styled.div`
  text-align: center;
  border-top: 1px solid ${theme.colors.gray30};
  color: ${theme.colors.red};
  padding: 0.5rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
`

const ClearfixWrapper = styled.div`
  height: 3rem;

  >* {
    position: absolute;
    width: calc(100% - 4rem);
  }

  .rdtPicker {
    left: 0;
  }
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray30};
  margin: 1.25rem 0 1rem;
`

class ContactFilter extends Component {
  constructor (props) {
    super(props)
    const filter = props.filter
    this.state = {
      filter
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const filter = nextProps.filter
    this.setState({
      filter
    })
  }

  changed (action, operator, value) {
    const {
      onUpdateFilter
    } = this.props
    if (!operator) {
      return onUpdateFilter(action)
    }

    if (operator === '!') {
      return onUpdateFilter(`!${action}`)
    }

    if (action === 'step') {
      value = parseInt(value)

      if (isNaN(value)) {
        value = ''
      } else if (value <= 0) {
        value = 0
      } else {
        value = value - 1
      }
    }

    return onUpdateFilter(`${action}${operator}${value}`)
  }

  getName (action) {
    switch (action) {
      case 'ongoing': return 'Ongoing'
      case 'visited': return 'Clicked'
      case 'viewed': return 'Opened'
      case 'last_messaged': return 'Last Messaged'
      case 'customized': return 'Personalized'
      case 'all_errors': return 'Error'
      case 'phone_number': return 'Phone Number'
      default: return titlecase(action)
    }
  }

  getPrefixType (action) {
    switch (action) {
      case 'ongoing':
      case 'paused':
      case 'customized':
        return 'Is'
      default:
        return 'Has'
    }
  }

  getValue (action, value) {
    if (action === 'step') {
      value = parseInt(value)

      if (isNaN(value)) {
        return ''
      }

      return value + 1
    }

    return value
  }

  getFilterOptions (action) {
    if (action === 'error' || action === 'all_errors') {
      return contactErrorsToOptions()
    } else if (action === 'sentiment') {
      return sentimentTypesToOptions()
    }
  }

  getFilterOptionValueName (action, value) {
    if (action === 'error' || action === 'all_errors') {
      return contactErrorToString(value)
    }

    if (action === 'step') {
      value = parseInt(value)

      if (isNaN(value)) {
        return ''
      }

      return value + 1
    }

    if (action === 'sentiment') {
      return SENTIMENT_TYPE[value]
    }

    return value
  }

  isDuplicateError (value) {

  }

  render () {
    const {
      onRemoveFilter
    } = this.props

    const {
      filter
    } = this.state

    if (!filter) {
      return (<Wrapper />)
    }

    if (filter === 'all') {
      return (<Wrapper static><strong>All Contacts</strong></Wrapper>)
    }

    let filterEq = false
    let filterHas = true
    let filterCount = false
    let filterDate = false
    let filterSubDate = false
    let action = filter
    let value = null
    let subValue = null
    let valueSign = 'equals'

    // separate action from value
    if (filter.startsWith('!')) {
      action = action.substr(1)
    } else if (filter.includes(':')) {
      action = action.split(':')
      value = action[1]
      action = action[0]
    } else if (filter.includes('>')) {
      action = action.split('>')
      value = action[1]
      action = action[0]
    } else if (filter.includes('<')) {
      action = action.split('<')
      value = action[1]
      action = action[0]
    }

    // this is a special condition that matches all_errors.DUPLICATE_ERROR>0
    if (action.includes('.')) {
      const actionSplit = action.split('.')
      subValue = value
      value = actionSplit[1]
      action = actionSplit[0]
    }

    if (action === 'opened' || action === 'visited') {
      filterCount = true
    }

    if (action === 'replied' || action === 'last_messaged' || action === 'created') {
      filterDate = true
    }

    if (action === 'last_messaged' || action === 'created') {
      filterHas = false
    }

    if (action === 'step') {
      filterHas = false
      filterEq = true
    }

    if (
      action === 'all_errors' &&
      (value === 'DUPLICATE_CAMPAIGN' || value === 'DUPLICATE_TEAM' || value === 'REPLIED_TEAM')
    ) {
      filterEq = true
      filterSubDate = true
    }

    // build display value
    const prefixType = this.getPrefixType(action)

    let prefixNegation = 'no'
    if (action.endsWith('ed') || action.endsWith('ing')) {
      prefixNegation = 'not'
    }

    let prefix = `${prefixType}`
    if (filter.startsWith('!')) {
      prefix = `${prefix} ${prefixNegation}`
    } else if (filter.includes(':')) {
      prefix = null
    } else if (filter.includes('>')) {
      prefix = null

      valueSign = 'more than'
      if (filterDate || filterSubDate) {
        valueSign = 'after'
      }
    } else if (filter.includes('<')) {
      prefix = null

      valueSign = 'less than'
      if (filterDate || filterSubDate) {
        valueSign = 'before'
      }
    }

    // get dropdown values
    const name = this.getName(action)
    let valueDisplay = this.getFilterOptionValueName(action, value)
    let subValueDisplay = subValue ? this.getFilterOptionValueName(action, subValue) : null
    const filterOptions = this.getFilterOptions(action)

    if (filterOptions) {
      filterEq = true
    }

    if (filterDate && value) {
      value = new Date(value * 1)

      if (!isValid(value)) {
        value = null
      } else {
        valueDisplay = format(value, 'MMM D, YYYY')
      }
    }

    if (filterSubDate && subValue) {
      subValue = new Date(subValue * 1)

      if (!isValid(subValue)) {
        subValue = null
      } else {
        subValueDisplay = format(subValue, 'MMM D, YYYY')
      }
    }

    // get any true value dependant on action
    value = this.getValue(action, value)
    subValue = this.getValue(action, subValue)

    return (
      <PopperWrapper>
        <Popper
          borderRadius={5}
          position='bottom-start'
          defaultOpen={this.props.isOpen}
          trigger={
            <>
              {!filterSubDate &&
                <Wrapper>
                  {prefix} <Action>{name}</Action>
                  {value && ` ${valueSign} ${valueDisplay}`}
                </Wrapper>}
              {filterSubDate &&
                <>
                  {subValueDisplay &&
                    <Wrapper>
                      {prefix} <Action>{valueDisplay}</Action>
                      {` ${valueSign} ${subValueDisplay}`}
                    </Wrapper>}
                  {!subValueDisplay &&
                    <Wrapper>
                      {prefix} <Action>{name}</Action>
                      {` equals ${valueDisplay}`}
                    </Wrapper>}
                </>}
            </>
          }
        >
          {close => (
            <PopTip>
              <Options>
                {filterHas &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={() => {
                        this.changed(action)
                      }}
                      checked={filter === `${action}`}
                    />
                    {prefixType} <Action>{name}</Action>
                  </Label>}
                {filterHas &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={() => {
                        this.changed(action, '!')
                      }}
                      checked={filter === `!${action}`}
                    />
                    {prefixType} {prefixNegation} <Action>{name}</Action>
                  </Label>}
                {filterCount &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={() => {
                        this.setState({
                          filter: `${action}>`
                        })
                      }}
                      checked={filter.includes('>')}
                    />
                    <Action>{name}</Action> more than
                  </Label>}
                {filterCount && filter.includes('>') &&
                  <NestedValue>
                    <Input
                      type='number'
                      small
                      value={value}
                      onValueChange={(value) => {
                        this.changed(action, '>', value)
                      }}
                    />
                  </NestedValue>}
                {filterCount &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={() => {
                        this.setState({
                          filter: `${action}<`
                        })
                      }}
                      checked={filter.includes('<')}
                    />
                    <Action>{name}</Action> less than
                  </Label>}
                {filterCount && filter.includes('<') &&
                  <NestedValue>
                    <Input
                      type='number'
                      small
                      value={value}
                      onValueChange={(value) => {
                        this.changed(action, '<', value)
                      }}
                    />
                  </NestedValue>}
                {filterEq &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={() => {
                        this.setState({
                          filter: `${action}:`
                        })
                      }}
                      checked={filter.includes(':') || filterSubDate}
                    />
                    <Action>{name}</Action> equals
                  </Label>}
                {filterEq && filterOptions && (filter.includes(':') || filterSubDate) &&
                  <NestedValue>
                    <ClearfixWrapper>
                      <DropDown
                        height='3rem'
                        width='12rem'
                        clearable={false}
                        options={filterOptions}
                        value={value}
                        onOptionChange={(option) => {
                          this.changed(action, ':', option.value)
                        }}
                      />
                    </ClearfixWrapper>
                  </NestedValue>}
                {filterEq && !filterOptions && filter.includes(':') &&
                  <NestedValue>
                    <Input
                      type='text'
                      small
                      value={value}
                      onValueChange={(value) => {
                        this.changed(action, ':', value)
                      }}
                    />
                  </NestedValue>}
                {filterDate &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={(e) => {
                        this.setState({
                          filter: `${action}>`
                        })
                      }}
                      checked={filter.includes('>')}
                    />
                    <Action>{name}</Action> after
                  </Label>}
                {filterDate && filter.includes('>') &&
                  <NestedValue>
                    <ClearfixWrapper>
                      <DateField
                        mt={0}
                        dateFormat='MMM D, YYYY'
                        timeFormat={false}
                        small
                        value={value}
                        onValueChange={(value) => {
                          this.changed(action, '>', toUnixTimestamp(value))
                        }}
                      />
                    </ClearfixWrapper>
                  </NestedValue>}
                {filterDate &&
                  <Label>
                    <input
                      type='radio'
                      name='action'
                      onChange={(e) => {
                        this.setState({
                          filter: `${action}<`
                        })
                      }}
                      checked={filter.includes('<')}
                    />
                    <Action>{name}</Action> before
                  </Label>}
                {filterDate && filter.includes('<') &&
                  <NestedValue>
                    <ClearfixWrapper>
                      <DateField
                        mt={0}
                        dateFormat='MMM D, YYYY'
                        timeFormat={false}
                        small
                        value={value}
                        onValueChange={(value) => {
                          this.changed(action, '<', toUnixTimestamp(value))
                        }}
                      />
                    </ClearfixWrapper>
                  </NestedValue>}
                {filterSubDate && <Divider />}
                {filterSubDate &&
                  <Label>
                    <input
                      type='radio'
                      name='subaction'
                      onChange={(e) => {
                        this.setState({
                          filter: `${action}:${value}`
                        })
                      }}
                      checked={filter.includes(':')}
                    />
                    All <Action>{valueDisplay}</Action>
                  </Label>}
                {filterSubDate &&
                  <Label>
                    <input
                      type='radio'
                      name='subaction'
                      onChange={(e) => {
                        this.setState({
                          filter: `${action}.${value}>`
                        })
                      }}
                      checked={filter.includes('>')}
                    />
                    <Action>{valueDisplay}</Action> after
                  </Label>}
                {filterSubDate && filter.includes('>') &&
                  <NestedValue>
                    <ClearfixWrapper>
                      <DateField
                        mt={0}
                        dateFormat='MMM D, YYYY'
                        timeFormat={false}
                        small
                        value={subValue}
                        onValueChange={(subValue) => {
                          this.changed(`${action}.${value}`, '>', toUnixTimestamp(subValue))
                        }}
                      />
                    </ClearfixWrapper>
                  </NestedValue>}
                {filterSubDate &&
                  <Label>
                    <input
                      type='radio'
                      name='subaction'
                      onChange={(e) => {
                        this.setState({
                          filter: `${action}.${value}<`
                        })
                      }}
                      checked={filter.includes('<')}
                    />
                    <Action>{valueDisplay}</Action> before
                  </Label>}
                {filterSubDate && filter.includes('<') &&
                  <NestedValue>
                    <ClearfixWrapper>
                      <DateField
                        mt={0}
                        dateFormat='MMM D, YYYY'
                        timeFormat={false}
                        small
                        value={subValue}
                        onValueChange={(subValue) => {
                          this.changed(`${action}.${value}`, '<', toUnixTimestamp(subValue))
                        }}
                      />
                    </ClearfixWrapper>
                  </NestedValue>}
              </Options>
              <RemoveButton
                onClick={onRemoveFilter}
              >Remove
              </RemoveButton>
            </PopTip>
          )}
        </Popper>
      </PopperWrapper>
    )
  }
}

ContactFilter.propTypes = {
  filter: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  onUpdateFilter: PropTypes.func,
  isOpen: PropTypes.bool
}

export default ContactFilter

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import Loading from 'components/Loading'
import { space, width, textAlign, fontSize } from 'styled-system'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
`

const Container = styled.div`

`

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${space}
  ${width}
`

const Label = styled.div`
  color: ${props => props.highlight ? theme.colors.blue : theme.labelColor};
  font: ${theme.fonts.button};
  text-align: center;
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: .05em;
  overflow: hidden;
  text-overflow: ellipsis;

  ${fontSize}
`

const ValueContainer = styled.div`
  ${space}
`

const Value = styled.div`
  font: ${theme.fonts.number};
  color: ${theme.titleColor};
  text-align: center;
  width: 100%;

  ${space}
  ${width}
  ${textAlign}
`

const TooltipWrapper = styled(Tooltip)`
  width: 100%
`

// fix for react tippy error
const WrappedContainer = function (props) {
  return (
    <Wrapper onClick={props.onClick}>
      <Container>
        <NumberContainer>
          <Label highlight={props.highlight}>
            {props.title}
          </Label>
          <ValueContainer>
            <Value>
              {props.value || 0}
            </Value>
          </ValueContainer>
        </NumberContainer>
      </Container>
    </Wrapper>
  )
}

WrappedContainer.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  highlight: PropTypes.bool
}

class NumberBox extends PureComponent {
  render () {
    const {
      title,
      loading = false,
      value,
      tooltip,
      highlight = false,
      onClick
    } = this.props

    if (loading) {
      return (
        <Wrapper>
          <Loading flex='1' padding='0' size={30} />
        </Wrapper>
      )
    }

    if (tooltip) {
      return (
        <TooltipWrapper
          title={tooltip}
          position='top'
          trigger='mouseenter'
          arrow
        >
          <WrappedContainer
            onClick={onClick}
            title={title}
            value={value}
            highlight={highlight}
          />
        </TooltipWrapper>
      )
    } else {
      return (
        <WrappedContainer
          onClick={onClick}
          title={title}
          value={value}
          highlight={highlight}
        />
      )
    }
  }
}

NumberBox.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.number,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  highlight: PropTypes.bool
}

export default NumberBox

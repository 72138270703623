import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ActivityItem from '../../../../Sequence/components/Contacts/ContactDetail/ContactActivity/ActivityItem'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.white};
`

const ActivityList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  list-style-type: none;
  line-height: 1.4em;
  margin: 0;
  padding-left: calc(.5rem + 2px);
  padding-top: calc(.5rem + 2px);

  >:last-child div {
    border-left: none!important;
  }
`

class ContactActivity extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  activityRow = () => {
    const { contactActivity, router } = this.props

    return contactActivity.map((activity, i) => {
      return (
        <ActivityItem
          activity={activity}
          key={i}
          isLast={i === contactActivity.count() - 1}
          onClick={() => {
            const step = activity.getIn(['_message', 'step'])
            if (step !== undefined && step !== null) {
              const sequence = activity.get('_campaign')
              const contactId = activity.get('_contact')
              router.push(`/sequence/${sequence}/preview?contactId=${contactId}&step=${step}&customize=true`)
            }
          }}
        />
      )
    })
  }

  render () {
    return (
      <Wrapper>
        <ActivityList>
          {this.activityRow()}
        </ActivityList>
      </Wrapper>
    )
  }
}

ContactActivity.propTypes = {
  contactActivity: PropTypes.object,
  router: PropTypes.object
}

export default ContactActivity

/**
*
* SaveTemplate Components
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Input from 'components/Input'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem 3rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

class SaveTemplate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      shared: false
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.state.name && this.state.name.length > 0) {
      e.preventDefault()
      return this.props.onSaveTemplate(this.state)
    }
    return false
  }

  render () {
    const {
      closeModal,
      onSaveTemplate
    } = this.props
    const {
      name,
      shared
    } = this.state

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer>
          <Title align='center'>Save Template</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Save your template to use in other sequences
          </Label>
          <Input
            label='Name of the template'
            inputId='name'
            value={name}
            disabledDays={{ before: new Date() }}
            onValueChange={(value) => {
              this.setState({
                name: value
              })
            }}
            mt='1.5rem'
          />
          <SwitchFieldForm
            label='Share with team'
            description='Share this template with your entire team and allow them to use it in their own sequences'
            inputId='shared'
            value={shared}
            onSave={(value) => {
              this.setState({
                shared: value
              })
            }}
            mt='1.5rem'
          />
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={() => {
              this.setState({})
              closeModal()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label='save'
            disabled={name.length < 1}
            onClick={() => {
              onSaveTemplate(this.state)
              this.setState({})
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

SaveTemplate.propTypes = {
  onSaveTemplate: PropTypes.func,
  closeModal: PropTypes.func
}

export default SaveTemplate

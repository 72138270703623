import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from 'elements/Title'
import Label from 'elements/Label'
import EmptySequences from 'svg/EmptySequences.svg'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.white};
  min-height: 500px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const ImageContainer = styled.div`
  width: 90%;
  padding-bottom: 1rem;
`

class EmptyState extends PureComponent {
  render () {
    const {
      shadow = true,
      title,
      description
    } = this.props

    return (
      <Wrapper shadow={shadow}>
        {title && <Title mt='0' mb='0'>{title}</Title>}
        {description && <Label align='center' mt='0.5rem' mb='1rem'>{description}</Label>}
        <ImageContainer>
          <img
            src={EmptySequences}
            alt='Empty Sequence Graph'
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        </ImageContainer>
      </Wrapper>
    )
  }
}

EmptyState.propTypes = {
  shadow: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string
}

export default EmptyState

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Check from 'svg/Check'
import theme from '../../themes/light'

const Wrapper = styled.div`
  padding: 0.5rem;
  flex: 0;
  width: fit-content;

  &:hover,
  &:focus {
    opacity: ${props => !props.disabled ? 0.5 : 1};
    transition: ${props => !props.disabled ? 'opacity .15s ease-in' : 'none'};
  }

  &:active {
    opacity: ${props => !props.disabled ? 0.8 : 1};
    transition: ${props => !props.disabled ? 'opacity .15s ease-in' : 'none'};
  }
`

const Box = styled.div`
  color: white;
  background-color: ${
    props => (props.disabled)
      ? theme.colors.gray30
      : (props.selected)
        ? theme.colors.blue
        : theme.colors.white
  };

  text-align: center;
  cursor: ${props => !props.disabled ? 'pointer' : 'auto'};
  width: 1.25rem;
  height: 1.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.selected ? theme.colors.blue : theme.colors.gray40)};
  display: flex;

  svg {
    fill: white;
    margin: auto;
  }
`

class Checkbox extends Component {
  render () {
    const { selected, handleClick, disabled } = this.props

    return (
      <Wrapper
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleClick(e)
        }}
      >
        <Box
          selected={selected}
          disabled={disabled}
        >
          {disabled
            ? null
            : <Check />}
        </Box>
      </Wrapper>
    )
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  handleClick: PropTypes.func

}

export default Checkbox

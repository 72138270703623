/**
*
* CrmFieldForm Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { space, width, height } from 'styled-system'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { TOP_LEVEL_FIELD_OPTIONS } from 'containers/Integrations/constants'
import Button from 'components/Button'
import Label from 'elements/Label'
import FieldMapping from './FieldMapping'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Title = styled.label`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  letter-spacing: 0.05em;
  font-size: 15px;
  text-transform: uppercase;
`

const Description = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05em;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
`

const ButtonContainer = styled.div`
  flex: 1 0 auto;
  align-items: end;
  justify-content: end;
  display: flex;
`

class CrmFieldForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showEditFieldsModal: false
    }
  }

  render () {
    const {
      label,
      description,
      crm,
      sequenceFields,
      onSave,
      ...rest
    } = this.props

    const {
      showEditFieldsModal
    } = this.state

    const fieldMap = Immutable.List(TOP_LEVEL_FIELD_OPTIONS
      .concat(sequenceFields.get('data').map(field => ({ label: field, value: `$raw.${field}` })).toArray()))
      .reduce((acc, field) => {
        acc[field.value] = field.label
        return acc
      }, {})

    const crmFieldMap = {}
    const crmFields = crm.get('fields')
    if (crmFields) {
      crmFields.mapKeys((key) => {
        const fields = crm.get('fields').get(key)
        if (fields) {
          fields.forEach((field) => {
            const fieldKey = [key, field.get('name')].join('.')
            crmFieldMap[fieldKey] = field.get('label')
          })
        }
      })
    }

    let rows = []
    const mapping = crm.get('mapping')
    if (mapping) {
      mapping.mapKeys((key) => {
        const fields = mapping.get(key)
        fields.mapKeys((field) => {
          const value = fields.get(field)

          let label = fieldMap[value]
          if (value && !value.startsWith('$')) {
            label = `Fill with "${value}"`
          }

          const fieldKey = [key, field].join('.')
          const crmFieldName = crmFieldMap[fieldKey]

          if (value) {
            if (crmFieldName) {
              rows = rows.concat([
                <Label key={fieldKey}>
                  {`${label} → `}<strong>{key} - {crmFieldName}</strong>
                </Label>
              ])
            } else {
              rows = rows.concat([
                <Tooltip
                  // options
                  title={`${field} no longer exists in ${crm.get('name')}. Create the field in ${crm.get('name')} or customize and the save the mappings to remove it.`}
                  position='top'
                  trigger='mouseenter'
                  arrow
                  key={key}
                >
                  <Label color={theme.colors.red}>
                    {`${label} → `}<strong>{key} - {field}</strong>
                  </Label>
                </Tooltip>
              ])
            }
          }
        })
      })
    }

    return (
      <Wrapper
        {...rest}
      >
        <TitleContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {label &&
              <Title>{label}</Title>}
            {description &&
              <Description>{description}</Description>}
          </div>
          {mapping &&
            <ButtonContainer>
              <Button
                label='Restore Defaults'
                handleClick={() => {
                  onSave(null)
                }}
                ml='auto'
              />
            </ButtonContainer>}
        </TitleContainer>
        {rows.length > 0 && rows}
        {rows.length <= 0 &&
          <Label>
            Fields are currently following the default mapping for {crm.get('name')}
          </Label>}
        {onSave &&
          <Button
            full
            primary
            label='Customize'
            handleClick={() => {
              this.setState({
                showEditFieldsModal: true
              })
            }}
            mt='1rem'
          />}
        <FieldMapping
          crm={crm}
          isOpen={showEditFieldsModal}
          onCancel={() => {
            this.setState({
              showEditFieldsModal: false
            })
          }}
          onSave={(m) => {
            this.setState({
              showEditFieldsModal: false
            })

            let updatedMap = m

            if (crmFields) {
              m.mapKeys((mapModel) => {
                const fields = m.get(mapModel)
                fields.mapKeys((field) => {
                  const fieldKey = [mapModel, field].join('.')
                  if (!crmFieldMap[fieldKey]) {
                    updatedMap = updatedMap.removeIn([mapModel, field])
                  }
                })
              })
            }

            onSave(updatedMap)
          }}
          mapping={mapping}
          sequenceFields={sequenceFields}
        />
      </Wrapper>
    )
  }
}

CrmFieldForm.propTypes = {
  label: PropTypes.string,
  description: PropTypes.any,
  crm: PropTypes.object,
  sequenceFields: PropTypes.object,
  onSave: PropTypes.func
}

export default CrmFieldForm

import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import Immutable, { fromJS } from 'immutable'
import styled from 'styled-components'
import R from 'utils/ramda'
import Sticky from 'react-stickynode'
import {
  WindowScroller,
  CellMeasurerCache,
  AutoSizer,
  List,
  CellMeasurer
} from 'react-virtualized'
import withSession from 'hocs/session'
import Button from 'components/Button'
import Loading from 'components/Loading'
import ConfirmModal from 'components/ConfirmModal'
import { pluralize } from 'utils/strings'
import { handleLinkEvent } from 'utils/clicks'
import { getGroupLabel } from 'utils/groups'
import SequenceToolbar from './SequenceToolbar'
import EmptyState from './EmptyState'
import Wrapper from './Wrapper'
import AddToFolder from './AddToFolder'
import SequenceRowRenderer from './SequenceRowRenderer'
import SequenceHeaderRowRenderer from './SequenceHeaderRowRenderer'
import ContactEmptyState from 'containers/Sequence/components/Contacts/EmptyState'
import debounce from 'lodash.debounce'
import theme from '../../../../themes/light'

const TableWrapper = styled.div`
  background: ${theme.colors.white};
  color: #FFF;
  transition: background-color 0.3s ease, color .3s ease;
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
`

const SequenceTableWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const VirtualTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.containerBackground};

  .ReactVirtualized__Grid {
    outline: none;
  }
`

class Sequences extends Component {
  constructor (props) {
    super(props)

    this.rowCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 92
    })

    this.lastSelectedIndex = null
    this.sequencesInView = new Set()
    this.folderSequencesInView = new Set()
    this.currentScrollTop = 0
    this.isNewFilterState = true

    this.state = {
      visibleSequences: Immutable.List([]),
      filteredSequences: Immutable.List([]),
      selectedSequences: [],
      allSelected: false,
      showDeleteConfirmModal: false,
      showArchived: false,
      filterUserId: null,
      filterFolder: null,
      showFolderModal: false,
      loading: true
    }
  }

  getCurrentFilter = (location) => {
    let {
      userId,
      groupId
    } = location.query

    if (!userId && !groupId) {
      const bookmarkedId = this.getBookmarkedId()
      userId = bookmarkedId.userId
      groupId = bookmarkedId.groupId
    }

    return {
      userId,
      groupId
    }
  }

  listenBrowser = () => {
    return browserHistory.listen(location => {
      this.lastSelectedIndex = null

      const {
        userId,
        groupId
      } = this.getCurrentFilter(location)
      const {
        folder,
        archived
      } = location.query

      // set default query if the query has no parameters
      if (!location.query.userId && !location.query.groupId && location.pathname === '/') {
        const query = location.query
        query.userId = userId
        query.groupId = groupId

        return this.props.router.replace({
          ...location,
          query
        })
      }

      this.setState({
        selectedSequences: [],
        allSelected: false,
        filterUserId: userId,
        filterFolder: folder,
        filterGroupId: groupId,
        showArchived: !!archived
      })
    })
  }

  UNSAFE_componentWillMount () {
    const {
      userId,
      groupId
    } = this.getCurrentFilter(this.props.location)
    const {
      folder,
      archived
    } = this.props.location.query

    this.setState({
      filterUserId: userId,
      filterFolder: folder,
      filterGroupId: groupId,
      showArchived: !!archived
    })

    this.browserHistoryUnlisten = this.listenBrowser() // sets up listener to watch for changes in the url

    const query = {
      userId,
      folder,
      archived,
      groupId
    }

    this.props.router.replace({
      ...this.props.location,
      query
    })
  }

  componentWillUnmount () {
    this.browserHistoryUnlisten()
  }

  getSelectedSequenceIds = () => {
    const {
      selectedSequences,
      allSelected
    } = this.state
    const {
      visibleSequences
    } = this

    if (allSelected) {
      return visibleSequences
        .map(s => (s.get('_id')))
        .toArray()
    }

    return selectedSequences
  }

  filterSequences = (opts) => {
    const {
      userId,
      folder,
      archived,
      groupId
    } = opts

    this.lastSelectedIndex = null

    this.setState({
      allSelected: false,
      selectedSequences: []
    })

    const {
      location,
      router
    } = this.props

    const query = location.query
    if (userId) {
      query.userId = userId
      delete query.groupId
    } else if (userId === null) {
      query.userId = 'all'
    }

    if (folder) {
      query.folder = folder
    } else if (folder === null) {
      delete query.folder
    }

    if (groupId) {
      query.groupId = groupId
      delete query.userId
    } else if (groupId === null) {
      delete query.groupId
    }

    if (archived) {
      query.archived = 1
    } else if (archived === false) {
      delete query.archived
    }

    this.isNewFilterState = true
    this.currentScrollTop = 0

    // Update route to reflect new filters
    router.push({
      ...location,
      query
    })
  }

  getSortedSequences = (sequences, sequencesStats, currentSortKey) => {
    if (!currentSortKey) {
      return sequences
    }

    const direction = currentSortKey.indexOf('-') > -1 ? '-' : '+'
    const sortKey = currentSortKey.replace('+', '').replace('-', '')

    return sequences
      .sort((a, b) => {
        if (['title', 'active'].includes(sortKey)) {
          if (a.get(sortKey) > b.get(sortKey)) {
            return direction === '-' ? -1 : 1
          }

          return direction === '-' ? 1 : -1
        }

        if (sortKey === 'owner') {
          if (a.getIn(['_user', 'full_name']) > b.getIn(['_user', 'full_name'])) {
            return direction === '-' ? -1 : 1
          }

          return direction === '-' ? 1 : -1
        }

        const aStat = sequencesStats.get(a.get('_id')) || Immutable.Map({})
        const bStat = sequencesStats.get(b.get('_id')) || Immutable.Map({})
        const aTotal = aStat.get('contacts_count') || 0
        const bTotal = bStat.get('contacts_count') || 0
        if (sortKey === 'contacts_count') {
          if (aTotal > bTotal) {
            return direction === '-' ? -1 : 1
          }
          return direction === '-' ? 1 : -1
        }
        const aPerc = Math.round(((aStat.get(sortKey) || 0) / aTotal) * 100)
        const bPerc = Math.round(((bStat.get(sortKey) || 0) / bTotal) * 100)
        if (aPerc > bPerc) {
          return direction === '-' ? -1 : 1
        }
        return direction === '-' ? 1 : -1
      })
  }

  getFilteredSequences = (sequences, filterUserId, filterFolder, filterGroupId, members) => {
    let filterSequences = sequences

    if (filterUserId && filterUserId !== 'all') {
      filterSequences = filterSequences
        .filter((s) => {
          return s.getIn(['_user', '_id']) === filterUserId
        })
    }

    if (filterFolder) {
      filterSequences = filterSequences
        .filter((s) => {
          return s.get('group') === filterFolder
        })
    }

    if (filterGroupId) {
      const groupMembers = members.get('data')
        .filter((m) => {
          return m.get('group') === filterGroupId
        })
        .map((m) => m.get('id'))

      filterSequences = filterSequences
        .filter((s) => {
          return groupMembers.includes(s.getIn(['_user', '_id']))
        })
    }

    return filterSequences
  }

  getVisibleSequences = (sequences, filterFolder) => {
    let visibleSequences = sequences

    if (filterFolder) {
      visibleSequences = visibleSequences
        .filter((s) => {
          return s.get('group') === filterFolder
        })
    } else {
      visibleSequences = visibleSequences
        .filter((s) => {
          return !s.get('group')
        })
    }

    return visibleSequences
  }

  getArchiveRow = (archiveCount) => {
    return fromJS({
      key: 'archive',
      type: 'archive',
      data: {
        count: archiveCount
      }
    })
  }

  getSequenceRows = (sequences, sequencesStats, sequencesStatus) => {
    return sequences.map((s) => {
      const sequenceId = s.get('_id')

      const sequenceStats = Immutable
        .Map({
          loading: sequencesStats.getIn(['data', sequenceId]) ? false : sequencesStats.get('loading')
        })
        .merge(sequencesStats.getIn(['data', sequenceId]))

      const sequenceStatus = Immutable
        .Map({
          loading: sequencesStatus.getIn(['data', sequenceId]) === undefined
        })
        .merge(sequencesStatus.getIn(['data', sequenceId]))

      return fromJS({
        key: `sequence_${sequenceId}`,
        type: 'sequence',
        data: {
          sequence: s,
          stats: sequenceStats,
          status: sequenceStatus
        }
      })
    })
  }

  getFolderStats = (sequences, sequencesStats, sequencesStatus) => {
    let contactsCount = 0
    let messagedContactsCount = 0
    let viewedContactsCount = 0
    let repliedContactsCount = 0
    let erroredContactsCount = 0
    let manualCount = 0
    let loading = true

    sequences.forEach((s) => {
      const stat = sequencesStats.getIn(['data', s.get('_id')])
      if (!stat) {
        return
      }

      loading = false

      const status = sequencesStatus.getIn(['data', s.get('_id')])

      contactsCount = contactsCount + (stat.get('contacts_count') || 0)
      messagedContactsCount = messagedContactsCount + (stat.get('messaged_contacts_count') || 0)
      viewedContactsCount = viewedContactsCount + (stat.get('viewed_contacts_count') || 0)
      repliedContactsCount = repliedContactsCount + (stat.get('replied_contacts_count') || 0)
      erroredContactsCount = erroredContactsCount + (stat.get('errored_contacts_count') || 0)
      manualCount = manualCount + (status ? status.get('manual') : 0)
    })

    return Immutable.Map({
      loading,
      contacts_count: contactsCount,
      messaged_contacts_count: messagedContactsCount,
      viewed_contacts_count: viewedContactsCount,
      replied_contacts_count: repliedContactsCount,
      errored_contacts_count: erroredContactsCount,
      manual: manualCount
    })
  }

  getFolderRows = (sequences, sequencesStats, sequencesStatus) => {
    return sequences
      .filter((s) => {
        return s.get('group')
      })
      .groupBy((s) => {
        return s.get('group')
      })
      .toList()
      .sort((a, b) => {
        return a.getIn(['0', 'group']) > b.getIn(['0', 'group']) ? 1 : -1
      })
      .map((groupedSequences) => {
        const folderName = groupedSequences.getIn(['0', 'group'])

        return fromJS({
          key: `folder_${folderName}`,
          type: 'folder',
          data: {
            name: folderName,
            sequences: groupedSequences,
            stats: this.getFolderStats(groupedSequences, sequencesStats, sequencesStatus)
          }
        })
      })
  }

  onSelectAll = () => {
    const {
      selectedSequences
    } = this.state
    const {
      filteredSequences
    } = this

    if (selectedSequences.length === filteredSequences.count()) {
      this.setState({
        selectedSequences: []
      })
    } else {
      this.setState({
        selectedSequences: filteredSequences.map(s => s.get('_id')).toArray()
      })
    }

    this.lastSelectedIndex = null
  }

  onSort = (sortKey) => {
    this.setState({
      currentSortKey: sortKey
    })
  }

  getHeaderRowRenderer = (rowProps) => {
    const {
      allSelected,
      selectedSequences,
      currentSortKey
    } = this.state

    const {
      visibleSequences,
      filteredSequences
    } = this

    return (
      <SequenceHeaderRowRenderer
        selected={visibleSequences.count() > 0 && (allSelected || selectedSequences.length === filteredSequences.count())}
        currentSortKey={currentSortKey}
        onSelectAll={this.onSelectAll}
        onSort={this.onSort}
        {...rowProps}
      />
    )
  }

  onSequenceStatusToggle = (sequence, value) => {
    const {
      actions
    } = this.props

    const sequenceId = sequence.get('_id')
    const sequenceTitle = sequence.get('title')

    let message
    if (value) {
      message = `Activated ${sequenceTitle}! 🚀`
    } else {
      message = `Paused ${sequenceTitle}! 🚀`
    }
    actions.updateSequences([sequenceId], { active: value }, message)
  }

  handleSelect = (key, e) => {
    document.getSelection().removeAllRanges()

    const {
      selectedSequences
    } = this.state

    const {
      filteredSequences,
      visibleSequences
    } = this

    let rows = Immutable.List()
    rows = rows.concat(this.getFolderRows(filteredSequences, Immutable.Map(), Immutable.Map()))
    rows = rows.concat(this.getSequenceRows(visibleSequences, Immutable.Map(), Immutable.Map()))

    const index = rows.findIndex((row) => {
      return row.get('key') === key
    })

    const sequenceId = rows.getIn([index, 'data', 'sequence', 'id']) || rows.getIn([index, 'data', 'sequences', '0', 'id'])

    const isSelected = selectedSequences.includes(sequenceId)

    const toggleSequence = (sequenceId) => {
      if (isSelected) {
        const sequenceIdx = selectedSequences.indexOf(sequenceId)
        if (sequenceIdx >= 0) {
          selectedSequences.splice(sequenceIdx, 1)
        }
      } else {
        selectedSequences.push(sequenceId)
      }
    }

    const toggle = (rowIndex) => {
      const row = rows.get(rowIndex)

      switch (row.get('type')) {
        case 'folder':
          return row.getIn(['data', 'sequences']).forEach((s) => {
            toggleSequence(s.get('id'))
          })
        case 'sequence':
          return toggleSequence(row.getIn(['data', 'sequence', 'id']))
      }
    }

    toggle(index)

    const nativeE = (e || {}).nativeEvent || {}
    const shiftKey = this.lastSelectedIndex !== null && nativeE.shiftKey

    if (shiftKey) {
      let direction = 1
      if (this.lastSelectedIndex < index) {
        direction = -1
      }

      let i = index
      while (i !== this.lastSelectedIndex + direction) {
        toggle(i)
        i = i + direction
      }
    }

    this.lastSelectedIndex = index

    this.setState({
      selectedSequences: Immutable.Set(selectedSequences).toArray()
    })
  }

  onSequenceSelected = (sequenceId, e) => {
    this.handleSelect(`sequence_${sequenceId}`, e)
  }

  onSequenceClick = (sequenceId, e) => {
    const path = `/sequence/${sequenceId}/`
    return handleLinkEvent(e, path) || this.props.router.push(path)
  }

  onFolderSelected = (folderName, e) => {
    this.handleSelect(`folder_${folderName}`, e)
  }

  onFolderClick = (folderName) => {
    this.filterSequences({
      folder: folderName
    })
  }

  onArchiveClick = () => {
    this.props.actions.fetchSequencesStats({ archived: true })

    this.filterSequences({
      archived: true
    })
  }

  onScrollingPauseOrLoad = () => {
    const { sequencesStats, sequencesStatus, actions } = this.props
    const { showArchived } = this.state

    if (!sequencesStats.get('loading')) {
      actions.fetchSequencesStats({ sequenceIds: [...this.sequencesInView, ...this.folderSequencesInView] })
    }

    if (!sequencesStatus.get('loading') && !showArchived) {
      actions.fetchSequencesStatus({ sequenceIds: [...this.sequencesInView] })
    }

    this.isNewFilterState = false
  }

  onScrollingPauseOrLoadDebounce = debounce(() => {
    this.onScrollingPauseOrLoad()
  }, 250)

  getRowRenderer = (rows, rowProps) => {
    const {
      index,
      parent,
      isVisible
    } = rowProps
    const { router } = this.props
    const {
      selectedSequences,
      allSelected,
      showArchived
    } = this.state

    if (isVisible) {
      const sequenceType = rows?.getIn([index, 'type'])

      switch (sequenceType) {
        case 'sequence':
          this.sequencesInView.add(rows?.getIn([index, 'data', 'sequence', 'id']))
          break
        case 'folder':
          for (const sequence of rows?.getIn([index, 'data', 'sequences'])) {
            this.folderSequencesInView.add(sequence.get('id'))
          }
          break
        default:
          break
      }

      if (this.isNewFilterState) {
        this.onScrollingPauseOrLoadDebounce()
      }
    }

    if (index === 0) {
      return this.getHeaderRowRenderer(rowProps)
    }

    const rowData = rows.get(index - 1)

    return (
      <CellMeasurer
        cache={this.rowCache}
        columnIndex={0}
        key={rowData.get('key')}
        rowIndex={index}
        parent={parent}
        fixedWidth
      >
        <SequenceRowRenderer
          router={router}
          selectedSequences={selectedSequences}
          allSelected={allSelected}
          showArchived={showArchived}
          onSequenceSelected={this.onSequenceSelected}
          onSequenceClick={this.onSequenceClick}
          onSequenceStatusToggle={this.onSequenceStatusToggle}
          onFolderClick={this.onFolderClick}
          onArchiveClick={this.onArchiveClick}
          onFolderSelected={this.onFolderSelected}
          rowData={rowData}
          {...rowProps}
        />
      </CellMeasurer>
    )
  }

  shouldListUpdate (prevProps, prevState, nextProps, nextState) {
    const stateKeys = [
      'currentSortKey',
      'filterUserId',
      'filterFolder',
      'filterGroupId',
      'showArchived'
    ]

    const prevStateCompare = R.pick(stateKeys, prevState)
    const nextStateCompare = R.pick(stateKeys, nextState)

    const isSame = R.equals(prevStateCompare, nextStateCompare) &&
      prevProps.sequences === nextProps.sequences &&
      prevProps.archivedSequences === nextProps.archivedSequences &&
      prevProps.sequencesStats === nextProps.sequencesStats &&
      prevProps.sequencesStatus === nextProps.sequencesStatus &&
      prevProps.members === nextProps.members

    return !isSame
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (this.shouldListUpdate(this.props, this.state, nextProps, nextState)) {
      this.rowCache.clearAll()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.shouldListUpdate(prevProps, prevState, this.props, this.state)) {
      this.rowCache.clearAll()
    }
  }

  onResize = () => {
    this.rowCache.clearAll()
  }

  getBookmarkedId () {
    const { session } = this.props
    const dashboardView = session.get('dashboard_view')
    const featureFlags = session.get('feature_flags') || session.get('whitelist')
    const defaultView = featureFlags.includes('default_my_sequences') ? session.get('id') : 'all'

    const bookmarkedId = dashboardView || defaultView
    const groupId = bookmarkedId.includes('group_') ? bookmarkedId.split('_').pop() : undefined
    const userId = !groupId ? bookmarkedId : undefined

    return {
      groupId,
      userId
    }
  }

  setBookmarkedId () {
    const { filterGroupId, filterUserId } = this.state
    let dashboardView = 'all'

    if (filterGroupId) {
      dashboardView = `group_${filterGroupId}`
    } else if (filterUserId) {
      dashboardView = filterUserId
    }
    this.props.actions.updateSession({
      dashboard_view: dashboardView
    }, 'Default view set!')
  }

  render () {
    const {
      sequencesStats,
      onExportCsv,
      onAddSequence,
      actions,
      sequences,
      archivedSequences,
      sequencesStatus,
      session,
      state,
      members,
      fetchSequences
    } = this.props

    const {
      currentSortKey,
      selectedSequences,
      allSelected,
      showDeleteConfirmModal,
      showArchived,
      filterUserId,
      filterFolder,
      filterGroupId,
      showFolderModal
    } = this.state

    const selection = showArchived ? archivedSequences : sequences
    const firstSequence = archivedSequences.count() <= 0

    const shownSequences = showArchived ? archivedSequences : sequences

    // sort & filter sequences out based on the current state
    const sortedSequences = this.getSortedSequences(shownSequences, sequencesStats, currentSortKey)
    const filteredSequences = this.getFilteredSequences(sortedSequences, filterUserId, filterFolder, filterGroupId, members)
    const visibleSequences = this.getVisibleSequences(filteredSequences, filterFolder)

    const loading = members.get('loading') || this.props.loading

    this.filteredSequences = filteredSequences
    this.visibleSequences = visibleSequences

    // get a list of all folders
    const folders = sequences
      .filter(s => s.get('group'))
      .map(s => s.get('group'))
      .toSet()

    // get team members for filter
    const teamMembers = members.get('data')
    const teamMap = teamMembers.reduce((acc, u) => {
      acc[u.get('id')] = u
      return acc
    }, {})

    // check userid filter is a team member
    const memberIds = teamMembers.map(m => (m.get('id'))).toArray()
    if (filterUserId && filterUserId !== 'all' && selection.count() && !members.get('loading') && !memberIds.includes(filterUserId)) {
      this.filterSequences({ userId: null })
    }

    let title = showArchived ? 'Archived Sequences' : 'Sequences'
    let archiveCount = archivedSequences ? archivedSequences.count() : 0

    if (filterUserId && filterUserId !== 'all') {
      if (filterUserId === session.get('_id')) {
        title = `My ${title}`
      } else {
        const selectedUser = teamMap[filterUserId]
        if (selectedUser) {
          title = `${selectedUser.get('full_name')}'s ${title}`
        }
      }

      archiveCount = archivedSequences
        .filter(s => s.getIn(['_user', '_id']) === filterUserId)
        .count()
    } else if (filterGroupId) {
      title = `${getGroupLabel(filterGroupId)} ${title}`
    } else {
      title = `All ${title}`
    }

    if (filterFolder) {
      title = `${title} › ${filterFolder}`
    }

    const count = allSelected ? 'all' : selectedSequences.length
    const word = allSelected ? 'sequences' : pluralize('sequence', 'sequences', selectedSequences.length)

    // create a list of rows for VirtualTable
    let visibleRows = Immutable.List([])

    // if (!filterFolder && !showArchived && archiveCount > 0) {
    //   visibleRows = visibleRows.push(this.getArchiveRow(archiveCount))
    // }

    if (!filterFolder) {
      visibleRows = visibleRows.concat(this.getFolderRows(filteredSequences, sequencesStats, sequencesStatus))
    }

    visibleRows = visibleRows.concat(this.getSequenceRows(visibleSequences, sequencesStats, sequencesStatus))

    // begin rendering our table view
    const hasRows = visibleRows.count()

    return (
      <Wrapper>
        <TableWrapper>
          <Sticky innerZ={3}>
            <SequenceToolbar
              title={title}
              showArchived={showArchived}
              archiveCount={archiveCount}
              session={session}
              state={state}
              members={teamMembers}
              filterUserId={filterUserId}
              filterFolder={filterFolder}
              filterGroupId={filterGroupId}
              onExportCsv={() => {
                onExportCsv(this.getSelectedSequenceIds())
              }}
              onArchiveToggled={() => {
                fetchSequences(!showArchived)
                this.filterSequences({
                  archived: !showArchived
                })
              }}
              onAddSequence={onAddSequence}
              selectedSequences={selectedSequences}
              allSelected={filteredSequences.count() > 0 && (allSelected || filteredSequences.count() === selectedSequences.length)}
              onDelete={() => {
                this.setState({
                  showDeleteConfirmModal: true
                })
              }}
              onUpdate={(params) => {
                actions.updateSequences(this.getSelectedSequenceIds(), params)
                this.lastSelectedIndex = null
                this.setState({
                  allSelected: false,
                  selectedSequences: []
                })
              }}
              onClear={() => {
                this.lastSelectedIndex = null
                this.setState({
                  allSelected: false,
                  selectedSequences: []
                })
              }}
              onRestore={() => {
                const selectedIds = this.getSelectedSequenceIds()
                actions.restoreSequences(
                  selectedIds
                )
                // TODO: Create restore sequence action and move from archived to sequence
                // for now we are just refetching everything in the saga and showing active
                this.filterSequences({
                  archived: false
                })
              }}
              onFilterSequences={(params) => {
                if (params && params.userId) {
                  this.filterSequences({
                    userId: params.userId,
                    groupId: null
                  })
                } else if (params && params.folder) {
                  this.filterSequences({
                    folder: params.folder
                  })
                } else if (params && params.group) {
                  this.filterSequences({
                    groupId: params.group
                  })
                } else {
                  this.filterSequences({
                    userId: null,
                    archived: false,
                    folder: null,
                    groupId: null
                  })
                }
              }}
              onClearFolder={() => {
                this.filterSequences({
                  folder: null
                })
              }}
              onAddToFolder={() => {
                this.setState({
                  showFolderModal: true
                })
              }}
              onRemoveFromFolder={() => {
                actions.updateSequences(this.getSelectedSequenceIds(), {
                  group: null
                })
              }}
              bookmarkedId={this.getBookmarkedId()}
              setBookmarkedId={() => this.setBookmarkedId()}
            />
          </Sticky>
          <SequenceTableWrapper>
            {loading && <Loading padding='9rem' mb='2rem' />}

            {!loading && !hasRows &&
              <>
                {!filterFolder &&
                  <EmptyState
                    onAddSequence={onAddSequence}
                    shadow={false}
                    title='Create a Sequence'
                    description={`Add ${firstSequence ? 'your first' : 'a new'} sequence to build a list of contacts to reach out to.`}
                  />}

                {filterFolder &&
                  <ContactEmptyState
                    title='Empty Folder'
                    description={<span>There are no sequences under the "{filterFolder}" folder</span>}
                  >
                    <Button
                      label='View all sequences'
                      handleClick={() => {
                        this.filterSequences({
                          userId: null,
                          archived: false,
                          folder: null
                        })
                      }}
                    />
                  </ContactEmptyState>}
              </>}

            {!loading && hasRows &&
              <VirtualTableWrapper>
                <WindowScroller>
                  {({
                    height,
                    scrollTop,
                    isScrolling,
                    onChildScroll
                  }) => (
                    <AutoSizer disableHeight onResize={this.onResize}>
                      {({ width }) => (
                        <List
                          ref={() => {
                            const minScrollDistance = 20

                            if (
                              isScrolling === false &&
                              this.isNewFilterState === false &&
                              scrollTop - this.currentScrollTop > minScrollDistance
                            ) {
                              this.currentScrollTop = scrollTop
                              this.onScrollingPauseOrLoad()
                            }

                            return this.sequenceList
                          }}
                          autoHeight
                          height={height}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          overscanRowCount={10}
                          rowCount={visibleRows.count() + 1}
                          rowHeight={(index) => {
                            if (index.index === 0) {
                              return 50
                            }

                            return this.rowCache.rowHeight(index)
                          }}
                          scrollTop={scrollTop}
                          width={width}
                          deferredMeasurementCache={this.rowCache}
                          rowRenderer={(rowProps) => {
                            return this.getRowRenderer(visibleRows, rowProps)
                          }}
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              </VirtualTableWrapper>}
          </SequenceTableWrapper>
        </TableWrapper>
        <ConfirmModal
          isOpen={showDeleteConfirmModal}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false
            })
          }}
          onConfirm={() => {
            const selectedIds = this.getSelectedSequenceIds()
            actions.deleteSequences(selectedIds)
            this.lastSelectedIndex = null
            this.setState({
              showDeleteConfirmModal: false,
              allSelected: false,
              selectedSequences: []
            })
          }}
          title='Archive Confirmation'
          description={`Are you sure you want to archive ${count} ${word}?`}
        />
        <AddToFolder
          isOpen={showFolderModal}
          folders={folders}
          confirmText='Move Sequences'
          onCancel={() => {
            this.setState({
              showFolderModal: false
            })
          }}
          onSave={folder => {
            actions.updateSequences(this.getSelectedSequenceIds(), {
              group: folder
            })

            this.lastSelectedIndex = null
            this.setState({
              showFolderModal: false,
              selectedSequences: [],
              allSequences: false
            })
          }}
        />
      </Wrapper>
    )
  }
}

Sequences.propTypes = {
  sequences: PropTypes.instanceOf(Immutable.List),
  archivedSequences: PropTypes.instanceOf(Immutable.List),
  loading: PropTypes.bool,
  onExportCsv: PropTypes.func,
  onAddSequence: PropTypes.func,
  actions: PropTypes.object,
  loadMore: PropTypes.func,
  router: PropTypes.object,
  sequencesStats: PropTypes.object,
  sequencesStatus: PropTypes.object,
  session: PropTypes.object,
  location: PropTypes.object,
  members: PropTypes.instanceOf(Immutable.Map),
  state: PropTypes.object,
  fetchSequences: PropTypes.func
}

export default withSession(Sequences)

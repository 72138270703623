import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import R from 'utils/ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import {
  selectSession,
  selectState
} from 'containers/App/selectors'
import {
  selectSubscription,
  selectUpdatePreview
} from 'containers/Billing/selectors'
import {
  fetchSubscription,
  fetchPreview,
  fetchUpdatePreview,
  resetUpdatePreview
} from 'containers/Billing/actions'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Progress from 'elements/Progress'
import { success } from 'utils/toast'
import { getGroupsFromMembers } from 'utils/groups'
import InviteMemberModal from './components/InviteMemberModal'
import Wrapper from './Wrapper'
import MemberList from './components/MemberList'
import RemoveTeammate from './components/RemoveTeammate'
import AssignGroupModal from './components/AssignGroupModal'
import {
  selectMembers,
  selectInvites,
  selectTeamCounts
} from './selectors'
import {
  fetchTeamMembers,
  updateTeamMember,
  deleteTeamMember,
  fetchTeamInvites,
  resendTeamInvite,
  deleteTeamInvite,
  sendTeamInvite,
  fetchTeamCounts,
  assignGroupMember
} from './actions'
import theme from '../../themes/light'

const ContentContainer = styled.div`
  background-color: ${theme.containerBackground};
  flex: 1 0 auto;
  border: solid 1px ${theme.borderColor};
  padding: ${theme.padding};
  margin-bottom: 2rem;
`

const TitleContainer = styled.div`
  display: flex;
  padding: ${theme.padding};
  margin-bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // margin-top: 0.5rem;
`

const SeatContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-top: 0.25rem;
  padding-left: 0;
`

class Team extends Component {
  constructor (props) {
    super(props)
    this.seats = null
    this.state = {
      showAssignGroupModal: false,
      showDeleteConfirmModal: false,
      showInviteMembersModal: false,
      fetchedInvites: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    actions.fetchTeamMembers()
    document.title = 'Interseller | Team'
    this.fetchBilling()

    const query = this.props.location.query || {}
    if (query.accepted) {
      success('Accepted invite to team! 🚀')
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      state,
      actions
    } = nextProps
    const {
      fetchedInvites
    } = this.state
    if (!fetchedInvites && state && (state.get('is_owner') || state.get('is_manager'))) {
      this.setState({
        fetchedInvites: true
      })
      actions.fetchTeamInvites()
      actions.fetchTeamCounts()
    }

    if (!this.seats) {
      this.seats = this.props.subscription?.getIn(['data', 'quantity'])
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.state !== this.props.state) {
      this.fetchBilling()
    }

    if (!R.equals(prevProps.invites?.get('data'), this.props.invites?.get('data'))) {
      this.setState({ showInviteMembersModal: false })
    }
  }

  fetchBilling = () => {
    const { actions, state } = this.props
    const subscribed = state?.get('subscribed')
    const owner = state?.get('is_owner')

    if (subscribed && owner) {
      actions.fetchSubscription()
      actions.fetchPreview()
    }
  }

  render () {
    const {
      members,
      invites,
      actions,
      state,
      counts,
      session,
      subscription,
      updatePreview
    } = this.props

    const {
      showAssignGroupModal,
      showDeleteConfirmModal,
      showInviteMembersModal,
      deleteMember,
      assignGroupMember,
      selectedGroup
    } = this.state

    const loading = members.get('loading') || invites.get('invites') || counts.get('loading')
    const invitesData = invites.get('data')
    const countsData = counts.get('data')
    const membersData = members.get('data')
    const isOwner = state && state.get('is_owner')
    const isManager = state && state.get('is_manager')

    const groups = getGroupsFromMembers(members)

    let groupOptions = groups
      .reduce((acc, next) => {
        const isNumeric = !isNaN(next)

        acc = acc.concat([{
          value: next,
          label: isNumeric ? `Group ${next}` : next
        }])

        return acc
      }, [{
        label: 'No group...',
        value: null
      }])

    groupOptions = Immutable.List(groupOptions)

    if (loading) {
      return (
        <Wrapper>
          <ContentContainer>
            <Loading />
          </ContentContainer>
        </Wrapper>
      )
    }

    const usedSeats = countsData && countsData.getIn(['counts', 'total'])
    const totalSeats = countsData && countsData.getIn(['subscription', 'seats'])
    const areSeatsRemaining = !totalSeats || usedSeats < totalSeats

    // when totalSeats is nil, this means that they have unlimited seats and the
    // seat indicator should not be displayed

    return (
      <Wrapper>
        <ContentContainer>
          <TitleContainer>
            <div>
              <Row>
                <Title>Team</Title>
                {(isOwner || isManager) && totalSeats > 0 &&
                  <SeatContainer>
                    <Progress percentage={usedSeats / totalSeats} barColor={areSeatsRemaining ? null : '#E4542B'} />
                    <Label ml='0.5rem' fontSize={11}>{usedSeats}/{totalSeats} SEATS USED</Label>
                  </SeatContainer>}
              </Row>
              {(!isOwner && !isManager) && <Label mt='0.5rem'>You are currently a member of a team! Contact an owner or manager to invite additional team members.</Label>}
            </div>
            {(isOwner || isManager) &&
              <div>
                <InviteMemberModal
                  actions={actions}
                  groups={groupOptions}
                  isManager={isManager}
                  isOwner={isOwner}
                  session={session}
                  subscription={subscription}
                  seats={this.seats}
                  preview={updatePreview}
                  invites={invites}
                  areSeatsRemaining={areSeatsRemaining}
                  isOpen={showInviteMembersModal}
                  onCancel={() => this.setState({ showInviteMembersModal: false })}
                  onSave={(email, role, group, willAddSeat) => {
                    let seats
                    let prorationDate
                    if (willAddSeat) {
                      prorationDate = Math.round((new Date()).getTime() / 1000)
                      seats = this.seats + 1
                    }

                    actions.sendTeamInvite({ email, role, group, seats, prorationDate })
                  }}
                />
                <Button
                  label='Invite Members'
                  primary
                  onClick={() => this.setState({ showInviteMembersModal: true })}
                />
              </div>}
          </TitleContainer>
          <MemberList
            members={membersData}
            invites={invitesData}
            isOwner={isOwner}
            isManager={isManager}
            onUpdateMember={(member, params) => {
              actions.updateTeamMember(member, params)
            }}
            onDeleteMember={(member) => {
              this.setState({
                showDeleteConfirmModal: true,
                deleteMember: member
              })
            }}
            onAssignGroupMember={(member) => {
              this.setState({
                showAssignGroupModal: true,
                assignGroupMember: member,
                selectedGroup: member.get('group') // dropdown will default to "None" group
              })
            }}
            onDeleteInvite={(invite) => {
              actions.deleteTeamInvite(invite)
            }}
            onResendInvite={(invite) => {
              actions.resendTeamInvite(invite)
            }}
            session={session}
          />
        </ContentContainer>
        <RemoveTeammate
          isOpen={showDeleteConfirmModal}
          deleteMember={deleteMember}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false,
              deleteMember: null
            })
          }}
          onConfirm={() => {
            actions.deleteTeamMember(deleteMember)
            this.setState({
              showDeleteConfirmModal: false,
              deleteMember: null
            })
          }}
        />
        <AssignGroupModal
          isOpen={showAssignGroupModal}
          onCancel={() => {
            this.setState({
              showAssignGroupModal: false,
              assignGroupMember: null
            })
          }}
          onConfirm={() => {
            const params = {
              targetUserId: assignGroupMember.get('id'),
              group: selectedGroup
            }
            actions.assignGroupMember(params)
            this.setState({
              showAssignGroupModal: false,
              assignGroupMember: null
            })
          }}
          member={assignGroupMember}
          groups={groupOptions}
          selectedGroup={selectedGroup}
          setGroup={(newGroup) => {
            this.setState({ selectedGroup: newGroup })
          }}
        />
      </Wrapper>
    )
  }
}

Team.propTypes = {
  actions: PropTypes.object,
  members: PropTypes.object,
  invites: PropTypes.object,
  state: PropTypes.object,
  location: PropTypes.object,
  counts: PropTypes.object,
  navigation: PropTypes.object,
  session: PropTypes.object,
  subscription: PropTypes.object,
  updatePreview: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  members: selectMembers(),
  invites: selectInvites(),
  session: selectSession(),
  state: selectState(),
  counts: selectTeamCounts(),
  subscription: selectSubscription(),
  updatePreview: selectUpdatePreview()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTeamMembers,
      updateTeamMember,
      deleteTeamMember,
      fetchTeamInvites,
      resendTeamInvite,
      deleteTeamInvite,
      sendTeamInvite,
      fetchTeamCounts,
      assignGroupMember,
      fetchSubscription,
      fetchPreview,
      fetchUpdatePreview,
      resetUpdatePreview
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)

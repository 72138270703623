/**
*
* Confirm Components
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  width: 42rem;
  height: fit-content;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

const TabList = styled.ul`
  background-color: ${props => props.danger ? theme.colors.red : theme.colors.gray10};
  width: 100%;
  margin: 0;
  padding: 0 10rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const Tab = styled.li`
  display: flex;
  list-style: none;
  justify-content: center;

  ${props => props.hidden ? 'display: none;' : ''}
`

const TabLink = styled.div`
  text-align: center;
  margin: 1rem;
  margin-top: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${theme.fonts.button};
  letter-spacing: .05em;
  opacity: 1;
  transition: opacity .15s ease-in;

  ${props => props.active && css`
    border-bottom: ${`2px solid ${props.danger ? theme.colors.white : theme.colors.blue}`};
    color: ${props.danger ? theme.colors.white : theme.colors.blue};
    cursor: default;
    transition: none;

    &:hover,
    &:focus {
      opacity: 1;
      transition: none;
    }
  `};

  ${props => !props.active && css`
    border-bottom: 2px solid transparent;
    color: ${props.danger ? theme.colors.white : theme.colors.gray60};
    cursor: ${props.selectable ? 'pointer' : 'default'};
    transition: none;

    &:hover,
    &:focus {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: 1;
      transition: opacity .15s ease-out;
    }
  `};
`

class TabbedModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedTab: props.initialTab
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.props.initialTab !== newProps.initialTab) {
      this.setState({
        selectedTab: newProps.initialTab
      })
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && !this.props.disabled) {
      e.preventDefault()
      return this.props.onConfirm()
    }
    return false
  }

  render () {
    const {
      tabs,
      loading,
      onCancel,
      onConfirm,
      isOpen,
      confirmText = 'save changes',
      disabled = false,
      initialTab,
      ...rest
    } = this.props

    const {
      selectedTab
    } = this.state

    let dangerSelected = false
    tabs.map((tab, i) => {
      const selected = i === selectedTab
      if (selected && tab.danger) {
        dangerSelected = true
      }
      return dangerSelected
    })

    const navItems = tabs.map((tab, i) => {
      const label = tab.label
      const selected = i === selectedTab
      const selectable = !!tab.content
      const hidden = tab.hidden
      return (
        <Tab hidden={hidden} key={label}>
          <TabLink
            selectable={selectable}
            danger={dangerSelected}
            active={selected}
            onClick={() => {
              if (!selectable) {
                return
              }

              this.setState({
                selectedTab: i
              })
            }}
          >
            {label}
          </TabLink>
        </Tab>
      )
    })

    const { content, hideButtons } = tabs[selectedTab]

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
        {...rest}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <TabList
            danger={dangerSelected}
          >
            {navItems}
          </TabList>
          {loading && <Loading />}
          {!loading && content}
          <Divider />
          {!hideButtons &&
            <ButtonContainer>
              <Button
                label='cancel'
                onClick={onCancel}
                mr='0.5rem'
                ml='0.5rem'
              />
              <Button
                primary
                disabled={disabled}
                label={confirmText}
                onClick={onConfirm}
                mr='0.5rem'
              />
            </ButtonContainer>}
        </Wrapper>
      </Modal>
    )
  }
}

TabbedModal.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.any,
    hideButtons: PropTypes.bool,
    danger: PropTypes.bool,
    hidden: PropTypes.bool
  })),
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  initialTab: PropTypes.number
}

TabbedModal.defaultProps = {
  loading: false,
  isOpen: false,
  disabled: false,
  initialTab: 0,
  tabs: []
}

export default TabbedModal

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fadeInRule } from 'utils/animations'
import { Cross } from 'svg'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  display: ${props => (props.open ? 'flex' : 'none')};
  position: fixed;
  outline: none !important;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 100%;
  z-index: 4;
  display: block;
  overflow-y: auto;
  animation: ${fadeInRule};
  box-shadow: ${props => (props.shadow ? theme.shadow : '')};
`

const CloseContainer = styled.div`
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    stroke: ${theme.colors.black};
    fill: ${theme.colors.black};
    margin: auto;
  }
`

class SidePanel extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      open: props.isOpen
    }
  }

  UNSAFE_componentWillMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isOpen !== this.state.open) {
      this.setState({
        open: nextProps.isOpen
      })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleContentClick = (e) => {
    const { open } = this.state
    if (open) {
      e.stopPropagation()
      return false
    }
    return true
  }

  handleKeyDown = (e) => {
    // If 'escape' is pressed and open
    if (this.state.open && e.keyCode === 27) {
      e.preventDefault()
      this.setState({ open: false })
      this.props.onSidePanelClose()
      return true
    }
    return false
  }

  close = () => {
    this.setState({ open: false })
    this.props.onSidePanelClose()
  }

  render () {
    const {
      content,
      children,
      maxHeight,
      shadow = true,
      left,
      right,
      minWidth,
      maxWidth,
      className,
      marginTop,
      ...rest
    } = this.props

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        tabIndex='0'
        className={className}
        minWidth={minWidth}
        maxWidth={maxWidth}
        marginTop={marginTop}
        shadow={shadow}
        closeSidePanel={this.close}
        onClick={this.handleContentClick}
        {...rest}
        {...this.state}
      >
        {children && React.cloneElement(children, {
          closeSidePanel: this.close
        })}
        <CloseContainer onClick={this.close}>
          <Cross />
        </CloseContainer>
      </Wrapper>
    )
  }
}

SidePanel.defaultProps = {
  isOpen: false
}

SidePanel.propTypes = {
  height: PropTypes.any,
  maxHeight: PropTypes.any,
  width: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  marginTop: PropTypes.any,
  position: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  dismissOnClick: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSidePanelClose: PropTypes.func
}

export default SidePanel

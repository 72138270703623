import Immutable from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import R from 'utils/ramda'
import TextFieldForm from 'components/TextFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import DropDownFieldForm from 'components/DropDownFieldForm'
import withSession from 'hocs/session'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  padding: 1rem;
  text-align: left;
`

const AdvancedSettingsContainer = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 3px;
  background-color: ${theme.colors.lightYellow}
`

const AdvancedLabel = styled.div`
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${theme.colors.darkYellow};
`

function General ({
  sequence,
  onSettingUpdate,
  session,
  addresses
}) {
  const title = sequence.get('title')
  const shared = sequence.get('shared')
  const disableDedupe = sequence.get('dedupe_disabled')

  const userId = sequence.getIn(['_user', '_id']) || sequence.get('_user')
  const isOwner = session.get('_id') === userId
  const featureFlags = session.get('feature_flags') || session.get('whitelist')

  // Send as variables
  const addressValue = sequence.getIn(['gmail_send_as', 'email'])

  // Build options for alias dropdown
  let addressOptions = Immutable.List([])
  let addressMap = Immutable.Map({})
  if (addresses) {
    addressOptions = addresses.get('data').map((option, i) => {
      return {
        label: option.get('email'),
        value: option.get('email'),
        index: i
      }
    })
    const byEmail = R.groupBy(option => (option.get('email')))
    addressMap = byEmail(addresses.get('data'))
  }

  const showAliases = addressOptions.count() > 0 && featureFlags.includes('sequence_email_settings')
  const showDisableDedupe = featureFlags.includes('sequence_disable_dedupe')

  return (
    <Wrapper>
      <FieldContainer>
        <TextFieldForm
          label='Sequence Name'
          value={title}
          description='Choose a name for your sequence that best describes it. We recommend having very specific sequences with three identifiable characteristics (e.g. Engineers, NYC, Interseller).'
          inputId='title'
          onChange={(value) => {
            onSettingUpdate({ title: value })
          }}
        />
      </FieldContainer>
      {isOwner &&
        <FieldContainer>
          <SwitchFieldForm
            label='Share with team'
            value={(shared === 'rw')}
            description='This sequence is currently private to you. Sharing allows teammates to add contacts and modify steps for this sequence.'
            descriptionOn='This sequence is currently being shared with your teammates. Teammates are allowed to add contacts and modify steps to this sequence.'
            inputId='shared'
            onSave={(value) => {
              if (value) {
                onSettingUpdate({ shared: 'rw' })
              } else {
                onSettingUpdate({ shared: null })
              }
            }}
          />
        </FieldContainer>}
      {(showAliases || showDisableDedupe) &&
        <AdvancedSettingsContainer>
          <AdvancedLabel>ADVANCED SETTINGS</AdvancedLabel>
          {showAliases &&
            <FieldContainer>
              <DropDownFieldForm
                label='Send as'
                description="Set the Gmail alias that your emails get sent as. If this setting is left blank, it will use your email setting's value instead."
                helpUrl='https://help.interseller.io/article/29-changing-the-send-as-email-for-your-account'
                options={addressOptions}
                value={addressValue}
                clearable
                inputId='gmail_send_as'
                onOptionChange={(option) => {
                  const gmailAlias = option
                    ? addressMap.get(option).first().toJS()
                    : null

                  onSettingUpdate({ gmail_send_as: gmailAlias })
                }}
              />
            </FieldContainer>}
          {showDisableDedupe &&
            <FieldContainer>
              <SwitchFieldForm
                label='Deduplication'
                value={!disableDedupe}
                descriptionOn="Turning this option off will disable user, team, and CRM/ATS deduplication. This is useful when you're running a customer-based sequence or marketing sequence."
                description="Contact deduplication is turned off and will not deduplicate across your sequences, your teammate's sequences or your integration."
                inputId='shared'
                onSave={(value) => {
                  onSettingUpdate({ dedupe_disabled: !value })
                }}
              />
            </FieldContainer>}
        </AdvancedSettingsContainer>}
    </Wrapper>
  )
}

General.propTypes = {
  addresses: PropTypes.object,
  sequence: PropTypes.object,
  onSettingUpdate: PropTypes.func,
  session: PropTypes.object
}

export default withSession(General)

import { fromJS } from 'immutable'

import {
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,

  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,

  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,

  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_FAILURE,

  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,

  FETCH_PREVIEW_REQUEST,
  FETCH_PREVIEW_SUCCESS,
  FETCH_PREVIEW_FAILURE,

  FETCH_UPDATE_PREVIEW_REQUEST,
  FETCH_UPDATE_PREVIEW_SUCCESS,
  FETCH_UPDATE_PREVIEW_FAILURE,
  FETCH_UPDATE_PREVIEW_RESET,

  FETCH_CHARGES_REQUEST,
  FETCH_CHARGES_SUCCESS,
  FETCH_CHARGES_FAILURE,

  FETCH_REQUESTS_REQUEST,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_FAILURE,

  FETCH_BILLING_EMAIL_REQUEST,
  FETCH_BILLING_EMAIL_SUCCESS,
  FETCH_BILLING_EMAIL_FAILURE,

  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE,

  ADD_LOOKUPS_REQUEST,
  ADD_LOOKUPS_SUCCESS,
  ADD_LOOKUPS_FAILURE
} from './constants'

const initialArray = {
  data: [],
  loading: false,
  error: null
}

const initialObject = {
  data: null,
  loading: false,
  error: null
}

// The initial state of the App
const initialState = fromJS({
  subscription: initialObject,
  customer: initialObject,
  preview: initialObject,
  updatePreview: initialObject,
  charges: initialArray,
  requests: initialArray,
  billingEmails: initialArray,
  pricing: initialObject
})

function billingReducer (state = initialState, action) {
  switch (action.type) {
    // Fetch subscription
    case FETCH_SUBSCRIPTION_REQUEST:
    case CREATE_SUBSCRIPTION_REQUEST:
    case UPDATE_SUBSCRIPTION_REQUEST:
    case CANCEL_SUBSCRIPTION_REQUEST:
      return state.set('subscription', state.get('subscription').merge({
        loading: true
      }))
    case FETCH_SUBSCRIPTION_SUCCESS:
    case CREATE_SUBSCRIPTION_SUCCESS:
    case UPDATE_SUBSCRIPTION_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return state.set('subscription', fromJS({
        data: action.payload.subscription,
        loading: false,
        error: null
      }))
    case FETCH_SUBSCRIPTION_FAILURE:
    case CREATE_SUBSCRIPTION_FAILURE:
    case UPDATE_SUBSCRIPTION_FAILURE:
    case CANCEL_SUBSCRIPTION_FAILURE:
      return state.set('subscription', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case ADD_LOOKUPS_REQUEST:
      return state.set('subscription', state.get('subscription').merge({
        loading: true
      }))
    case ADD_LOOKUPS_SUCCESS:
      return state.set('subscription', state.get('subscription').merge({
        loading: false,
        error: null
      }))
    case ADD_LOOKUPS_FAILURE:
      return state.set('subscription', state.get('subscription').merge({
        loading: false,
        error: action.err
      }))

    // Fetch customer
    case FETCH_CUSTOMER_REQUEST:
      return state.set('customer', state.get('customer').merge({ loading: true }))
    case FETCH_CUSTOMER_SUCCESS:
      return state.set('customer', fromJS({
        data: action.payload.customer,
        loading: false,
        error: null
      }))
    case FETCH_CUSTOMER_FAILURE:
      return state.set('customer', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch preview
    case FETCH_PREVIEW_REQUEST:
      return state.set('preview', state.get('preview').merge({ loading: true }))
    case FETCH_PREVIEW_SUCCESS: {
      return state.set('preview', fromJS({
        data: action.payload.preview,
        loading: false,
        error: null
      })).set('updatePreview', fromJS({
        data: action.payload.preview,
        loading: false,
        error: null
      }))
    }
    case FETCH_PREVIEW_FAILURE:
      return state.set('preview', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch preview
    case FETCH_UPDATE_PREVIEW_REQUEST:
      return state.set('updatePreview', state.get('updatePreview').merge({ loading: true }))
    case FETCH_UPDATE_PREVIEW_SUCCESS:
      return state.set('updatePreview', fromJS({
        data: action.payload.preview,
        loading: false,
        error: null
      }))
    case FETCH_UPDATE_PREVIEW_FAILURE:
      return state.set('updatePreview', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))
    case FETCH_UPDATE_PREVIEW_RESET:
      return state.set('updatePreview', fromJS({
        data: state.getIn(['preview', 'data']),
        loading: false,
        error: null
      }))

    // Fetch charges
    case FETCH_CHARGES_REQUEST:
      return state.set('charges', state.get('charges').merge({ loading: true }))
    case FETCH_CHARGES_SUCCESS:
      return state.set('charges', fromJS({
        data: action.payload.charges,
        loading: false,
        error: null
      }))
    case FETCH_CHARGES_FAILURE:
      return state.set('charges', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch requests
    case FETCH_REQUESTS_REQUEST:
      return state.set('requests', state.get('requests').merge({ loading: true }))
    case FETCH_REQUESTS_SUCCESS:
      return state.set('requests', fromJS({
        data: action.payload.requests,
        loading: false,
        error: null
      }))
    case FETCH_REQUESTS_FAILURE:
      return state.set('requests', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch billingEmails
    case FETCH_BILLING_EMAIL_REQUEST:
      return state.set('billingEmails', state.get('billingEmails').merge({ loading: true }))
    case FETCH_BILLING_EMAIL_SUCCESS:
      return state.set('billingEmails', fromJS({
        data: action.payload.billingEmails,
        loading: false,
        error: null
      }))
    case FETCH_BILLING_EMAIL_FAILURE:
      return state.set('billingEmails', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch pricing
    case FETCH_PRICING_REQUEST:
      return state.set('pricing', state.get('pricing').merge({ loading: true }))
    case FETCH_PRICING_SUCCESS:
      return state.set('pricing', fromJS({
        data: action.payload.pricing,
        loading: false,
        error: null
      }))
    case FETCH_PRICING_FAILURE:
      return state.set('pricing', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    default:
      return state
  }
}

export default billingReducer

import styled from 'styled-components'
import { space, textAlign } from 'styled-system'
import Badge from 'elements/Badge'
import theme from '../../../../../themes/light'

export const HeaderWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 0;

  display: flex;
  width: 100%;
`

export const Wrapper = styled(HeaderWrapper)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 92px;

  cursor: pointer;
  font: ${theme.fonts.normal};
  background-color: ${props => (props.selected ? theme.highlightBackground : theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  letter-spacing: .025em;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${theme.highlightBackground};
  }
`

export const SequenceColumn = styled.div`
  color: ${theme.titleColor};
  text-align: left;
  padding-left: ${theme.padding};
  padding-right: ${theme.padding};

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SequenceTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  font-weight: 700;
  font-size: 17px;

  line-height: 1.3;
  margin-bottom: 2px;
`

export const ArchiveTitle = styled(SequenceTitle)`
  color: ${theme.colors.gray70};
`

export const SequenceLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.colors.gray70};
  text-align: left;
`

export const SequenceText = styled.div`
  font: ${theme.fonts.normal};
  color: ${theme.titleColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  width: ${props => props.width};
  padding-left: ${theme.padding};
  padding-right: ${theme.padding};

  ${space}
  ${textAlign}
`

export const CheckboxColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 36px;
`

export const ContactErrorsBadge = styled(Badge)`
  background-color: ${theme.colors.red};
  margin-left: 3px;
  margin-right: 0;
  border-radius: 9999px;
  min-width: 25px;
  line-height: 10px;
`

export const ContactTasksBadge = styled(ContactErrorsBadge)`
  background-color: ${theme.colors.blue};
`

export const Column = styled.div`
  display: flex;
  justify-content: center;
`

export const BadgeColumn = styled(Column)`
  width: 100px;
`

export const UserColumn = styled(SequenceText)`
  width: 200px;

  display: flex;
  flex-direction: column;

  line-height: 1.4;
`

const CenteredColumn = styled(SequenceText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const AvatarPhotoColumn = styled(CenteredColumn)`
  padding: 0;
  padding-left: ${theme.padding};
`

const UserText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UserName = styled(UserText)`
  font-weight: 700;
`

export const UserEmail = styled(UserText)`

`

const FixedColumn = styled(CenteredColumn)`
  width: 110px;
`

export const IconColumn = styled(FixedColumn)`
  text-align: center;
  width: 90px;
`

export const ContactNumberColumn = styled(FixedColumn)`
  font: ${theme.fonts.number};
  text-align: center;
  width: 120px;
`

export const StatColumn = styled(FixedColumn)`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const FolderUsersColumn = styled.div`
  width: calc(3rem + 200px);
`

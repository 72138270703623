/**
*
* Badge Element
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../themes/light'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.gray40};

  width: 36px;
  height: 36px;

  ${props => !props.disabled && css`
    cursor: pointer;

    &:hover {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: .8;
      transition: opacity .15s ease-out;
    }
  `};

  ${space}
  ${width}
  ${textAlign}
`

const PlusButton = ({
  color = theme.colors.blue,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <FontAwesomeIcon
        icon={['far', 'filter']}
        color={color}
        transform={{
          size: 12
        }}
      />
    </Wrapper>
  )
}

PlusButton.propTypes = {
  color: PropTypes.string
}

export default PlusButton

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { space, width, height } from 'styled-system'
import styled from 'styled-components'
import DropdownArrow from 'elements/DropdownArrow'
import Modal from 'components/Modal'
import Popover from 'components/Popover'
import { Menu, MenuItem } from 'elements/Menu'
import Button from 'components/Button'
import Label from 'elements/Label'
import { formatHours } from 'utils/strings'
import EditSchedule from './EditSchedule'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: normal;
  margin-top: 1rem;
  padding: 1rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
  cursor: pointer;
  border-radius: 3;
`

const Title = styled.label`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  letter-spacing: 0.05em;
`

const HoursContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 0.5rem;
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
  color: ${theme.labelColor};
  font: ${theme.fonts.normal};
  padding-top: 0;
  overflow: hidden;
  max-width: 90%;
`

const days = [
  {
    label: 'Sunday',
    startingHour: 0,
    index: 0
  },
  {
    label: 'Monday',
    startingHour: 24,
    index: 1
  },
  {
    label: 'Tuesday',
    startingHour: 48,
    index: 2
  },
  {
    label: 'Wednesday',
    startingHour: 72,
    index: 3
  },
  {
    label: 'Thursday',
    startingHour: 96,
    index: 4
  },
  {
    label: 'Friday',
    startingHour: 120,
    index: 5
  },
  {
    label: 'Saturday',
    startingHour: 144,
    index: 6
  }
]

class ScheduleFieldForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      schedule: props.schedule,
      showEditScheduleModal: false,
      editingDay: null
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState({
      schedule: newProps.schedule
    })
  }

  onClearDay = (day) => {
    const dayIndex = day.index
    if (this.state.schedule) {
      const hours = this.state.schedule.filter(hour => (
        hour < dayIndex * 24 || hour > ((dayIndex + 1) * 24) - 1
      )).sort()
      this.setState({
        schedule: hours
      })
      if (this.props.onChange) {
        this.props.onChange(hours)
      }
    }
  }

  onEditDay = (day) => {
    this.setState({
      showEditScheduleModal: true,
      editingDay: day
    })
  }

  render () {
    const {
      label,
      description,
      inputId,
      value,
      onChange,
      onSave,
      ...rest
    } = this.props

    const {
      schedule,
      showEditScheduleModal,
      editingDay
    } = this.state

    const rows = days.map((d) => {
      const hours = formatHours(schedule, d.index)
      return (
        <InnerContainer
          key={d.label}
          onClick={() => {
            this.onEditDay(d)
          }}
        >
          <Label ml='0.5rem'>{d.label}</Label>
          <HoursContainer dangerouslySetInnerHTML={{ __html: hours }} />
          <Popover
            ref={(element) => { this.popover = element }}
            minWidth='7rem'
            marginTop='0.5rem'
            position='right'
            content={
              <Menu>
                <MenuItem onClick={() => this.onEditDay(d)}>
                  Edit
                </MenuItem>
                {hours.length > 0 &&
                  <MenuItem onClick={() => this.onClearDay(d)}>
                    Clear
                  </MenuItem>}
              </Menu>
            }
          >
            <DropdownArrow />
          </Popover>
        </InnerContainer>
      )
    })

    return (
      <Wrapper
        {...rest}
      >
        {label &&
          <Title htmlFor={inputId}>{label}</Title>}
        {description &&
          <Description htmlFor={inputId}>{description}</Description>}
        {rows}
        {onSave &&
          <Button
            full
            primary
            label='Save'
            handleClick={() => {
              onSave(this.state.schedule)
            }}
            mt='1rem'
          />}
        {editingDay &&
          <Modal
            width='700px'
            isOpen={showEditScheduleModal}
            onModalClose={() => {
              this.setState({
                showEditScheduleModal: false,
                editingDay: null
              })
            }}
          >
            <EditSchedule
              day={editingDay}
              schedule={schedule}
              closeModal={() => {
                this.setState({
                  showEditScheduleModal: false,
                  editingDay: null
                })
              }}
              onEditSchedule={(hours) => {
                this.setState({
                  showEditScheduleModal: false,
                  editingDay: null,
                  schedule: hours
                })
                if (onChange) {
                  onChange(hours)
                }
              }}
            />
          </Modal>}
      </Wrapper>
    )
  }
}

ScheduleFieldForm.propTypes = {
  schedule: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func
}

export default ScheduleFieldForm

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import withSession from 'hocs/session'
import Loading from 'components/Loading'
import Title from 'elements/Title'
import NavigationLink from 'components/NavigationLink'
import PauseModal from 'containers/Sequence/components/Contacts/PauseModal'
import { selectMembers } from 'containers/Team/selectors'
import { fetchTeamMembers } from 'containers/Team/actions'
import { selectSequences } from 'containers/Dashboard/selectors'
import { fetchSequences } from 'containers/Dashboard/actions'
import {
  selectTemplates,
  selectContactCompany,
  selectContact,
  selectContactActivity,
  selectContactActivityLoading,
  selectSequence
} from 'containers/Sequence/selectors'
import {
  fetchTemplates,
  deleteTemplate,
  fetchContactCompany,
  fetchContact,
  fetchContactActions,
  updateContact as updateSequenceContact,
  fetchSequence,
  copyContacts,
  updateContacts
} from 'containers/Sequence/actions'
import {
  selectTasks,
  selectRepliedContact,
  selectContactMessages,
  selectContactErrors,
  selectContactErrorsStats,
  selectSentimentScore,
  selectManualTasks,
  selectContactTask,
  selectSequenceTasks,
  selectPauseContactTask
} from './selectors'
import {
  fetchTasks,
  fetchContactMessages,
  updateContactMessage,
  fetchContactErrors,
  fetchContactErrorsStats,
  removeContactErrors,
  removeContact,
  updateContact,
  updateContactErrors,
  fetchContactMessagesReset,
  fetchRepliedContact,
  fetchSentimentScore,
  updateContactSentiment,
  fetchManualTasks,
  fetchContactTask,
  fetchContactTaskReset,
  updateContactTask,
  setPauseContactTask,
  clearPauseContactTask,
  skipManualTask,
  skipManualTasks
} from './actions'
import Wrapper from './Wrapper'
import { withRouter } from 'react-router'

const Container = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8rem;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.maxWidth || 'initial'};
  margin: 0 auto 2rem auto;
  padding-left: ${props => props.maxWidth ? '0' : '2rem'};
  padding-right: ${props => props.maxWidth ? '0' : '2rem'};
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`

class Tasks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTask: null
    }
  }

  render () {
    const {
      location,
      children,
      session,
      actions,
      tasks,
      contacts,
      repliedContact,
      contactMessages,
      contactActivity,
      contactActivityLoading,
      contactErrors,
      contactErrorsStats,
      templates,
      contactCompany,
      selectedContact,
      members,
      sequences,
      sentimentScore,
      manualTasks,
      contactTask,
      sequence,
      params,
      sequenceTasks,
      pauseContactTask
    } = this.props
    const {
      activeTask
    } = this.state

    const loading = false
    if (loading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const isReplies = location.pathname.startsWith('/tasks/replies')
    const isInSequence = location.pathname.startsWith('/sequence/')
    let sequenceId
    if (isInSequence && params && params.id) {
      sequenceId = params.id
    }

    return (
      <Wrapper>
        {!isInSequence &&
          <HeaderContainer maxWidth={isReplies ? '892px' : null}>
            <TitleContainer>
              <Title>Tasks</Title>
            </TitleContainer>
            <NavContainer>
              <NavigationLink
                label='Emails'
                to='/tasks/emails'
              />
              <NavigationLink
                label='Manual'
                to='/tasks/manual'
              />
              <NavigationLink
                label='Need Attention'
                to='/tasks/contacts'
              />
              <NavigationLink
                label='Replies'
                to='/tasks/replies'
              />
            </NavContainer>
          </HeaderContainer>}
        <Content>
          <Container>
            {children && React.cloneElement(children, {
              actions,
              session,
              tasks,
              contacts,
              repliedContact,
              contactMessages,
              contactActivity,
              contactActivityLoading,
              sentimentScore,
              contactErrors,
              contactErrorsStats,
              activeTask,
              templates,
              contactCompany,
              selectedContact,
              members,
              sequences,
              manualTasks,
              contactTask,
              sequence,
              location,
              sequenceId,
              sequenceTasks
            })}
          </Container>
        </Content>

        <PauseModal
          isOpen={pauseContactTask}
          onCancel={() => {
            actions.clearPauseContactTask()
          }}
          onConfirm={(date) => {
            actions.clearPauseContactTask()
            actions.updateContact(pauseContactTask.get('id'),
              { delay_until: date },
              { sequenceId: pauseContactTask.getIn(['_campaign', '_id']) })
          }}
          title='Pause Contact'
          description={`Pause messages and tasks for ${pauseContactTask ? pauseContactTask.get('email') : 'contact'} indefinitely or until a specified date`}
        />
      </Wrapper>
    )
  }
}

Tasks.propTypes = {
  location: PropTypes.object,
  children: PropTypes.object,
  actions: PropTypes.object,
  session: PropTypes.object,
  tasks: PropTypes.object,
  contacts: PropTypes.object,
  repliedContact: PropTypes.object,
  contactMessages: PropTypes.object,
  contactErrors: PropTypes.object,
  contactErrorsStats: PropTypes.object,
  contactActivity: PropTypes.object,
  templates: PropTypes.object,
  contactCompany: PropTypes.object,
  selectedContact: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  sentimentScore: PropTypes.object,
  manualTasks: PropTypes.object,
  contactTask: PropTypes.object,
  sequence: PropTypes.object,
  params: PropTypes.object,
  contactActivityLoading: PropTypes.object,
  sequenceTasks: PropTypes.object,
  pauseContactTask: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  tasks: selectTasks(),
  repliedContact: selectRepliedContact(),
  contactMessages: selectContactMessages(),
  contactErrors: selectContactErrors(),
  contactErrorsStats: selectContactErrorsStats(),
  contactCompany: selectContactCompany(),
  templates: selectTemplates(),
  selectedContact: selectContact(),
  contactActivity: selectContactActivity(),
  contactActivityLoading: selectContactActivityLoading(),
  sequences: selectSequences(),
  members: selectMembers(),
  sentimentScore: selectSentimentScore(),
  manualTasks: selectManualTasks(),
  contactTask: selectContactTask(),
  sequence: selectSequence(),
  sequenceTasks: selectSequenceTasks(),
  pauseContactTask: selectPauseContactTask()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTasks,
      fetchContactMessages,
      fetchTemplates,
      deleteTemplate,
      updateContactMessage,
      fetchContactErrors,
      fetchContactErrorsStats,
      removeContactErrors,
      removeContact,
      updateContact,
      updateContactErrors,
      fetchContactCompany,
      fetchSequences,
      fetchTeamMembers,
      fetchContactMessagesReset,
      fetchContactActions,
      fetchRepliedContact,
      fetchSentimentScore,
      fetchContact,
      updateContactSentiment,
      fetchManualTasks,
      fetchContactTask,
      fetchContactTaskReset,
      updateContactTask,
      updateSequenceContact,
      fetchSequence,
      copyContacts,
      setPauseContactTask,
      clearPauseContactTask,
      skipManualTask,
      updateContacts,
      skipManualTasks
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSession(Tasks)))

import React from 'react'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { space, width } from 'styled-system'
import { isToday } from 'date-fns'
import StatusText from 'components/StatusText'
import Checkbox from 'elements/Checkbox'
import Verified from 'elements/Verified'
import Warning from 'elements/Warning'
import Error from 'elements/Error'
import Label from 'elements/Label'
import Unknown from 'elements/Unknown'
import ContactOptions from '../ContactOptions'
import { Steps, Dots } from 'svg'
import { timeInFutureDay } from 'utils/strings'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  cursor: pointer;
  font: ${theme.fonts.normal};
  background-color: ${props => (props.selected ? theme.highlightBackground : theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: solid 1px ${theme.borderColor};
  letter-spacing: .025em;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: ${theme.highlightBackground};
  }
`

const ContactDetail = styled.div`
  height: 100%;
  color: ${theme.titleColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  max-width: ${props => props.maxWidth};
  padding-left: ${theme.padding};
  padding-right: ${theme.padding};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  ${space}
  ${width}
`

const Filler = styled.div`
  height: 100%;
  flex: 1 0 auto;
`

const AlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`

const CheckboxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1.5rem;

  ${space}
  ${width}
`

const UserAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const TitleContainer = styled(Label)`
  padding: 0 3px;
  margin-left: 1rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.anonymized ? theme.labelColor : theme.titleColor};
`

const ContactDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: relative;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem 0;
`

export default function contactRowRenderer ({
  className,
  rowData,
  onClick,
  style,
  router,
  session,
  sequence,
  selected,
  onSelected,
  totalSteps,
  onClearError,
  onUpdateContact,
  onCustomizeMessages,
  onRepliedContactUpdate,
  onOpenInGmail,
  onReportContactInaccuracy,
  onPauseContact,
  onUnsubscribeContact,
  onBlockContact,
  onAssignSentiment,
  onCloneContact,
  onCompanyClick,
  onStepClick,
  onResyncCrm,
  onDelete,
  onMove,
  onCopy,
  onSendNow,
  onJumpToTask,
  isScrolling,
  crm,
  isArchived
}) {
  const contact = rowData
  const a11yProps = {}

  const name = contact.get('name')
  const currentStep = contact.get('step') === undefined ? -1 : contact.get('step')
  const validity = contact.get('validity')
  const photoUrl = contact.get('image_url')
  const anonymized = contact.get('anonymized')

  let validMessage
  let validElement

  if (validity === 'GOOD') {
    validMessage = 'Email verified and deliverable'
    validElement = <Verified />
  } else if (validity === 'RISKY') {
    validMessage = 'Email is deliverable but not verified'
    validElement = <Warning />
  } else if (validity === 'INVALID' || validity === 'REJECTED') {
    validMessage = 'Email is invalid and will probably bounce'
    validElement = <Error />
  } else if (validity === 'UNKNOWN') {
    validMessage = 'Email could not be verified'
    validElement = <Unknown />
  }

  const sequenceActive = sequence.get('active')
  const sequenceUserId = sequence.getIn(['_user', 'id'])

  const company = contact.get('company')
  const domain = contact.get('domain')
  const title = contact.get('title')
  const nextMessageAt = contact.get('next_message_at')
  const nextMessageIsToday = isToday(nextMessageAt)

  const contactFinished = contact.get('finished')
  const hasStepsToSend = (currentStep + 1) < totalSteps
  const hasCrm = !!crm.getIn(['data', 'type'])

  const needsAttention = contact.get('needs_manual_attention')

  const timeTooltip = nextMessageIsToday ? 'today' : timeInFutureDay(nextMessageAt)

  let stepTooltip
  if (sequenceActive && hasStepsToSend && !contactFinished) {
    if (nextMessageAt) {
      if (needsAttention === 'message') {
        stepTooltip = `Next personalized step is scheduled for ${timeTooltip}`
      } else if (needsAttention === 'task') {
        stepTooltip = `Next task is scheduled for ${timeTooltip}`
      } else {
        stepTooltip = `Next message is scheduled for ${timeTooltip}`
      }
    } else if (contact.get('state') !== 'error') {
      stepTooltip = 'Messages are paused until errors are cleared'
    }
  }

  const stepsElement = (
    <Steps
      contact={contact}
      current={currentStep + 1}
      total={totalSteps}
    />
  )

  if (isScrolling) {
    return (
      <Wrapper
        selected={selected}
        {...a11yProps}
        className={className}
        key={`contact_row_${contact.get('_id')}`}
        role='row'
        style={style}
      >
        <CheckboxContainer>
          <Checkbox
            selected={selected}
          />
        </CheckboxContainer>
        <ContactDetail width='30%'>
          <AlignCenter>
            <Avatar
              email={photoUrl ? null : contact.get('email')}
              maxInitials={2}
              src={photoUrl}
              name={name}
              size={38}
              textSizeRatio={2}
              round
              colors={theme.avatarColors}
              style={{ filter: `${anonymized ? 'blur(4px)' : 'none'}` }}
            />
            <ContactDetailContainer>
              <TitleContainer
                anonymized={anonymized}
                style={{ marginLeft: '1rem', fontWeight: 700 }}
              >
                {name}
              </TitleContainer>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <TitleContainer
                  style={{
                    marginLeft: '1rem',
                    marginRight: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {!anonymized ? contact.get('email') : ''}
                </TitleContainer>
                {!anonymized &&
                  <span style={{ marginTop: '2px' }}>
                    {validElement}
                  </span>}
              </div>
            </ContactDetailContainer>
          </AlignCenter>
        </ContactDetail>
        <ContactDetail width='20%'>
          <AlignCenter>
            <StatusText
              contact={contact}
              crm={crm}
              readOnly={isArchived}
              portal
            />
          </AlignCenter>
          <Filler />
        </ContactDetail>
        <ContactDetail width='30%'>
          <AlignCenter>
            <UserAvatar
              src={(domain && !anonymized) ? `https://logo.clearbit.com/${domain}` : null}
              name={!anonymized ? company : ''}
              size={38}
              textSizeRatio={2.5}
              maxInitials={2}
              round
              colors={theme.avatarColors}
              style={{ filter: `${anonymized ? 'blur(4px)' : 'none'}` }}
            />
            <ContactDetailContainer>
              <TitleContainer
                anonymized={anonymized}
                color={theme.titleColor}
                style={{ marginLeft: '1rem', fontWeight: 700 }}
              >
                {company}
              </TitleContainer>
              <TitleContainer>
                {title}
              </TitleContainer>
            </ContactDetailContainer>
          </AlignCenter>
        </ContactDetail>
        <ContactDetail width='10%'>
          <StepContainer>
            {stepsElement}
          </StepContainer>
        </ContactDetail>
        <ContactDetail width='5%'>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <CaretContainer><Dots /></CaretContainer>
          </div>
        </ContactDetail>
      </Wrapper>
    )
  }

  return (
    <Wrapper
      selected={selected}
      {...a11yProps}
      className={className}
      key={`contact_row_${contact.get('_id')}`}
      role='row'
      style={style}
    >
      <CheckboxContainer onClick={onSelected}>
        <Checkbox
          selected={selected}
          handleClick={onSelected}
        />
      </CheckboxContainer>
      <ContactDetail onClick={onClick} width='30%'>
        <AlignCenter>
          <Avatar
            email={photoUrl ? null : contact.get('email')}
            maxInitials={2}
            src={photoUrl}
            name={name}
            size={38}
            textSizeRatio={2}
            round
            colors={theme.avatarColors}
            style={{ filter: `${anonymized ? 'blur(4px)' : 'none'}` }}
          />
          <ContactDetailContainer>
            <TitleContainer
              anonymized={anonymized}
              style={{ marginLeft: '1rem', fontWeight: 700 }}
            >
              {name}
            </TitleContainer>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <TitleContainer
                style={{
                  marginLeft: '1rem',
                  marginRight: '5px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {!anonymized ? contact.get('email') : ''}
              </TitleContainer>
              {validElement && !anonymized && (
                <Tooltip
                  title={validMessage}
                  position='top'
                  trigger='mouseenter'
                  style={{ marginTop: '2px' }}
                  arrow
                >
                  {validElement}
                </Tooltip>)}
            </div>
          </ContactDetailContainer>
        </AlignCenter>
      </ContactDetail>
      <ContactDetail width='20%'>
        <AlignCenter>
          <StatusText
            session={session}
            sequenceUserId={sequenceUserId}
            contact={contact}
            crm={crm}
            onCustomizeMessages={onCustomizeMessages}
            onUpdateContact={onUpdateContact}
            onClearError={onClearError}
            onRepliedContactUpdate={onRepliedContactUpdate}
            onOpenInGmail={onOpenInGmail}
            onReportContactInaccuracy={onReportContactInaccuracy}
            onPauseContact={onPauseContact}
            onUnsubscribeContact={onUnsubscribeContact}
            onCloneContact={onCloneContact}
            onBlockContact={onBlockContact}
            onAssignSentiment={onAssignSentiment}
            onJumpToTask={onJumpToTask}
            portal
            readOnly={isArchived}
          />
        </AlignCenter>
        <Filler onClick={onClick} />
      </ContactDetail>
      <ContactDetail onClick={onCompanyClick} width='30%'>
        <AlignCenter>
          <UserAvatar
            src={(domain && !anonymized) ? `https://logo.clearbit.com/${domain}` : null}
            name={!anonymized ? company : ''}
            size={38}
            textSizeRatio={2.5}
            maxInitials={2}
            round
            colors={theme.avatarColors}
            style={{ filter: `${anonymized ? 'blur(4px)' : 'none'}` }}
          />
          <ContactDetailContainer>
            <TitleContainer
              anonymized={anonymized}
              style={{ marginLeft: '1rem', fontWeight: 700 }}
            >
              {company}
            </TitleContainer>
            <TitleContainer>
              {title}
            </TitleContainer>
          </ContactDetailContainer>
        </AlignCenter>
      </ContactDetail>
      <ContactDetail onClick={onStepClick} width='10%'>
        {stepTooltip &&
          <Tooltip
            title={stepTooltip}
            position='top'
            trigger='mouseenter'
            arrow
          >
            <StepContainer>
              {stepsElement}
            </StepContainer>
          </Tooltip>}
        {!stepTooltip &&
          <StepContainer>
            {stepsElement}
          </StepContainer>}
      </ContactDetail>
      <ContactDetail width='5%'>
        <ContactOptions
          trigger={<Dots />}
          router={router}
          sequence={sequence}
          contact={contact}
          crm={crm}
          contactFinished={contactFinished}
          hasStepsToSend={hasStepsToSend}
          hasCrm={hasCrm}
          isArchived={isArchived}
          onSendNow={onSendNow}
          onMoveContact={onMove}
          onCopyContact={onCopy}
          onDeleteContact={onDelete}
          onResyncCrm={onResyncCrm}
          onUnsubscribeContact={onUnsubscribeContact}
          onBlockContact={onBlockContact}
          onReportContactInaccuracy={onReportContactInaccuracy}
        />
      </ContactDetail>
    </Wrapper>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from 'elements/Menu'
import Popper from 'components/Popper'

function ContactOptions ({
  trigger,
  router,
  sequence,
  contact,
  crm,
  contactFinished,
  hasStepsToSend,
  hasCrm,
  isArchived,
  onSendNow,
  onMoveContact,
  onCopyContact,
  onDeleteContact,
  onResyncCrm,
  onUnsubscribeContact,
  onBlockContact,
  onReportContactInaccuracy
}) {
  return (
    <Popper
      position='bottom-end'
      portal
      trigger={trigger}
    >
      {close => (
        <Menu style={{ minWidth: '10rem' }} onClick={close}>
          {!contactFinished && hasStepsToSend && !isArchived &&
            <MenuItem
              onClick={onSendNow}
            >
              Send Now
            </MenuItem>}
          {!contactFinished && hasStepsToSend && !isArchived &&
            <MenuItem
              onClick={() => {
                router.push(`/sequence/${sequence.get('_id')}/preview?contactId=${contact.get('_id')}&customize=true`)
              }}
            >
              Customize Messages
            </MenuItem>}
          {!isArchived &&
            <MenuItem
              onClick={onMoveContact}
            >
              Move Contact
            </MenuItem>}
          <MenuItem
            onClick={() => {
              onCopyContact(contact)
            }}
          >
            Copy Contact
          </MenuItem>
          {!isArchived &&
            <MenuItem
              onClick={onDeleteContact}
            >
              Delete Contact
            </MenuItem>}
          {hasCrm &&
            <MenuItem
              onClick={() => {
                onResyncCrm([contact.get('id')])
              }}
            >
              Resync to {crm?.getIn(['data', 'name']) || 'CRM / ATS'}
            </MenuItem>}
          <MenuItem
            onClick={() => {
              onUnsubscribeContact(contact)
            }}
          >
            Unsubscribe Contact
          </MenuItem>
          <MenuItem
            onClick={() => {
              onBlockContact(contact)
            }}
          >
            Block {contact.get('email_domain')}
          </MenuItem>
          {contact.get('request_hash_valid') &&
            <MenuItem
              onClick={() => {
                onReportContactInaccuracy(contact)
              }}
            >
              Report Incorrect Email
            </MenuItem>}
        </Menu>
      )}
    </Popper>
  )
}

ContactOptions.propTypes = {
  trigger: PropTypes.object,
  router: PropTypes.object,
  sequence: PropTypes.object,
  contact: PropTypes.object,
  crm: PropTypes.object,
  contactFinished: PropTypes.bool,
  hasStepsToSend: PropTypes.bool,
  hasCrm: PropTypes.bool,
  isArchived: PropTypes.bool,
  onSendNow: PropTypes.func,
  onMoveContact: PropTypes.func,
  onCopyContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onResyncCrm: PropTypes.func,
  onUnsubscribeContact: PropTypes.func,
  onBlockContact: PropTypes.func,
  onReportContactInaccuracy: PropTypes.func
}

export default ContactOptions

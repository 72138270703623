import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import Diamond from 'svg/Diamond'
import theme from '../../../../../themes/light'

const SentimentList = styled.div`
  position: relative;
  display: inline-block;
  float: left;
  background-color: ${theme.colors.white};
  padding: 1rem;
  width: 36rem;
  border: 1px solid ${theme.borderColor};
`

const SentimentButton = styled.div`
  font: ${theme.fonts.button};
  color: ${props => props.isSelected
    ? (props.danger ? theme.colors.red : theme.colors.lightBlue)
    : theme.colors.blueGray
  };

  border: 2px solid
    ${props => props.isSelected
      ? (props.danger ? theme.colors.red : theme.colors.lightBlue)
      : theme.colors.blueGray
    };

  padding: 0.5rem 1.5rem;
  border-radius: 35px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0.5rem;
  cursor: pointer;
  user-select: none;
`

const IconWrapper = styled.div`
  display: inline-block;
  padding-left: 1rem;
  transform: translateY(-2px);
`

class SentimentSuggestions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.sentimentClassifierRef = React.createRef()
    this.preventDefaults = this.preventDefaults.bind(this)
    // this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    const prevProps = this.props

    const prevSelectedSentimentIndex = prevProps.selectedSentimentIndex
    const nextSelectedSentimentIndex = nextProps.selectedSentimentIndex
    const prevSentimentGuessIndex = prevProps.sentimentGuessIndex
    const nextSentimenetGuessIndex = nextProps.sentimentGuessIndex

    if (nextSelectedSentimentIndex && prevSelectedSentimentIndex !== nextSelectedSentimentIndex) {
      this.setState({ selectedSentimentIndex: nextProps.selectedSentimentIndex })
    }

    if (nextSentimenetGuessIndex && prevSentimentGuessIndex !== nextSentimenetGuessIndex) {
      this.setState({ sentimentGuessIndex: nextProps.sentimentGuessIndex })
    }
  }

  componentDidMount () {
    this.mountKeyListeners()
  }

  componentDidUpdate () {
    this.mountKeyListeners()
  }

  mountKeyListeners = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const keyListener = ReactDOM.findDOMNode(this.sentimentClassifierRef.current)
    if (keyListener) {
      keyListener.addEventListener('keydown', this.preventDefaults, false)
      // keyListener.addEventListener('keydown', this.handleKeyDown, false)
    }
  }

  preventDefaults = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleOnClick = (event, index) => {
    event.preventDefault()
    this.setState({ selectedSentimentIndex: index })
    this.props.setSentimentState(index)
  }

  mapPropsToState = (props) => {
    const {
      selectedSentimentIndex,
      sentimentGuessIndex
    } = props

    let selectedIndex = null
    if (selectedSentimentIndex === null) {
      selectedIndex = null
    }

    return {
      selectedSentimentIndex: selectedIndex,
      sentimentGuessIndex
    }
  }

  renderSenitmentButtons = () => {
    const { sentimentValues, sentimentGuessIndex, selectedSentimentIndex } = this.props

    const sentimentButtons = sentimentValues.map((value, index) => {
      const label = value
        .toLowerCase()
        .split('_')
        .join(' ')

      if (label === 'skip') {
        // eslint-disable-next-line array-callback-return
        return
      }

      let danger = false
      if (label === 'unsubscribe') {
        danger = true
      }

      return (
        <SentimentButton
          key={index}
          danger={danger}
          isSelected={selectedSentimentIndex === index}
          onClick={(event) => {
            this.handleOnClick(event, index)
          }}
        >
          {label}
          {sentimentGuessIndex === index &&
            <IconWrapper>
              <Tooltip
                title='Our AI has chosen this based on the reply'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <Diamond
                  width='12px'
                  height='12px'
                />
              </Tooltip>
            </IconWrapper>}
        </SentimentButton>
      )
    })

    return (
      <SentimentList ref={this.sentimentClassifierRef}>
        {sentimentButtons}
      </SentimentList>
    )
  }

  render () {
    return this.renderSenitmentButtons()
  }
}

SentimentSuggestions.propTypes = {
  sentimentValues: PropTypes.array,
  selectedSentimentIndex: PropTypes.number,
  sentimentGuessIndex: PropTypes.number,
  setSentimentState: PropTypes.func
}

export default SentimentSuggestions

import styled from 'styled-components'
import Label from 'elements/Label'
import theme from '../../../../../../../themes/light'

const ErrorContainer = styled.div`
  padding: .5rem 0 .75rem;
  width: 100%;
  display: table;
`

const ErrorRow = styled.div`
  user-select: none;
  padding: .5rem 2rem;
  width: 100%;
  display: table-row;
  border-bottom: ${props => !(props.last)
    ? `solid 1px ${theme.colors.gray20}`
    : '0'
  };
`

const ErrorRowCell = styled.div`
  padding: .5rem 0;
  display: table-cell;
  vertical-align: middle;
`

const ErrorRowDivider = styled(ErrorRowCell)`
  padding: 0 .75rem;
`

const ErrorRowStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ErrorLabel = styled(Label)`
  cursor: ${props => !props.disabled
    ? 'pointer'
    : 'auto'
  };

  margin: 0 2rem 0 1rem;
  white-space: nowrap;
  text-transform: uppercase;
  font: ${theme.fonts.formtitle};
  color: ${props => (props.disabled)
      ? theme.colors.gray50
      : theme.colors.darkBlue
  };
`

export {
  ErrorContainer,
  ErrorRow,
  ErrorRowCell,
  ErrorRowDivider,
  ErrorRowStatus,
  ErrorLabel
}

import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popover from 'components/Popover'
import { Menu, MenuItem } from 'elements/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  flex: 0 1 auto;
  background: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
  height: 100%;
  padding: 0.7rem 1rem 0.7rem 0;
`

const NavTitle = styled.h1`
  color: ${theme.colors.darkGray};
  font: ${theme.fonts.normal};
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
  letter-spacing: .05em;
  text-transform: uppercase;
`

const NavContainer = styled.div`
  flex: 0 1 auto;
  background: #FFF;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.2rem;
  padding-right: 0.7rem;
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-color: ${theme.colors.gray60};
  border-radius: 80px;
`

class SectionNav extends PureComponent {
  sectionTitle = (pathname) => {
    if (pathname.startsWith('/settings') || pathname.startsWith('/integrations')) {
      return 'Settings'
    } else if (pathname.startsWith('/team')) {
      return 'Team'
    } else if (pathname.startsWith('/tasks')) {
      return 'Tasks'
    } else if (pathname.startsWith('/reports')) {
      return 'Reports'
    } else if (pathname.startsWith('/billing')) {
      return 'Billing'
    } else if (pathname.startsWith('/templates')) {
      return 'Templates'
    } else if (pathname.startsWith('/talent_pool')) {
      return 'Talent Pool'
    }

    return 'Sequences'
  }

  render () {
    const { router, location, session } = this.props
    const navTitle = this.sectionTitle(location.pathname)
    const featureFlags = session?.get('feature_flags') || session?.get('whitelist')

    return (
      <Wrapper>
        <Popover
          ref={(element) => { this.popover = element }}
          minWidth='8.8rem;'
          width='100%'
          marginTop='8px'
          content={
            <Menu>
              <MenuItem onClick={() => {
                router.push('/')
              }}
              >
                Sequences
              </MenuItem>
              <MenuItem onClick={() => {
                router.push('/reports')
              }}
              >
                Reports
              </MenuItem>

              <MenuItem onClick={() => {
                router.push('/tasks')
              }}
              >
                Tasks
              </MenuItem>
              <MenuItem onClick={() => {
                router.push('/templates')
              }}
              >
                Templates
              </MenuItem>
              {featureFlags?.includes('talent_pool') &&
                <MenuItem onClick={() => {
                  router.push('/talent_pool')
                }}
                >
                  Talent Pool
                </MenuItem>}
            </Menu>
          }
        >
          <NavContainer>
            <NavTitle>
              {navTitle}
            </NavTitle>
            <FontAwesomeIcon
              size='lg'
              icon={['fal', 'angle-down']}
              color={theme.colors.gray70}
              style={{
                width: '20px',
                height: '20px',
                margin: '1.25px 0 0 0.3rem'
              }}
            />
          </NavContainer>
        </Popover>
      </Wrapper>
    )
  }
}

SectionNav.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
  session: PropTypes.object
}

export default withRouter(SectionNav)

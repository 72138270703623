import styled from 'styled-components'
import theme from '../../themes/light'

export default styled.div`
  background: ${theme.background};
  -webkit-app-region: drag;
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`

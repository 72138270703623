import {
  FETCH_EMAIL_DISCOVERY_REQUEST,
  FETCH_EMAIL_DISCOVERY_SUCCESS,
  FETCH_EMAIL_DISCOVERY_FAILURE,
  FETCH_EMAIL_DISCOVERY_RESET,

  FETCH_EMAIL_LINK_REQUEST,
  FETCH_EMAIL_LINK_SUCCESS,
  FETCH_EMAIL_LINK_FAILURE
} from './constants'

export function fetchEmailDiscovery ({ email }) {
  return {
    type: FETCH_EMAIL_DISCOVERY_REQUEST,
    email
  }
}

export function fetchEmailDiscoverySuccess ({ discovery, email }) {
  return {
    type: FETCH_EMAIL_DISCOVERY_SUCCESS,
    payload: {
      discovery,
      email
    }
  }
}

export function fetchEmailDiscoveryFailure (err) {
  return {
    type: FETCH_EMAIL_DISCOVERY_FAILURE,
    err
  }
}

export function fetchEmailDiscoveryReset () {
  return {
    type: FETCH_EMAIL_DISCOVERY_RESET
  }
}

export function fetchEmailLink ({ email }) {
  return {
    type: FETCH_EMAIL_LINK_REQUEST,
    email
  }
}

export function fetchEmailLinkSuccess (payload) {
  return {
    type: FETCH_EMAIL_LINK_SUCCESS,
    payload
  }
}

export function fetchEmailLinkFailure (err) {
  return {
    type: FETCH_EMAIL_LINK_FAILURE,
    err
  }
}

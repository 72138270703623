import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import GraphTooltip from './components/GraphTooltip'
import 'react-toggle/style.css'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from 'recharts'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background-color: ${theme.colors.white};
`

const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  padding: 1.5rem 1.5rem 0.75rem 1rem;

  .recharts-cartesian-axis-tick-line {
    display: none;
  }
`

const GraphSizingContainer = styled.div`
  flex: 1;
  width: 0;
`

class Graph extends React.Component {
  render () {
    const {
      graphData,
      unitLabel,
      unitLabelPlural,
      selectedDateRange,
      xAxisDomain,
      xAxisDomainType,
      yAxisDomain,
      yAxisDomainType
    } = this.props

    if (!graphData || graphData.length < 1) {
      return (<></>)
    }

    const graphValues = graphData[selectedDateRange]

    return (
      <Wrapper>
        <GraphContainer>
          <GraphSizingContainer>
            <ResponsiveContainer>
              <ComposedChart data={graphValues}>
                <CartesianGrid
                  stroke={theme.colors.gray30}
                  vertical={false}
                />
                <XAxis
                  dataKey='date'
                  tick={{ stroke: theme.colors.gray70, fill: theme.colors.gray70, strokeWidth: 0, fontSize: '13px', fontWeight: 300 }}
                  axisLine={{ stroke: theme.colors.gray40 }}
                  type={xAxisDomainType}
                  domain={xAxisDomain}
                />
                <YAxis
                  width={30}
                  tick={{ stroke: theme.colors.gray70, fill: theme.colors.gray70, strokeWidth: 0, fontSize: '13px', fontWeight: 300 }}
                  axisLine={{ stroke: theme.colors.gray40, strokeWidth: 1 }}
                  type={yAxisDomainType}
                  domain={yAxisDomain}
                />
                <Tooltip
                  cursor={false}
                  animationDuration={0}
                  content={
                    <GraphTooltip
                      unitLabel={unitLabel}
                      unitLabelPlural={unitLabelPlural}
                      dateRange={selectedDateRange}
                    />
                    }
                />

                {this.props.children}

              </ComposedChart>
            </ResponsiveContainer>
          </GraphSizingContainer>
        </GraphContainer>
      </Wrapper>
    )
  }
}

Graph.propTypes = {
  graphData: PropTypes.object,
  unitLabel: PropTypes.string,
  unitLabelPlural: PropTypes.string,
  selectedDateRange: PropTypes.string,
  yAxisDomain: PropTypes.array,
  xAxisDomain: PropTypes.array,
  yAxisDomainType: PropTypes.string,
  xAxisDomainType: PropTypes.string,
  children: PropTypes.any
}

export default Graph

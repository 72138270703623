import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import NumberFormat from 'react-number-format'
import {
  Column,
  AutoSizer,
  Table,
  SortIndicator
} from 'react-virtualized'

import Loading from 'components/Loading'
import { FullContainer } from 'containers/Reports/styles'
import {
  CONTACT_REPORTS,
  PERSONALIZED_CONTACT_REPORTS
} from 'containers/Reports/constants'
import theme from '../../../../../themes/light'

const TableContainer = styled.div`
  /* background-color: white; */

  .ReactVirtualized__Table { }

  .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1rem;
    text-align: left;
  }

  .ReactVirtualized__Table__headerColumn:last-of-type, .ReactVirtualized__Table__rowColumn:last-of-type {
    margin-right: 1rem;
  }

  .ReactVirtualized__Table__headerRow {
    border: 1px solid ${theme.colors.gray30};
    background-color: ${theme.colors.gray10};
    color: ${theme.colors.darkBlue};
    font-size: 15px;
    text-transform: capitalize;
    text-align: left;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    background-color: white;
    padding: 0;
    color: ${theme.colors.darkBlue};

    border: 1px solid ${theme.colors.gray30};
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid ${theme.colors.gray30};
    }
  }
`

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: ${theme.colors.darkGray};
  font-size: 18px;
  font: ${theme.fonts.normal};
`

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const UserCell = styled.div`
  display: flex;
  color: ${theme.colors.darkGray};
`

const UserDetails = styled.div`
  flex: 1;
  width: 0;
  font: ${theme.fonts.normal};
  margin-left: 0.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const UserDetailName = styled.div`
  font: ${theme.fonts.formtitle};
  color: ${theme.colors.darkBlue};
`

const Badge = styled.span`
  background-color: ${theme.background};
  border-radius: 3px;
  font-size: .8rem;
  margin-left: 5px;
  padding: 0.5px 4px;
`

class ContactPerformanceReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  formatTableData = () => {
    const {
      users,
      reports,
      showPersonalizedContactPerformance,
      personalizedMessagesReports
    } = this.props

    let { sortBy, sortDirection } = this.state
    const tableRows = []

    if (showPersonalizedContactPerformance) {
      const columnNames = PERSONALIZED_CONTACT_REPORTS

      users
        .get('data')
        .filter(user => {
          return personalizedMessagesReports.hasIn(['data', 'users', user.get('id')])
        })
        .forEach(user => {
          // pull data from the `team report` and `contrast team report`
          const report = personalizedMessagesReports.getIn(['data', 'users', user.get('id')]) || Immutable.Map()

          // `newRow` represents each row that get's added to the table
          // add the `team report` and `contrast team report` data to the `newRow`
          const newRow = { user, userName: user.get('full_name') }
          columnNames.forEach(columnName => {
            const total = report.get(columnName) || Immutable.Map({})
            newRow[`report_${columnName}`] = total.hasIn(['total', 'count']) ? total.getIn(['total', 'count']) : total.get('total')
          })

          tableRows.push(newRow)
        })
    } else {
      const columnNames = CONTACT_REPORTS

      users
        .get('data')
        .filter(user => {
          return reports.hasIn(['data', user.get('id')])
        })
        .forEach(user => {
          // pull data from the `team report` and `contrast team report`
          const report = reports.getIn(['data', user.get('id')]) || Immutable.Map()

          // `newRow` represents each row that get's added to the table
          // add the `team report` and `contrast team report` data to the `newRow`
          const newRow = { user, userName: user.get('full_name') }
          columnNames.forEach(columnName => {
            const total = report.get(columnName) || Immutable.Map({})
            newRow[`report_${columnName}`] = total.hasIn(['total', 'count']) ? total.getIn(['total', 'count']) : total.get('total')
          })

          tableRows.push(newRow)
        })
    }

    if (sortBy) {
      if (sortBy === 'user') {
        sortBy = 'userName'
      }

      let sort = Immutable.List(tableRows)
      sort = sort.sortBy(row => row[sortBy])

      if (sortDirection === 'DESC') {
        sort = sort.reverse()
      }

      return sort.toArray()
    }
    return tableRows
  }

  _numberCellRenderer = ({ dataKey, rowData }) => {
    const { showPersonalizedContactPerformance } = this.props

    const total = showPersonalizedContactPerformance
      ? rowData.report_customized_contacts_messaged
      : rowData.report_contacts_messaged
    const reportData = rowData[dataKey] || 0
    const showPct = dataKey !== 'report_contacts' &&
      dataKey !== 'report_contacts_messaged' &&
      dataKey !== 'report_customized_contacts_messaged' &&
      total > 0

    return (
      <ColumnGroup>
        <RowGroup>
          <NumberFormat
            value={reportData}
            displayType='text'
            thousandSeparator=','
            style={{ fontWeight: '300', fontSize: '18px' }}
          />
          {showPct &&
            <Badge>
              <NumberFormat
                value={Math.abs(reportData / total * 100)}
                suffix='%'
                displayType='text'
                thousandSeparator=','
                decimalScale='1'
                style={{ }}
              />
            </Badge>}
        </RowGroup>
      </ColumnGroup>
    )
  }

  _userCellRenderer = ({ cellData }) => {
    return (
      <UserCell>
        <Avatar
          key={`user_${cellData.get('id')}`}
          src={cellData.get('photo_url' || '/bear_avatar.png')}
          size={44}
          round
        />
        <UserDetails>
          <UserDetailName>{cellData.get('full_name')}</UserDetailName>
          <div>{cellData.get('email')}</div>
        </UserDetails>
      </UserCell>
    )
  }

  _headerRenderer ({ label, dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {label}

        {// indicator displaying which way a column is sorted
          sortBy === dataKey &&
            <SortIndicator sortDirection={sortDirection} />
}
      </div>
    )
  }

  sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortBy,
      sortDirection
    })
  }

  render () {
    const {
      users,
      reports,
      showPersonalizedContactPerformance
    } = this.props
    const { sortBy, sortDirection } = this.state

    if (!users || !reports || users.get('loading') || reports.get('loading')) {
      return <Loading width='100%' />
    }

    const tableRows = this.formatTableData()
    const tableColumnWidth = 140

    return (
      <FullContainer>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              const firstColumnWidth = Math.max(tableColumnWidth, width - (tableColumnWidth * 3.5))

              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={70}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    key='user'
                    dataKey='user'
                    label='User'
                    width={firstColumnWidth}
                    cellRenderer={this._userCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                  {!showPersonalizedContactPerformance &&
                    <Column
                      key='Added'
                      dataKey='report_contacts'
                      label='Added'
                      width={tableColumnWidth}
                      cellRenderer={this._numberCellRenderer}
                      headerRenderer={this._headerRenderer}
                    />}

                  <Column
                    key='Messaged'
                    dataKey={showPersonalizedContactPerformance
                      ? 'report_customized_contacts_messaged'
                      : 'report_contacts_messaged'}
                    label='Messaged'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Clicked'
                    dataKey={showPersonalizedContactPerformance
                      ? 'report_customized_contacts_messaged_opened'
                      : 'report_contacts_messaged_opened'}
                    label='Opened'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Replied'
                    dataKey={showPersonalizedContactPerformance
                      ? 'report_customized_contacts_messaged_replied'
                      : 'report_contacts_messaged_replied'}
                    label='Replied'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Bounced'
                    dataKey={showPersonalizedContactPerformance
                      ? 'report_customized_contacts_messaged_booked'
                      : 'report_contacts_messaged_booked'}
                    label='Booked'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                  <Column
                    key='Bounced'
                    dataKey={showPersonalizedContactPerformance
                      ? 'report_customized_contacts_messaged_bounced'
                      : 'report_contacts_messaged_bounced'}
                    label='Bounced'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </FullContainer>
    )
  }
}

ContactPerformanceReport.propTypes = {
  actions: PropTypes.object,
  reports: PropTypes.object,
  users: PropTypes.object,
  showPersonalizedContactPerformance: PropTypes.bool,
  personalizedMessagesReports: PropTypes.object
}

export default ContactPerformanceReport

import React, { Component } from 'react'
import { Link } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextFieldForm from 'components/TextFieldForm'
import DropDownFieldForm from 'components/DropDownFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Loading from 'components/Loading'
import Label from 'elements/Label'
import Subtitle from 'elements/Subtitle'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

class Safety extends Component {
  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props
    actions.fetchBlocklist()
  }

  render () {
    const {
      session,
      onSettingUpdate,
      blocklist,
      actions
    } = this.props

    const loading = blocklist.get('loading')

    if (loading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const domainSafety = session.get('domain_safety')
    const domainReply = session.get('domain_reply')
    const contactSafety = session.get('contact_safety') || 'OFF'
    const contactSafetyDays = session.get('contact_safety_days')
    const blocklistString = blocklist.get('data').join('\n')

    const options = Immutable.List([
      {
        value: 'OFF',
        label: 'Off - Do not deduplicate'
      },
      {
        value: 'MESSAGING',
        label: 'Low - Contact is being actively messaged'
      },
      {
        value: 'MESSAGED',
        label: 'Medium - Contact is being messaged or has been messaged'
      },
      {
        value: 'CAMPAIGNS',
        label: 'High - Contact exists in another sequence'
      }
    ])

    return (
      <Wrapper>
        <Subtitle>Safety</Subtitle>
        <Label mt='0.25rem'>
          <span>
            These are your personal safety settings. (Note: <Link to='/settings/teamsafety'>Team safety</Link> settings will still apply).
          </span>
        </Label>
        <FieldContainer>
          <DropDownFieldForm
            label='Deduplication'
            description={<span>Adjust your deduplication threshold to be warned when adding a duplicate contact to two different sequences. Learn more about the <a href='https://help.interseller.io/article/46-running-duplicate-checks-across-individual-user-sequences' target='_new'>different thresholds</a>.</span>}
            options={options}
            value={contactSafety}
            inputId='contact_safety'
            onSave={(value) => {
              onSettingUpdate({ contact_safety: value === 'OFF' ? null : value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Deduplication Period'
            description={
              <span>
                Adjust the number of days contacts should be considered for deduplication.
                Contacts that were messaged or created longer than this period will be ignored.
                Removing this setting will set this to all time.
              </span>
            }
            value={contactSafetyDays}
            textType='number'
            inputId='contact_safety_days'
            onSave={(value) => {
              onSettingUpdate({ contact_safety_days: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Account Throttling'
            description={<span>Adjust the total number of contacts that can be actively messaged at the same company (based on email domain).</span>}
            value={domainSafety}
            textType='number'
            inputId='domain_safety'
            onSave={(value) => {
              onSettingUpdate({ domain_safety: value === 'OFF' ? null : value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Account Replies'
            description="Replies from a contact will stop messages to all other contacts in the replied contact's domain"
            inputId='domain_reply'
            value={domainReply}
            onSave={(value) => {
              onSettingUpdate({ domain_reply: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Blocklist'
            description='Block domains and email addresses to help prevent emails being sent to people you do not want to contact. Separate each by a new line.'
            value={blocklistString}
            inputId='blocklist'
            multiline
            onSave={(value) => {
              const valueArray = value.split('\n')
              actions.updateBlocklist(valueArray)
            }}
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

Safety.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func,
  actions: PropTypes.object,
  blocklist: PropTypes.object
}

export default Safety

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Label from 'elements/Label'
import Title from 'elements/Title'
import Subtitle from 'elements/Subtitle'
import LogoImage from 'elements/LogoImage'
import { apiBaseURI } from 'utils/api'
import Zap from './Zap'
import ZapTemplate from './ZapTemplate'
import theme from '../../../../themes/light'

const ContentContainer = styled.div`
  background-color: ${theme.containerBackground};
  flex: 1 0 auto;
  border: solid 1px ${theme.borderColor};
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 42rem;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
`

class Zapier extends Component {
  UNSAFE_omponentWillMount () {
    const {
      session,
      actions
    } = this.props
    const hasZapier = session.get('has_zapier_token')
    if (hasZapier) {
      actions.fetchZaps()
    }
    actions.fetchZapTemplates()
  }

  render () {
    const {
      session,
      zaps,
      zapTemplates
    } = this.props

    const hasZapier = session.get('has_zapier_token')

    const zapRows = zaps.get('data').map((zap, idx) => (<Zap zap={zap} key={idx} />))
    const zapTemplateRows = zapTemplates.get('data').map((zap, idx) => (<ZapTemplate zap={zap} key={idx} />))

    const loading = zapTemplates.get('loading') || zaps.get('loading')

    return (
      <ContentContainer>
        <TitleContainer>
          <Title>Zapier</Title>
          <LogoImage type='zapier' ml={0} mr={0} mt='auto' mb='auto' />
        </TitleContainer>
        <Label mt='0.5rem' mb='1rem'>
          Zapier can help you automate actions with thousands of other services without a single line of code. Connecting your Zapier account to Interseller will display all your Zaps that interact with Interseller.
        </Label>
        {!hasZapier &&
          <Button
            primary
            label='Connect Zapier'
            handleClick={() => {
              window.location = `${apiBaseURI}/auth/zapier`
            }}
          />}

        {loading && <Loading />}
        {!loading &&
          <>
            {hasZapier &&
              <Subtitle mt='2rem' mb='1rem'>
                Your Zaps
              </Subtitle>}
            {hasZapier && zapRows}

            <Subtitle mt='2rem' mb='1rem'>
              Suggested Zaps
            </Subtitle>
            {zapTemplateRows}
          </>}
      </ContentContainer>
    )
  }
}

Zapier.propTypes = {
  session: PropTypes.object,
  zaps: PropTypes.object,
  zapTemplates: PropTypes.object,
  actions: PropTypes.object
}

export default Zapier

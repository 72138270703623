import { fromJS } from 'immutable'
import {
  FETCH_EMAIL_DISCOVERY_REQUEST,
  FETCH_EMAIL_DISCOVERY_SUCCESS,
  FETCH_EMAIL_DISCOVERY_FAILURE,
  FETCH_EMAIL_DISCOVERY_RESET,
  FETCH_EMAIL_LINK_REQUEST,
  FETCH_EMAIL_LINK_SUCCESS,
  FETCH_EMAIL_LINK_FAILURE
} from './constants'

const initialObject = {
  loading: false,
  data: {},
  error: null
}

// The initial state of the App
const initialState = fromJS({
  discovery: initialObject,
  magic: initialObject
})

function onboardingReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_DISCOVERY_REQUEST:
      return state.set('discovery', state.get('discovery').merge({ loading: true }))
    case FETCH_EMAIL_DISCOVERY_SUCCESS:
      return state.set('discovery', fromJS({
        data: action.payload.discovery.merge(fromJS({
          email: action.payload.email
        })),
        loading: false,
        error: null
      }))
    case FETCH_EMAIL_DISCOVERY_FAILURE:
      return state.set('discovery', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))
    case FETCH_EMAIL_DISCOVERY_RESET:
      return state.set('discovery', fromJS({
        data: {},
        loading: false,
        error: null
      }))

    case FETCH_EMAIL_LINK_REQUEST: {
      const {
        email
      } = action
      return state.set('magic', state.get('magic').merge(fromJS({ loading: true, data: { email } })))
    }
    case FETCH_EMAIL_LINK_SUCCESS:
      return state.set('magic', fromJS({
        data: action.payload,
        loading: false,
        error: null
      }))
    case FETCH_EMAIL_LINK_FAILURE:
      return state.set('magic', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))
    default:
      return state
  }
}

export default onboardingReducer

/**
*
* Templates Components
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import { Menu, MenuItem } from 'elements/Menu'
import Popper from 'components/Popper'
import SlateEditor from 'components/SlateEditor'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import DropdownArrow from 'elements/DropdownArrow'
import withSession from 'hocs/session'
import { Satellite, Search } from 'svg'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  max-height: 500px;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`

const TemplateListContainer = styled.div`
  background-color: ${theme.colors.gray10};
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  overflow: auto;
  width: 44%;
  height: 50vh;
  max-height: 500px;
  border-right: solid 1px ${theme.colors.gray20};
`

const TemplateContainer = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  width: 56%;
  height: 50vh;
  max-height: 500px;
`

const TemplateRow = styled.div`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  text-align: left;
  background-color: ${theme.colors.gray10};
  padding: 0.5rem ${theme.padding};
  min-height: fit-content;

  ${props => props.selected && css`
    background-color: ${theme.colors.blueGray};
    cursor: default;
  `};
`

const TemplateContent = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const TemplateTitle = styled.div`
  font: ${theme.fonts.small};
  font-weight: 700;
  color: ${theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
`

const TemplateDetails = styled.div`
  font: ${theme.fonts.small};
  color: ${theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 0.5rem;
  padding: 0;
`

const SearchWrapper = styled.div`
  color: white;
  align-items: start;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  position: relative;
  min-height: 56px;
  max-height: 56px;
  border-bottom: 1px solid ${theme.colors.gray20};
`

const InputBox = styled.input`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  width: 100%;
  padding-left: 0.5rem;
  color: black;
  border: none;
  outline: none;
`

class Templates extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedIndex: 0
    }
  }

  UNSAFE_componentWillMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      templates
    } = newProps

    if (templates !== this.props.templates && this.state.search) {
      this.onSearch(templates)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  onSearch = (templates) => {
    const {
      search
    } = this.state

    const searchData = templates.get('data').filter(t => (t.get('name') || '').search(new RegExp(search, 'i')) > -1 ||
      (t.getIn(['_user', 'full_name']) || '').search(new RegExp(search, 'i')) > -1)
    this.setState({
      searchData,
      selectedIndex: 0
    })
  }

  handleKeyDown = (e) => {
    const {
      templates,
      onTemplateSelected
    } = this.props
    const {
      selectedIndex,
      searchData
    } = this.state

    const data = searchData || templates.get('data')
    const selectedTemplate = data.get(selectedIndex)

    if (e.keyCode === 38) {
      e.preventDefault()
      e.stopPropagation()
      return this.setState({
        selectedIndex: Math.max(selectedIndex - 1, 0)
      })
    } else if (e.keyCode === 40) {
      e.preventDefault()
      e.stopPropagation()
      return this.setState({
        selectedIndex: Math.min(selectedIndex + 1, data.count() - 1)
      })
    } else if (e.keyCode === 13) {
      e.preventDefault()
      return onTemplateSelected(selectedTemplate)
    }

    return false
  }

  render () {
    const {
      templates,
      closeModal,
      onTemplateSelected,
      session
    } = this.props
    const {
      selectedIndex,
      search,
      searchData
    } = this.state

    const loading = templates.get('loading')
    const data = searchData || templates.get('data')
    const rows = data.map((t, i) => {
      const name = t.get('name')
      const selected = selectedIndex === i
      const username = t.getIn(['_user', 'full_name'])
      const isOwner = t.getIn(['_user', '_id']) === session.get('id')
      const date = format(new Date(t.get('created_at')), 'MMM Do')
      const details = `Created by ${username} on ${date}`
      return (
        <TemplateRow
          key={`template-row-${i}`}
          selected={selected}
          onClick={() => {
            this.setState({
              selectedIndex: i
            })
          }}
        >
          <TemplateContent>
            <TemplateTitle>
              {name}
            </TemplateTitle>
            <TemplateDetails>
              {details}
            </TemplateDetails>
          </TemplateContent>
          {isOwner &&
            <Popper
              position='bottom-start'
              trigger={<CaretContainer><DropdownArrow /></CaretContainer>}
            >
              {close => (
                <Menu style={{ width: '10rem' }} onClick={close}>
                  <MenuItem onClick={() => { window.open(`/templates?template=${t.get('id')}`) }}>
                    Edit Template
                  </MenuItem>
                </Menu>
              )}
            </Popper>}
        </TemplateRow>
      )
    })

    const selectedTemplate = data.get(selectedIndex)

    let timeout

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <TitleContainer>
          <Title align='center'>Templates</Title>
        </TitleContainer>
        <Divider />
        <ContentContainer>
          {loading && <Loading />}
          {!loading &&
            <TemplateListContainer>
              {templates.get('data').count() > 0 &&
                <SearchWrapper>
                  <Search />
                  <InputBox
                    ref={(comp) => { this.input = comp }}
                    placeholder='Search templates…'
                    value={search}
                    onChange={(e) => {
                      const text = e.target.value
                      this.setState({
                        search: text
                      })
                      if (timeout) {
                        clearTimeout(timeout)
                      }
                      timeout = setTimeout(() => {
                        this.onSearch(templates)
                      }, 200)
                    }}
                  />
                </SearchWrapper>}
              {rows.count() > 0 && rows}
              {rows.count() < 1 &&
                <EmptyState description='No saved templates to show' />}
            </TemplateListContainer>}
          {!loading &&
            <TemplateContainer>
              {rows.count() < 1 &&
                <EmptyState
                  icon={<Satellite />}
                  title='No Saved Templates'
                  description='Save a template in your sequence steps to use in other sequences or share with your team'
                />}
              {selectedTemplate && rows.count() > 0 &&
                <div>
                  <SlateEditor
                    key={selectedTemplate.get('id')}
                    hideEditorCoach
                    readOnly
                    hideToolbar
                    hideSubjectLine={!selectedTemplate.get('subject')}
                    subject={selectedTemplate.get('subject')}
                    body={selectedTemplate.get('markdown')}
                  />
                </div>}
            </TemplateContainer>}
        </ContentContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            handleClick={closeModal}
            mr='0.5rem'
          />
          <Button
            primary
            label='select template'
            handleClick={() => {
              onTemplateSelected(selectedTemplate)
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

Templates.propTypes = {
  templates: PropTypes.object,
  closeModal: PropTypes.func,
  onTemplateSelected: PropTypes.func,
  onDeleteTemplate: PropTypes.func,
  session: PropTypes.object
}

export default withSession(Templates)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { Tooltip } from 'react-tippy'
import { space, width, height } from 'styled-system'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import DropDown from 'components/DropDown'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Link = styled.a`

`

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled(Button)`
  padding-left: 1rem;
  padding-right: 0;
  border: 0;
  height: 56px;
  cursor: pointer;
`

class DropDownFieldForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.valid()) {
      e.preventDefault()
      return this.props.onSave(this.state.value)
    }
    return false
  }

  valid = () => (
    this.props.value !== this.state.value
  )

  render () {
    const {
      label,
      description,
      inputId,
      onSave,
      onOptionChange,
      options,
      helpUrl,
      searchable = false,
      clearable = false,
      disabled = false,
      buttonText = 'Save',
      refreshing = false,
      onRefresh,
      refreshTooltip,
      ...rest
    } = this.props

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        {...rest}
      >
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {description &&
          <Description htmlFor={inputId}>
            {description} {helpUrl && <Link href={helpUrl} target='_new'>Learn more</Link>}
          </Description>}
        <DropDownContainer>
          <DropDown
            options={options}
            clearable={clearable}
            searchable={searchable}
            value={this.state.value}
            onOptionChange={(option) => {
              const newValue = option ? option.value : null
              this.setState({ value: newValue })
              if (onOptionChange) {
                onOptionChange(newValue)
              }
            }}
            disabled={disabled}
          />
          {onRefresh &&
            <Tooltip
              title={refreshTooltip || `Refresh ${label || 'Options'}`}
              position='left'
              trigger='mouseenter'
              arrow
            >
              <Icon
                onClick={onRefresh}
                icon={<FontAwesomeIcon icon={refreshing ? ['far', 'spinner-third'] : ['fa', 'redo']} transform={{ y: 1 }} />}
                label='Refresh'
                iconPadding='1.25rem'
              />
            </Tooltip>}
        </DropDownContainer>
        {(!disabled && onSave) &&
          <Button
            full
            primary
            label={buttonText}
            handleClick={() => {
              onSave(this.state.value)
            }}
            mt='1rem'
          />}
      </Wrapper>
    )
  }
}

DropDownFieldForm.propTypes = {
  label: PropTypes.string,
  description: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.instanceOf(Immutable.List),
  inputId: PropTypes.string,
  onSave: PropTypes.func,
  onOptionChange: PropTypes.func,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  helpUrl: PropTypes.string,
  buttonText: PropTypes.string,
  refreshing: PropTypes.bool,
  onRefresh: PropTypes.func,
  refreshTooltip: PropTypes.string
}

export default DropDownFieldForm

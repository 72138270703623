import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { bootSession } from 'utils/user'
import { selectSession, selectLoading } from 'containers/App/selectors'
import { fetchSession } from 'containers/App/actions'
import Loading from 'components/Loading'
import Bear from 'svg/Bear.svg'
import { selectDiscovery, selectMagic } from './selectors'
import { fetchEmailDiscovery, fetchEmailDiscoveryReset, fetchEmailLink } from './actions'
import Wrapper from './Wrapper'
import Stars from './components/Stars'
import theme from '../../themes/light'

const Container = styled.div`
  background-color: ${props => (props.dark ? theme.colors.darkBlue : theme.colors.white)};
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

class Onboarding extends Component {
  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props
    actions.fetchSession()
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      session
    } = newProps

    if (session) {
      bootSession(session)
      this.props.router.push('/')
    }
  }

  render () {
    const {
      children,
      session,
      discovery,
      magic,
      loading,
      actions,
      route
    } = this.props

    if (loading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Container>
          {children && React.cloneElement(children, {
            actions,
            session,
            loading,
            discovery,
            magic,
            signup: route.signup
          })}
        </Container>
        <Container dark>
          <Stars>
            <img
              src={Bear}
              alt='Hero Bear'
              className='hero-bear'
            />
          </Stars>
        </Container>
      </Wrapper>
    )
  }
}

Onboarding.propTypes = {
  actions: PropTypes.object,
  router: PropTypes.object,
  route: PropTypes.object,
  children: PropTypes.object,
  session: PropTypes.object,
  loading: PropTypes.bool,
  discovery: PropTypes.object,
  magic: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  session: selectSession(),
  loading: selectLoading(),
  discovery: selectDiscovery(),
  magic: selectMagic()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSession,
      fetchEmailDiscovery,
      fetchEmailDiscoveryReset,
      fetchEmailLink
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)

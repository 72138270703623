import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from 'elements/Title'
import Subtitle from 'elements/Subtitle'
import Label from 'elements/Label'
import Divider from 'elements/Divider'
import DangerLabel from 'elements/DangerLabel'
import RichTextFieldForm from 'components/RichTextFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import TextFieldForm from 'components/TextFieldForm'
import ConfirmModal from 'components/ConfirmModal'
import Modal from 'components/Modal'
import Input from 'components/Input'
import Button from 'components/Button'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const ModalWrapper = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem 0 3rem;
`

const InputContainer = styled.div`
  padding: 3rem 0 1rem 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

class Privacy extends Component {
  constructor (props) {
    super(props)
    this.state = {
      teamSignature: props.teamSettings.getIn(['data', 'team_signature'])
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      teamSignature: nextProps.teamSettings.getIn(['data', 'team_signature'])
    })
  }

  deletePurgeEmail () {
    this.props.deletePurgeEmail(this.state.emailToPurge)
    this.setState({
      emailToPurge: '',
      confirmDeleteEmail: ''
    })
  }

  render () {
    const {
      state,
      session,
      actions,
      teamSettings,
      onTeamSettingUpdate,
      getPurgeEmailCount,
      purgeEmailCount,
      showPurgeModal,
      hidePurgeModal
    } = this.props

    const {
      emailToPurge,
      confirmDeleteEmail,
      teamSignature,
      resubscribeEmail,
      showResubscribeModal
    } = this.state

    const canUpdate = state && (state.get('is_manager') || state.get('is_owner'))
    const anonymizeContacts = teamSettings.getIn(['data', 'anonymize_contacts'])
    const gdprWarning = teamSettings.getIn(['data', 'gdpr_warning'])
    const purgeCount = purgeEmailCount.get('data')
    const featureFlags = session?.get('feature_flags') || session?.get('whitelist')

    return (
      <Wrapper>
        <Subtitle>Privacy</Subtitle>
        <Label mt='0.25rem'>Privacy settings can only be updated by a manager or owner.</Label>
        {featureFlags?.includes('anonymize_contacts') &&
          <FieldContainer>
            <SwitchFieldForm
              label='Auto Anonymize (Beta)'
              description={
                <span>
                  Contacts that have not responded within 30 days of being added to a sequence will
                  automatically have their identifying information hidden (e.g. name, photo, email,
                  LinkedIn profile). Contacts that have been anonymized cannot be restored.
                </span>
              }
              inputId='anonymize_contacts'
              value={!!anonymizeContacts}
              onSave={(value) => {
                onTeamSettingUpdate({ anonymize_contacts: value })
              }}
              disabled={!canUpdate}
            />
          </FieldContainer>}
        <FieldContainer>
          <SwitchFieldForm
            label='GDPR Warning'
            description={
              <span>
                Contacts that are located in a GDPR country will appear with a warning before they can be messaged.
                These warnings can be cleared to continue emailing contacts.
              </span>
            }
            inputId='gdpr_warning'
            value={!!gdprWarning}
            onSave={(value) => {
              onTeamSettingUpdate({ gdpr_warning: value })
            }}
            disabled={!canUpdate}
          />
        </FieldContainer>
        <FieldContainer>
          <RichTextFieldForm
            label='Email Disclosure'
            description={
              <span>
                This adds a disclosure message at the bottom of every email sent using Interseller.
                You can test this by sending a preview email from your sequences.
              </span>
            }
            value={teamSignature}
            inputId='team_signature'
            isBrandedDomain={session.get('branded_domain_enabled') || false}
            onSave={(value) => {
              onTeamSettingUpdate({ team_signature: value })
            }}
            disabled={!canUpdate}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Remove Contact'
            description={
              <span>
                Remove all instances of a contact from Interseller by their email address. Contacts and all related data are completely removed and cannot be restored.
              </span>
            }
            value={emailToPurge}
            inputId='purgeEmail'
            saveLabel='Search'
            disabledButton={!emailToPurge}
            onChange={(value) => {
              this.setState({
                emailToPurge: value
              })
            }}
            onSave={(value) => {
              getPurgeEmailCount(value)
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Resubscribe Contact'
            description={
              <span>
                Fill in the email address of the contact that you'd like to resubscribe
              </span>
            }
            value={resubscribeEmail}
            inputId='resubscribeEmail'
            saveLabel='Resubscribe'
            textType='email'
            required
            disabled={!canUpdate}
            disabledButton={!resubscribeEmail}
            placeholder='spacebear@interseller.io'
            onChange={(value) => {
              this.setState({
                resubscribeEmail: value
              })
            }}
            onSave={() => {
              this.setState({
                showResubscribeModal: true
              })
            }}
          />
        </FieldContainer>
        <Modal
          isOpen={purgeCount && showPurgeModal}
          onModalClose={() => {
            hidePurgeModal()
          }}
        >
          <ModalWrapper>
            <InputContainer>
              <Title align='center'>Remove {purgeCount} {purgeCount === 1 ? 'contact' : 'contacts'}</Title>
              <Label mt='0.5rem' mb='0.5rem' align='center'>
                This will remove all references of <b>{emailToPurge}</b> from Interseller. This action is permanent and irreversible. To continue, please confirm the email address, then press delete.
              </Label>
            </InputContainer>
            <Input
              inputId='deleteEmail'
              type='string'
              width='100%'
              mb='2rem'
              autoFocus
              label='Confirm'
              value={confirmDeleteEmail}
              onValueChange={(value) => {
                this.setState({
                  confirmDeleteEmail: value
                })
              }}
            />
            <Divider />
            <ButtonContainer>
              <Button
                destructive
                label='Delete'
                disabled={emailToPurge !== confirmDeleteEmail}
                handleClick={() => {
                  this.deletePurgeEmail()
                }}
                mr='0.5rem'
              />
            </ButtonContainer>
          </ModalWrapper>
        </Modal>
        <ConfirmModal
          title='Resubscribe Confirmation'
          description=''
          isOpen={showResubscribeModal}
          onCancel={() => {
            this.setState({
              showResubscribeModal: false
            })
          }}
          onConfirm={() => {
            actions.resubscribeEmail(resubscribeEmail)
            this.setState({
              showResubscribeModal: false,
              resubscribeEmail: ''
            })
          }}
        >
          <DangerLabel>
            Please make sure you have permission to resubscribe. Doing so without their consent may have adverse effects with spam filters. Contacts that are resubscribed will be paused and must be resumed manually to continue receiving emails.
          </DangerLabel>
          Are you sure you want to resubscribe <strong>{resubscribeEmail}</strong>?
        </ConfirmModal>
      </Wrapper>
    )
  }
}

Privacy.propTypes = {
  state: PropTypes.object,
  session: PropTypes.object,
  actions: PropTypes.object,
  teamSettings: PropTypes.object,
  onTeamSettingUpdate: PropTypes.func,
  showPurgeModal: PropTypes.bool,
  getPurgeEmailCount: PropTypes.func,
  purgeEmailCount: PropTypes.object,
  deletePurgeContacts: PropTypes.func,
  hidePurgeModal: PropTypes.func,
  deletePurgeEmail: PropTypes.func,
  resubscribeEmail: PropTypes.func
}

export default Privacy

/**
*
* ArchiveRow
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pluralize } from 'utils/strings'
import { ArchiveIcon } from 'svg'
import {
  Wrapper,
  SequenceColumn,
  SequenceLabel,
  CheckboxColumn,
  IconColumn,
  ArchiveTitle
} from '../Rows'

class ArchiveRow extends Component {
  render () {
    const {
      archiveCount,
      onClick
    } = this.props

    return (
      <Wrapper onClick={onClick}>
        <CheckboxColumn />
        <IconColumn>
          <ArchiveIcon />
        </IconColumn>
        <SequenceColumn>
          <ArchiveTitle>
            Archived Sequences
          </ArchiveTitle>
          <SequenceLabel>
            {archiveCount} archived {pluralize('sequence', 'sequences', archiveCount)}
          </SequenceLabel>
        </SequenceColumn>
      </Wrapper>
    )
  }
}

ArchiveRow.propTypes = {
  archiveCount: PropTypes.number,
  onClick: PropTypes.func
}

export default ArchiveRow

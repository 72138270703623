import styled from 'styled-components'
import theme from '../../../themes/light'

// Used as header to describe an issue where there is no data to graph or display in the table
const NoDataHeader = styled.div`
  color: ${theme.colors.darkBlue};
  font: ${theme.fonts.title};
  text-align: center;
`

// Used as label to describe in more detail why there is no data to graph or present in the table
const NoDataLabel = styled.div`
  color: ${theme.colors.gray50};
  font: ${theme.fonts.normal};
  text-align: center;
`

const EmptyContainer = styled.div`
  padding: 2rem;

  border: solid 1px ${theme.colors.gray30};

  background-color: ${theme.colors.white};
`

const ReportContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  min-height: 640px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 420px 1fr;
  grid-gap: 1rem;

  margin-left: 1rem;
`

const FullContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  margin-left: 1rem;
`

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${theme.colors.white};
  border: solid 1px ${theme.colors.gray30};
  border-radius: 3px;

  width: 100%;
  height: 100%;
`

const TooltipContainer = styled.div`
  background-color: ${theme.colors.white};
  border: solid 1px ${theme.colors.gray30};

  padding: 1rem;
  padding-top: 0.5rem;
`

const TooltipValue = styled.div`
  font: ${theme.fonts.subtitle};
  color: ${props => (props.color)
    ? props.color
    : theme.colors.blue
  };
`

const TooltipLabel = styled.div`
  font: ${theme.fonts.xsmall};
  color: ${theme.colors.gray70};
`

const TooltipUnitLabel = styled.span`
  font: ${theme.fonts.searchtitle};
  color: ${theme.colors.gray70};
`

export {
  NoDataHeader,
  NoDataLabel,
  EmptyContainer,
  ReportContainer,
  FullContainer,
  GraphContainer,
  TooltipContainer,
  TooltipValue,
  TooltipLabel,
  TooltipUnitLabel
}

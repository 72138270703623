import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import ConfirmModal from 'components/ConfirmModal'
import SequencePickerModal from 'components/SequencePickerModal'
import Loading from 'components/Loading'
import Label from 'elements/Label'
import { constructContactStatus } from 'utils/contacts'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  display: block;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.white};
`

const HeaderContainer = styled.div`
  width: 100%;
  height: 251px;
  padding-top: 2rem;
  background-color: ${theme.colors.gray10};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0;
  overflow: hidden;
`

const ProfileContainer = styled.div`
  padding: 1rem;
`

const ContactName = styled.h2`
  font: ${theme.fonts.header};
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.5rem;
  color: ${props => props.anonymized ? theme.labelColor : theme.titleColor};
`

const TabList = styled.ul`
  background-color: ${theme.colors.gray10};
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom: ${props => `1px solid ${theme.colors.gray60}`};
`

const Tab = styled.li`
  display: flex;
  list-style: none;
  width: 100%;
`

const TabLink = styled(Link)`
  font: ${theme.fonts.label};
  color: ${theme.colors.gray60};
  flex: 1 0 auto;
  width: 100%;
  cursor: pointer;
  padding: 0.75rem;
  transition: all .5s ease;
  border: none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .05em;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: 1;
    transition: opacity .15s ease-out;
  }

  &.${props => props.activeClassName} {
    color: ${theme.primaryColor};
    cursor: default;
    border-bottom: 1px solid ${theme.primaryColor};

    &:hover,
    &:focus {
      opacity: 1;
      transition: opacity .15s ease-in;
    }
  };
`

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 1 0 auto;
  width: 100%;
  height: calc(100% - 251px);

`

class ContactDetail extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showDeleteConfirmModal: false,
      showMoveModal: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      params
    } = this.props

    this.updateContact(params.id, params.contactId)
  }

  componentDidUpdate () {
    this.checkPropsForUpdate(this.props)
  }

  checkPropsForUpdate = (props) => {
    const {
      selectedContact,
      params
    } = props

    if (selectedContact.getIn(['data', '_id']) !== params.contactId && !selectedContact.get('loading')) {
      const sequenceId = props.params.id
      this.updateContact(sequenceId, params.contactId)
    }
  }

  updateContact = (sequenceId, contactId) => {
    this.props.actions.fetchContact(sequenceId, contactId)
    this.props.actions.fetchContactSteps(sequenceId, contactId)
    this.props.actions.fetchContactActions(contactId)
    this.props.actions.fetchContactIntegration(contactId)
    this.props.actions.fetchSimilarContacts(contactId)
  }

  render () {
    const {
      session,
      actions,
      sequence,
      crm,
      selectedContact,
      contactPhone,
      contactActivity,
      contactMessages,
      contactSteps,
      similarContacts,
      contactIntegration,
      contactCompany,
      sequences,
      closeSidePanel,
      onUnsubscribeContact,
      onBlockContact,
      onAssignSentiment,
      onCustomizeMessages,
      onPauseContact,
      onCopyContact,
      onPreviewReply,
      onClearError,
      onSendNow,
      onCloneContact,
      onUpdateContact,
      onRepliedContactUpdate,
      onReportContactInaccuracy,
      router,
      isArchived,
      onResyncCrm,
      onJumpToTask
    } = this.props

    const {
      showDeleteConfirmModal,
      showMoveModal
    } = this.state

    if (selectedContact.get('loading') || !selectedContact.get('data')) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const contact = selectedContact.get('data')
    const name = contact.get('name')
    const contactId = contact.get('_id')
    const sequenceId = sequence.get('_id')
    const photoUrl = contact.get('image_url')
    const anonymized = contact.get('anonymized')

    const showWorkHistory = !!contact.get('is_free_email') && contact.get('linkedin_positions') && contact.get('linkedin_positions').count() > 0

    return (
      <Wrapper>
        <HeaderContainer>
          <ProfileContainer>
            <Avatar
              src={photoUrl}
              email={photoUrl ? null : contact.get('email')}
              maxInitials={2}
              name={name}
              size={80}
              textSizeRatio={2}
              round
              colors={theme.avatarColors}
              style={{ filter: `${anonymized ? 'blur(4px)' : 'none'}` }}
            />
            <ContactName anonymized={anonymized}>{name}</ContactName>
            <Label align='center' pt='0.25rem'>{constructContactStatus(contact)}</Label>
          </ProfileContainer>
          <TabList>
            <Tab>
              <TabLink to={`/sequence/${sequenceId}/contacts/${contactId}/detail`} activeClassName='active'>
                Details
              </TabLink>
            </Tab>
            <Tab>
              {showWorkHistory &&
                <TabLink to={`/sequence/${sequenceId}/contacts/${contactId}/workhistory`} activeClassName='active'>
                  Work Exp
                </TabLink>}
              {!showWorkHistory &&
                <TabLink to={`/sequence/${sequenceId}/contacts/${contactId}/company`} activeClassName='active'>
                  Company
                </TabLink>}
            </Tab>
            <Tab>
              <TabLink to={`/sequence/${sequenceId}/contacts/${contactId}/activity`} activeClassName='active'>
                Activity
              </TabLink>
            </Tab>
          </TabList>
        </HeaderContainer>
        <Panel>
          {this.props.children && React.cloneElement(this.props.children, {
            session,
            actions,
            sequence,
            contact,
            contactPhone,
            crm,
            contactActivity,
            contactMessages,
            contactSteps,
            contactIntegration,
            similarContacts,
            contactCompany,
            onUnsubscribeContact,
            onBlockContact,
            onAssignSentiment,
            onCustomizeMessages,
            onPauseContact,
            onPreviewReply,
            onCopyContact,
            onClearError,
            onCloneContact,
            onSendNow,
            onUpdateContact,
            onReportContactInaccuracy,
            router,
            isArchived,
            onResyncCrm,
            onDeleteContact: () => {
              this.setState({
                showDeleteConfirmModal: true
              })
            },
            onMoveContact: () => {
              this.setState({
                showMoveModal: true
              })
            },
            onSaveContact: (params) => {
              actions.updateContact(contactId, params)
            },
            onRepliedContactUpdate: (remove, booked = false) => {
              onRepliedContactUpdate(contact, { remove, booked })
            },
            onSearchPhone: () => {
              actions.fetchContactPhone(contactId)
            },
            onJumpToTask: () => {
              onJumpToTask(contact)
            }
          })}
        </Panel>
        <ConfirmModal
          isOpen={showDeleteConfirmModal}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false
            })
          }}
          onConfirm={() => {
            actions.deleteContacts(sequenceId, [contactId])
            this.setState({
              showDeleteConfirmModal: false
            })
            closeSidePanel()
          }}
          title='Delete Confirmation'
          description={`Are you sure you want to remove ${name}?`}
        />
        <SequencePickerModal
          isOpen={showMoveModal}
          onCancel={() => {
            this.setState({
              showMoveModal: false
            })
          }}
          onConfirm={(s) => {
            actions.moveContacts(sequenceId, [contactId], s)
            this.setState({
              showMoveModal: false
            })
            closeSidePanel()
          }}
          title='Move Confirmation'
          description={`Select the sequence to move ${name} to`}
          sequences={sequences}
          actions={actions}
        />
      </Wrapper>
    )
  }
}

ContactDetail.propTypes = {
  children: PropTypes.object.isRequired,
  actions: PropTypes.object,
  sequence: PropTypes.object,
  contactActivity: PropTypes.instanceOf(Immutable.List),
  contactMessages: PropTypes.instanceOf(Immutable.List),
  contactIntegration: PropTypes.instanceOf(Immutable.Map),
  contactCompany: PropTypes.instanceOf(Immutable.Map),
  contactPhone: PropTypes.instanceOf(Immutable.Map),
  contactSteps: PropTypes.instanceOf(Immutable.Map),
  similarContacts: PropTypes.instanceOf(Immutable.Map),
  sequences: PropTypes.instanceOf(Immutable.List),
  selectedContact: PropTypes.object,
  closeSidePanel: PropTypes.func,
  onUnsubscribeContact: PropTypes.func,
  onBlockContact: PropTypes.func,
  onAssignSentiment: PropTypes.func,
  onCustomizeMessages: PropTypes.func,
  onPauseContact: PropTypes.func,
  onPreviewReply: PropTypes.func,
  onCopyContact: PropTypes.func,
  onCloneContact: PropTypes.func,
  onClearError: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onRepliedContactUpdate: PropTypes.func,
  onSendNow: PropTypes.func,
  onReportContactInaccuracy: PropTypes.func,
  router: PropTypes.object,
  crm: PropTypes.object,
  session: PropTypes.object,
  isArchived: PropTypes.bool,
  params: PropTypes.object,
  onResyncCrm: PropTypes.func,
  onJumpToTask: PropTypes.func
}

export default ContactDetail

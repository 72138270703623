/* eslint-disable react/jsx-indent */
import styled from 'styled-components'
import React, { Component } from 'react'
import Toggle from 'react-toggle'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Title from 'elements/Title'
import Divider from 'elements/Divider'
import Label from 'elements/Label'
import theme from '../../../../themes/light'

const ModalContent = styled.div`
  width: 100%;
  padding: 2rem 2rem 0.5rem 2rem;
  justify-items: start;
`

const ToggleForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 0 2rem 0;
`

const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
  padding: 0.75rem 1rem;
  justify-items: end;
`

const ToggleLabel = styled.div`
  font-weight: 300;
  justify-self: start;
  color: ${theme.labelColor};
  font: ${theme.fonts.normal};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

class ConfigModal extends Component {
  render () {
    const {
      isOpen,
      handleModalClose,
      updateConfig,
      numberTiles,
      toggleTile,
      config
    } = this.props

    return (
      <Modal
        width='695px'
        isOpen={isOpen}
        onModalClose={handleModalClose}
      >
        <ModalContent>
          <Title align='center'>Dashboard Tiles</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Choose the tiles you would like to display
          </Label>
          <ToggleForm>
            <ToggleContainer>
              <Toggle
                id='messageQueue'
                checked
                icons={false}
                disabled
                className='interseller-switch'
              />
              <ToggleLabel>Message Queue</ToggleLabel>
            </ToggleContainer>
            {numberTiles.map((tile) => {
              return tile.hidden
                ? null
                : <ToggleContainer key={tile.name}>
                  <Toggle
                    id={tile.name}
                    checked={config[tile.name]}
                    icons={false}
                    disabled={tile.disabled}
                    className='interseller-switch'
                    onChange={e => {
                      const id = e.target.id
                      const checked = e.target.checked
                      toggleTile(id, checked)
                    }}
                  />
                  <ToggleLabel>{tile.label}</ToggleLabel>
                  </ToggleContainer>
            })}
          </ToggleForm>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={handleModalClose}
              mr='0.5rem'
            />
            <Button
              primary
              label='save'
              handleClick={() => {
                updateConfig()
              }}
            />
          </ButtonContainer>
        </ModalContent>
      </Modal>
    )
  }
}

ConfigModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  config: PropTypes.object,
  updateConfig: PropTypes.func,
  numberTiles: PropTypes.array,
  toggleTile: PropTypes.func
}

export default ConfigModal

import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from 'elements/Label'
import LogoImage from 'elements/LogoImage'
import theme from '../../../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

const Title = styled(Label)`
  padding-left: 0.5rem;
  color: ${theme.titleColor};
`

class IntegrationLink extends PureComponent {
  render () {
    const {
      crm
    } = this.props

    const type = crm.get('type') || crm.get('name')
    const name = crm.get('name')
    const url = crm.get('url')
    const title = <span>Open in <strong>{name}</strong></span>

    return (
      <Wrapper onClick={() => window.open(url)}>
        <LogoImage width='1.5rem' height='1.5rem' type={type} />
        <Title>{title}</Title>
      </Wrapper>
    )
  }
}

IntegrationLink.propTypes = {
  crm: PropTypes.instanceOf(Immutable.Map)
}

export default IntegrationLink

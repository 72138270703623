import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import { Tooltip } from 'react-tippy'
import StepStat from '../StepStat'
import { Info } from 'svg'
import theme from '../../../../../../../../themes/light'

const Container = styled.div`
  padding: ${theme.padding};
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  background-color: #FBFCFD;
`

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  max-width: calc(100% - (${theme.grid.columnGap} * 4 + ${theme.grid.buttonGap} + ${theme.grid.firstColumnGap}));
  align-items: start;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0.5rem;
  text-align: left;
`

const InfoWrapper = styled.div`
  fill: ${theme.labelColor};
  margin-right: .5rem;
  transform: translateY(-4.5px);
  height: 18px;
`

const Subject = styled.div`
  color: ${theme.labelColor};
  font: ${theme.fonts.button};
  text-transform: uppercase;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 100%;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
`

const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(${theme.grid.columnGap} + ${theme.grid.firstColumnGap}) repeat(3, ${theme.grid.columnGap}) ${theme.grid.buttonGap};
  align-items: end;
`

class StepStatOverview extends Component {
  render () {
    const {
      stepIndex,
      stepStats
    } = this.props

    const stepIdx = '' + stepIndex
    const stepAvgData = (stepStats || Immutable.Map({})).get(stepIdx)

    let viewed = '-'
    let visited = '-'
    let replied = '-'
    let total = (stepAvgData || Immutable.Map({})).get('total')

    if (total) {
      viewed = Math.round((stepAvgData.get('viewed') / total) * 100)
      visited = Math.round((stepAvgData.get('visited') / total) * 100)
      replied = Math.round((stepAvgData.get('replied') / total) * 100)
    } else {
      total = '-'
    }

    return (
      <Container>
        <PreviewWrapper>
          <InfoWrapper>
            <Tooltip
              title={`This includes stats for all messages sent for step ${stepIndex + 1}, including personalized messages and deleted A/B tests`}
              position='top'
              trigger='mouseenter'
              arrow
            >
              <Info width='14px' height='14px' />
            </Tooltip>
          </InfoWrapper>
          <Subject>Totals &amp; averages for step {stepIndex + 1}</Subject>
        </PreviewWrapper>
        <StatsWrapper>
          <StepStat value={total} isFooter />
          <StepStat value={viewed} isFooter percentage />
          <StepStat value={visited} isFooter percentage />
          <StepStat value={replied} isFooter percentage />
        </StatsWrapper>
      </Container>
    )
  }
}

StepStatOverview.propTypes = {
  stepIndex: PropTypes.number,
  stepStats: PropTypes.object
}

export default StepStatOverview

import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DropDown from 'components/DropDown'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class SequencePickerModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sequence: null
    }
  }

  componentDidMount () {
    this.props.actions.fetchSequences()
  }

  handleKeyDown = (e) => {
    const {
      sequence
    } = this.state
    // If 'enter' is pressed
    if (e.keyCode === 13 && sequence !== null) {
      e.preventDefault()
      return this.props.onConfirm(sequence)
    }
    return false
  }

  render () {
    const {
      title,
      description,
      loading,
      onCancel,
      onConfirm,
      isOpen,
      sequences
    } = this.props

    const {
      sequence
    } = this.state

    if (loading) {
      return <Loading />
    }

    const options = sequences.map((s, i) => (
      {
        label: s.get('title'),
        value: s.get('_id'),
        index: i,
        sequence: s
      }
    ))

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='0.5rem' align='center'>
              {description}
            </Label>
            {options.count() > 0 &&
              <DropDown
                placeholder='Select or search sequence'
                inputId='sequence'
                onOptionChange={(option) => {
                  this.setState({
                    sequence: option ? option.sequence : null
                  })
                }}
                options={options}
                mt='1.5rem'
              // maxHeight={105}
                autoFocus
              />}
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              disabled={sequence === null}
              label='confirm'
              onClick={() => {
                onConfirm(sequence)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

SequencePickerModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  sequences: PropTypes.instanceOf(Immutable.List),
  actions: PropTypes.object
}

SequencePickerModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default SequencePickerModal

import styled from 'styled-components'
import { space, display } from 'styled-system'
import theme from '../../themes/light'

const InfoLabel = styled.div`
  color: ${theme.colors.blue};
  font-weight: 400;
  padding: 1rem;
  border: 1px solid ${theme.colors.blue};
  border-radius: 3px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: justify;
  align-items: baseline;

  h1 {
    font-size: 16px;
    margin: 0;
    padding-bottom: 0.5rem;
  }

  a {
    color: ${theme.colors.blue};
  }

  p {
    margin: 0;
  }

  svg {
    margin-right: 0.75rem;
    padding-top: 2px;
  }

  ${space}
  ${display}
`

export default InfoLabel

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tippy'
import EditorCoach from './EditorCoach'
import AttachmentModal from './AttachmentModal'
import LinkMenu from './LinkMenu'
import EmojiMenu from './EmojiMenu'
import CustomFieldMenu from './CustomFieldMenu'
import EllipsesMenu from './EllipsesMenu'
import SendTestEmail from './SendTestEmail'
import VideoLinkModal from './VideoLinkModal'
import UnsubscribeModal from './UnsubscribeModal'
import SaveActionsMenu from './SaveActionsMenu'
import UrlImageModal from './UrlImageModal'
import DragNDropImageModal from './DragNDropImageModal'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import {
  LINK_MENUS,
  SLATE_EDITORS,
  IMAGE_MENUS
} from 'components/SlateEditor/utils/slate/constants'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 68px;
  padding: 16px;
  border-bottom: 1px solid ${theme.colors.gray20};
  background-color: ${theme.colors.gray10};
`

const VerticalDivider = styled.div`
  float: left;
  height: 18px;
  border-left: 1px solid ${theme.colors.gray20};
  margin: 8px 12px 9px;
`

const HorizontalSpacer = styled.div`
  margin-left: ${props => props.ml ? props.ml : 'auto'};
  margin-right: ${props => props.mr ? props.mr : 'auto'};
`

const TextToolbar = styled.div`
  margin-top: 1px;
`

const EditorButtonWrapper = styled.div`
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 3px 1px;
  text-align: center;
  border-radius: 2px;
  background-color: ${props => props.isActive
    ? theme.colors.gray30
    : theme.colors.gray10};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.isActive
      ? theme.colors.gray30
      : theme.colors.gray20};
  }
`

const EditorButton = styled.div``

const ActionToolbar = styled.div`
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`

const ActionToolSet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RemoveButton = styled.div`
  margin-right: 0.5rem;
`

const SendTestEmailButton = styled.div`
  margin-right: 0.5rem;
`

const SaveActionsButton = styled.div`
  margin-left: 0.5rem;
`

class Toolbar extends React.Component {
  renderSaveActions = () => {
    const {
      menuVisibility,
      toggleToolbarMenuVisibility
    } = this.props

    return (
      <SaveActionsButton
        onClick={() => toggleToolbarMenuVisibility('showSaveActionsMenu')}
      >
        <IconButton
          type={['fal', 'angle-down']}
          iconSize='lg'
          translateY='1'
        />
        {menuVisibility && menuVisibility.showSaveActionsMenu &&
          <SaveActionsMenu saveActions={this.props.saveActions} />}
      </SaveActionsButton>
    )
  }

  renderSendTestEmail = () => {
    if (!this.props.onSendTestEmail) {
      return
    }

    const { toggleToolbarMenuVisibility } = this.props
    return (
      <Button
        handleClick={() => toggleToolbarMenuVisibility('showSendTestEmailMenu')}
        label='Send Test Email'
        small
        primary
      />
    )
  }

  renderToolbarButtons = () => {
    const {
      getSubjectEditorColor,
      setToolbarMenuVisibility,
      toggleToolbarMenuVisibility,
      buttonsActive,
      menuVisibility,
      readOnly,
      customFields,
      onShowTemplates,
      onSaveTemplate,
      hideVariables,
      hideTemplates,
      activeEditorRef,
      activeEditorName,
      showSignature,
      onToggleSignature,
      isSignatureDisabled
    } = this.props

    return [
      {
        key: 'bold',
        faIcon: ['far', 'bold'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isBoldButtonActive,
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            activeEditorRef.current.toggleMark('bold')
          }
        }
      },
      {
        key: 'italic',
        faIcon: ['far', 'italic'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isItalicButtonActive,
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            activeEditorRef.current.toggleMark('italic')
          }
        }
      },
      {
        key: 'underline',
        faIcon: ['far', 'underline'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isUnderlineButtonActive,
        style: { position: 'relative', top: '1px' },
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            activeEditorRef.current.toggleMark('underline')
          }
        }
      },
      { key: 'divider' },
      {
        key: 'ordered_list',
        faIcon: ['far', 'list-ol'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isOrderedListButtonActive,
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            activeEditorRef.current.toggleOrderedList()
          }
        }
      },
      {
        key: 'unordered_list',
        faIcon: ['far', 'list'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isUnorderedListButtonActive,
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            activeEditorRef.current.toggleUnorderedList()
          }
        }
      },
      { key: 'divider' },
      {
        key: 'link',
        faIcon: ['far', 'link'],
        faIconColor: getSubjectEditorColor(),
        isActive: buttonsActive.isLinkButtonActive,
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            setToolbarMenuVisibility('showLinkMenu', LINK_MENUS.EDIT)
          }
        }
      },
      {
        key: 'emoji',
        faIcon: ['far', 'smile'],
        faIconColor: readOnly ? theme.colors.blueGray : theme.colors.darkBlue,
        isActive: buttonsActive.isEmojiButtonActive,
        handleOnClick: () => toggleToolbarMenuVisibility('showEmojiMenu'),
        modal: () => { // modal attribute is here because the modal is rendered beneath the `emoji` button
          return (
            <>
              {menuVisibility.showEmojiMenu &&
                <EmojiMenu
                  toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
                  editorRef={activeEditorRef}
                />}
            </>
          )
        }
      },
      {
        key: 'custom_field',
        faIcon: ['far', 'brackets-curly'],
        faIconColor: readOnly ? theme.colors.blueGray : theme.colors.darkBlue,
        isActive: buttonsActive.isCustomFieldButtonActive,
        handleOnClick: () => toggleToolbarMenuVisibility('showCustomFieldMenu'),
        modal: () => { // modal attribute is here because the modal is rendered beneath the `customfield` button
          return (
            <>
              {menuVisibility && menuVisibility.showCustomFieldMenu &&
                <CustomFieldMenu
                  toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
                  customFields={customFields}
                  editorRef={activeEditorRef}
                />}
            </>
          )
        }
      },
      {
        key: 'ellipses',
        faIcon: ['far', 'ellipsis-h'],
        faIconColor: getSubjectEditorColor(),
        isActive: menuVisibility.showEllipsesMenu, // `isActive` is determined by if the menu is visible
        handleOnClick: () => {
          if (activeEditorName === SLATE_EDITORS.BODY) {
            toggleToolbarMenuVisibility('showEllipsesMenu')
          }
        },
        modal: () => {
          return (
            <>
              {menuVisibility && menuVisibility.showEllipsesMenu &&
                <EllipsesMenu
                  onShowTemplates={onShowTemplates}
                  onSaveTemplate={onSaveTemplate}
                  editorRef={activeEditorRef}
                  hideVariables={hideVariables}
                  hideTemplates={hideTemplates}
                  toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
                  onToggleSignature={onToggleSignature}
                  showSignature={showSignature}
                  isSignatureDisabled={isSignatureDisabled}
                  setToolbarMenuVisibility={setToolbarMenuVisibility}
                />}
            </>
          )
        }
      }
    ].map(({ key, isActive, faIcon, faIconColor, handleOnClick, style = {}, modal = null }, index) => {
      if (key === 'divider') {
        return <VerticalDivider key={index} />
      } else if (key === 'custom_field' && hideVariables) {
        return null
      }

      return (
        <EditorButtonWrapper key={index} isActive={isActive}>
          <EditorButton onMouseDown={handleOnClick}>
            <FontAwesomeIcon
              icon={faIcon}
              color={faIconColor}
              style={style}
            />
          </EditorButton>
          {modal && modal()}
        </EditorButtonWrapper>
      )
    })
  }

  renderToolbarModals = () => {
    const {
      actions,
      isBrandedDomain,
      onSendTestEmail,
      menuVisibility,
      setToolbarMenuVisibility,
      toggleToolbarMenuVisibility,
      sessions,
      activeEditorRef,
      activeEditorName
    } = this.props

    return (
      <>
        {menuVisibility?.showLinkMenu &&
          <LinkMenu
            editorRef={activeEditorRef}
            activeEditorName={activeEditorName}
            linkMenuType={menuVisibility.showLinkMenu}
            setToolbarMenuVisibility={setToolbarMenuVisibility}
          />}

        {menuVisibility?.showImageMenu === IMAGE_MENUS.URL &&
          <UrlImageModal
            actions={actions}
            isBrandedDomain={isBrandedDomain}
            editorRef={activeEditorRef}
            setToolbarMenuVisibility={setToolbarMenuVisibility}
          />}

        {menuVisibility?.showImageMenu === IMAGE_MENUS.DRAG_N_DROP &&
          <DragNDropImageModal
            actions={actions}
            isBrandedDomain={isBrandedDomain}
            editorRef={activeEditorRef}
            setToolbarMenuVisibility={setToolbarMenuVisibility}
          />}

        {menuVisibility?.showSendTestEmailMenu &&
          <SendTestEmail
            // TODO: `session` NOT `sessions`
            session={sessions}
            onSendEmail={onSendTestEmail}
            toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
          />}

        {menuVisibility?.showUnsubscribeMenu &&
          <UnsubscribeModal
            editorRef={activeEditorRef}
            activeEditorName={activeEditorName}
            toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
          />}

        {menuVisibility?.showVideoLinkMenu &&
          <VideoLinkModal
            editorRef={activeEditorRef}
            activeEditorName={activeEditorName}
            toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
          />}

        {menuVisibility?.showAttachmentsMenu &&
          <AttachmentModal
            editorRef={activeEditorRef}
            isBrandedDomain={isBrandedDomain}
            toggleToolbarMenuVisibility={toggleToolbarMenuVisibility}
          />}

      </>
    )
  }

  render () {
    const {
      readOnly,
      onRemove,
      onSave,
      onSaveLabel,
      saveActions,
      onSendTestEmail,
      warningMsg,
      getBodyMarkdown,
      getSubjectMarkdown,
      toggleToolbarMenuVisibility,
      brokenRules,
      hideEditorCoach,
      needsSaving
    } = this.props

    return (
      <Wrapper>
        <TextToolbar>
          {readOnly
            ? this.renderSendTestEmail()
            : this.renderToolbarButtons()}
        </TextToolbar>

        <ActionToolbar>
          <ActionToolSet>
            {!readOnly && onRemove &&
              <RemoveButton>
                <Tooltip
                  style={{ display: 'block' }}
                  title='Clear Personalized Email'
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <IconButton
                    onClick={onRemove}
                    type={['fal', 'trash-alt']}
                  />
                </Tooltip>
              </RemoveButton>}

            {!readOnly && onSendTestEmail &&
              <SendTestEmailButton>
                <Tooltip
                  style={{ display: 'block' }}
                  title='Send Test Email'
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <IconButton
                    type={['fas', 'paper-plane']}
                    translateX={-1}
                    onClick={() => toggleToolbarMenuVisibility('showSendTestEmailMenu')}
                  />
                </Tooltip>
              </SendTestEmailButton>}

            {!readOnly && warningMsg &&
              <HorizontalSpacer mr='1rem'>
                <Tooltip
                  title={warningMsg}
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <FontAwesomeIcon
                    icon={['fas', 'exclamation-triangle']}
                    color={theme.colors.yellow}
                    size='lg'
                  />
                </Tooltip>
              </HorizontalSpacer>}

            {!hideEditorCoach &&
              <HorizontalSpacer mr='1rem'>
                <EditorCoach
                  brokenRules={brokenRules}
                />
              </HorizontalSpacer>}

            {!readOnly && onSave &&
              <Button
                label={onSaveLabel || 'Save'}
                small
                primary={needsSaving ? needsSaving() : true}
                handleClick={() => {
                  const subject = getSubjectMarkdown()
                  const body = getBodyMarkdown()
                  onSave(subject, body)
                }}
              />}

            {saveActions && saveActions.length && this.renderSaveActions()}
            {this.renderToolbarModals()}
          </ActionToolSet>
        </ActionToolbar>

      </Wrapper>
    )
  }
}

Toolbar.propTypes = {
  actions: PropTypes.any,
  session: PropTypes.object,
  onSendTestEmail: PropTypes.func,
  onShowTemplates: PropTypes.func,
  onSaveTemplate: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
  onSaveLabel: PropTypes.string,
  isBrandedDomain: PropTypes.bool,
  customFields: PropTypes.array,
  showLinkMenu: PropTypes.bool,
  getBodyMarkdown: PropTypes.func,
  getSubjectMarkdown: PropTypes.func,
  readOnly: PropTypes.bool,
  hideVariables: PropTypes.bool,
  hideEditorCoach: PropTypes.bool,
  warningMsg: PropTypes.string,
  hideTemplates: PropTypes.bool,
  saveActions: PropTypes.func,
  toggleToolbarMenuVisibility: PropTypes.func,
  setToolbarMenuVisibility: PropTypes.func,
  menuVisibility: PropTypes.object,
  getSubjectEditorColor: PropTypes.func,
  sessions: PropTypes.object,
  activeEditorRef: PropTypes.object,
  activeEditorName: PropTypes.string,
  buttonsActive: PropTypes.object,
  brokenRules: PropTypes.array,

  // Signature
  showSignature: PropTypes.bool,
  onToggleSignature: PropTypes.func,
  isSignatureDisabled: PropTypes.bool,

  needsSaving: PropTypes.func
}

export default Toolbar

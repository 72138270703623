import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loading from 'components/Loading'
import Workflow from 'components/Workflow'
import EmptyState from 'components/EmptyState'
import ImportOptions from './ImportOptions'
import ImportFinished from '../Import/ImportFinished'
import { Satellite } from 'svg'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  padding: 2rem;
`

const ContentContainer = styled.div`
  background-color: ${theme.containerBackground};
  box-shadow: ${theme.shadow};
  padding: ${theme.padding};
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 32rem;
  max-width: 45rem;
  transition: width 0.3s ease-in, height 0.3s ease-in;
`

const steps = [
  {
    title: 'Import From'
  },
  /* {
    title: 'Recurring' // todo uncomment once backend performance issues fixed
  }, */
  {
    title: 'Finished'
  }
]

class ImportCrm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedOption: undefined,
      createCron: false
    }
    props.actions.importCrmContactsReset()
  }

  UNSAFE_componentWillMount () {
    this.props.actions.fetchActiveCrm({ include_import_options: true })
  }

  render () {
    const {
      sequence,
      activeCRM,
      actions,
      refetchSequence,
      importCrmStep,
      onActiveCRMRefresh
    } = this.props

    const {
      selectedOption
      // createCron
    } = this.state

    if (activeCRM.get('loading') || importCrmStep.get('loading')) {
      return <Loading />
    }

    const options = activeCRM.getIn(['data', 'import_options'])

    let content
    switch (importCrmStep.getIn(['data', 'step'])) {
      case 2:
      case 1:
        content = (
          <ImportFinished
            sequence={sequence}
            csv={activeCRM} // csv and importingCsv are just used for loading check
            importingCsv={activeCRM}
            refetchSequence={refetchSequence}
          />
        )
        break
      /* case 1:
        content = (
          <ImportCron
            activeCRM={activeCRM}
            createCron={createCron}
            onCronToggle={(createCron) => {
              this.setState({
                createCron: createCron
              })
            }}
            onClickSelect={() =>
              actions.importCrmContacts(sequence.get('id'), selectedOption.value, createCron)
            }
          />);
        break; */
      case 0:
      default:
        content = (
          <ImportOptions
            activeCRM={activeCRM}
            options={options}
            selectedOption={selectedOption}
            onOptionSelect={(option) => {
              this.setState({
                selectedOption: option
              })
            }}
            onClickSelect={() => {
              // actions.importCrmOptionSelected()
              actions.importCrmContacts(sequence.get('id'), selectedOption)
            }}
            onActiveCRMRefresh={onActiveCRMRefresh}
          />
        )
        break
    }

    return (
      <Wrapper>
        <Workflow steps={steps} currentStep={importCrmStep.getIn(['data', 'step'])} />
        <ContentContainer currentStep={importCrmStep.getIn(['data', 'step'])}>
          {!options &&
            <EmptyState
              icon={<Satellite />}
              title='Nowhere to Import From'
              description={`We could not find any ${activeCRM.getIn(['data', 'name'])} ${activeCRM.getIn(['data', 'import_type']) ? activeCRM.getIn(['data', 'import_type']).toLowerCase() : 'folder'}s to import from`}
            />}
          {options && content && content}
        </ContentContainer>
      </Wrapper>
    )
  }
}

ImportCrm.propTypes = {
  actions: PropTypes.object,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  sequence: PropTypes.instanceOf(Immutable.Map),
  refetchSequence: PropTypes.func,
  importCrmStep: PropTypes.instanceOf(Immutable.Map),
  onActiveCRMRefresh: PropTypes.func
}

export default ImportCrm

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MainPrice = styled.div`
  display: flex;
  flex-direction: row;
`

const Currency = styled(Label)`
  font: ${theme.fonts.header};
  margin-top: 5px;
  margin-right: 2px;
`

const Price = styled.span`
  color: ${theme.titleColor};
  font: ${theme.fonts.price};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
`

const Period = styled(Label)`
  font: ${theme.fonts.formtitle};
  margin-bottom: 5px;
`

class ChargePreview extends Component {
  render () {
    const {
      label,
      amount
    } = this.props

    return (
      <Wrapper>
        <MainPrice>
          <Currency>$</Currency>
          <Price>{Number(amount / 100).toFixed(2)}</Price>
          {label &&
            <Period>{label}</Period>}
        </MainPrice>
      </Wrapper>
    )
  }
}

ChargePreview.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.number
}

export default ChargePreview

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectState } from 'containers/App/selectors'
import Loading from 'components/Loading'
import ConfirmModal from 'components/ConfirmModal'
import { setNotification } from 'containers/App/actions'
import { selectMembers, selectTeamCounts } from 'containers/Team/selectors'
import { fetchTeamMembers, fetchTeamCounts } from 'containers/Team/actions'
import {
  selectSubscription,
  selectCustomer,
  selectPreview,
  selectUpdatePreview,
  selectCharges,
  selectRequests,
  selectBillingEmails,
  selectPricing
} from './selectors'
import {
  fetchSubscription,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  fetchCustomer,
  fetchPreview,
  fetchUpdatePreview,
  resetUpdatePreview,
  fetchCharges,
  fetchRequests,
  fetchBillingEmail,
  fetchPricing,
  addLookups
} from './actions'
import Subscription from './components/Subscription'
import Subscribe from './components/Subscribe'
import BillingHistory from './components/BillingHistory'
import ChangeSeats from './components/ChangeSeats'
import AddLookups from './components/AddLookups'
import LookupProgressBar from './components/LookupProgressBar'
import Wrapper from './Wrapper'
import { apiBaseURI } from 'utils/api'
import { persistentError } from 'utils/toast'
import theme from '../../themes/light'

const LookupContainer = styled.div`
  background: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  flex: 1 1 auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
`

class Billing extends Component {
  constructor (props) {
    super(props)

    // Default state
    this.state = {
      cancelModal: false,
      change: false,
      add: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      state
    } = this.props

    this.fetchBilling(state)
    document.title = 'Interseller | Billing'

    const { change, add, lookups, error } = this.props.location.query

    if (error !== undefined) {
      persistentError(error)

      const query = this.props.location.query
      delete query.error
      this.props.router.push({
        ...this.props.location,
        query
      })
    }

    if (change !== undefined) {
      this.redirectToStripePortal()
    }

    this.setState({
      change: change !== undefined,
      add: add !== undefined,
      lookups: lookups !== undefined
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.state !== nextProps.state) {
      this.fetchBilling(nextProps.state)
    }
    if (this.props.subscription !== nextProps.subscription && !nextProps.subscription.get('loading')) {
      this.props.actions.fetchCharges()
      this.props.actions.fetchRequests()
    }
  }

  fetchBilling (state) {
    const {
      actions
    } = this.props

    if (state) {
      const subscribed = state.get('subscribed')
      const owner = state.get('is_owner')

      if (subscribed) {
        if (owner) {
          actions.fetchSubscription()
          actions.fetchCustomer()
          actions.fetchPreview()
          actions.fetchCharges()
        }
      } else if (owner) {
        actions.fetchPricing({ recruiter: true })
      }

      actions.fetchRequests()
      actions.fetchTeamMembers()
      actions.fetchTeamCounts()
    }
  }

  redirectToStripePortal () {
    window.location.href = `${apiBaseURI}/stripe_portal`
  }

  render () {
    const {
      subscription,
      charges,
      customer,
      preview,
      updatePreview,
      requests,
      members,
      counts,
      pricing,
      actions,
      billingEmails,
      state,
      router
    } = this.props

    const {
      cancelModal,
      add,
      lookups
    } = this.state

    if (!state || state.get('loading')) {
      return (
        <Loading />
      )
    }

    const subscribed = state.get('subscribed')
    const isOwner = state.get('is_owner')
    const owner = state.get('owner')

    // Only the owner can subscribe
    if (subscribed || !isOwner) {
      return (
        <Wrapper>
          <Subscription
            subscribed={subscribed}
            subscription={subscription}
            customer={customer}
            preview={preview}
            requests={requests}
            isOwner={isOwner}
            owner={owner}
            billingEmails={billingEmails}
            onCancel={() => {
              this.setState({
                cancelModal: true
              })
            }}
            onUpdateBilling={this.redirectToStripePortal}
            onAddSeats={() => {
              this.setState({
                add: true
              })
              const query = { add: null }
              this.props.router.push({
                ...this.props.location,
                query
              })
            }}
          />
          {subscribed &&
            <LookupContainer>
              <LookupProgressBar
                subscription={subscription}
                requests={requests}
                members={members}
                isOwner={isOwner}
                onAddLookups={() => {
                  this.setState({
                    lookups: true
                  })
                  const query = { lookups: null }
                  this.props.router.push({
                    ...this.props.location,
                    query
                  })
                }}
                onBillingCycleChange={(cyclesAgo) => {
                  this.props.actions.fetchRequests(cyclesAgo)
                }}
              />
            </LookupContainer>}
          {isOwner &&
            <BillingHistory
              charges={charges}
              onViewMore={() => {
                this.redirectToStripePortal()
              }}
            />}
          <ConfirmModal
            isOpen={cancelModal}
            onCancel={() => {
              this.setState({
                cancelModal: false
              })
            }}
            onConfirm={() => {
              actions.cancelSubscription()

              this.setState({
                cancelModal: false
              })
            }}
            confirmLabel='Cancel Subscription'
            title='Are you sure you want to cancel?'
            description='To cancel your subscription, please click “Cancel Subscription.” Your Interseller account will remain active until the end of your billing cycle.'
          />
          {isOwner &&
            <ChangeSeats
              isOpen={add}
              onModalClose={() => {
                this.setState({
                  add: false
                })
                const query = { }
                this.props.router.push({
                  ...this.props.location,
                  query
                })
              }}
              preview={updatePreview}
              subscription={subscription}
              actions={actions}
              onSave={(seats) => {
                const prorationDate = Math.round((new Date()).getTime() / 1000)
                actions.updateSubscription({ seats, proration_date: prorationDate })
                this.setState({
                  add: false
                })
              }}
            />}
          {isOwner &&
            <AddLookups
              isOpen={lookups}
              onModalClose={() => {
                this.setState({
                  lookups: false
                })
                const query = { }
                this.props.router.push({
                  ...this.props.location,
                  query
                })
              }}
              subscription={subscription}
              requests={requests}
              onSave={(quantity) => {
                actions.addLookups({ quantity })
                this.setState({
                  lookups: false
                })
              }}
            />}
        </Wrapper>
      )
    }

    const loading = subscription.get('loading')

    return (
      <Wrapper>
        {!loading &&
          <Subscribe
            actions={actions}
            pricing={pricing}
            counts={counts}
            router={router}
          />}
        {loading &&
          <Loading />}
      </Wrapper>
    )
  }
}

Billing.propTypes = {
  state: PropTypes.object,
  subscription: PropTypes.object,
  customer: PropTypes.object,
  preview: PropTypes.object,
  updatePreview: PropTypes.object,
  charges: PropTypes.object,
  requests: PropTypes.object,
  billingEmails: PropTypes.object,
  pricing: PropTypes.object,
  actions: PropTypes.object,
  members: PropTypes.object,
  counts: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  state: selectState(),
  subscription: selectSubscription(),
  customer: selectCustomer(),
  preview: selectPreview(),
  updatePreview: selectUpdatePreview(),
  charges: selectCharges(),
  requests: selectRequests(),
  billingEmails: selectBillingEmails(),
  pricing: selectPricing(),
  members: selectMembers(),
  counts: selectTeamCounts()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSubscription,
      fetchCustomer,
      fetchPreview,
      fetchUpdatePreview,
      resetUpdatePreview,
      fetchCharges,
      fetchRequests,
      fetchTeamMembers,
      fetchTeamCounts,
      fetchBillingEmail,
      fetchPricing,
      setNotification,
      createSubscription,
      updateSubscription,
      cancelSubscription,
      addLookups
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)

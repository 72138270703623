import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { space, width, height } from 'styled-system'
import styled, { css } from 'styled-components'
import { Tooltip } from 'react-tippy'
import Loading from 'components/Loading'
import Verified from 'elements/Verified'
import Warning from 'elements/Warning'
import Error from 'elements/Error'
import Unknown from 'elements/Unknown'
import api from 'utils/api'
import { fromJS } from 'immutable'
import theme from '../../themes/light'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Field = styled.input`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  width: 100%;
  padding: ${props => (props.small ? '0.65rem' : '1rem')};
  border-radius: 3px;
  border-width: 1px;
  border-color: ${theme.colors.gray60};
  border-style: solid;

  ${props => props.disabled && css`
    cursor: default;
    opacity: .5;
  `};
`

const ValidContainer = styled.div`
  position: absolute;
  right: 1.3rem;
  bottom: 1.3rem;

  ${props => props.small && css`
    right: 0.9rem;
    bottom: 0.9rem;
  `}
`

class EmailInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      verdict: null,
      loading: false,
      value: props.value
    }
  }

  validateEmail = (email) => {
    if (email && email.length > 0) {
      this.setState({ loading: true })
      api.post('/emails/verify', { email })
        .then((response) => {
          if (email === this.state.value) {
            const data = fromJS(response.data)
            this.setState({
              verdict: data.get('verdict'),
              loading: false
            })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      this.setState({
        verdict: null,
        loading: false
      })
    }
  }

  componentDidMount () {
    if (this.props.value && this.props.value.length > 0) {
      this.validateEmail(this.props.value)
    }
  }

  render () {
    const {
      label,
      inputId,
      small = false,
      value,
      disabled,
      tooltip,
      action,
      ...rest
    } = this.props
    const {
      verdict,
      loading
    } = this.state

    let validMessage
    let validElement

    if (loading) {
      validMessage = 'Verifying email…'
      validElement = <Loading size={14} padding={0} />
    } else if (verdict === 'GOOD') {
      validMessage = 'Email verified and deliverable'
      validElement = <Verified />
    } else if (verdict === 'RISKY') {
      validMessage = 'Email is deliverable but not verified'
      validElement = <Warning />
    } else if (verdict === 'INVALID' || verdict === 'REJECTED') {
      validMessage = 'Email is invalid and will probably bounce'
      validElement = <Error />
    } else if (verdict === 'UNKNOWN') {
      validMessage = 'Email could not be verified'
      validElement = <Unknown />
    }

    let inputField = (
      <Field
        id={inputId}
        value={value}
        type='email'
        onChange={(e) => {
          this.props.onValueChange(e.target.value)
          this.setState({
            value: e.target.value
          })
        }}
        onBlur={() => (this.validateEmail(this.state.value))}
        small={small}
        disabled={disabled}
      />
    )

    if (tooltip) {
      inputField = (
        <Tooltip
          title={tooltip}
          position='top'
          trigger='mouseenter'
          arrow
        >
          {inputField}
        </Tooltip>
      )
    }

    return (
      <Wrapper {...rest}>
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {action && action}
        {inputField}
        {validElement &&
          <ValidContainer small={small}>
            <Tooltip
            // options
              style={{ display: 'block' }}
              title={validMessage}
              position='top'
              trigger='mouseenter'
              arrow
            >
              {validElement}
            </Tooltip>
          </ValidContainer>}
      </Wrapper>
    )
  }
}

EmailInput.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  action: PropTypes.any,
  onValueChange: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
}

export default EmailInput

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import Loading from 'components/Loading'
import Divider from 'elements/Divider'
import Label from 'elements/Label'
import FormLabel from 'elements/FormLabel'
import IconButton from 'components/IconButton'
import { pluralize } from 'utils/strings'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  display: block;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.white};
  padding-bottom: 8rem;
`

const ContentContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  padding-bottom: 5rem;
`

const ContactTitle = styled.div`
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
`

const ContactLocation = styled(Label)`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  padding-top: ${props => props.tp};
`

const FormContainer = styled.div`
  width: 100%;
`

const FormValue = styled(Label)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`

class ContactMain extends PureComponent {
  UNSAFE_componentWillMount () {
    const {
      contact,
      actions
    } = this.props
    if (contact && !contact.get('anonymized')) {
      actions.fetchContactCompany(contact.get('domain'))
    }
  }

  render () {
    const {
      contact,
      contactCompany
    } = this.props

    const loading = contactCompany.get('loading')

    if (loading) {
      return (
        <Wrapper>
          <ContentContainer>
            <Loading />
          </ContentContainer>
        </Wrapper>
      )
    }

    const name = contactCompany.getIn(['data', 'name']) || contact.get('company')
    const location = contactCompany.getIn(['data', 'location'])
    const domain = contactCompany.getIn(['data', 'domain'])
    const topSocialPadding = name || location ? '1rem' : 0

    const twitter = contactCompany.getIn(['data', 'twitter'])
    const facebook = contactCompany.getIn(['data', 'facebook'])
    const linkedin = contactCompany.getIn(['data', 'linkedin'])

    const phoneNumbers = contactCompany.getIn(['data', 'phone_numbers']) || Immutable.List([])

    const description = contactCompany.getIn(['data', 'description'])
    const url = contactCompany.getIn(['data', 'url'])

    const industry = contactCompany.getIn(['data', 'industry'])
    const employeeRange = contactCompany.getIn(['data', 'employee_range'])
    const emails = contactCompany.getIn(['data', 'emails'])

    return (
      <Wrapper>
        <ContentContainer>
          {domain &&
            <Avatar
              src={domain ? `https://logo.clearbit.com/${domain}` : null}
              name={name}
              size={47}
              maxInitials={2}
              textSizeRatio={2.5}
              round
              colors={theme.avatarColors}
            />}
          {name && (<ContactTitle>{name}</ContactTitle>)}
          {url && (<ContactLocation>(<a href={url} target='_blank' rel='noopener noreferrer'>{url}</a>)</ContactLocation>)}
          {location && <ContactLocation>{location}</ContactLocation>}
          <SocialContainer tp={topSocialPadding}>
            {
              linkedin &&
                <IconButton
                  onClick={() => {
                    window.open(`https://www.linkedin.com/company/${linkedin}/`)
                  }}
                  type={['fab', 'linkedin-in']}
                  marginLeft='0.25rem'
                  marginRight='0.25rem'
                  size={25}
                  buttonColor={theme.colors.linkedin}
                  borderColor={theme.colors.linkedin}
                  iconColor='#FFF'
                />
            }
            {
              twitter &&
                <IconButton
                  onClick={() => {
                    window.open(`https://twitter.com/${twitter}`)
                  }}
                  type={['fab', 'twitter']}
                  marginLeft='0.25rem'
                  marginRight='0.25rem'
                  size={25}
                  buttonColor={theme.colors.twitter}
                  borderColor={theme.colors.twitter}
                  iconColor='#FFF'
                />
            }
            {
              facebook &&
                <IconButton
                  onClick={() => {
                    window.open(`https://facebook.com/${facebook}`)
                  }}
                  type={['fab', 'facebook-f']}
                  marginLeft='0.25rem'
                  marginRight='0.25rem'
                  size={25}
                  buttonColor={theme.colors.facebook}
                  borderColor={theme.colors.facebook}
                  iconColor='#FFF'
                />
            }
          </SocialContainer>
          {industry &&
            <FormContainer>
              <Divider />
              <FormLabel label='Industry'>
                <FormValue align='right' mr='0.25rem'>
                  {industry}
                </FormValue>
              </FormLabel>
            </FormContainer>}
          {employeeRange &&
            <FormContainer>
              <Divider />
              <FormLabel label='Employees'>
                <FormValue align='right' mr='0.25rem'>
                  {employeeRange}
                </FormValue>
              </FormLabel>
            </FormContainer>}
          {description &&
            <FormContainer>
              <Divider />
              <FormLabel label='description' full>
                <FormValue align='left' mr='0.25rem' mt='0.5rem'>
                  {description}
                </FormValue>
              </FormLabel>
            </FormContainer>}
          {(phoneNumbers && phoneNumbers.count()) > 0 &&
            <FormContainer>
              <Divider />
              <FormLabel label={pluralize('phone number', 'phone numbers', phoneNumbers.length)}>
                <FormValue align='right' mr='0.25rem'>
                  {phoneNumbers.join(', ')}
                </FormValue>
              </FormLabel>
            </FormContainer>}
          {(emails && emails.count() > 0) &&
            <FormContainer>
              <Divider />
              <FormLabel label={pluralize('email', 'emails', emails.length)}>
                <FormValue align='right' mr='0.25rem'>
                  {emails.join(', ')}
                </FormValue>
              </FormLabel>
            </FormContainer>}
        </ContentContainer>
      </Wrapper>
    )
  }
}

ContactMain.propTypes = {
  contact: PropTypes.object,
  actions: PropTypes.object,
  contactCompany: PropTypes.object
}

export default ContactMain

import React, { Component } from 'react'
import Immutabale from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FIELD_BLOCKLIST, TOP_LEVEL_FIELD_VALUES } from 'containers/Sequence/constants'
import EmailInput from 'components/EmailInput'
import Input from 'components/Input'
import { fadeInRule } from 'utils/animations'
import { titlecase } from 'utils/strings'
import theme from '../../../../../../../themes/light'

const Wrapper = styled.div`
  display: block;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.white};
  padding-bottom: 2rem;
  animation: ${fadeInRule};
`

const CloneContactButton = styled.label`
  width: 100%;
  color: ${theme.colors.blue};
  font: ${theme.fonts.label};
  text-align: right;
  position: absolute;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.grayBlue};
  }
`

class ContactEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      params: this.paramsFromProps(props)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      params: this.paramsFromProps(nextProps)
    })
  }

  paramsFromProps = (props) => {
    const {
      contact,
      sequence
    } = props

    const name = contact.get('name') || ''
    const email = contact.get('email') || ''
    const title = contact.get('title') || ''
    const location = contact.get('location') || ''
    /* eslint-disable camelcase */
    const location_city = contact.get('location_city') || ''
    /* eslint-disable camelcase */
    const location_state = contact.get('location_state') || ''
    /* eslint-disable camelcase */
    const location_country = contact.get('location_country') || ''
    const college = contact.get('college') || ''
    const degree = contact.get('degree') || ''
    const url = contact.get('url') || ''
    /* eslint-disable camelcase */
    const phone_number = contact.get('phone_number') || ''
    const company = contact.get('company') || ''

    const rawFields = contact.get('raw') || Immutabale.Map({})

    const fields = sequence.get('fields')
      .filter(f => !FIELD_BLOCKLIST.includes(f) && !TOP_LEVEL_FIELD_VALUES.includes(f))
    const customFields = fields.reduce((c, key) => {
      const cFields = c
      cFields[key] = rawFields.get(key) || ''
      return cFields
    }, {})

    return {
      name,
      email,
      title,
      company,
      phone_number,
      location,
      location_city,
      location_state,
      location_country,
      url,
      college,
      degree,
      ...customFields
    }
  }

  render () {
    const {
      contact,
      onCloneContact
    } = this.props
    const {
      params
    } = this.state

    const lastMessagedAt = contact.get('last_messaged_at')

    const rows = Object.keys(params).map((key) => {
      const value = params[key] || ''
      const label = key === 'url' ? 'Website' : titlecase(key.replace(/_/g, ' '))
      const disabled = (key === 'email' && (lastMessagedAt !== undefined))
      let type = 'text'
      switch (key) {
        case 'email':
          type = 'email'
          break
        case 'phone':
          type = 'tel'
          break
        default:
          type = 'text'
          break
      }
      if (key === 'email') {
        let emailTooltip
        let action
        if (disabled) {
          emailTooltip = 'The email address for this contact cannot be edited after messages have been sent'
          action = (
            <CloneContactButton onClick={onCloneContact}>Update</CloneContactButton>
          )
        }

        return (
          <EmailInput
            small
            disabled={disabled}
            action={action}
            key={`contact_form_${key}`}
            label={label}
            value={value}
            type={type}
            inputId={key}
            tooltip={emailTooltip}
            onValueChange={(v) => {
              const p = params
              p[key] = v
              this.setState({ params: p })
            }}
            mb='1.5rem'
          />
        )
      }
      return (
        <Input
          small
          disabled={disabled}
          key={`contact_form_${key}`}
          label={label}
          value={value}
          type={type}
          inputId={key}
          onValueChange={(v) => {
            const p = params
            p[key] = v
            this.setState({ params: p })
          }}
          mb='1.5rem'
        />
      )
    })

    return (
      <Wrapper>
        {rows}
      </Wrapper>
    )
  }
}

ContactEdit.propTypes = {
  contact: PropTypes.object,
  onCloneContact: PropTypes.func
}

export default ContactEdit

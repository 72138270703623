import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'
import { authApi } from 'utils/api'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchEmailDiscoverySuccess,
  fetchEmailDiscoveryFailure,
  fetchEmailLinkFailure,
  fetchEmailLinkSuccess
} from './actions'
import {
  FETCH_EMAIL_DISCOVERY_REQUEST,
  FETCH_EMAIL_LINK_REQUEST
} from './constants'

const authGet = (url, query) => authApi.get(url, {
  params: query
})

export function * fetchEmailDiscovery (action) {
  const {
    email
  } = action

  try {
    const response = yield call(authGet, `/magic/autodiscover/${email}`)
    const discovery = fromJS(response.data)
    yield put(fetchEmailDiscoverySuccess({
      email,
      discovery
    }))
  } catch (err) {
    yield put(fetchEmailDiscoveryFailure(err))
  }
}

export function * fetchEmailLink (action) {
  const {
    email
  } = action

  try {
    yield call(authGet, `/magic/link/${email}`)

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `A sign-in link was emailed to ${email}`
    }))
    yield put(fetchEmailLinkSuccess({ email }))
  } catch (err) {
    yield put(fetchEmailLinkFailure(err))
  }
}

function * fetchEmailDiscoveryRequest () {
  yield takeLatest(FETCH_EMAIL_DISCOVERY_REQUEST, fetchEmailDiscovery)
}

function * fetchEmailLinkRequest () {
  yield takeLatest(FETCH_EMAIL_LINK_REQUEST, fetchEmailLink)
}

export default [
  fetchEmailDiscoveryRequest,
  fetchEmailLinkRequest
]

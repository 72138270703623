import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import Immutable from 'immutable'
import R from 'utils/ramda'
import withSession from 'hocs/session'
import Title from 'elements/Title'
import Label from 'elements/Label'
import DropDown from 'components/DropDown'
import SlateEditor from 'components/SlateEditor'
import Button from 'components/Button'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import ConfirmModal from 'components/ConfirmModal'
import { Satellite } from 'svg'
import Wrapper from './Wrapper'
import TemplateList from './components/TemplateList'
import NewTemplateModal from './components/NewTemplateModal'
import RenameTemplateModal from './components/RenameTemplateModal'
import {
  selectTemplates
} from './selectors'
import {
  fetchTemplates,
  saveTemplate,
  deleteTemplate
} from './actions'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'
import theme from '../../themes/light'

const Column = styled.div`
  &:first-child {
    padding-right: 1rem;
  }
`

const FixedHeightColumn = styled(Column)`
  height: 80vh;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;
`

const HeaderTitle = styled(Title)`
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  display: grid;
  grid-template-columns: 45% 55%;
  align-items: start;
`

const EmptyContainer = styled.div`
  padding: 2rem;
  margin-top: 1rem;
  background-color: ${theme.colors.white};
`

const CloneWarningBar = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray10};
  border-bottom: 1px solid ${theme.colors.gray20};
  padding: 16px 20px;

  display: flex;
  flex-direction: row;
`

const MessageLabel = styled(Label)`
  font: ${theme.fonts.button};
  color: ${theme.labelColor};
  text-transform: uppercase;
`

const CloneButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
`

class Templates extends Component {
  draftPending = false

  constructor (props) {
    super(props)
    this.state = {
      filter: 'me',
      selectedTemplate: null,
      showTemplateModal: false,
      cloneTemplate: null,
      showRenameTemplateModal: false,
      showSaveChangesModal: false
    }
  }

  needsSaving () {
    return this.draftPending && !this.state.showSaveChangesModal
  }

  setStatePendingSave (nextState, nextFn) {
    if (this.needsSaving()) {
      this.setState({
        showSaveChangesModal: true,
        pendingCall: () => {
          this.setState(nextState)
          return nextFn ? nextFn() : null
        }
      })

      return
    }

    this.setState(nextState)
    return nextFn ? nextFn() : null
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
      if (this.needsSaving()) {
        this.setState({
          showSaveChangesModal: true,
          nextLocation
        })

        return false
      }

      return true
    })

    const nextState = {}
    const query = this.props.location.query || {}

    nextState.filter = query.filter || 'me'

    if (query.template) {
      if (nextState.filter !== 'public') {
        nextState.filter = 'team'
      }
      nextState.selectedTemplate = query.template
    }

    this.setState(nextState)
    actions.fetchTemplates(nextState.filter)
    document.title = 'Interseller | Templates'
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const prevProps = this.props

    const prevLoading = prevProps.templates.get('loading')
    const nextLoading = nextProps.templates.get('loading')
    const prevTemplates = prevProps.templates.get('data')
    const nextTemplates = nextProps.templates.get('data')

    if (prevTemplates.count() !== nextTemplates.count() || (!nextLoading && prevLoading !== nextLoading)) {
      let selectedTemplate = null

      // if we're still loading from the query string, use that first
      // string will represent the "interim" loading state
      if (typeof this.state.selectedTemplate === 'string') {
        selectedTemplate = nextTemplates.find((t) => t.get('id') === this.state.selectedTemplate)
      }

      this.setState({
        selectedTemplate: selectedTemplate || nextTemplates.first()
      })
    }
  }

  updateQueryParams () {
    const {
      filter,
      selectedTemplate
    } = this.state

    let template = selectedTemplate
    if (template && typeof template !== 'string' && template.get('id')) {
      template = template.get('id')
    }

    const query = {
      template,
      filter
    }

    if (!query.template) {
      delete query.template
    }

    if (!query.filter) {
      delete query.filter
    }

    if (!R.equals(query, this.props.location.query)) {
      this.props.router.push({
        ...this.props.location,
        query
      })
    }
  }

  renderTemplateDropdown () {
    const {
      actions
    } = this.props

    const selectOptions = Immutable.List([
      {
        label: 'My Templates',
        value: 'me'
      },
      {
        label: 'Team Templates',
        value: 'team'
      },
      {
        label: 'Public Templates',
        value: 'public'
      }
    ])

    return (
      <DropDown
        options={selectOptions}
        clearable={false}
        controlled
        searchable
        height='3rem'
        placeholder=''
        value={this.state.filter}
        onOptionChange={(option) => {
          this.setStatePendingSave({
            filter: option.value,
            selectedTemplate: null
          }, () => {
            actions.fetchTemplates(option.value)
          })
        }}
        borderColor={theme.borderColor}
        style={{
          zIndex: 100
        }}
      />
    )
  }

  renderTemplateHeader () {
    return (
      <Header>
        <HeaderTitle>Templates</HeaderTitle>
        <Button
          label='New Template'
          primary
          onClick={() => {
            this.setState({
              showTemplateModal: true,
              cloneTemplate: null
            })
          }}
        />
      </Header>
    )
  }

  render () {
    const {
      templates,
      actions,
      session
    } = this.props

    const {
      showTemplateModal,
      cloneTemplate,
      showRenameTemplateModal,
      showSaveChangesModal
    } = this.state

    const selectedTemplate = this.state.selectedTemplate || templates.get('data').first()

    if (templates.get('loading') || typeof selectedTemplate === 'string') {
      return (
        <Wrapper>
          {this.renderTemplateHeader()}
          <Container>
            <Column>
              {this.renderTemplateDropdown()}
            </Column>
          </Container>
          <Loading />
        </Wrapper>
      )
    }

    this.updateQueryParams()

    const isPublic = selectedTemplate && selectedTemplate.get('public')
    const isOwner = selectedTemplate && !isPublic && selectedTemplate.getIn(['_user', '_id']) === session.get('id')

    return (
      <Wrapper>
        {this.renderTemplateHeader()}
        {!selectedTemplate &&
          <Container>
            <Column>
              {this.renderTemplateDropdown()}
            </Column>
          </Container>}
        {!selectedTemplate &&
          <EmptyContainer>
            <EmptyState
              icon={<Satellite />}
              title='No Templates'
              description='Use templates as a library to store and share email copy with your team. Get started by creating a new template or check out our list of public templates.'
            >
              <Button
                primary
                label='New Template'
                handleClick={() => {
                  this.setStatePendingSave({
                    showTemplateModal: true,
                    cloneTemplate: selectedTemplate
                  })
                }}
                mr='1rem'
              />
              <Button
                label='View Public Templates'
                handleClick={() => {
                  this.setState({
                    filter: 'public'
                  })

                  actions.fetchTemplates('public')
                }}
              />
            </EmptyState>
          </EmptyContainer>}
        {selectedTemplate &&
          <Container>
            <FixedHeightColumn>
              {this.renderTemplateDropdown()}
              <TemplateList
                templates={templates}
                session={session}
                selectedTemplate={selectedTemplate}
                onSelectTemplate={(template) => {
                  this.setStatePendingSave({
                    selectedTemplate: template
                  })
                }}
                onDeleteTemplate={(template) => {
                  actions.deleteTemplate(template)
                  this.setState({
                    selectedTemplate: null
                  })
                }}
                onRenameTemplate={(template) => {
                  this.setStatePendingSave({
                    selectedTemplate: template,
                    showRenameTemplateModal: true
                  })
                }}
              />
            </FixedHeightColumn>
            <Column>
              {(isPublic || !isOwner) &&
                <CloneWarningBar>
                  <MessageLabel>
                    {isPublic && <span>You are viewing a template that is public.&nbsp;</span>}
                    {!isOwner && <span>You are viewing a teammate's template.&nbsp;</span>}
                    It is recommended to clone and personalize the template before using it in your sequence.
                  </MessageLabel>
                  <CloneButtonContainer>
                    <Button
                      label='Clone'
                      small
                      onClick={() => {
                        this.setState({
                          showTemplateModal: true,
                          cloneTemplate: selectedTemplate
                        })
                      }}
                    />
                  </CloneButtonContainer>
                </CloneWarningBar>}
              <SlateEditor
                key={selectedTemplate.get('id')}
                ref={(element) => { this.editor = element; return this.editor }}
                onSave={() => {
                  const subject = this.editor.getMarkdown(SLATE_EDITORS.SUBJECT)
                  const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

                  actions.saveTemplate({
                    id: selectedTemplate.get('id'),
                    subject,
                    markdown
                  })

                  this.draftPending = false
                  return this.draftPending
                }}
                isBrandedDomain={session.get('branded_domain_enabled')}
                subject={selectedTemplate.get('subject')}
                body={selectedTemplate.get('markdown')}
                readOnly={isPublic || !isOwner}
                hideToolbar={isPublic || !isOwner}
                hideTemplates
                hideEditorCoach
                hasEnteredKeyStroke={() => {
                  this.draftPending = true
                }}
                mt={0}
              />
            </Column>
          </Container>}
        <NewTemplateModal
          title={cloneTemplate ? 'Clone Template' : 'New Template'}
          description={cloneTemplate ? 'Choose a name for your cloned template' : 'Create a template to use in your sequences'}
          isOpen={showTemplateModal}
          onSaveTemplate={(values) => {
            actions.saveTemplate({
              name: values.name,
              shared: values.shared,
              subject: cloneTemplate ? cloneTemplate.get('subject') : null,
              markdown: cloneTemplate ? cloneTemplate.get('markdown') : null
            })

            this.setState({
              showTemplateModal: false,
              cloneTemplate: null,
              selectedTemplate: null,
              filter: 'me'
            })
          }}
          onCancel={() => {
            this.setState({
              showTemplateModal: false,
              cloneTemplate: null
            })
          }}
        />
        <RenameTemplateModal
          title='Rename Template'
          description='Input the name you would like to rename your template to'
          selectedTemplate={selectedTemplate}
          isOpen={showRenameTemplateModal}
          onSaveTemplate={(values) => {
            actions.saveTemplate({
              id: selectedTemplate.get('id'),
              name: values.name
            })

            this.setState({
              showRenameTemplateModal: false
            })
          }}
          onCancel={() => {
            this.setState({
              showRenameTemplateModal: false
            })
          }}
        />
        <ConfirmModal
          isOpen={showSaveChangesModal}
          onCancel={() => {
            const {
              nextLocation,
              pendingCall
            } = this.state

            if (nextLocation) {
              this.props.router.push(this.state.nextLocation)
            } else if (pendingCall) {
              pendingCall()
            }

            this.setState({
              nextLocation: null,
              pendingCall: null,
              showSaveChangesModal: false
            })
            this.draftPending = false
          }}
          onConfirm={() => {
            const subject = this.editor.getMarkdown(SLATE_EDITORS.SUBJECT) || null
            const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

            actions.saveTemplate({
              id: selectedTemplate.get('id'),
              subject,
              markdown
            })

            setTimeout(() => {
              const {
                nextLocation,
                pendingCall
              } = this.state
              if (nextLocation) {
                this.props.router.push(this.state.nextLocation)
              } else if (pendingCall) {
                pendingCall()
              }
              this.setState({
                nextLocation: null,
                pendingCall: null,
                showSaveChangesModal: false
              })
              this.draftPending = false
            }, 1000)
          }}
          cancelLabel='Discard Changes'
          confirmLabel='Save Changes'
          title='Unsaved changes'
          description='Do you want to save changes to your template?'
        />
      </Wrapper>
    )
  }
}

Templates.propTypes = {
  actions: PropTypes.object,
  templates: PropTypes.object,
  session: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
  route: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  templates: selectTemplates()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTemplates,
      saveTemplate,
      deleteTemplate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSession(Templates))

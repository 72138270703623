import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import General from './General'
import Schedule from './Schedule'
import CustomFields from './CustomFields'
import Archive from './Archive'
import Integration from './Integration'
import Transfer from './Transfer'
import Immutable from 'immutable'
import { FIELD_BLOCKLIST, TOP_LEVEL_FIELD_VALUES } from 'containers/Sequence/constants'
import TabbedModal from 'components/TabbedModal'

class Settings extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      sequence: props.sequence,
      mappedFields: Immutable.List([])
    }
  }

  getMappedFields (sequence) {
    const fields = sequence.get('fields')
    const values = sequence.get('fields_default_values') || Immutable.Map({})

    const mappedFields = fields
      .filter(f => !FIELD_BLOCKLIST.includes(f) && !TOP_LEVEL_FIELD_VALUES.includes(f))
      .map((field) => {
        let value = null

        if (values.get(field) !== undefined) {
          value = values.get(field)
        }

        return {
          field,
          value
        }
      })

    return mappedFields
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const mappedFields = this.getMappedFields(nextProps.sequence)

    this.setState({
      sequence: nextProps.sequence,
      mappedFields
    })
  }

  setMappedFields = (mappedFields) => {
    this.setState({
      mappedFields
    })
  }

  handleChange = (value, index) => {
    const { mappedFields } = this.state

    this.setMappedFields(
      mappedFields.update(index, () => ({
        ...mappedFields.get(index),
        ...value
      }))
    )
  }

  handleAddRow = () => {
    const { mappedFields } = this.state

    this.setMappedFields(
      mappedFields.push({
        field: '',
        value: '',
        unsaved: true
      })
    )
  }

  handleDeleteRow = (idx) => {
    const { mappedFields } = this.state

    this.setMappedFields(
      mappedFields.filter((field, index) => index !== parseInt(idx))
    )
  }

  render () {
    const {
      session,
      actions,
      crm,
      addresses,
      members,
      newSequence,
      onSave,
      onCancel,
      onArchive,
      onTransfer,
      settingsTab,
      isManager,
      onCRMRefresh,
      ...rest
    } = this.props

    const {
      sequence,
      mappedFields
    } = this.state

    const general = (
      <General
        sequence={sequence}
        addresses={addresses}
        onSettingUpdate={(params) => {
          this.setState({ sequence: sequence.merge(params) })
        }}
      />
    )

    const schedule = (
      <Schedule
        session={session}
        sequence={sequence}
        onSettingUpdate={(params) => {
          this.setState({ sequence: sequence.merge(params) })
        }}
      />
    )

    const integration = (
      <Integration
        sequence={sequence}
        crm={crm}
        onSettingUpdate={(params) => {
          this.setState({ sequence: sequence.merge(params) })
        }}
        onCRMRefresh={onCRMRefresh}
      />
    )

    const customFields = (
      <CustomFields
        actions={actions}
        sequence={sequence}
        mappedFields={mappedFields}
        onAddRow={this.handleAddRow}
        onDeleteRow={this.handleDeleteRow}
        onChange={this.handleChange}
      />
    )

    const archiveSequence = (
      <Archive
        sequence={sequence}
        onArchive={onArchive}
      />
    )

    const transferSequence = (
      <Transfer
        members={members}
        sequence={sequence}
        onTransfer={onTransfer}
        newSequence={newSequence}
        isManager={isManager}
        group={session.get('group')}
      />
    )

    const userId = sequence.getIn(['_user', '_id']) || sequence.get('_user')
    const isOwner = session.get('_id') === userId

    const tabs = [
      {
        label: 'General',
        content: general
      },
      {
        label: 'Schedule',
        content: schedule
      },
      {
        label: 'Syncing',
        content: integration,
        hideButtons: !crm.getIn(['data', 'name'])
      },
      {
        label: 'Fields',
        content: customFields
      },
      {
        label: '·',
        hidden: !isOwner && !isManager
      },
      {
        label: 'Transfer',
        content: transferSequence,
        hideButtons: true,
        danger: true,
        hidden: !isManager
      },
      {
        label: 'Archive',
        content: archiveSequence,
        hideButtons: true,
        danger: true,
        hidden: !isOwner && !isManager
      }
    ]

    return (
      <TabbedModal
        tabs={tabs}
        width='40rem'
        initialTab={settingsTab || 0}
        onCancel={onCancel}
        onConfirm={() => {
          const params = {
            fields: Immutable.List([...FIELD_BLOCKLIST]),
            fields_default_values: Immutable.Map({})
          }

          // save current custom field -> default value
          for (const { field, value } of mappedFields) {
            if (field && !params.fields.includes(field)) {
              params.fields = params.fields.push(field)
            }

            if (field && value) {
              params.fields_default_values = params.fields_default_values
                .merge({
                  [field]: value
                })
            }
          }

          onSave(sequence.merge(params))
        }}
        {...rest}
      />
    )
  }
}

Settings.propTypes = {
  actions: PropTypes.object,
  session: PropTypes.instanceOf(Immutable.Map),
  sequence: PropTypes.instanceOf(Immutable.Map),
  settingsTab: PropTypes.number,
  crm: PropTypes.instanceOf(Immutable.Map),
  members: PropTypes.instanceOf(Immutable.Map),
  newSequence: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onArchive: PropTypes.func,
  onTransfer: PropTypes.func,
  isManager: PropTypes.bool,
  addresses: PropTypes.object,
  onCRMRefresh: PropTypes.func
}

export default Settings

/* eslint-disable react/no-string-refs */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popper from 'components/Popper'
import Immutable from 'immutable'
import { Caret } from 'svg'
import theme from '../../../../../../themes/light'

const PopperWrapper = styled.div`
  .popper {
    overflow: visible;
  }
`

const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`

const Button = styled.button`
  color: ${theme.labelColor};
  background-color: transparent;
  text-align: center;
  padding: 0.5rem 1.5rem;
  width: 100%;
  text-transform: uppercase;
  font: ${theme.fonts.button};
  letter-spacing: .05em;
  border-style: solid;
  border-radius: 9999px;
  border-width: 1px;
  border-color: ${theme.gray40};
  white-space: nowrap;
  opacity: 1;
  cursor: pointer;
  &:hover {
    opacity: .5;
    transition: opacity .15s ease-in;
  }
  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1.7rem;
`

const PopTip = styled.form`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${theme.colors.gray30};
`

const Options = styled.div`
  padding: 0.5rem;
`

const MenuItem = styled.li`
  padding: 0.5rem;
  display: block;
  font-size: 15px;
  line-height: 16px;
  border-radius: 2px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: ${theme.highlightBackground};
  }
`

class ImportDropdown extends Component {
  render () {
    const {
      onClickCrm,
      onClickCsv,
      activeCRM
    } = this.props

    return (
      <PopperWrapper>
        <Popper
          ref='popper'
          position='bottom-start'
          trigger={
            <ButtonWrapper>
              <Button>
                IMPORT
                <Caret
                  color={theme.colors.gray70}
                  style={{ margin: '-4px -12px -4px 0' }}
                  viewBox='-1 -3 28 28'
                />
              </Button>
            </ButtonWrapper>
          }
        >
          <PopTip>
            <Options>
              <MenuItem onClick={onClickCrm}>
                Import from {activeCRM.getIn(['data', 'name'])}
              </MenuItem>
              <MenuItem onClick={onClickCsv}>
                Import from CSV
              </MenuItem>
            </Options>
          </PopTip>
        </Popper>
      </PopperWrapper>
    )
  }
}

ImportDropdown.propTypes = {
  onClickCrm: PropTypes.func,
  onClickCsv: PropTypes.func,
  activeCRM: PropTypes.instanceOf(Immutable.Map)
}

export default ImportDropdown

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { timeAgo } from 'utils/dates'
import Subtitle from 'elements/Subtitle'
import ComponentError from 'elements/ComponentError'
import Label from 'elements/Label'
import Loading from 'components/Loading'
import EmptyState from 'components/EmptyState'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const LogContainer = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 2rem 0 0;
  border: 1px solid ${theme.colors.gray30};
`

const Log = styled.li`
  padding: 1rem;
  color: ${theme.colors.darkBlue};

  & > * {
    line-height: 1.25;
  }

  span {
    display: inline-block;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    font-weight: 300;
    color: ${theme.colors.gray70};

    &:last-child {
      font-size: 0.875rem;
    }
  }

  & + li {
    border-top: 1px solid ${theme.colors.gray30};
  }
`

const ErrorContainer = styled.div`
  padding: 2rem;
  max-width: 480px;
  margin: 0 auto;
`

function Logs ({ state, actions, teamLogs }) {
  useEffect(() => {
    const now = new Date().toISOString()
    actions.fetchTeamLogs(now)
  }, [JSON.stringify(teamLogs?.get('data'))])

  const renderLog = (user, type, description, date, key) => {
    let formattedTime = timeAgo(date)
    formattedTime = formattedTime.charAt(0).toUpperCase() + formattedTime.slice(1)

    return (
      <Log key={key}>
        <span>{user}</span> – <span>{type}</span>
        <p>{description}</p>
        <p>{formattedTime}</p>
      </Log>
    )
  }

  const renderState = () => {
    let pendingState

    if (!(state?.get('is_owner') || state?.get('is_manager'))) {
      pendingState = (
        <ErrorContainer>
          <ComponentError message='404 NOT_FOUND — You must be an owner or manager to view logs.' />
        </ErrorContainer>
      )
    }

    if (teamLogs.get('loading') === true) {
      pendingState = <Loading />
    }

    if (teamLogs.get('loading') === false && !teamLogs?.get('data')?.size) {
      pendingState = (
        <EmptyState
          title='No logs'
          description='There are no team activities to display.'
        />
      )
    }

    return pendingState
  }

  return (
    <Wrapper>
      <Subtitle>Logs</Subtitle>
      <Label mt='0.25rem'>View your team's most recent activities.</Label>
      <LogContainer>
        {renderState() ||
          teamLogs?.get('data').map((log, index) => (
            renderLog(
              log?.getIn(['_user', 'full_name']) || log?.getIn(['_user', 'email']),
              log?.get('type'),
              log?.get('description'),
              log?.get('created_at'),
              'log-' + index
            )))}
      </LogContainer>
    </Wrapper>
  )
}

Logs.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  teamLogs: PropTypes.object
}

export default Logs

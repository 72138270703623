import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import format from 'date-fns/format'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const SentMessageBar = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray10};
  border-bottom: 1px solid ${theme.colors.gray20};
  padding: 16px 20px;
`

const DayLabel = styled(Label)`
  font: ${theme.fonts.button};
  color: ${theme.labelColor};
  text-transform: uppercase;
`

class EditorStatusBar extends Component {
  render () {
    const {
      selectedContact,
      selectedStep,
      showPersonalized
    } = this.props

    if (!selectedContact || !selectedStep) {
      return null
    }

    let contactName
    if (selectedContact) {
      contactName = selectedContact.getIn(['data', 'parsed_name', 'full']) || selectedContact.getIn(['data', 'email'])
    }

    const contactFinished = selectedContact.getIn(['data', 'finished'])

    let contactStatus
    if (selectedContact && contactFinished) {
      let contactState = selectedContact.getIn(['data', 'state'])
      if (contactState === 'error') {
        contactState = selectedContact.getIn(['data', 'error']).toLowerCase()
      }

      switch (contactState) {
        case 'replied':
          contactStatus = 'responded to your sequence'
          break
        case 'booked':
          contactStatus = 'responded to your sequence'
          break
        case 'unsubscribed':
          contactStatus = 'unsubscribed from your sequence'
          break
      }
    }

    const stepType = selectedStep.get('type')
    if (stepType === 'message') {
      const message = selectedStep.get('object')
      const messageSentAt = message.get('sent_at')

      if (messageSentAt) {
        return (
          <SentMessageBar>
            <DayLabel>
              You are viewing a message that was sent on {format(messageSentAt, 'MMMM D')} at {format(messageSentAt, 'h:mma')} to {contactName}
            </DayLabel>
          </SentMessageBar>
        )
      }

      if (showPersonalized && contactName) {
        if (contactStatus) {
          return (
            <SentMessageBar>
              <DayLabel>
                Emails to {contactName} cannot be personalized because they've {contactStatus}
              </DayLabel>
            </SentMessageBar>
          )
        }

        if (message.get('type') === 'manual' && !messageSentAt) {
          return (
            <SentMessageBar>
              <DayLabel>
                You are editing a personalized message to {contactName} that has not yet been sent
              </DayLabel>
            </SentMessageBar>
          )
        }
      }
    } else if (stepType === 'task') {
      const task = selectedStep.get('object')
      const taskCompletedAt = task.get('completed_at')

      if (taskCompletedAt) {
        return (
          <SentMessageBar>
            <DayLabel>
              You are viewing a task that was completed on {format(taskCompletedAt, 'MMMM D')} at {format(taskCompletedAt, 'h:mma')}
            </DayLabel>
          </SentMessageBar>
        )
      }

      if (showPersonalized && contactName) {
        return (
          <SentMessageBar>
            <DayLabel>
              You are viewing a preview of the task that will be added to your task queue for {contactName}. You cannot complete this task until it is added to your queue.
            </DayLabel>
          </SentMessageBar>
        )
      }

      return (
        <SentMessageBar>
          <DayLabel>
            You are viewing a preview of the task that will be added to your task queue
          </DayLabel>
        </SentMessageBar>
      )
    }

    return null
  }
}

EditorStatusBar.propTypes = {
  selectedContact: PropTypes.object,
  selectedStep: PropTypes.object,
  showPersonalized: PropTypes.bool
}

export default EditorStatusBar

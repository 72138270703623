import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign, color, fontSize, fontWeight } from 'styled-system'
import theme from '../../themes/light'

const Wrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  color: ${theme.labelColor};
  font: ${theme.fonts.normal};
  text-align: left;
  padding-top: 0;

  ${space}
  ${width}
  ${textAlign}
  ${color}
  ${fontSize}
  ${fontWeight}
`

class Label extends Component {
  render () {
    return (
      <Wrapper {...this.props}>
        {this.props.children}
      </Wrapper>
    )
  }
}

Label.propTypes = {
  children: PropTypes.any
}

export default Label

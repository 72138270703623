import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import DropDown from 'components/DropDown'

const Wrapper = styled.div``

class LegacyDropdown extends React.Component {
  UNSAFE_componentWillReceiveProps (nextProps) {
    // this will reset the parameters if there are too many and assuming that
    // the report this component is displayed in cannot support it either
    if (
      nextProps.selectedUserIds.count() > 1 ||
      nextProps.selectedSequenceIds.count() > 1
    ) {
      nextProps.setFilter()
    }
  }

  render () {
    const {
      state,
      users,
      sequences,
      session,

      selectedUserIds,
      selectedSequenceIds,

      setFilter
    } = this.props

    const isManager = state.get('is_manager')

    let options = []
    if (isManager) {
      options.push({
        label: 'Users',
        type: 'header'
      })
      users.get('data')
        .filter(user => !session.get('group') || session.get('group') === user.get('group'))
        .forEach((user, i) => {
          options.push({
            i,
            label: user.get('full_name') || user.get('email'),
            value: JSON.stringify({
              userId: user.get('id')
            }),
            index: i
          })
        })
    }
    options.push({
      label: 'Sequences',
      type: 'header'
    })
    sequences.get('data')
      .forEach((sequence, i) => {
        options.push({
          i,
          label: sequence.get('title'),
          value: JSON.stringify({
            // userId: sequence.getIn(['_user', '_id']),
            sequenceId: sequence.get('_id')
          }),
          index: users.get('data').count() + i
        })
      })
    options = Immutable.List(options)

    let selectedOption
    if (selectedUserIds.count() > 0) {
      selectedOption = {
        userId: selectedUserIds.first()
      }
    } else if (selectedSequenceIds.count() > 0) {
      selectedOption = {
        sequenceId: selectedSequenceIds.first()
      }
    }
    if (selectedOption) {
      selectedOption = JSON.stringify(selectedOption)
    }

    return (
      <Wrapper>
        <DropDown
          options={options}
          clearable
          controlled
          searchable
          height='48px'
          placeholder={isManager ? 'Filter by user or sequence…' : 'Filter by sequence…'}
          value={selectedOption}
          onOptionChange={(option) => {
            const value = option ? JSON.parse(option.value) : {}
            if (value.userId) {
              setFilter({
                selectedUserIds: Immutable.Set([value.userId])
              })
            } else if (value.sequenceId) {
              setFilter({
                selectedSequenceIds: Immutable.Set([value.sequenceId])
              })
            } else {
              setFilter()
            }
          }}
        />
      </Wrapper>
    )
  }
}

LegacyDropdown.propTypes = {
  state: PropTypes.object,
  sequences: PropTypes.object,
  users: PropTypes.object,
  session: PropTypes.object,

  selectedSequenceIds: PropTypes.instanceOf(Immutable.Set),
  selectedUserIds: PropTypes.instanceOf(Immutable.Set),

  setFilter: PropTypes.func
}

export default LegacyDropdown

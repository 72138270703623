import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Archive } from 'svg'
import theme from '../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  color: ${theme.labelColor};
  font: ${theme.fonts.normal};
  text-align: left;
`

const Label = styled.span`
  margin-left: .5rem;
`

const SequenceActivity = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  transition: border 0.1s linear, box-shadow 0.1s linear;
  background-color: ${props => props.color};
  margin-top: .15rem;
  margin-left: .15rem;
  border-radius: 100px;
`

class SequenceStatusLabel extends Component {
  constructSequenceIcon = (isSequenceArchived, isSequenceActive) => {
    if (isSequenceActive) {
      return (
        <SequenceActivity
          width='.60rem'
          height='.60rem'
          color={theme.colors.green}
        />
      )
    } else if (isSequenceArchived) {
      const { width, height } = this.props
      return (
        <Archive
          width={width}
          height={height}
          translateY='1px'
        />
      )
    } else {
      return (
        <SequenceActivity
          width='.60rem'
          height='.60rem'
          color={theme.colors.gray50}
        />
      )
    }
  }

  render () {
    const { sequence, label, ...rest } = this.props
    const isSequenceArchived = sequence.get('deleted_at')
    const isSequenceActive = sequence.get('active')

    const sequenceIcon = this.constructSequenceIcon(isSequenceArchived, isSequenceActive)
    const sequenceLabel = sequence.get('title')

    return (
      <Wrapper {...rest}>
        {sequenceIcon}
        <Label>
          {label || sequenceLabel}
        </Label>
      </Wrapper>
    )
  }
}

SequenceStatusLabel.propTypes = {
  sequence: PropTypes.object,
  label: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number
}

export default SequenceStatusLabel

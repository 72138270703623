import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  Header,
  TabContainer
} from '../ToolbarModal'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Divider from 'elements/Divider'
import Input from 'components/Input'
import Button from 'components/Button'
import {
  slateHelpers,
  slateConstants
} from 'components/SlateEditor/utils/slate'
import axios from 'axios'
import theme from '../../../../../themes/light'

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const InvalidLabel = styled(Label)`
  color: ${theme.colors.red};
  font: ${theme.fonts.normal};
  text-align: center;
`

const InputContainer = styled.div``

const YOUTUBE_REGEX = /.*(youtube.com)\/watch\?v=(([a-zA-Z0-9_-]){10,})/i
const YOUTUBE_SHARE_REGEX = /.*(youtu.be)\/(([a-zA-Z0-9_-]){10,})/i
const LOOM_REGEX = /(.*)loom.com\/share\/(([a-zA-Z0-9]){10,})/i
const VIDYARD_REGEX = /(.*)vidyard.com\/watch\/([a-zA-Z0-9-]{10,})/i
// VIDYARD_REGEX_2 is for a specific user with a frustrating edge case - https://vineresources.com
// if a `play.vidyard.com` url is pasted in, we will convert the url to the proper video thumbnail
const VIDYARD_REGEX_2 = /(.*)play.vidyard.com\/([a-zA-Z0-9-]{10,}).jpg/i

const REG_EXPRESSIONS = [
  YOUTUBE_REGEX,
  YOUTUBE_SHARE_REGEX,
  LOOM_REGEX,
  VIDYARD_REGEX,
  VIDYARD_REGEX_2
]

class VideoLinkModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      videoLink: '',
      hasSubmitted: false,
      submitError: false,
      errorMsg: ''
    }
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
    window.addEventListener('keydown', this.handleEnterKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
    window.removeEventListener('keydown', this.handleEnterKey, false)
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showVideoLinkMenu')
    }
  }

  handleEnterKey = event => {
    const ENTER_KEY = 13
    if (event.keyCode === ENTER_KEY) {
      this.handleSubmit()
    }
  }

  handleClickOutside = event => {
    const { toggleToolbarMenuVisibility } = this.props
    toggleToolbarMenuVisibility('showVideoLinkMenu')
  }

  isValidVideoLink = videoLink => {
    if (videoLink && slateHelpers.isValidUrl(videoLink)) {
      return YOUTUBE_REGEX.test(videoLink) ||
        YOUTUBE_SHARE_REGEX.test(videoLink) ||
        LOOM_REGEX.test(videoLink) ||
        VIDYARD_REGEX.test(videoLink) ||
        VIDYARD_REGEX_2.test(videoLink)
    }

    return false
  }

  createVideoImageLink = (videoLink) => {
    function getImgString (REGEX, vidId) {
      if (REGEX === YOUTUBE_REGEX || REGEX === YOUTUBE_SHARE_REGEX) {
        return `https://img.youtube.com/vi/${vidId}/0.jpg`
      } else if (REGEX === LOOM_REGEX) {
        return `https://cdn.loom.com/sessions/thumbnails/${vidId}-with-play.gif`
      } else if (REGEX === VIDYARD_REGEX || REGEX === VIDYARD_REGEX_2) {
        // hitting the "email=1" Vidyard url results in a 302 redirect to a smaller sized thumbnail for the vid
        // the image url we need to give to slate editor is the one after the 302
        return axios
          .get(`https://play.vidyard.com/${vidId}.jpg?email=1`)
          .then(function (response) {
            return response.request.responseURL
          })
      }
    }

    for (let i = 0; i < REG_EXPRESSIONS.length; i++) {
      if (REG_EXPRESSIONS[i].test(videoLink)) {
        const matches = REG_EXPRESSIONS[i].exec(videoLink)
        const vidId = matches[2]
        return getImgString(REG_EXPRESSIONS[i], vidId)
      }
    }

    console.error('Error in Video Link: ', videoLink)
    return null
  }

  handleSubmit = () => {
    const { toggleToolbarMenuVisibility, editorRef, activeEditorName } = this.props
    const { videoLink } = this.state
    const pastedVideoLink = videoLink.trim()

    if (activeEditorName === slateConstants.SLATE_EDITORS.SUBJECT ||
      !editorRef ||
      !editorRef.current ||
      !editorRef.current.insertVideoLink) {
      console.error('Error: EditorRef is ', editorRef, '- activeEditorName is ', activeEditorName)
    } else if (!pastedVideoLink) {
      this.setState({
        errorMsg: 'No link provided, please provide valid YouTube, Loom or Vidyard link',
        submitError: true,
        hasSubmitted: true
      })
    } else if (!this.isValidVideoLink(pastedVideoLink)) {
      this.setState({
        errorMsg: 'Please provide valid YouTube, Loom, or Vidyard link',
        submitError: true,
        hasSubmitted: true
      })
    } else {
      Promise
        .resolve()
        .then(() => {
          return this.createVideoImageLink(pastedVideoLink)
        })
        .then(videoThumbnailLink => {
          if (videoThumbnailLink) {
            editorRef
              .current
              .insertVideoLink(videoThumbnailLink, pastedVideoLink)
            return true
          }

          return false
        })
        .then(validLink => {
          if (validLink) {
            toggleToolbarMenuVisibility('showVideoLinkMenu')
          } else {
            this.setState({ hasSubmitted: true, submitError: true })
          }
        })
    }
  }

  render () {
    const {
      toggleToolbarMenuVisibility
    } = this.props
    const {
      videoLink,
      hasSubmitted,
      submitError,
      errorMsg
    } = this.state

    return (
      <Wrapper>
        <Curtain
          onClick={this.handleClickOutside}
          visible
        />
        <Window>
          <TabContainer>
            <Header>
              <Title>Add Video Link</Title>
              {submitError && hasSubmitted
                ? <InvalidLabel mt='0.5rem' mb='0.5rem'>{`${errorMsg}`}</InvalidLabel>
                : <Label mt='0.5rem' mb='0.5rem' align='center'>Add a YouTube,  Loom or Vidyard video link to your email. This will create a clickable thumbnail from the videos link.</Label>}
            </Header>

            <InputContainer>
              <InputWrapper>
                <Input
                  label='Link'
                  type='text'
                  value={videoLink}
                  onValueChange={value =>
                    this.setState({ videoLink: value })}
                />
              </InputWrapper>
            </InputContainer>
          </TabContainer>

          <Divider />

          <ButtonContainer>
            <Button
              mr='0.5rem'
              label='cancel'
              handleClick={() => {
                toggleToolbarMenuVisibility('showVideoLinkMenu')
              }}
            />
            <Button
              primary
              label='add video link'
              handleClick={this.handleSubmit}
            />
          </ButtonContainer>
        </Window>
      </Wrapper>
    )
  }
}

VideoLinkModal.propTypes = {
  editorRef: PropTypes.object,
  activeEditorName: PropTypes.string,
  toggleToolbarMenuVisibility: PropTypes.func
}

export default VideoLinkModal

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../themes/light'

const Wrapper = styled.div`
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.gray40};
  color: ${theme.colors.gray70};
  display: flex;
  width: 26px;
  height: 26px;
  text-align: center;
  font-size: 18px;

  ${props => !props.disabled && css`
    cursor: pointer;

    &:hover {
      transition: all .15s ease-in;
      border-color: ${theme.colors.blue};
      color: ${theme.colors.blue};
    }

    &:active {
      transition: all .15s ease-in;
      border-color: ${theme.colors.blue};
      color: ${theme.colors.blue};
    }
  `};

  ${space}
  ${width}
  ${textAlign}
`

const DropdownArrow = (passthroughProps) => {
  return (
    <Wrapper {...passthroughProps}>
      <FontAwesomeIcon
        icon={['fal', 'angle-down']}
        fixedWidth
        transform={{
          y: 3.5,
          x: 0.75
        }}
      />
    </Wrapper>
  )
}

DropdownArrow.propTypes = {
  color: PropTypes.string
}

export default DropdownArrow

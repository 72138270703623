import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Tooltip } from 'react-tippy'
import { space } from 'styled-system'
import { greenGlowRule, yellowGlowRule } from 'utils/animations'
import theme from '../../../../../themes/light'

const Label = styled.label`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.div`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.small};
  text-align: left;
  padding-top: 0;
  letter-spacing: 0.05em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${space}
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StateDot = styled.div`
  background: ${theme.colors.gray40};
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-top: 0.25rem;

  ${props => props.active && css`
    background: ${theme.colors.green};
    border: 1px solid transparent;
    animation: ${greenGlowRule};
    transition: border 0.1s linear, box-shadow 0.1s linear;
  `}

  ${props => props.warning && css`
    background: ${theme.colors.yellow};
    border: 1px solid transparent;
    animation: ${yellowGlowRule};
    transition: border 0.1s linear, box-shadow 0.1s linear;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

class BrandedDomain extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      session,
      state,
      teamDomain
    } = this.props

    const enabled = session.get('branded_domain_enabled')
    const isOwner = state.get('is_owner')
    const brandedDomain = session.get('branded_domain')
    const distributionDomain = teamDomain.getIn(['data', 'distribution_domain'])
    const distributionPing = teamDomain.getIn(['data', 'distribution_ping'])
    const distributionCertificate = teamDomain.getIn(['data', 'certificate_approved'])
    const brandedDomainConfigured = distributionDomain !== undefined

    let description

    switch (true) {
      case brandedDomainConfigured && isOwner:
        description = (
          <DescriptionWrapper>
            <Description>
              <Tooltip
                // options
                title={enabled
                  ? 'Branded domain is correctly configured and active'
                  : 'Branded domain isn\'t configured correctly<br />Please check your DNS settings'}
                position='top-start'
                trigger='mouseenter'
                arrow
              >
                <StateDot active={enabled} warning={!enabled} />
              </Tooltip>
              <span style={{ marginLeft: '0.5rem' }}>Custom domain: <strong>{brandedDomain}</strong></span>
            </Description>
            <Description mt='0.5rem'>
              <Tooltip
                // options
                title={distributionPing
                  ? 'Distribution domain is correctly configured'
                  : 'Distribution domain isn\'t configured correctly<br />Please check your CNAME configuration on your DNS host provider'}
                position='top-start'
                trigger='mouseenter'
                arrow
              >
                <StateDot mt='0.25rem' active={distributionPing} warning={!distributionPing} />
              </Tooltip>
              <span style={{ marginLeft: '0.5rem' }}>Distribution mapping:<br /><strong>{brandedDomain}</strong> → <strong>{teamDomain.getIn(['data', 'distribution_domain'])}</strong></span>
            </Description>
            <Description mt='0.25rem'>
              <Tooltip
                // options
                title={distributionCertificate
                  ? 'Distribution certificate is correctly configured'
                  : 'Distribution certificate isn\'t configured correctly<br />Please check your CNAME configuration on your DNS host provider'}
                position='top-start'
                trigger='mouseenter'
                arrow
              >
                <StateDot mt='0.25rem' active={distributionCertificate} warning={!distributionCertificate} />
              </Tooltip>
              <span style={{ marginLeft: '0.5rem' }}>Distribution certificate:<br /><strong>{teamDomain.getIn(['data', 'certificate_cname'])}</strong> → <strong>{teamDomain.getIn(['data', 'certificate_cname_value'])}</strong></span>
            </Description>
          </DescriptionWrapper>
        )
        break
      case enabled && (!isOwner || !brandedDomainConfigured):
        description = (
          <Description>
            <Tooltip
              // options
              title='Branded domain is correctly configured and active'
              position='top-start'
              trigger='mouseenter'
              arrow
            >
              <StateDot active />
            </Tooltip>
            <span style={{ marginLeft: '0.5rem' }}>Custom domain: <strong>{brandedDomain}</strong></span>
          </Description>
        )
        break
      default:
        break
    }

    return (
      <>
        {description && (
          <FieldContainer>
            <Wrapper>
              <Label>
                Branded Domain
              </Label>
              {description}
            </Wrapper>
          </FieldContainer>
        )}
      </>
    )
  }
}

BrandedDomain.propTypes = {
  session: PropTypes.object,
  state: PropTypes.object,
  teamDomain: PropTypes.object
}

export default BrandedDomain

import styled from 'styled-components'
import { space } from 'styled-system'
import { fadeInRule } from 'utils/animations'
import theme from '../../../../../themes/light'

const Window = styled.div`
  background-color: ${theme.colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  max-width: 800px;
  z-index: 10;
  animation: ${fadeInRule};
  box-shadow: ${theme.shadow};

  ${space};
`

export default Window

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Label from 'elements/Label'
import Title from 'elements/Title'
import SwitchFieldForm from 'components/SwitchFieldForm'
import theme from '../../../../themes/light'

const ContentContainer = styled.div`
  background-color: ${theme.containerBackground};
  flex: 1 0 auto;
  border: solid 1px ${theme.borderColor};
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 42rem;
`

const TitleContainer = styled.div``

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

class ChromeExtension extends Component {
  render () {
    const {
      session,
      onSettingUpdate
    } = this.props

    const lookup = session.get('chrome_email_lookup')
    const featureFlags = session.get('feature_flags') || session.get('whitelist')
    const redact = session.get('chrome_redact')

    const lookupOptions = Immutable.List([
      {
        value: 'NONE',
        label: 'None - Use only what\'s available on the page'
      },
      {
        value: 'WORK',
        label: 'Work Only - Find only work emails'
      },
      {
        value: 'PERSONAL',
        label: 'Personal Only - Find only personal emails'
      },
      {
        value: 'WORK_PERSONAL',
        label: 'Any (Work First) - Try finding work emails first, then personal emails after'
      },
      {
        value: 'PERSONAL_WORK',
        label: 'Any (Personal First) - Try finding personal emails first, then work emails after'
      }
    ])

    return (
      <ContentContainer>
        <TitleContainer>
          <Title>Chrome Extension</Title>
          <Label mt='0.5rem'>
            Edit and modify how our Chrome extension interacts
            with websites. Our Chrome extension works on all
            websites including LinkedIn, GitHub, AngelList,
            and Crunchbase. You can install our Chrome extension
            from the <a href='https://chrome.google.com/webstore/detail/interseller/coggaiakecmfpkamlplbpacajahgeofh' target='_blank' rel='noopener noreferrer'>Chrome Web Store</a>.
          </Label>
        </TitleContainer>
        <FieldContainer>
          <DropDownFieldForm
            label='Email Lookup'
            description={
              <span>Choose how the Chrome extension tries to lookup emails.</span>
}
            options={lookupOptions}
            value={lookup}
            inputId='chrome_email_lookup'
            onSave={(value) => {
              onSettingUpdate({
                chrome_email_lookup: value
              })
            }}
          />
        </FieldContainer>
        {
          featureFlags.includes('show_chrome_redact') &&
            <FieldContainer>
              <SwitchFieldForm
                label='Enable Redaction (Beta)'
                value={redact}
                description='Redaction is disabled. Names and photos of profiles will be visible while you browse.'
                descriptionOn='Redaction is enabled. Names and photos of profiles will be blanked out while you browse.'
                inputId='redact'
                onSave={(value) => {
                  onSettingUpdate({
                    chrome_redact: value
                  })
                }}
              />
            </FieldContainer>
        }
      </ContentContainer>
    )
  }
}

ChromeExtension.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func
}

export default ChromeExtension

import axios from 'axios'

function getBaseURI () {
  const baseUrl = window.SERVER_DATA.baseUrl || process.env.BASE_URL
  if (baseUrl) {
    return baseUrl
  }
  const nodeEnv = window.SERVER_DATA.nodeEnv || process.env.REACT_APP_INTERSELLER_ENV || process.env.NODE_ENV
  switch (nodeEnv) {
    case 'production':
      return 'https://interseller.io'
    case 'staging':
      return 'https://interseller.dev'
    default:
      return 'https://localhost-interseller.greenhouse.dev'
  }
}

const apiBaseURI = getBaseURI()

const api = axios.create({
  baseURL: `${apiBaseURI}/api`,
  timeout: 20000,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const authApi = axios.create({
  baseURL: `${apiBaseURI}/auth`,
  timeout: 20000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const fileApi = axios.create({
  baseURL: `${apiBaseURI}/api`,
  timeout: 90000,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    'Content-Type': undefined
  }
})

const messageFromError = (error) => {
  if (error.response) {
    if (error.response.data && error.response.data.message && typeof error.response.data.message === 'string') {
      return error.response.data.message
    } else if (error.response.data && error.response.data.name) {
      return `Server responded with error code: ${error.response.status} - ${error.response.data.name}`
    } else if (error.response.status === 400) {
      return 'Unexpected data, please try again'
    } else if (error.response.status === 403) {
      return 'Unauthorized access'
    } else if (error.response.status === 401) {
      return 'Unauthenticated access, please refresh and login again'
    } else if (error.response.status === 404) {
      return 'Resource not found'
    } else if (error.response.status === 500) {
      return 'An unexpected error has occured'
    }
  }

  return 'An unexpected error has occured'
}

export default api

export {
  apiBaseURI,
  fileApi,
  authApi,
  messageFromError
}

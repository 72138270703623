import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../../../../../themes/light'

const Wrapper = styled.span`
  background-color: ${props => props.isFocused ? theme.colors.gray20 : 'transparent'};
`

class Emoji extends React.Component {
  render () {
    const { isFocused, emojiCode } = this.props
    return (
      <Wrapper
        isFocused={isFocused}
      >
        {emojiCode}
      </Wrapper>
    )
  }
}

Emoji.propTypes = {
  isFocused: PropTypes.bool,
  emojiCode: PropTypes.string
}

export default Emoji

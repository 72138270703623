import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign, fontSize } from 'styled-system'
import Loading from 'components/Loading'
import PercentageCircle from 'elements/PercentageCircle'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
`

const Label = styled.div`
  color: ${theme.labelColor};
  font: ${theme.fonts.button};
  text-align: center;
  padding-top: 0;
  text-transform: uppercase;
  margin-bottom: 0.25rem;

  ${fontSize}
`

const Container = styled.div`

`

const TodayContainer = styled.div`
  position: relative;
  width: 100%;
`

const TodayValueContainer = styled.div`
  display: inline-block;
  margin: 0 0.25rem;
`

const PercentageCircleWrapper = styled.div`
  margin: 3px 0.25rem;
  display: inline-block;
  vertical-align: top;
`

const ValueContainer = styled.div`
  text-align: center;

  ${space}
`

const Value = styled.span`
  font: ${theme.fonts.number};
  color: ${theme.titleColor};
  text-align: center;

  ${space}
  ${width}
  ${textAlign}
`

const Limit = styled.span`
  font: ${theme.fonts.formTitle};
  color: ${theme.labelColor};
  text-align: center;

  ${space}
  ${width}
  ${textAlign}
`

class MessageBox extends Component {
  render () {
    const {
      messageCounts
    } = this.props

    if (messageCounts.get('loading')) {
      return (
        <Wrapper>
          <Loading flex='1' padding='0' size={30} />
        </Wrapper>
      )
    }

    // Today numbers
    const sentToday = messageCounts.getIn(['data', 'sent'])
    const queuedToday = messageCounts.getIn(['data', 'queued'])
    const totalToday = sentToday + queuedToday
    const percent = Math.round((sentToday / totalToday) * 100)

    return (
      <Wrapper onClick={this.props.onClick}>
        <Container>
          <TodayContainer>
            <Label>
              Message Queue
            </Label>
            <PercentageCircleWrapper>
              <PercentageCircle
                showPercentage={false}
                size={30}
                // For some reason the second color doesn't show when percent === 0
                percent={percent || 0.0}
                emptyColor={theme.colors.gray30}
                fillColor={theme.colors.blue}
              />
            </PercentageCircleWrapper>
            <TodayValueContainer>
              <ValueContainer>
                <Value>
                  {sentToday}
                </Value>
                <Limit ml='0.25rem' mt='0.25rem' mb='0'>
                  / {totalToday}
                </Limit>
              </ValueContainer>
            </TodayValueContainer>
          </TodayContainer>
        </Container>
      </Wrapper>
    )
  }
}

MessageBox.propTypes = {
  reports: PropTypes.object,
  messageCounts: PropTypes.object,
  onClick: PropTypes.func
}

export default MessageBox

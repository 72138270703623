import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'
import { fadeInRule } from 'utils/animations'
import theme from '../../themes/light'

const Wrapper = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  outline: none !important;
  cursor: pointer;
  display: flex;
  border: none;

  padding: 0;
  margin: 0;

  &::selection { background: none; }
`

const ContentWrapper = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  bottom: ${props => (props.vposition === 'above' ? '100%' : 'initial')};
  top: ${props => (props.vposition === 'below' ? '100%' : 'initial')};
  margin-top: ${props => (props.marginTop || 0)};
  margin-bottom: ${props => (props.marginBottom || 0)};
  left: ${props => (props.position === 'left' ? props.left || 0 : 'initial')};
  right: ${props => (props.position === 'right' ? props.right || 0 : 'initial')};
  min-width: ${props => props.minWidth || '100%'};
  max-width: ${props => props.maxWidth || 'initial'};
  z-index: 101;
  animation: ${fadeInRule};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  max-height: 415px;
  overflow-y: auto;
  border-radius: 3px;
`

class Popover extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      open: props.isOpen
    }
  }

  handleContentClick = (e) => {
    const { open } = this.state
    const { dismissOnClick, onOpen } = this.props

    if (!dismissOnClick && open) {
      e.stopPropagation()
      return false
    }

    this.setState({
      open: !this.state.open
    })

    if (!open && onOpen) {
      onOpen()
    }

    return true
  }

  handleSelectorClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  handleClickOutside = () => {
    this.setState({ open: false })
  }

  handleKeyDown = (e) => {
    // If 'escape' is pressed
    if (e.keyCode === 27) {
      e.preventDefault()
      return this.setState({ open: false })
    }
    return false
  }

  close = () => {
    this.setState({ open: false })
  }

  render () {
    const {
      content,
      children,
      width = 'auto',
      height = '100%',
      position = 'left',
      vposition = 'below',
      shadow = true,
      left,
      right,
      minWidth,
      maxWidth,
      className,
      marginTop,
      marginBottom,
      ...rest
    } = this.props

    return (
      <Wrapper
        height={height}
        width={width}
        onClick={this.handleSelectorClick}
        onKeyDown={this.handleKeyDown}
        tabIndex='0'
        className={className}
        {...rest}
      >
        {children}
        <ContentWrapper
          {...this.state}
          position={position}
          vposition={vposition}
          onClick={this.handleContentClick}
          minWidth={minWidth}
          maxWidth={maxWidth}
          left={left}
          right={right}
          marginTop={marginTop}
          marginBottom={marginBottom}
          closePopover={this.close}
          shadow={shadow}
        >
          {children && React.cloneElement(content, {
            closePopover: this.close,
            open: this.state.open
          })}
        </ContentWrapper>
      </Wrapper>
    )
  }
}

Popover.defaultProps = {
  dismissOnClick: true,
  isOpen: false
}

Popover.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  marginTop: PropTypes.any,
  marginBottom: PropTypes.any,
  position: PropTypes.string,
  vposition: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  dismissOnClick: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func
}

export default onClickOutside(Popover)

import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space, width, textAlign } from 'styled-system'
import withSession from 'hocs/session'
import Label from 'elements/Label'
import { contactErrorToString } from 'utils/keyMapping'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-right: ${props => (props.options ? '1.75rem' : theme.padding)};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${theme.fonts.button};
  opacity: 1;
  transition: opacity .15s ease-in;
  transition: transform 0.25s ease-out;

  ${space}
  ${width}
  ${textAlign}
`

const StatusHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  font-weight: 700;
`

const StatusTitle = styled(Label)`
  color: ${theme.colors.red};
  text-transform: uppercase;
  font-weight: 500;
`

const StatusDetail = styled(Label)`
  color: ${theme.labelColor};
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: 'ellipsis';
`

class StatusText extends Component {
  tooltipForStatus = (error) => {
    if (error) {
      return this.props.contact.get('error_message')
    }
    return null
  }

  render () {
    const {
      contact,
      session,
      readOnly,
      errorFilter,
      ...rest
    } = this.props

    // Forcing paused status since it exists as delayed on the server now
    // const error = contact.get('error');
    let fullError = contact
    if (errorFilter) {
      fullError = contact
        .get('all_errors')
        .find((err) => {
          return err.get('error') === errorFilter
        }) || contact
    }

    const text = contactErrorToString(fullError.get('error'))
    const tooltip = fullError.get('error_message')

    return (
      <Wrapper
        status='error'
        {...rest}
      >
        <StatusHeader>
          <StatusTitle>
            {text}
          </StatusTitle>
        </StatusHeader>
        <StatusDetail>
          {tooltip}
        </StatusDetail>
      </Wrapper>
    )
  }
}

StatusText.propTypes = {
  contact: PropTypes.object,
  onUpdateContact: PropTypes.func,
  onRepliedContactUpdate: PropTypes.func,
  onPauseContact: PropTypes.func,
  onCloneContact: PropTypes.func,
  onUnsubscribeContact: PropTypes.func,
  position: PropTypes.string,
  align: PropTypes.string,
  session: PropTypes.object,
  portal: PropTypes.bool,
  readOnly: PropTypes.bool,
  errorFilter: PropTypes.string
}

export default withSession(StatusText)

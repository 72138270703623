import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const StyledTooltip = styled(ReactTooltip).attrs(props => ({
  className: 'styled-tooltip'
}))`
  &.styled-tooltip {
    font-family: soleil, sans-serif;
    background-color: #333;
    color: #fff;
    padding: 0.6rem 0.8rem;
    border-radius: 4px;
    font-size: 0.95rem;
    -webkit-font-smoothing: antialiased;
    line-height: 1.1rem;
  }

  &.__react_component_tooltip.place-left::after {
    border-left: 8px solid #333!important;
  }

  &.__react_component_tooltip.place-right::after {
    border-right: 8px solid #333 !important;
  }

  &.__react_component_tooltip.place-top::after {
    border-top: 8px solid #333 !important;
  }

  &.__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid #333 !important;
  }
`

export default StyledTooltip

/**
*
* ContactSearch
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  display: inline-block;
  height: 36px;
  min-width: 36px;
  border-radius: 9999px;

  padding: 6px 1rem;

  margin-left: 0.5rem;
  margin-right: 0.5rem;

  border: 1px solid ${props => props.isFocused ? theme.colors.blue : theme.colors.gray40};
  background-color: ${props => props.isFocused ? theme.colors.white : 'transparent'};

  transition: all 0.2s ease;
`

const Icon = styled.div`
  display: inline-block;
  position: absolute;
  margin-top: -1px;
  margin-left: -7px;
  cursor: pointer;
`

const Input = styled.input`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  border: 0;

  font-size: 14px;
  line-height: 20px;

  float: left;
  padding-left: 14px;
  padding-right: 0;

  outline: none;
`

const Label = styled.div`
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  border: 0;

  font-size: 14px;
  line-height: 22px;

  float: left;
  padding-left: 14px;
  padding-right: 0;

  cursor: pointer;
`

class ContactSearch extends Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      isFocused: false,
      inputText: '',
      inputSize: 30
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      inputText: nextProps.search
    })
  }

  onSearch () {
    const {
      search,
      onSearchContacts
    } = this.props

    const {
      inputText
    } = this.state

    if ((search || '') !== (inputText || '')) {
      onSearchContacts(inputText)
    }
  }

  render () {
    const {
      isFocused,
      inputSize,
      inputText
    } = this.state

    let minInputSize = inputSize
    if (isFocused && inputSize < 30) {
      minInputSize = 30
    }

    return (
      <Wrapper
        isFocused={isFocused}
        onClick={() => {
          this.setState({
            isFocused: true
          })
        }}
      >
        <Icon>
          <FontAwesomeIcon
            icon={['far', 'search']}
            color={theme.colors.blue}
            transform={{
              size: 13
            }}
          />
        </Icon>

        {isFocused &&
          <Input
            ref={this.inputRef}
            contentEditable
            value={inputText}
            placeholder={isFocused ? 'Search by name or company...' : ''}
            onChange={(e) => {
              const value = e.target.value
              this.setState({
                inputSize: value.length,
                inputText: value
              })
            }}
            onFocus={(e) => {
              this.setState({
                isFocused: true
              })
            }}
            onBlur={(e) => {
              this.setState({
                isFocused: false
              })

              this.onSearch()
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.onSearch()
                this.inputRef.current.blur()
              }
            }}
            autoFocus
            size={minInputSize}
          />}

        {!isFocused && inputText &&
          <Label>{inputText}</Label>}
      </Wrapper>
    )
  }
}

ContactSearch.propTypes = {
  search: PropTypes.string,
  onSearchContacts: PropTypes.func
}

export default ContactSearch

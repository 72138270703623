import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Editor } from 'slate-react'
import {
  slateSchemas,
  slateQueries,
  slateCommands,
  slateDecorations,
  slateNormalizers,
  slateConstants
} from 'components/SlateEditor/utils/slate/'
import {
  CustomField,
  Emoji
} from '../EditorTags/'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.gray20};
  background-color: ${
    props => props.grayedOut ? theme.colors.gray10 : theme.colors.white
  };

  flex: 1;
  min-height: auto;
  width: 90%;
`

const EditorWrapper = styled.div`
  opacity: ${props => props.grayedOut ? 0.5 : 1};
`

const Paragraph = styled.p`
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const StyledSubjectEditor = styled(Editor)`
  cursor: auto;
  padding: 1rem 1.25rem 1rem 1.25rem;
  width: 100%;
  text-align: left;
  display: block;
  overflow: auto;
`

class SubjectEditor extends React.Component {
  handleOnChange = ({ value }) => {
    const { setActiveEditor, setEditorText } = this.props
    setActiveEditor(slateConstants.SLATE_EDITORS.SUBJECT) // update the active editor to be `Subject`
    setEditorText(value, slateConstants.SLATE_EDITORS.SUBJECT) // update the current value
  }

  handleOnMouseUp = (_event, editor, next) => {
    const { debouncedCheckRules } = this.props
    debouncedCheckRules()
    slateNormalizers.wrapCustomFields(editor)
    return next()
  }

  handleOnKeyUp = (_event, editor, next) => {
    const { debouncedCheckRules } = this.props
    debouncedCheckRules()
    slateNormalizers.wrapCustomFields(editor)
    return next()
  }

  handleOnKeyDown = (_event, _editor, next) => {
    const {
      hasEnteredKeyStroke
    } = this.props

    if (hasEnteredKeyStroke) {
      hasEnteredKeyStroke()
    }

    return next()
  }

  decorateNodes = (node, editor, next) => {
    return slateDecorations.decorateCustomFields(node, editor, next)
  }

  renderBlock = (props, _editor, _next) => {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'paragraph': {
        return (
          <Paragraph {...attributes}>
            {children}
          </Paragraph>
        )
      }
    }
  }

  renderInline = (props, _editor, next) => {
    const {
      node,
      isFocused
    } = props

    switch (node.type) {
      case 'emoji': {
        return (
          <Emoji
            emojiCode={node.data.get('code')}
            isFocused={isFocused}
          />
        )
      }

      default:
        return next()
    }
  }

  renderDecoration = (props, _editor, next) => {
    const { children, decoration, attributes } = props

    switch (decoration.type) {
      case 'custom_field':
        return (
          <CustomField {...attributes}>
            {children}
          </CustomField>
        )
      default:
        return next()
    }
  }

  render () {
    const {
      grayedOut,
      readOnly,
      subject,
      placeholder,
      subjectEditorRef
    } = this.props

    return (
      <Wrapper grayedOut={grayedOut}>
        <EditorWrapper grayedOut={grayedOut}>
          <StyledSubjectEditor
            // DO NOT DELETE: this fixes a bug that occurs in chrome v105+
            style={{
              WebkitUserModify: 'read-write'
            }}
            ref={subjectEditorRef}
            value={subject}
            placeholder={placeholder}
            onKeyDown={this.handleOnKeyDown}
            onKeyUp={this.handleOnKeyUp}
            onChange={this.handleOnChange}
            onSelect={this.handleOnMouseUp}
            decorateNode={this.decorateNodes}
            renderInline={this.renderInline}
            renderBlock={this.renderBlock}
            renderDecoration={this.renderDecoration}
            readOnly={readOnly}
            schema={slateSchemas.subjectSchema}
            plugins={[
              {
                queries: slateQueries.subjectQueries,
                commands: slateCommands.subjectCommands
              }
            ]}
          />
        </EditorWrapper>
      </Wrapper>
    )
  }
}

SubjectEditor.propTypes = {
  subject: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  grayedOut: PropTypes.bool,
  setActiveEditor: PropTypes.func,
  setEditorText: PropTypes.func,
  hasEnteredKeyStroke: PropTypes.func,
  subjectEditorRef: PropTypes.object,
  debouncedCheckRules: PropTypes.func
}

export default SubjectEditor

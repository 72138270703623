import React, { PureComponent } from 'react'
import { Context, getRollbarFromContext } from '@rollbar/react'

// Wraps components and adds session to component
export default function withErrorHandler (ErrorComponent, WrappedComponent) {
  class WrappedErrorComponent extends PureComponent {
    static contextType = Context

    constructor (props) {
      super(props)
      this.state = {
        hasError: false
      }
    }

    componentDidCatch (err) {
      try {
        const rollbar = getRollbarFromContext(this.context)
        rollbar.error(err)
      } catch (rollbarError) {
        console.error(rollbarError)
      }

      this.setState({
        hasError: true
      })
    }

    render () {
      const {
        hasError
      } = this.state
      if (hasError) {
        return ErrorComponent
      }
      return <WrappedComponent {...this.props} />
    }
  }

  return WrappedErrorComponent
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, height } from 'styled-system'
import Loading from 'components/Loading'
import TaskList from '../TaskList'
import EditContactSidebar from '../EditContactSidebar'
import SequenceTaskDropdown from './SequenceTasksDropdown'
import Immutable from 'immutable'
import SlateEditor from 'components/SlateEditor'
import Modal from 'components/Modal'
import Templates from 'components/Templates'
import ConfirmModal from 'components/ConfirmModal'
import EmptyState from 'components/EmptyState'
import { Satellite } from 'svg'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  align-items: start;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 360px);
  flex-direction: column;
  align-items: start;
  justify-content: start;
  ${space}
  ${height}
`

const EditorContainer = styled.div`
  width: 100%;
  flex: 1;
`

const EditorWrapper = styled.div``

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptySection = styled.div`
  height: 100%;
  width: 100%;
  background: ${theme.containerBackground};
  border: 1px solid ${theme.borderColor};
  padding: 5rem;
`

const FullEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.white};
  min-height: 50vh;
  box-shadow: ${props => (props.shadow ? theme.shadow : 'none')};
  padding: 2rem;
  width: 100%;
`

class SequenceTasks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTask: null,
      activeTaskIndex: 0,
      selectedStep: null,
      showEditContact: false,
      showTemplateModal: false,
      showDeleteConfirmModal: false,
      newThread: false, // for email tasks
      newTasks: null,
      newManualTasks: null, // need these to combine in setState callback
      actionTask: null
    }
  }

  fetchTasks () {
    const {
      actions,
      sequence,
      sequenceId
    } = this.props

    const userId = sequence.getIn(['data', '_user', '_id'])

    actions.fetchTasks(userId, sequenceId)
    actions.fetchManualTasks(userId, sequenceId)
  }

  UNSAFE_componentWillMount () {
    this.fetchTasks()
  }

  // this is the second layer of selection
  onTaskSelected = (task = Immutable.Map({}), index) => {
    const { actions, location } = this.props
    const contactId = task.get('id')
    const locationId = location.query.contactId

    if (task.get('manual') === 'task') {
      const stepIndex = task.get('next_step')
      actions.fetchContactTask(contactId, stepIndex)
    }
    if (task.get('manual') === 'message') {
      actions.fetchContactMessages(contactId)
    }

    if (locationId !== contactId) {
      this.clearLocationIdParams()
    }

    this.setState({
      activeTask: task,
      activeTaskIndex: index,
      showEditContact: false
    })
  }

  clearLocationIdParams = () => {
    const { location, router } = this.props
    const query = location.query
    delete query.contactId
    router.push({
      ...location,
      query
    })
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const { contactMessages, sequenceTasks } = newProps
    const { activeTask, activeTaskIndex } = this.state

    // pick the correct contactMessage from the message endpoint that is determined
    // from the activeTask
    if (contactMessages !== this.props.contactMessages && activeTask) {
      const contactMessage = contactMessages
        .get('data')
        .find(message => message.get('step') === activeTask.get('next_step'))

      if (contactMessage) {
        this.setState({
          newThread: !!contactMessage.get('subject'),
          contactMessage
        })
      }
    }

    // pick / advance the activeTask counter
    // Check to see if navigating from a different screen
    // if so, get the id and set activeTask and index to corresponding task that matches id
    // else, set activeTask to default activeTaskIndex
    if ((!activeTask || sequenceTasks !== this.props.sequenceTasks) && sequenceTasks.get('data').count() > 0) {
      if (activeTaskIndex !== null && activeTaskIndex < sequenceTasks.get('data').count()) {
        let task = sequenceTasks.getIn(['data', activeTaskIndex])
        let idx = activeTaskIndex

        // check to see if selected contactId still exist
        // if not, remove it from route
        const currentLocation = this.props.router.getCurrentLocation()
        if (currentLocation && currentLocation.query && currentLocation.query.contactId) {
          const selectedTask = sequenceTasks.get('data').find(task => task.get('id') === currentLocation.query.contactId)
          if (selectedTask) {
            task = selectedTask
            idx = sequenceTasks.get('data').indexOf(selectedTask)
          }
        }
        this.onTaskSelected(task, idx)
      } else {
        this.onTaskSelected(sequenceTasks.getIn(['data', 0]), 0)
      }
    }

    if (sequenceTasks.get('data').count() === 0 && activeTask) {
      this.setState({ activeTask: null, contactMessage: null })
    }
  }

  saveManualTask = ({ replied } = {}) => {
    const { actions, sequenceId, location } = this.props
    const { activeTask } = this.state
    const activeTaskId = activeTask.get('id')

    const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

    const locationId = location.query.contactId
    if (activeTaskId === locationId) {
      this.clearLocationIdParams()
    }

    actions.updateContactTask(
      activeTaskId,
      activeTask.get('next_step'),
      markdown,
      {
        completed: true,
        replied,
        sequenceId
      }
    )
  }

  saveContactMessage = () => {
    const { actions } = this.props
    const { activeTask, newThread } = this.state

    const subject = newThread
      ? this.editor.getMarkdown(SLATE_EDITORS.SUBJECT)
      : null
    const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

    actions.updateContactMessage(
      activeTask.get('id'),
      activeTask.get('next_step'),
      subject,
      markdown
    )
  }

  deleteContact = (task) => {
    this.setState({
      showDeleteConfirmModal: true,
      actionTask: task
    })
  }

  editContact = () => {
    this.setState({
      showEditContact: true
    })
  }

  renderEmptyState = (title, description) => {
    return (
      <Wrapper>
        <FullEmptyWrapper>
          <EmptyState
            icon={<Satellite />}
            title={title}
            description={description}
          />
        </FullEmptyWrapper>
      </Wrapper>
    )
  }

  render () {
    const {
      actions,
      contactCompany,
      selectedContact,
      contactTask,
      sequence,
      session,
      sequenceId,
      templates,
      contactMessages,
      sequenceTasks
    } = this.props
    const {
      showDeleteConfirmModal,
      showTemplateModal,
      activeTask,
      selectedStep,
      contactMessage,
      newThread,
      actionTask
    } = this.state

    const loading = sequence.get('loading') || sequenceTasks.get('loading')
    const taskLoading = contactTask.get('loading') || contactMessages.get('loading') || loading

    const userId = sequence.getIn(['data', '_user', '_id'])

    const steps = sequence.getIn(['data', 'steps'], Immutable.List())
    const manualSteps = steps.filter(s => s.get('manual'))

    if (!sequence.getIn(['data', 'active'])) {
      return this.renderEmptyState('Sequence is paused', 'Launch your sequence to have tasks show in this queue')
    }

    if (!manualSteps || manualSteps.count() < 1) {
      return this.renderEmptyState('No tasks configured', 'Set your message to require personalization or set steps to manual tasks')
    }

    const sourceUrl = selectedContact.getIn(['data', 'source_url'])
    const profileUrl = selectedContact.getIn(['data', 'profile_url'])
    const linkedInProfileUrl = (sourceUrl && sourceUrl.includes('linkedin.com/talent'))
      ? sourceUrl
      : profileUrl

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <FlexWrapper>
          <Container>
            <SequenceTaskDropdown
              sequence={sequence}
              selectedStep={selectedStep}
              onOptionChange={(option) => {
                const value = option ? JSON.parse(option.value) : {}
                this.setState({
                  activeTask: null,
                  activeTaskIndex: null,
                  contactMessage: null,
                  selectedStep: value.selectedStep
                })
                actions.fetchContactMessagesReset()
                actions.fetchContactTaskReset()
                actions.fetchTasks(userId, sequenceId, value.selectedStep)
                actions.fetchManualTasks(userId, sequenceId, value.selectedStep)
              }}
            />
            <TaskList
              actions={actions}
              tasks={sequenceTasks}
              handleTaskSelected={this.onTaskSelected}
              activeTask={activeTask}
              contactCompany={contactCompany}
              selectedContact={selectedContact}
              deleteContact={this.deleteContact}
              editContact={this.editContact}
              emptyDescription={selectedStep ? 'Try switching to a different step' : 'Looks like there are no tasks for you to complete right now'}
            />
          </Container>

          {taskLoading &&
            <Container pl='1rem' height='100%'>
              <EmptyWrapper>
                <Loading />
              </EmptyWrapper>
            </Container>}

          {!taskLoading &&
            <Container pl='1rem' height='100%'>
              {(!activeTask || activeTask.manual === null) && <EmptySection />}

              {activeTask &&
                <EditorContainer>
                  <EditorWrapper>
                    {activeTask.get('manual') === 'task'
                      ? <SlateEditor
                          key={contactTask.getIn(['data', 'id'])}
                          ref={(element) => { this.editor = element }}
                          onSave={this.saveManualTask}
                          onSaveLabel='Complete Task'
                          body={contactTask.getIn(['data', 'markdown'])}
                          hideSubjectLine
                          hideVariables
                          hideTemplates
                          hideEditorCoach
                          renderOverlayMenu
                          backgroundColor={theme.colors.lightYellow}
                          saveActions={[
                            {
                              title: 'Complete Task & Mark Replied',
                              fn: () => {
                                return this.saveManualTask({ replied: true })
                              }
                            },
                            {
                              title: 'Pause Task/Contact',
                              fn: () => {
                                return actions.setPauseContactTask(activeTask)
                              }
                            },
                            {
                              title: 'Skip Task',
                              fn: () => {
                                return actions.skipManualTask(
                                  activeTask.get('id'),
                                  activeTask.get('next_step'),
                                  activeTask.getIn(['_campaign', '_id'])
                                )
                              }
                            }
                          ]}
                          linkedInProfileUrl={linkedInProfileUrl}
                          mt={0}
                        />
                      : contactMessage &&
                        <SlateEditor
                          key={contactMessage.get('id')}
                          ref={(element) => { this.editor = element }}
                          onSave={this.saveContactMessage}
                          isBrandedDomain={session.get('branded_domain_enabled')}
                          onShowTemplates={() => {
                            actions.fetchTemplates()
                            this.setState({
                              showTemplateModal: true
                            })
                          }}
                          subject={contactMessage.get('subject') || contactMessage.get('previous_subject')}
                          body={contactMessage.get('markdown')}
                          newThread={newThread}
                          subjectDisabled={!newThread}
                          hideVariables
                          hideEditorCoach
                          mt={0}
                          saveActions={[
                            {
                              title: 'Pause Task/Contact',
                              fn: () => {
                                return actions.setPauseContactTask(activeTask)
                              }
                            }
                          ]}
                        />}
                  </EditorWrapper>
                </EditorContainer>}
            </Container>}
          <Modal
            isOpen={showTemplateModal}
            width='700px'
            onModalClose={() => {
              this.setState({
                showTemplateModal: false
              })
            }}
          >
            <Templates
              templates={templates}
              onTemplateSelected={(template) => {
                if (template) {
                  const markdown = template.get('markdown')
                  const subject = template.get('subject')
                  let updatedMessage = contactMessage.set('markdown', markdown)
                  if (subject) {
                    updatedMessage = updatedMessage.set('subject', subject)
                  }
                  this.setState({
                    contactMessage: updatedMessage,
                    showTemplateModal: false
                  })
                }
              }}
              onDeleteTemplate={(template) => {
                actions.deleteTemplate(template)
              }}
            />
          </Modal>
          <ConfirmModal
            isOpen={showDeleteConfirmModal}
            onCancel={() => {
              this.setState({
                showDeleteConfirmModal: null,
                actionTask: null
              })
            }}
            onConfirm={() => {
              actions.removeContact(actionTask.get('id'))
              this.setState({
                showDeleteConfirmModal: null,
                actionTask: null
              })
            }}
            title='Are you sure?'
            description={`Are you sure you want to remove ${actionTask ? actionTask.get('name') : ''} from your ${actionTask ? actionTask.getIn(['_campaign', 'title']) : ''} sequence?`}
          />
        </FlexWrapper>

        {this.state.showEditContact &&
          <EditContactSidebar
            contact={selectedContact.get('data')}
            sequence={sequence.get('data')}
            loading={selectedContact.get('loading')}
            router={this.props.router}
            onClose={() => {
              this.setState({
                showEditContact: false
              })
            }}
            onRemove={() => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.removeContact(contactId)
            }}
            onSave={(params) => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.updateSequenceContact(contactId, params)
              this.setState({
                showEditContact: false
              })
            }}
          />}
      </Wrapper>
    )
  }
}

SequenceTasks.propTypes = {
  tasks: PropTypes.object,
  manualTasks: PropTypes.object,
  contactTask: PropTypes.object,
  contactCompany: PropTypes.object,
  actions: PropTypes.object,
  selectedContact: PropTypes.object,
  session: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  sequenceId: PropTypes.string,
  sequence: PropTypes.object,
  contactMessages: PropTypes.any,
  sequenceTasks: PropTypes.any,
  templates: PropTypes.any,
  router: PropTypes.object,
  location: PropTypes.object
}

export default SequenceTasks

import { SLATE_EDITORS } from '../slate/constants'
import {
  _wrapHtml,
  _parseBreaksInTags,
  _parseInlinesToHtml,
  sanitize
} from './helpers'

function groupConsoleLog (name, data) {
  console.groupCollapsed(name)
  console.log(data)
  console.groupEnd(name)
}

function toHtml (editor, text, { wrapEmojis = true, wrapHtml = true } = {}) {
  console.group('showdown.toHtml')
  groupConsoleLog('text', text)

  const whiteSpaceRegex = /^\s*$/ig
  if (!text || !text.length || text.match(whiteSpaceRegex)) {
    console.groupEnd('showdown.toHtml')
    return ''
  }

  if (editor === SLATE_EDITORS.SUBJECT) {
    console.groupEnd('showdown.toHtml')
    // a while saving the subject editor, we want none of the text of turn into formatted html.
    return `<p>${text}</p>`
  }

  // find html blocks in the text and wrap them in
  // code blocks because they shouldn't be parsed
  text = wrapHtml ? _wrapHtml(text) : text

  // this block helps replace tags previously created by our editor
  // with an alt tag and replaces it with a title tag instead
  text = text.replace(/<a href=".*?">(<img src=".*?" alt=".*?" data-href=".*?"\/>)<\/a>/ig, function (match) {
    return match.replace(/alt="(.*?)"/ig, 'title="$1"')
  })
  // remove the a tags that are wrapping any image tags
  text = text.replace(/<a href=".*?">(<img src=".*?" .*?data-href=".*?"\/>)<\/a>/ig, '$1')

  const showdown = require('showdown')
  const converter = new showdown.Converter({
    simpleLineBreaks: true,
    literalMidWordUnderscores: true,
    tables: false
  })
  let result = converter.makeHtml(text)

  // allow only specific attributes in html message
  result = sanitize(result)

  // replace any <em> tags that showed up in variables back to underscores
  result = _parseInlinesToHtml(editor, result, wrapEmojis)

  // html default has margin-bottom for each paragraph tag
  // but our custom flavor does not. in our case, paragraph
  // tags represent lines, which also is the default for
  // "email HTML"
  result = result.replace(/<\/(p)>/ig, '</$1><p></p>')

  // take all extra list spaces and remove them
  result = result.replace(/<p>\s?<\/p>\s?<(ol|ul)>/ig, '<$1>')

  // p tags are representation of lines so break
  // tags are no longer necessary - we want to
  // replace them into paragraph tags
  result = _parseBreaksInTags(result)

  // result = result.replace(/<code>(.*)<\/code>/, '$1')

  groupConsoleLog('result', result)
  console.groupEnd('showdown.toHtml')

  return result
}

function toMarkdown (editor, html) {
  if (!html || !editor) {
    console.error('ERROR: `editor` or `html` is null in `toMarkdown`')
    return
  }

  console.group('%cshowdown.toMarkdown', 'background-color: rgb(255,202,107)')
  groupConsoleLog('html', html)

  /*
   * - Not running subject line through markdown parser.
   *   Rather the subject line will be stripped of paragraph tags
   * - subject lines cannot contain return keys
   */
  if (editor === SLATE_EDITORS.SUBJECT) {
    const div = document.createElement('div')
    div.innerHTML = html

    console.groupEnd('showdown.toMarkdown')
    return div.innerText.trim()
  }

  // showdown compiles these elements like "** stuff **" (note the
  // spaces before and after **) but doesn't parse these into
  // proper HTML tags the opposite way around
  html = html.replace(/(\s)<\/(b|em|i|u)>/ig, '</$2>{[{[space]}]}')
  html = html.replace(/<(b|em|i|u)>(\s)/ig, '{[{[space]}]}<$1>')

  // spaces in between tags get stripped from showdown
  // this helps preserve them throughout the code
  html = html.replace(/<\/(b|em|i|u|a)> <((:?b|em|i|u|a).*?)>/ig, '</$1>{[{[space]}]}<$2>')

  // underlines are not recognized by showdown so we have
  // to convert it to a non-html tag so it doesn't get
  // parsed out from showdown
  html = html.replace(/<u>/ig, '[u]')
  html = html.replace(/<\/u>/ig, '[/u]')

  html = _parseBreaksInTags(html)

  // preserve whitespace in list items when separating the
  // bullets per line rather than having them all grouped together
  // before: <li><p>hello</p><p></p></li>
  // after: <li><p>hello</p><br /></li>
  html = html.replace(/<p><\/p><\/li>/ig, '<p><br /></p>')

  // showdown requires break tags to indicate
  // single-line breaks, otherwise extra paragraph
  // tags just get completely stripped
  html = html.replace(/<p><\/p>/ig, '<p>&#65279;</p>')

  // html = html.replace(/<u>/ig, '<span style="text-decoration: underline">');
  // html = html.replace(/<u>/ig, '<span style="text-decoration: underline">');

  // this helps take slate emojis and parses them before
  // showdown converts them to avoid unnecessary linebreaks
  html = html.replace(/<span contenteditable="false">(.*?)<\/span>/ig, '$1')

  // - This will parse any images that have the `data-href` attribute
  //   and prevent showdown from converting the image tag to the markdown
  //   image format.
  // - Showdown removes the `data-href` attribute when converting to
  //   markdown, but slate requires that attribute on images to know
  //   if an image already has a link. To prevent showdown from
  //   removing the attribute, image tags are converted
  //   from:  <img src="..." data-href="..." />
  //   to:    [img src="..." data-href="..." /]
  html = html.replace(/<(img src="[a-zA-Z0-9-_?:/.=]+?" title="[a-zA-Z0-9-_?:/.=]+?" data-href="[a-zA-Z0-9-_?:/.=]+?"\/)>/ig, '[$1]')

  const showdown = require('showdown')
  const converter = new showdown.Converter({
    simpleLineBreaks: true,
    underline: true,
    tables: false
  })
  let result = converter.makeMarkdown(html)

  // - Extracts the link from the an image's `data-href` attrbute
  //   and converts the image tag to an image link.
  // - This is done after showdown converts the text to markdown to
  //   prevent showdown from incorrectly formatting the link tags.
  result = result.replace(/\[(img src=".*?" title=.*? data-href="(.*?)"\/)\]/ig, '<a href="$2"><$1></a>')

  // replace all custom tags as seen from before makeMarkdown
  // such as the custom space and underline elements
  result = result.replace(/{\[{\[space]}]}/ig, ' ')
  result = result.replace(/\[(\/)?u\]/ig, '<$1u>')

  // showdown adds comments, which are completely not needed
  result = result.replace(/<!--.*?-->/ig, '')

  // by default, showdown adds two \n (new lines) for every paragraph
  // block that it sees. our paragraph tags represent one new line
  // so we need to convert them to a single new line
  result = result.replace(/\n\n/ig, '\n')

  // underscores do not need to be escaped but showdown does this
  result = result.replace(/\\_/ig, '_')

  // if showdown doesn't parse <br> tags that end at new lines
  // it'll cause it to display two new lines rather than just one
  result = result.replace(/^(.+?)<br>$/igm, '$1')

  // showdown escapes markdown commands like * | ~ but we've disabled
  // tables and no longer require escaping of table specific characters
  result = result.replace(/\\([~|])/g, '$1')

  // trim any and all whitespace before and after the content and
  // eliminates issues when someone just adds a "return" in the
  // body of the step
  result = result.trim()

  groupConsoleLog('result', result)
  console.groupEnd('showdown.toMarkdown')

  return result
}

function getTextFromMarkdown (md) {
  const html = toHtml(SLATE_EDITORS.BODY, md, false)
  const parser = new window.DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return doc.body.innerText
}

export {
  toMarkdown,
  toHtml,
  getTextFromMarkdown
}

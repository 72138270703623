/* eslint-disable camelcase */
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fadeInRule } from 'utils/animations'
import Avatar from 'react-avatar'
import Immutable from 'immutable'
import Label from 'elements/Label'
import Loading from 'components/Loading'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import Input from 'components/Input'
import { FIELD_BLOCKLIST, TOP_LEVEL_FIELD_VALUES } from 'containers/Sequence/constants'
import { Tooltip } from 'react-tippy'
import { Cross } from 'svg'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  position: fixed;
  width: 300px;
  height: 100%;
  left: calc(100% - 300px);
  top: 0px;
  z-index: 10;
  animation: ${fadeInRule};
  box-shadow: ${theme.shadow};
  overflow-y: scroll;
`

const ContactName = styled.div`
  color: ${theme.colors.darkBlue};
  font: ${theme.fonts.header};
  padding-top: 0.5rem;
`

const ProfileContainer = styled.div`
  justify-content: center;
  width: 100%;
  height: 250px;

  /* hard coded because the height for this box is hardcoded to 250px */
  padding-top: 56px;

  background-color: ${theme.colors.gray10};
  border-bottom: 1px solid #A7B2C5;
  max-height: 100%;
`

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;
`

const CloseContainer = styled.div`
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    stroke: ${theme.colors.black};
    fill: ${theme.colors.black};
    margin: auto;
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  width: 100%;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${theme.borderColor};
`

const ButtonsGroupLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const ButtonsGroupRight = styled.div``

const ButtonWrapper = styled.div`
  padding-right: 0.5rem;
`

const InputFieldContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
`

class EditContactSidebar extends Component {
  constructor (props) {
    super(props)

    const params = this.paramsFromProps(props)
    this.state = { ...params }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const params = this.paramsFromProps(newProps)
    this.setState({ ...params })
  }

  paramsFromProps = (props) => {
    const {
      contact,
      sequence
    } = props

    if (!contact || !sequence) {
      return {}
    }

    const name = contact.get('name') || ''
    const email = contact.get('email') || ''
    const title = contact.get('title') || ''
    const location = contact.get('location') || ''
    const location_city = contact.get('location_city') || ''
    const location_state = contact.get('location_state') || ''
    const location_country = contact.get('location_country') || ''
    const college = contact.get('college') || ''
    const degree = contact.get('degree') || ''
    const url = contact.get('url') || ''
    const phone_number = contact.get('phone_number') || ''
    const company = contact.get('company') || ''
    const rawFields = contact.get('raw') || Immutable.Map({})

    const customFields = sequence
      .get('fields', Immutable.List([]))
      .filter(f => !FIELD_BLOCKLIST.includes(f) && !TOP_LEVEL_FIELD_VALUES.includes(f))
      .reduce((c, key) => {
        const cFields = c
        cFields[key] = rawFields.get(key) || ''
        return cFields
      }, {})

    return {
      fields: {
        name,
        email,
        title,
        company,
        phone_number,
        location,
        location_city,
        location_state,
        location_country,
        url,
        college,
        degree
      },
      customFields
    }
  }

  getContactFields (fields) {
    return Object.keys(fields).map((field) => {
      return (
        <Input
          label={field}
          inputId={field}
          type='text'
          small
          mt='1.5rem'
          key={`contact_form_${field}`}
          value={fields[field]}
          onValueChange={(value) => {
            const fields = this.state.fields
            fields[field] = value
            this.setState({ fields })
          }}
        />
      )
    })
  }

  getCustomFields (fields) {
    return Object.keys(fields).map((field) => {
      return (
        <Input
          label={field}
          inputId={field}
          type='text'
          small
          mt='1.5rem'
          key={`contact_form_custom_${field}`}
          value={fields[field]}
          onValueChange={(value) => {
            const customFields = this.state.customFields
            customFields[field] = value
            this.setState({ customFields })
          }}
        />
      )
    })
  }

  save () {
    const {
      fields,
      customFields
    } = this.state

    this.props.onSave({
      raw: customFields,
      ...fields
    })
  }

  render () {
    const {
      contact,
      sequence,
      onClose,
      onRemove,
      onIgnore,
      loading
    } = this.props

    const {
      fields,
      customFields
    } = this.state

    if (!contact || !sequence || loading) {
      return (
        <Wrapper>
          <ProfileContainer>
            <ProfileContent>
              <Loading />
            </ProfileContent>
          </ProfileContainer>
        </Wrapper>
      )
    }

    // user data info
    const contactId = contact.get('_id')
    const contactName = contact.get('name')
    const contactPhoto = contact.get('photo_url')
    const contactEmail = contact.get('email')

    // sequence data info
    const sequenceTitle = sequence.get('title')
    const sequenceId = sequence.get('_id')

    return (
      <Wrapper>
        <ProfileContainer>
          <CloseContainer
            onClick={onClose}
          >
            <Cross />
          </CloseContainer>
          <ProfileContent>
            <Avatar
              email={contactEmail}
              maxInitials={2}
              src={contactPhoto}
              name={contactName}
              size={80}
              textSizeRatio={2}
              round
              colors={theme.avatarColors}
            />
            <ContactName>{contactName}</ContactName>
            <Label align='center' pt='0.25rem'>From {sequenceTitle}</Label>
          </ProfileContent>
        </ProfileContainer>
        <ButtonRow>
          <ButtonsGroupLeft>
            {onIgnore &&
              <ButtonWrapper>
                <Tooltip
                  style={{ display: 'block' }}
                  key='clear'
                  title='Ignore Error'
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <IconButton
                    onClick={onIgnore}
                    type={['fal', 'eye-slash']}
                  />
                </Tooltip>
              </ButtonWrapper>}
            {onRemove &&
              <ButtonWrapper>
                <Tooltip
                  style={{ display: 'block' }}
                  key='remove'
                  title='Remove Contact'
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <IconButton
                    onClick={onRemove}
                    type={['fal', 'trash-alt']}
                    translateY={-1}
                  />
                </Tooltip>
              </ButtonWrapper>}
            <ButtonWrapper>
              <Tooltip
                style={{ display: 'block' }}
                key='view'
                title='View in Sequence'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  onClick={() => {
                    this.props.router.push(`/sequence/${sequenceId}/contacts/${contactId}/detail`)
                  }}
                  type={['fal', 'external-link-square']}
                  translateY={-1}
                />
              </Tooltip>
            </ButtonWrapper>
          </ButtonsGroupLeft>
          <ButtonsGroupRight>
            <Button
              small
              primary
              label='Save'
              handleClick={() => {
                this.save()
              }}
            />
          </ButtonsGroupRight>
        </ButtonRow>

        <InputFieldContainer>
          {this.getContactFields(fields)}
          {this.getCustomFields(customFields)}
        </InputFieldContainer>

      </Wrapper>
    )
  }
}

EditContactSidebar.propTypes = {
  contact: PropTypes.object,
  sequence: PropTypes.object,
  router: PropTypes.object,
  onClose: PropTypes.function,
  onClick: PropTypes.function,
  onIgnore: PropTypes.function,
  onRemove: PropTypes.function,
  onSave: PropTypes.function,
  loading: PropTypes.bool
}

export default EditContactSidebar

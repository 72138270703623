import styled from 'styled-components'
import theme from '../../themes/light'

export default styled.div`
  font: ${theme.fonts.xxsmall};
  font-weight: 500;
  color: ${theme.menuHeader};
  padding: .25rem .5rem;
  text-transform: uppercase;
`

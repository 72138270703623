import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import { space, width } from 'styled-system'
import { Rocket } from 'svg'
import PriceLabel from './PriceLabel'
import { apiBaseURI } from 'utils/api'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  position: relative;
  background: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7.5rem;
  margin-bottom: 2rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  grid-gap: 0.625rem;
  padding-top: 1rem;
`

const RocketContainer = styled.div`
  position: absolute;
  bottom: calc(100% - 13px);
  left: 0;
  right: 0;
`

const Title = styled.h3`
  color: ${theme.titleColor};
  font: ${theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 1rem;
`

const FieldContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

const ACCOUNT_TYPES = {
  RECRUITER: 'recruiter',
  STANDARD: 'standard'
}

const Label = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Terms = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  color: ${theme.colors.gray60};
  font: ${theme.fonts.small};
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 90%;

  ${space}
  ${width}
`

class Subscribe extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedPlan: 'monthly',
      seats: 1,
      valid: false,
      account: {},
      accountType: ACCOUNT_TYPES.RECRUITER
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      pricing,
      counts
    } = nextProps
    const {
      selectedPlan
    } = this.state

    const pricingData = pricing.get('data')

    if (pricingData && !selectedPlan) {
      this.setState({
        selectedPlan: 'monthly'
      })
    }

    if (!counts.get('loading')) {
      const activeCount = counts.get('data').get('counts').get('total')

      this.setState({
        seats: activeCount
      })
    }
  }

  createStripeToken = (callback) => {
    const {
      account
    } = this.state
    this.props.stripe.createToken({ ...account }).then(({ token }) => {
      callback(token)
    })
  }

  render () {
    const {
      pricing,
      actions,
      router,
      counts
    } = this.props

    const {
      selectedPlan,
      seats,
      accountType
    } = this.state

    if (!pricing.get('data')) {
      return (
        <Loading />
      )
    }

    const pricingData = pricing.get('data')
    const priceLoading = pricing.get('loading') || counts.get('loading')

    return (
      <Wrapper>
        <RocketContainer>
          <Rocket />
        </RocketContainer>
        <Title>Subscribe to keep using Interseller</Title>
        <FieldContainer>
          <Label>Subscription Plan</Label>
          <ButtonWrapper>
            <Button
              primary={accountType === ACCOUNT_TYPES.RECRUITER}
              small
              label={ACCOUNT_TYPES.RECRUITER}
              onClick={() => {
                this.setState({ accountType: ACCOUNT_TYPES.RECRUITER })
                actions.fetchPricing({ recruiter: true })
              }}
            />
          </ButtonWrapper>
        </FieldContainer>
        {!priceLoading &&
          <PriceLabel
            router={router}
            pricing={pricingData}
            seats={seats}
            selectedPlan={selectedPlan}
            onPlanChange={(plan) => {
              this.setState({
                selectedPlan: plan
              })
            }}
          />}
        {priceLoading && <Loading padding='18px' />}
        <Button
          primary
          // disabled={!valid}
          label='Continue'
          width='90%'
          mt='1rem'
          handleClick={() => {
            let url = `${apiBaseURI}/stripe_checkout?seats=${this.state.seats}`
            if (this.state.accountType === ACCOUNT_TYPES.RECRUITER) {
              url += '&recruiter=1'
            }
            window.location.href = url
          }}
        />
        <Terms>
          We reserve the right to assess sales tax as required by local law.
          Sales taxes are based on your billing information. The billing
          receipt may not reflect sales taxes assessed that is charged on your
          credit card.
        </Terms>
      </Wrapper>
    )
  }
}

Subscribe.propTypes = {
  pricing: PropTypes.object,
  actions: PropTypes.object,
  stripe: PropTypes.object,
  counts: PropTypes.object,
  router: PropTypes.object
}

export default Subscribe

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import styled from 'styled-components'
import Label from 'elements/Label'
import { Time, Send, ClipboardChecklist } from 'svg'
import { pluralize, timeInFuture } from 'utils/strings'
import theme from '../../themes/light'

const Wrapper = styled.div`
  font: ${theme.fonts.normal};
  color: ${theme.colors.gray60};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  float: left;
  padding: ${props => (props.isPreview) ? theme.padding : 'auto'};
  width: ${props => (props.isPreview) ? '100%' : 'auto'};
`

const Container = styled.div`
  flex: 1 0 auto;
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const RightContainer = styled.div`
  flex: 1 0 auto;
  text-align: right;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const IconContainer = styled.div`
  margin-right: 0.5rem;
  margin-top: 1px;
  align-items: center;
  justify-content: center;
  display: flex;
`

const DayLabel = styled(Label)`
  font: ${theme.fonts.button};
  color: ${theme.labelColor};
  text-align: right;
  text-transform: uppercase;
`

class StepHeader extends Component {
  getIcon (manual, time) {
    if (time) {
      if (manual === 'task') {
        return (<ClipboardChecklist color={theme.colors.gray70} width='14' height='14' />)
      } else {
        return (<Send color={theme.colors.gray70} />)
      }
    } else if (manual === 'task') {
      return (<ClipboardChecklist color={theme.colors.yellow} width='14' height='14' />)
    } else if (manual === 'message') {
      return (<Time color={theme.colors.blue} />)
    } else {
      return (<Time color={theme.colors.gray70} />)
    }
  }

  render () {
    const {
      stepCount,
      wait,
      time,
      manual,
      schedule,
      onClick,
      rightLabel,
      isPreview
    } = this.props

    const days = pluralize('Day', 'Days', wait + 1)
    const firstEmail = stepCount === 0

    let manualString = 'Automatically sends'
    if (manual === 'message') {
      manualString = 'Requires personal message'
    } else if (manual === 'task') {
      manualString = 'Add task'
    }

    let scheduleString
    if (firstEmail) {
      if (wait !== null) {
        scheduleString = `${manualString} ${wait + 1} ${days} after contact is added`
      } else if (manual === 'message') {
        scheduleString = `${manualString} after contact is added`
      } else {
        scheduleString = `${manualString} 10 minutes after contact is added`
      }
    } else {
      if (wait !== null) {
        scheduleString = `${manualString} ${wait + 1} ${days} after`
      } else {
        scheduleString = `${manualString} on the same day of the previous step`
      }
    }

    let timeString
    if (time) {
      const date = Date.parse(time)
      const verb = manual === 'task' ? 'Completed' : 'Sent'
      timeString = `${verb} on ${format(date, 'MMMM D')} at ${format(date, 'h:mma')}`
    }

    if (schedule) {
      const verb = manual === 'task' ? 'Adds task' : 'Sends'
      timeString = `${verb} approximately on ${timeInFuture(schedule)}`
    }

    return (
      <Wrapper onClick={onClick} isPreview={isPreview}>
        <Container>
          <IconContainer>
            {this.getIcon(manual, time)}
          </IconContainer>
          <DayLabel>
            {timeString && timeString}
            {!timeString && scheduleString}
          </DayLabel>
        </Container>
        {rightLabel &&
          <RightContainer>
            <DayLabel>{rightLabel}</DayLabel>
          </RightContainer>}
      </Wrapper>
    )
  }
}

StepHeader.propTypes = {
  stepCount: PropTypes.number,
  wait: PropTypes.number,
  time: PropTypes.string,
  manual: PropTypes.string,
  schedule: PropTypes.string,
  onClick: PropTypes.func,
  rightLabel: PropTypes.string,
  isPreview: PropTypes.boolean
}

export default StepHeader

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import theme from '../../../../../../../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Label = styled.div`
  margin-top: 4px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: ${theme.colors.darkBlue};
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const Value = styled.div`
  font: ${theme.fonts.normal};
  color: ${theme.labelColor};
  text-align: left;
`

class StepStat extends PureComponent {
  render () {
    const {
      label,
      value,
      isFooter
    } = this.props

    return (
      <Wrapper>
        {label && <Label>{label}</Label>}
        {(this.props.percentage &&
          <Value isFooter={isFooter}><NumberFormat value={value} displayType='text' suffix='%' /></Value>) ||
            <Value isFooter={isFooter}><NumberFormat value={value} displayType='text' thousandSeparator=',' /></Value>}
      </Wrapper>
    )
  }
}

StepStat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  percentage: PropTypes.bool,
  isFooter: PropTypes.bool
}

export default StepStat

import styled from 'styled-components'
import { space } from 'styled-system'
import theme from '../../themes/light'

const DangerLabel = styled.div`
  color: ${theme.colors.red};
  font-weight: 400;
  padding: 1rem;
  border: 1px solid ${theme.colors.red};
  border-radius: 3px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: justify;

  a {
    color: ${theme.colors.red};
  }

  ${space}
`

export default DangerLabel

/**
*
* BillingHistory Components
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'
import NumberFormat from 'react-number-format'
import Label from 'elements/Label'
import Loading from 'components/Loading'
import HeaderLabel from 'elements/HeaderLabel'
import CardImage from 'elements/CardImage'
import Button from 'components/Button'
import { apiBaseURI } from 'utils/api'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  width: 100%;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const Title = styled.h3`
  color: ${theme.titleColor};
  font: ${theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`

const TitleContainer = styled.div`
  background: ${theme.colors.gray10};
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InvoiceContainer = styled.div`
  padding: 1rem 2rem;
  width: 100%;
`

const InvoiceTable = styled.table`
  width: 100%;
`

const ListItem = styled.tr`
  width: 100%;
  padding: 1rem;
  font: ${theme.fonts.normal};
  padding: ${theme.padding};
  background-color: ${props => (props.selected ? theme.highlightBackground : theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: solid 1px ${theme.borderColor};
  letter-spacing: .025em;
`

const Cell = styled.td`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  letter-spacing: .025em;
  padding: 0.75rem 0rem;
  text-align: left;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 13px;
`

const DownloadContainer = styled(Cell)`
  padding-right: 0.25rem;
  text-align: right;
  text-transform: uppercase;
`

const DownloadLink = styled(Label)`
  color: ${theme.colors.blue};
  font: ${theme.fonts.button};
  padding-right: 0.25rem;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

class BillingHistory extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      charges,
      onViewMore
    } = this.props

    const loading = charges.get('loading')

    if (loading) {
      return (
        <Wrapper>
          <TitleContainer>
            <Title>Billing History</Title>
          </TitleContainer>
          <Loading />
        </Wrapper>
      )
    }

    const data = charges.get('data')

    const invoiceRows = data.map((charge, i) => {
      const id = charge.get('id')
      const amount = charge.get('amount')
      const dateRaw = charge.get('created')
      const date = format(new Date(dateRaw * 1000), 'MMM D, YYYY')
      const invoice = charge.get('invoice')
      const brand = charge.getIn(['source', 'brand'])
      const last4 = charge.getIn(['source', 'last4'])
      return (
        <ListItem key={`invoice_${id}`} data-index={i}>
          <Cell>{date}</Cell>
          <Cell>
            <NumberFormat
              value={amount / 100}
              thousandSeparator
              decimalSeparator='.'
              fixedDecimalScale
              decimalScale={2}
              displayType='text'
              prefix='$'
            />
          </Cell>
          <Cell>
            <CardContainer>
              <CardImage
                width={25}
                height={16}
                cardType={brand}
              />
              <span style={{ marginLeft: '0.25rem' }}>
                *{last4}
              </span>
            </CardContainer>
          </Cell>
          <DownloadContainer>
            <DownloadLink
              onClick={() => {
                window.open(`${apiBaseURI}/receipts/${invoice}`)
              }}
            >
              View
            </DownloadLink>
          </DownloadContainer>
        </ListItem>
      )
    })

    return (
      <Wrapper>
        <TitleContainer>
          <Title>Billing History</Title>
        </TitleContainer>
        <InvoiceContainer>
          <InvoiceTable>
            <thead>
              <tr>
                <th><HeaderLabel pl='0'>Date</HeaderLabel></th>
                <th><HeaderLabel pl='0'>Amount</HeaderLabel></th>
                <th><HeaderLabel pl='0'>Card</HeaderLabel></th>
                <th><HeaderLabel pl='0' /></th>
              </tr>
            </thead>
            <tbody>
              {invoiceRows}
            </tbody>
          </InvoiceTable>
          <Button
            label='View More'
            mt='1rem'
            mb='1.5rem'
            full
            onClick={() => {
              onViewMore()
            }}
          />
        </InvoiceContainer>
      </Wrapper>
    )
  }
}

BillingHistory.propTypes = {
  charges: PropTypes.object,
  onViewMore: PropTypes.func
}

export default BillingHistory

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  subDays,
  subYears,
  startOfDay,
  startOfYear,
  endOfToday,
  endOfDay,
  format
} from 'date-fns'
import { Cross } from 'svg'
import CalendarDatePicker from 'containers/Reports/components/ReportMenu/components/DateRange/components/CalendarDatePicker'
import Curtain from 'components/SlateEditor/components/Toolbar/ToolbarModal/Curtain'
import { fadeInRule } from 'utils/animations'
import theme from '../../../../../../themes/light'

const Wrapper = styled.div`
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 1rem;
`

const DateInput = styled.div`
  outline: none;
  border: none;

  border: 1px solid ${theme.colors.gray60};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: white;

  user-select: none;
  cursor: pointer;

  padding: .75rem 0.5rem;
  height: 33px;
  width: 100%;

  ${props => props.focused && css`
    border: 1px solid ${theme.colors.blue};
    box-shadow: 0 0 4px ${theme.colors.lightBlue};
  `};

  animation: ${fadeInRule};
`

const DateText = styled.div`
  margin-right: .25rem;
  color: ${theme.colors.github};
  transition: .2s;
  text-decoration: underline;
`

const DateSeperator = styled.div`
  margin-right: .25rem;
  color: ${theme.colors.github};
`

const DateShortcut = styled.div`
  color: ${theme.colors.blue};
  font: ${theme.fonts.xsmall};
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;

  margin-right: 0.75rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${theme.colors.blue};
  }
`

const DateShortcutRow = styled.div`
  background-color: ${theme.colors.gray20};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding: 0.5rem;

  &:before {
    content: " ";
    flex: 1
  }

  &:after {
    content: " ";
    flex: 1;
  }
`

const DateDropDown = styled.div`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray40};
  border-radius: 3px;
  box-shadow: ${theme.shadow};

  z-index: 999;
  position: absolute;
  left: ${props => props.position.x}px;
  top: ${props => props.position.y}px;
  width: 460px;

  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* padding: 0 1rem; */

  width: 100%;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;
  /* padding: .4rem .6rem; */
  padding: .4rem 1rem;
  margin-bottom: .5rem;

  border-radius: 50px;
  border: 1px solid #588BE2;
  background-color: #E9F1FF;
  font: ${theme.fonts.label};
  color: ${theme.colors.darkBlue};
`

const SelectedItemCloseContainer = styled.div`
  cursor: pointer;
  padding-left: .55rem;

  svg {
    position: relative;
    top: -1px;
    width: 7px;
    height: 7px;
    stroke: ${theme.colors.gray50};
    fill: ${theme.colors.gray50};
    margin: auto;
  }
`

const SelectedItemText = styled.div`
  flex: 1;
`

const Placeholder = styled.div`
  color: #aaa;
  font: ${theme.fonts.normal};
`

function DateRange (props) {
  const dateInputRef = useRef()

  const [showDateDropDown, setShowDateDropDown] = useState(false)
  const [dateDropDownPosition, setDateDropDownPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    calculateDateDropDownPosition()
  }, [props.showPanel])

  const calculateDateDropDownPosition = () => {
    if (dateInputRef?.current) {
      const { bottom, left } = dateInputRef.current.getBoundingClientRect()
      setDateDropDownPosition({ x: left, y: (bottom + 21) })
    }
  }

  const toggleDateDropDown = () => {
    calculateDateDropDownPosition()
    setShowDateDropDown(!showDateDropDown)
  }

  const last30Days = () => {
    const { setDateRange } = props
    const today = endOfToday()
    const sub30Days = subDays(today, 30)

    setDateRange(sub30Days, today)
  }

  const last90Days = () => {
    const { setDateRange } = props
    const today = endOfToday()
    const sub90Days = subDays(today, 90)

    setDateRange(sub90Days, today)
  }

  const last365Days = () => {
    const { setDateRange } = props
    const today = endOfToday()
    const lastYear = subYears(today, 1)

    setDateRange(lastYear, today)
  }

  const YTD = () => {
    const { setDateRange } = props
    const today = endOfToday()
    const jan1 = startOfDay(startOfYear(today))

    setDateRange(jan1, today)
  }

  const renderDateDropDown = () => {
    if (!showDateDropDown) {
      return
    }

    const { startDate, endDate, setDateRange } = props
    const { x, y } = dateDropDownPosition

    return (
      <>
        <Curtain onClick={() => setShowDateDropDown(false)} />
        <DateDropDown position={{ x, y }}>
          <DateShortcutRow>
            <DateShortcut onClick={last30Days}>Last 30 Days</DateShortcut>
            <DateShortcut onClick={last90Days}>Last 90 Days</DateShortcut>
            <DateShortcut onClick={last365Days}>Last 365 Days</DateShortcut>
            <DateShortcut onClick={YTD}>YTD</DateShortcut>
          </DateShortcutRow>
          <CalendarDatePicker
            startDate={startDate || startOfDay(subDays(new Date(), 30))}
            endDate={endDate || endOfDay(new Date())}
            setDateRange={setDateRange}
          />
        </DateDropDown>
      </>
    )
  }

  const {
    startDate,
    endDate,
    showPanel,
    onRemoveSelectedFilter,
    placeholder = 'Select dates'
  } = props

  return (
    <Wrapper>
      {startDate && endDate &&
        <SelectedContainer>
          <SelectedItem>
            <SelectedItemText>{`${format(startDate, 'MMM D, YYYY')} to ${format(endDate, 'MMM D, YYYY')}`}</SelectedItemText>
            <SelectedItemCloseContainer onClick={onRemoveSelectedFilter}>
              <Cross />
            </SelectedItemCloseContainer>
          </SelectedItem>
        </SelectedContainer>}
      {showPanel &&
        <DateInput
          ref={dateInputRef}
          onClick={toggleDateDropDown}
          focused={showDateDropDown}
        >
          {!startDate && !endDate && <Placeholder>{placeholder}</Placeholder>}
          {startDate && endDate &&
            <>
              <DateText>{startDate ? format(startDate, 'MMM D, YYYY') : null}</DateText>
              <DateSeperator>{startDate && endDate ? 'to' : ''}</DateSeperator>
              <DateText>{endDate ? format(endDate, 'MMM D, YYYY') : null}</DateText>
            </>}
        </DateInput>}
      {renderDateDropDown()}
    </Wrapper>
  )
}

DateRange.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDateRange: PropTypes.func,
  showPanel: PropTypes.bool,
  onRemoveSelectedFilter: PropTypes.func,
  placeholder: PropTypes.string
}

export default DateRange

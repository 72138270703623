/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'
import HeatMapTooltip from './components/HeatMapTooltip'
import HeatMap from './components/HeatMap'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const EmptyWrapper = styled(Wrapper)`
  padding: 3rem 0;
  background-color: ${theme.colors.white};
  border: solid 1px ${theme.borderColor};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ErrorContainer = styled(EmptyWrapper)`
  border: None;
`

const Title = styled.div`
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding: 0 0 1rem 0;
  width: 100%;
  display: flex;
  justify-content: start;
  padding-left: 0.25rem;
`

const SectionWrapper = styled.div`
  background: ${theme.containerBackground};
  border: solid 1px ${theme.borderColor};
  padding: 1rem 1.25rem 1.5rem 1rem;
  margin: 0 1rem 1rem 0;
  width: 100%;
  max-width: 602px;
`

const HeatMapWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  float: left;
`

const rangeLabels = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

class HeatMapWidget extends Component {
  constructor (props) {
    super(props)
    this.heatMapWrapperRef = React.createRef()

    this.state = {
      heatMapWrapperDimensions: { width: 0, height: 0 }
    }
  }

  componentDidMount () {
    const heatMapWrapperDimensions = this.getHeatMapWrapperDimensions()
    this.setState({ heatMapWrapperDimensions })
  }

  getHeatMapWrapperDimensions = () => {
    const wrapper = ReactDOM.findDOMNode(this.heatMapWrapperRef.current)
    const width = wrapper.clientWidth
    const height = wrapper.clientHeight
    return { width, height }
  }

  getDataMatrix = dayData => {
    return Object.keys(dayData).reduce((acc, d) => {
      const value = dayData[d]
      const day = Number.parseInt(d.substring(0, 1), 10)
      const index = Number.parseInt(d.substring(1, 3), 10)
      const values = acc[index]
      if (values) {
        acc[index][day] = value
      } else {
        acc[index] = [0, 0, 0, 0, 0, 0, 0]
        acc[index][day] = value
      }
      return acc
    }, [])
  }

  generateTimeLabels = () => {
    return [...Array(24).keys()].map((i) => {
      if (i === 0) {
        return '12a'
      } else if (i === 12) {
        return '12p'
      }
      if (i % 3 === 0) {
        if (i < 12) {
          return `${i}am`
        }
        return `${i % 12}pm`
      }
      return ''
    })
  }

  renderNoDataScreen = () => {
    return (
      <EmptyWrapper>
        <EmptyState
          title='No opens or replies'
          description='No messages were opened or replied in the past 30 days'
        />
      </EmptyWrapper>
    )
  }

  renderLoadingScreen = () => {
    return (
      <ErrorContainer>
        <Loading flex='1' />
      </ErrorContainer>
    )
  }

  render () {
    const {
      openData,
      replyData,
      openTotal,
      replyTotal,
      isOpenHoursReportsLoading,
      isReplyHoursReportsLoading
    } = this.props

    // create matrix of svgs from openData or replyData if either or has finished loading
    let openDataMatrix = null
    let replyDataMatrix = null
    if (openData) {
      openDataMatrix = this.getDataMatrix(openData)
    }
    if (replyData) {
      replyDataMatrix = this.getDataMatrix(replyData)
    }

    // if there is no data from the report, render `empty state screen`
    if (!!openTotal && !!replyTotal && (openTotal + replyTotal <= 0)) {
      this.renderNoDataScreen()
    }

    const timeLabels = this.generateTimeLabels()
    return (
      <Wrapper>
        <SectionWrapper>
          <HeatMapWrapper ref={this.heatMapWrapperRef}>
            {isOpenHoursReportsLoading || !openDataMatrix
              ? this.renderLoadingScreen()
              : (
                <>
                  <Title> Open Hours </Title>
                  <HeatMap
                    dataMatrix={openDataMatrix}
                    domainLabels={timeLabels}
                    rangeLabels={rangeLabels.map(day => (day.substring(0, 1)))}
                    tooltipUnit='opens'
                    tooltipUnitSingle='open'
                    customTooltip={HeatMapTooltip}
                  />
                </>)}
          </HeatMapWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <HeatMapWrapper>
            {isReplyHoursReportsLoading || !replyDataMatrix
              ? this.renderLoadingScreen()
              : (
                <>
                  <Title> Reply Hours </Title>
                  <HeatMap
                    dataMatrix={replyDataMatrix}
                    domainLabels={timeLabels}
                    rangeLabels={rangeLabels.map(day => (day.substring(0, 1)))}
                    tooltipUnit='replies'
                    tooltipUnitSingle='reply'
                    tooltipValueColor={theme.colors.green}
                    colors={['#e9fcf1', '#bdf5d4', '#91eeb8', '#64e79b', '#38e07f', '#1fc765', '#189b4f', '#116e38', '#0a4222']}
                    customTooltip={HeatMapTooltip}
                  />
                </>)}
          </HeatMapWrapper>
        </SectionWrapper>
      </Wrapper>
    )
  }
}

HeatMapWidget.propTypes = {
  openData: PropTypes.object,
  replyData: PropTypes.object,
  openTotal: PropTypes.any,
  replyTotal: PropTypes.any,
  isOpenHoursReportsLoading: PropTypes.bool,
  isReplyHoursReportsLoading: PropTypes.bool
}

export default HeatMapWidget

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign, color } from 'styled-system'
import theme from '../../themes/light'

const Wrapper = styled.h1`
  color: ${theme.titleColor};
  font: ${theme.fonts.title};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
  letter-spacing: .05em;

  ${space}
  ${width}
  ${textAlign}
  ${color}
`

class Title extends Component {
  render () {
    return (
      <Wrapper {...this.props}>
        {this.props.children}
      </Wrapper>
    )
  }
}

Title.propTypes = {
  children: PropTypes.any
}

export default Title

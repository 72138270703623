import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import format from 'date-fns/format'
import Label from 'elements/Label'
import { stripHtml, truncate } from 'utils/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../../../../../themes/light'

const OPENED_VERB = 'viewed'
const REPLIED_VERB = 'replied'
const CLICKED_VERB = 'visited'
const EMAILED_VERB = 'messaged'
const BOOKED_VERB = 'booked'
const CREATED_VERB = 'created'
const BOUNCED_VERB = 'bounced'
const UNSUBSCRIBED_VERB = 'unsubscribed'
const TASK_VERB = 'task'
const SKIPPED_VERB = 'task_skipped'
const PAUSED_VERB = 'paused'

const Wrapper = styled.li`
  font: ${theme.fonts.normal};
  padding: ${theme.padding};
  background: ${theme.colors.white};
  width: 100%;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0;
  cursor: normal;
  position: relative;

  /* Also checking for subject line here because not all replies are tracked */
  ${props => props.activity.get('verb') === REPLIED_VERB && props.activity.getIn(['data', 'subject']) && css`
    cursor: pointer;
  `};

  ${props => props.activity.get('verb') === EMAILED_VERB && css`
    cursor: pointer;
  `};
`

const Container = styled.div`
  border-left: 2px solid ${theme.colors.gray60};
  margin-top: -17px;
  margin-left: -3px;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const ActivityTitle = styled.h3`
  font: ${theme.fonts.label};
  color: ${props => (props.color ? props.color : theme.colors.gray70)};
  text-align: left;
  text-transform: uppercase;
  margin: 0;
`

const DateLabel = styled.span`
  font: ${theme.fonts.label};
  color: ${theme.labelColor};
  text-align: right;
  text-transform: uppercase;
  margin: 0;
`

const IconContainer = styled.div`
  position: absolute;
  background-color: ${props => props.color};
  left: 8.5px;
  top: -5px;
  width: 27px;
  height: 27px;
  border-radius: 9999px;
  border: solid 1px white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BodyContainer = styled.div`
  background-color: ${theme.colors.gray10};
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  margin-top: 0.5rem;
  text-align: left;
  line-height: 0.8;
`

const BodyContainerWhite = styled.div`
  margin-top: 0.25rem;
  text-align: left;
`

const BodyContainerYellow = styled(BodyContainer)`
  background-color: ${theme.colors.lightYellow};
`

const BodyHeader = styled(Label)`
  font: ${theme.fonts.button};
  color: ${theme.titleColor};
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  text-align: left;
`

const BodyLabel = styled(Label)`
  font: ${theme.fonts.xsmall};
  color: ${theme.titleColor};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
`

const DoubleBodyLabel = styled(BodyLabel)`
  margin-top: 0;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Link = styled.a`
  color: ${theme.colors.blue};
  cursor: pointer;
  text-decoration: underline;
`

class ActivityItem extends PureComponent {
  titleForVerb = (verb) => {
    switch (verb) {
      case OPENED_VERB:
        return 'Opened Email'
      case REPLIED_VERB:
        return 'Replied to Email'
      case BOOKED_VERB:
        return 'Booked a Meeting'
      case CLICKED_VERB:
        return 'Clicked Link'
      case EMAILED_VERB:
        return 'Sent an Email'
      case CREATED_VERB:
        return 'Contact Added'
      case BOUNCED_VERB:
        return 'Email Bounced'
      case UNSUBSCRIBED_VERB:
        return 'Unsubscribed'
      case TASK_VERB:
        return 'Task Completed'
      case SKIPPED_VERB:
        return 'Task Skipped'
      case PAUSED_VERB:
        return 'Contact Paused'
      default:
        return ''
    }
  }

  colorForVerb = (verb) => {
    switch (verb) {
      case UNSUBSCRIBED_VERB:
      case BOUNCED_VERB:
        return theme.colors.red
      case CLICKED_VERB:
      case OPENED_VERB:
        return theme.colors.blue
      case REPLIED_VERB:
      case BOOKED_VERB:
        return theme.colors.green
      case EMAILED_VERB:
        return theme.colors.yellow
      case CREATED_VERB:
      default:
        return theme.colors.gray60
    }
  }

  iconForVerb = (verb) => {
    switch (verb) {
      case OPENED_VERB:
        return (
          <FontAwesomeIcon
            icon={['far', 'eye']}
            color='white'
            transform={{
              size: 12,
              x: 0.5
            }}
          />
        )
      case CLICKED_VERB:
        return (
          <FontAwesomeIcon
            icon={['fal', 'mouse-pointer']}
            color='white'
            transform={{
              x: 1,
              size: 12
            }}
          />
        )
      case EMAILED_VERB:
        return (
          <FontAwesomeIcon
            icon={['fas', 'paper-plane']}
            color='white'
            transform={{
              x: -1,
              size: 12
            }}
          />
        )
      case CREATED_VERB:
        return (
          <FontAwesomeIcon
            icon={['far', 'plus']}
            color='white'
            transform={{
              size: 12
            }}
          />
        )
      case UNSUBSCRIBED_VERB:
      case BOUNCED_VERB:
        return (
          <FontAwesomeIcon
            icon={['far', 'times']}
            color='white'
            transform={{
              size: 14
            }}
          />
        )
      case TASK_VERB:
        return (
          <FontAwesomeIcon
            icon={['fas', 'tasks']}
            color='white'
            transform={{
              size: 12
            }}
          />
        )
      case SKIPPED_VERB:
        return (
          <FontAwesomeIcon
            icon={['far', 'fast-forward']}
            color='white'
            transform={{
              size: 12
            }}
          />
        )
      case PAUSED_VERB:
        return (
          <FontAwesomeIcon
            icon={['fal', 'pause-circle']}
            color='white'
            transform={{
              size: 12
            }}
          />
        )
      case REPLIED_VERB:
        return (
          <FontAwesomeIcon
            icon={['fas', 'reply']}
            color='white'
            transform={{
              size: 12
            }}
          />
        )
      case BOOKED_VERB:
        return (
          <FontAwesomeIcon
            icon={['fas', 'calendar']}
            color='white'
            transform={{
              size: 12,
              x: 0.5
            }}
          />
        )
      default:
        return (
          <FontAwesomeIcon
            icon={['fas', 'paper-plane']}
            color='white'
            transform={{
              x: -1,
              size: 12
            }}
          />
        )
    }
  }

  titleForSource = (data, router) => {
    const from = data.get('from')
    const fromUser = data.getIn(['fromUser', 'full_name'])
    const fromCampaign = data.getIn(['fromCampaign', 'title'])
    switch (from) {
      case 'csv_import':
        return (
          <span>
            Created via CSV Import
            {fromUser ? ` by ${fromUser}` : ''}
          </span>
        )
      case 'chrome_extension':
        return (
          <span>
            Created via Chrome Extension
            {fromUser ? ` by ${fromUser}` : ''}
          </span>
        )
      case 'moved':
        return (
          <span>
            Moved {fromCampaign ? 'from ' : ''}
            {fromCampaign && <Link onClick={() => router.push(`/sequence/${data.getIn(['fromCampaign', '_id'])}`)}>{fromCampaign} sequence</Link>}
            {fromUser ? ` by ${fromUser} ` : ''}
          </span>
        )
      case 'copied':
        return (
          <span>
            Copied {fromCampaign ? 'from ' : ''}
            {fromCampaign && <Link onClick={() => router.push(`/sequence/${data.getIn(['fromCampaign', '_id'])}`)}>{fromCampaign} sequence</Link>}
            {fromUser ? ` by ${fromUser} ` : ''}
          </span>
        )
      case 'created':
        return <span>{fromUser ? `Created by ${fromUser} ` : ''}</span>
      default:
    }
  }

  bodyForActivity = (activity, router) => {
    const verb = activity.get('verb')
    const genericWhiteBody = (body) => {
      return (
        <BodyContainerWhite>
          <BodyLabel>{body}</BodyLabel>
        </BodyContainerWhite>
      )
    }

    switch (verb) {
      case CLICKED_VERB: {
        const link = activity.getIn(['data', 'url'])
        return genericWhiteBody(link)
      }
      case EMAILED_VERB: {
        const subject = activity.getIn(['_message', 'subject'])
        let body = activity.getIn(['_message', 'body'])
        body = stripHtml(body)
        body = truncate(body, 65)
        return (
          <BodyContainer>
            <BodyHeader>{subject}</BodyHeader>
            <BodyLabel>{body}</BodyLabel>
          </BodyContainer>
        )
      }
      case CREATED_VERB: {
        const data = activity.get('data')
        const source = this.titleForSource(data, router)
        if (source) {
          return genericWhiteBody(source)
        }
        return null
      }
      case TASK_VERB: {
        let body = activity.getIn(['_task', 'markdown'])
        body = stripHtml(body)
        body = truncate(body, 130)

        if (!body) {
          return
        }

        return (
          <BodyContainerYellow>
            <DoubleBodyLabel>{body}</DoubleBodyLabel>
          </BodyContainerYellow>
        )
      }
      case UNSUBSCRIBED_VERB: {
        const name = activity.getIn(['_manual_user', 'full_name'])
        if (name) {
          return genericWhiteBody(`Manually unsubscribed by ${name}`)
        }
        return null
      }
      case PAUSED_VERB: {
        const name = activity.getIn(['_manual_user', 'full_name'])
        if (name) {
          return genericWhiteBody(`By ${name}`)
        }
        return null
      }
      case BOOKED_VERB: {
        const name = activity.getIn(['_manual_user', 'full_name'])
        if (name) {
          return genericWhiteBody(`Manually set as booked by ${name}`)
        }
        const calendar = activity.getIn(['data', 'calendar'])
        const eventDate = activity.getIn(['data', 'start'])
        const eventSummary = activity.getIn(['data', 'summary'])
        if (eventSummary) {
          return (
            <BodyContainer>
              {eventSummary && <BodyHeader>{eventSummary}</BodyHeader>}
              <BodyLabel>{format(eventDate, 'dddd, MMM D, YYYY, h:mma')}</BodyLabel>
              {calendar && <BodyLabel>via {calendar}</BodyLabel>}
            </BodyContainer>
          )
        }
        return (
          <BodyContainerWhite>
            <BodyLabel>{format(eventDate, 'dddd, MMM D, YYYY, h:mma')}</BodyLabel>
            {calendar && <BodyLabel>via {calendar}</BodyLabel>}
          </BodyContainerWhite>
        )
      }
      case REPLIED_VERB: {
        const name = activity.getIn(['_manual_user', 'full_name'])
        if (name) {
          return genericWhiteBody(`Manually set as replied by ${name}`)
        }
        const subject = activity.getIn(['data', 'subject'])
        let body = activity.getIn(['data', 'reply'])
        if (subject && body) {
          body = stripHtml(body)
          body = truncate(body, 65)
          return (
            <BodyContainer>
              <BodyHeader>{subject}</BodyHeader>
              <BodyLabel>{body}</BodyLabel>
            </BodyContainer>
          )
        }
        return null
      }
      default:
        return null
    }
  }

  render () {
    const {
      activity,
      onClick,
      router
    } = this.props

    const verb = activity.get('verb')
    const aggregate = activity.get('aggregate')
    const dateString = aggregate ? activity.get('start_at') : activity.get('created_at')
    const color = this.colorForVerb(verb)
    const time = format(Date.parse(dateString), 'MMM D h:mma')
    const body = this.bodyForActivity(activity, router)
    const count = aggregate ? ` ${activity.get('count')}X` : ''

    return (
      <Wrapper
        onClick={onClick}
        activity={activity}
        color={color}
      >
        <IconContainer color={color}>
          {this.iconForVerb(verb)}
        </IconContainer>
        <Container>
          <HeaderWrapper>
            <ActivityTitle color={color}>{this.titleForVerb(verb)}{count}</ActivityTitle>
            <DateLabel>{time}</DateLabel>
          </HeaderWrapper>
          {body && body}
        </Container>
      </Wrapper>
    )
  }
}

ActivityItem.propTypes = {
  activity: PropTypes.object,
  onClick: PropTypes.func,
  router: PropTypes.object
}

export default ActivityItem

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { space, width, height } from 'styled-system'
import styled, { css } from 'styled-components'
import theme from '../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .companies {
    background: ${theme.colors.white};
    width: 100%;
    position: absolute;
    top: 100%;
    box-shadow: ${theme.shadow};
    box-sizing: border-box;
    z-index: 1;
  }
  .company {
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
    padding: 0.5rem;
    &.selected {
      background: ${theme.highlightBackground};
    }
    img {
      height: 100%;
      width: 40px;
    }
    .company-container {
      display: flex;
      flex-direction: column;
      margin-left: 0.75rem;
      max-width: 78%;
    }
    .company-name {
      color: ${theme.titleColor};
      font: ${theme.fonts.normal};
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .company-domain {
      color: ${theme.labelColor};
      font: ${theme.fonts.small};
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  ${space}
  ${width}
  ${height}
`

const CompanyAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const Label = styled.label`
  width: 100%;
  color: ${theme.labelColor};
  font: ${theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Field = styled.input`
  color: ${theme.titleColor};
  font: ${theme.fonts.normal};
  width: 100%;
  padding: ${props => (props.small ? '0.65rem' : '1rem')};
  border-radius: 3px;
  border-width: 1px;
  border-color: ${theme.colors.gray60};
  border-style: solid;

  ${props => props.disabled && css`
    cursor: default;
    opacity: .5;
  `};
`

class CompanyInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      results: [],
      highlightedIndex: 0
    }
  }

  onSelect = (index) => {
    const {
      onCompanySelect,
      domainSelection,
      onValueChange
    } = this.props
    const company = this.state.results[index]
    if (!company) {
      return
    }
    onValueChange(domainSelection ? company.domain : company.name)
    this.setState({ results: [] })
    if (onCompanySelect) {
      onCompanySelect(company)
    }
  }

  handleKeyDown = (e) => {
    if (!this.state.results.length) {
      return
    }
    let currentIndex = this.state.highlightedIndex

    if (e.key === 'ArrowDown') {
      if (currentIndex === null) {
        currentIndex = 0
      } else {
        currentIndex = (currentIndex + 1) % this.state.results.length
      }
      this.setState({ highlightedIndex: currentIndex })
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (currentIndex === -1) {
        currentIndex = this.state.results.length
      }
      currentIndex -= 1
      this.setState({ highlightedIndex: currentIndex })
    }

    if (e.key === 'Enter') {
      currentIndex = this.state.highlightedIndex
      if (currentIndex < 0) return
      this.onSelect(currentIndex)
    }
  }

  queryClearbit = (query) => {
    if (query.length === 0) {
      this.setState({ results: [], highlightedIndex: null })
      return
    }
    fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`)
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(new Error(response.statusText))
        }
        return response.json()
      })
      .then(this.updateResults)
  }

  highlightItemFromMouse = (highlightedIndex) => {
    this.setState({ highlightedIndex })
  }

  selectItemFromMouse = (index) => {
    this.onSelect(index)
  }

  updateResults = (results) => {
    this.setState({ results })
  }

  renderResults = () => (
    this.state.results.map((result, index) => {
      const companyClassName = this.props.companyProps.className
      return (
        <div
          key={`${result.domain}-${index}`}
          className={this.state.highlightedIndex === index ? `${companyClassName} selected` : companyClassName}
          onMouseEnter={() => { this.highlightItemFromMouse(index) }}
          onMouseDown={() => { this.selectItemFromMouse(index) }}
        >
          <CompanyAvatar
            src={result.logo ? result.logo : null}
            name={result.name}
            size={40}
            textSizeRatio={2.5}
            round
          />
          <div className={`${companyClassName}-container`}>
            <span className={`${companyClassName}-name`}>
              {result.name}
            </span>
            <span className={`${companyClassName}-domain`}>
              {result.domain}
            </span>
          </div>
        </div>
      )
    })
  )

  render () {
    const {
      label,
      inputId,
      small = false,
      value,
      disabled,
      min,
      autoFocus,
      ...rest
    } = this.props

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        {...rest}
      >
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        <Field
          id={inputId}
          value={value}
          type='text'
          onChange={(e) => {
            const query = e.target.value
            this.props.onValueChange(query)
            this.queryClearbit(query)
          }}
          onBlur={() => {
            this.setState({
              results: [],
              highlightedIndex: 0
            })
          }}
          // onChange={e => (this.props.onValueChange(e.target.value))}
          small={small}
          disabled={disabled}
          autoFocus={autoFocus}
          autcomplete='off'
          {...this.props.inputProps}
        />
        {this.state.results.length > 0 &&
          <div {...this.props.companiesProps}>{this.props.renderResults || this.renderResults()}</div>}
      </Wrapper>
    )
  }
}

CompanyInput.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  onValueChange: PropTypes.func,
  multiline: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.number,
  autoFocus: PropTypes.bool,
  /*
  * Optional props to pass into the dropdown box containing the returned companies.
  */
  companiesProps: PropTypes.object,
  /*
  * Optional props to pass into each company in the companies box.
   */
  companyProps: PropTypes.object,
  /*
  * Optional props to pass to input box (ie. className, id, etc.)
   */
  inputProps: PropTypes.object,
  /*
  * Required function to call after a user has selected a company.
   */
  onCompanySelect: PropTypes.func,
  /*
  * Optional function to specify how results should be rendered.
   */
  renderResults: PropTypes.func,
  domainSelection: PropTypes.bool
}

CompanyInput.defaultProps = {
  companiesProps: { className: 'companies' },
  companyProps: { className: 'company' },
  domainSelection: true
}

export default CompanyInput

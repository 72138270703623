import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import Label from 'elements/Label'
import Title from 'elements/Title'
import theme from '../../../../themes/light'

const ContentContainer = styled.div`
  background-color: ${theme.containerBackground};
  flex: 1 0 auto;
  border: solid 1px ${theme.borderColor};
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 42rem;
`

const TitleContainer = styled.div`
`

const CodeBlock = styled.div`
  padding: ${theme.padding};
  background-color: ${theme.background};
  margin-bottom: 1rem;
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

class Api extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showGenerateConfirmModal: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchApiKey()
  }

  render () {
    const {
      actions,
      apiKey
    } = this.props

    const {
      showGenerateConfirmModal
    } = this.state

    // if (loading || loadingList) {
    //   return (
    //     <ContentContainer>
    //       <Loading />
    //     </ContentContainer>
    //   );
    // }

    return (
      <ContentContainer>
        <TitleContainer>
          <Title>API Key</Title>
          <Label mt='0.5rem'>You can interact with Interseller directly using the API. Read the <a href='https://api-docs.interseller.io'>documentation</a> for more information.</Label>
        </TitleContainer>
        <FieldContainer>
          {apiKey.get('data') &&
            <CodeBlock>
              <Label align='center' fontSize='18px'>{apiKey.get('data')}</Label>
            </CodeBlock>}
          <Button
            primary
            label='Generate New Key'
            full
            onClick={() => {
              if (apiKey.get('data')) {
                this.setState({
                  showGenerateConfirmModal: true
                })
              } else {
                actions.fetchApiKey({ regenerate: true })
              }
            }}
          />
        </FieldContainer>
        <ConfirmModal
          isOpen={showGenerateConfirmModal}
          onCancel={() => {
            this.setState({
              showGenerateConfirmModal: false
            })
          }}
          onConfirm={() => {
            actions.fetchApiKey({ regenerate: true })
            this.setState({
              showGenerateConfirmModal: false
            })
          }}
          title='Confirm Generate New Key'
          description='Are you sure you want to generate a new key? Any third party services using your existing Interseller key will no longer have access.'
        />
      </ContentContainer>
    )
  }
}

Api.propTypes = {
  session: PropTypes.object,
  actions: PropTypes.object,
  apiKey: PropTypes.object
}

export default Api

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import withSession from 'hocs/session'
import { Wrapper, Window, Curtain } from '../ToolbarModal'
import theme from '../../../../../themes/light'

const Modal = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: relative;

  width: 100%;
  max-width: 600px;
`

const InputContainer = styled.div`
  padding: 2rem 4rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

class SendTestEmail extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      email: props.session.get('email') || null
    }

    this.handleEnterKey = this.handleEnterKey.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEnterKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEnterKey, false)
  }

  handleEnterKey = event => {
    const { toggleToolbarMenuVisibility, onSendEmail } = this.props
    const { email } = this.state

    const ENTER_KEY = 13
    if (event.keyCode === ENTER_KEY && email && email.length > 0) {
      // send test email
      onSendEmail(email)

      // close menu
      toggleToolbarMenuVisibility('showSendTestEmailMenu')
    }

    return false
  }

  render () {
    const {
      loading,
      onSendEmail,
      toggleToolbarMenuVisibility
    } = this.props
    const {
      email
    } = this.state

    if (loading) {
      return <Loading />
    }

    return (
      <Wrapper>
        <Curtain
          onClick={() => toggleToolbarMenuVisibility('showSendTestEmailMenu')}
          visible
        />
        <Window>
          <Modal
            onKeyDown={this.handleKeyDown}
          >
            <InputContainer>
              <Title align='center'>Send Test Email</Title>
              <Label mt='0.5rem' mb='0.5rem' align='center'>
                Send a test email to view how the email will display in your email client. Previous steps are not visible when sending test emails.
              </Label>
              <Input
                label='Email'
                value={email}
                inputId='email'
                onValueChange={(value) => {
                  this.setState({
                    email: value
                  })
                }}
                mt='1.5rem'
              />
            </InputContainer>
            <Divider />
            <ButtonContainer>
              <Button
                label='cancel'
                onClick={() => toggleToolbarMenuVisibility('showSendTestEmailMenu')}
                mr='0.5rem'
              />
              <Button
                primary
                disabled={!this.state.email || !(this.state.email.length > 0)}
                label='send test email'
                onClick={() => {
                  onSendEmail(this.state.email)
                  toggleToolbarMenuVisibility('showSendTestEmailMenu')
                }}
              />
            </ButtonContainer>
          </Modal>
        </Window>
      </Wrapper>
    )
  }
}

SendTestEmail.propTypes = {
  session: PropTypes.object,
  loading: PropTypes.bool,
  onSendEmail: PropTypes.func,
  toggleToolbarMenuVisibility: PropTypes.func
}

SendTestEmail.defaultProps = {
  loading: false
}

export default withSession(SendTestEmail)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class BlockContactModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addToTeam: true
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState({
      addToTeam: newProps.canBlockForTeam
    })
  }

  handleKeyDown = (e) => {
    const {
      email
    } = this.state
    // If 'enter' is pressed
    if (e.keyCode === 13 && email !== null) {
      e.preventDefault()
      return this.props.onConfirm(email)
    }
    return false
  }

  render () {
    const {
      title,
      description,
      loading,
      onCancel,
      onConfirm,
      isOpen,
      canBlockForTeam
    } = this.props

    const {
      addToTeam
    } = this.state

    if (loading) {
      return <Loading />
    }

    const listOptions = Immutable.List([
      {
        value: false,
        label: 'Add to my personal blocklist'
      },
      {
        value: true,
        label: 'Add to my team\'s blocklist'
      }
    ])

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='1rem' align='center'>
              {description}
            </Label>
            {canBlockForTeam &&
              <DropDownFieldForm
                options={listOptions}
                value={addToTeam}
                inputId='list'
                onOptionChange={(option) => {
                  this.setState({
                    addToTeam: option ? option.value : null
                  })
                }}
              />}
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label='confirm'
              onClick={() => {
                onConfirm(canBlockForTeam ? addToTeam : false)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

BlockContactModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  actions: PropTypes.object,
  canBlockForTeam: PropTypes.bool
}

BlockContactModal.defaultProps = {
  loading: false,
  isOpen: false,
  addToTeam: true
}

export default BlockContactModal

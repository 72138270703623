import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../../themes/light'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StatusLabel = styled.p`
  background-color: ${props => props.selected ? theme.colors.blue : 'transparent'};
  text-transform: uppercase;
  color: ${props => props.selected ? theme.colors.white : theme.colors.gray70};
  font: ${theme.fonts.xxsmall};
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 3px;
  font-weight: 400;
  border: 1px solid ${props => props.selected ? theme.colors.blue : theme.colors.gray70};
  z-index: ${props => props.selected ? '1' : '0'};

  &:first-child {
    position: relative;
    right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  &:hover {
    background-color: ${props => props.selected ? theme.colors.blue : theme.colors.gray20};
  }
`

class StatusToggle extends Component {
  render () {
    const {
      leftLabel,
      rightLabel,
      leftValue,
      rightValue,
      value,
      handleOnToggle
    } = this.props

    const leftSelected = leftValue === value
    const rightSelected = rightValue === value

    return (
      <Wrapper>
        <StatusLabel
          selected={leftSelected}
          onClick={() => {
            if (!leftSelected) {
              handleOnToggle()
            }
          }}
        >
          {leftLabel}
        </StatusLabel>
        <StatusLabel
          selected={rightSelected}
          onClick={() => {
            if (!rightSelected) {
              handleOnToggle()
            }
          }}
        >
          {rightLabel}
        </StatusLabel>
      </Wrapper>
    )
  }
}

StatusToggle.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  leftValue: PropTypes.string,
  rightValue: PropTypes.string,
  handleOnToggle: PropTypes.func,
  value: PropTypes.string
}

export default StatusToggle

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Immutable from 'immutable'
import DropDownFieldForm from 'components/DropDownFieldForm'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 2rem;
`

const ButtonContainer = styled.div`
  margin: 2rem auto;
  margin-bottom: 1rem;
`

const Details = styled.div`
  padding-left: 1rem;
  text-align: left;
  width: 100%;
`

const Title = styled.h3`
  width: 100%;
  color: ${theme.titleColor};
  font: ${theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

class ImportOptions extends PureComponent {
  render () {
    const {
      onOptionSelect,
      options,
      selectedOption,
      activeCRM,
      onClickSelect,
      onActiveCRMRefresh
    } = this.props

    // Need an Immutable.List of plain JS Objects for DropDown
    const dropdownOptions = options.map(function (option) {
      return {
        value: option.get('id'),
        label: option.get('name')
      }
    })

    return (
      <Wrapper>
        <Details>
          <Title>Import from {activeCRM.getIn(['data', 'name'])} {activeCRM.getIn(['data', 'import_type'])}</Title>
          <DropDownFieldForm
            // label={activeCRM.getIn(['data', 'import_type'])}
            description={`Choose the ${activeCRM.getIn(['data', 'name'])} ${activeCRM.getIn(['data', 'import_type'])} you'd like to import contacts from`}
            searchable
            options={dropdownOptions}
            value={selectedOption ? selectedOption.value : undefined}
            inputId='list'
            refreshing={activeCRM?.get('refreshing')}
            onOptionChange={(option) => {
              onOptionSelect(option)
            }}
            onRefresh={() => {
              onActiveCRMRefresh({ include_import_options: true })
            }}
            refreshTooltip={`Refresh ${activeCRM.getIn(['data', 'import_type'])}s from ${activeCRM.getIn(['data', 'name'])}`}
          />
        </Details>
        <ButtonContainer>
          <Button
            label='Select'
            primary
            disabled={!selectedOption}
            handleClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onClickSelect()
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

ImportOptions.propTypes = {
  activeCRM: PropTypes.object,
  onOptionSelect: PropTypes.func,
  onClickSelect: PropTypes.func,
  options: PropTypes.instanceOf(Immutable.List),
  selectedOption: PropTypes.object,
  onActiveCRMRefresh: PropTypes.func
}

export default ImportOptions

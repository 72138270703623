import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import Title from 'elements/Title'
import Subtitle from 'elements/Subtitle'
import NavigationLink from 'components/NavigationLink'
import ExternalLink from 'components/ExternalLink'
import theme from '../../themes/light'

const SettingsHeader = styled(Subtitle)`
  font: ${theme.fonts.formtitle};
  text-transform: uppercase;
`

class SideNavigation extends Component {
  getLinks = () => {
    const logsItem = {
      label: 'logs',
      to: '/settings/logs'
    }

    return [
      {
        header: 'Personal'
      },
      {
        label: 'email',
        to: '/settings/general'
      },
      {
        label: 'tracking',
        to: '/settings/tracking'
      },
      {
        label: 'schedule',
        to: '/settings/schedule'
      },
      {
        label: 'inbox',
        to: '/settings/inbox'
      },
      {
        label: 'safety',
        to: '/settings/safety'
      },
      {
        label: 'notifications',
        to: '/settings/notifications'
      },
      {
        header: 'Team'
      },
      {
        label: 'members',
        icon: ['far', 'external-link'],
        to: '/team'
      },
      {
        label: 'team safety',
        to: '/settings/teamsafety'
      },
      {
        label: 'privacy',
        to: '/settings/privacy'
      },
      ...(this.props.state?.get('is_owner') || this.props.state?.get('is_manager') ? [logsItem] : []),
      {
        header: 'Integrations'
      },
      {
        label: 'CRM / ATS',
        to: '/integrations/crm'
      },
      // {
      //   label: 'Chrome Extension',
      //   url: 'https://chrome.google.com/webstore/detail/interseller/coggaiakecmfpkamlplbpacajahgeofh'
      // },
      {
        label: 'Chrome Extension',
        to: '/integrations/chrome'
      },
      {
        label: 'Zapier',
        to: '/integrations/zapier'
      },
      {
        label: 'API',
        to: '/integrations/api'
      },
      {
        header: 'Data'
      },
      {
        label: 'Exports',
        to: '/settings/exports'
      }
    ]
  }

  render () {
    const navLinks = this.getLinks().map((link, i) => {
      if (link.header) {
        return (
          <SettingsHeader key={`header_${link.header}`} ml='1rem' mt={i === 0 ? '1rem' : '2rem'}>
            {link.header}
          </SettingsHeader>
        )
      }
      if (link.url) {
        return (
          // No idea why it thinks the key prop is needed here
          // eslint-disable-next-line react/jsx-key
          <ExternalLink
            href={link.url}
            target='_new'
          >
            {link.label}
          </ExternalLink>
        )
      }
      return (
        <NavigationLink
          key={`link_${link.to}`}
          data-index={i}
          label={link.label}
          icon={link.icon}
          to={link.to}
          mb='0rem'
        />
      )
    })

    return (
      <Wrapper>
        <Title ml='1rem' mb='1rem'>Settings</Title>
        {navLinks}
      </Wrapper>
    )
  }
}

SideNavigation.propTypes = {
  state: PropTypes.object
}

export default SideNavigation

import styled from 'styled-components'
import theme from '../../themes/light'

const Wrapper = styled.div`
  flex: 1 0 auto;
  background: ${theme.containerBackground};
  border-bottom: solid 1px ${theme.borderColor};
  color: #FFF;
  transition: background-color 0.3s ease, color .3s ease;
  /* position: fixed; */
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  overflow: visible;
  height: 60px;
  width: 100%;
  z-index: 1000;
`

export default Wrapper

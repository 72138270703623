import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Subtitle from 'elements/Subtitle'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  padding: ${theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${theme.colors.gray30};
  border-style: solid;
`

class Inbox extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      session,
      onSettingUpdate
    } = this.props

    const archiveBounces = session.get('archive_bounces')
    const archiveAuto = session.get('archive_auto')
    const archivedUnsubscribed = session.get('archive_unsubscribe')
    const actionEmail = session.get('action_email')

    return (
      <Wrapper>
        <Subtitle>Inbox</Subtitle>
        <FieldContainer>
          <SwitchFieldForm
            label='Actions'
            description='Append an action email to every first email sent to a contact which helps give you quick shortcuts to actions and bookmarks to Interseller, your CRM and LinkedIn.'
            inputId='action_email'
            value={actionEmail}
            onSave={(value) => {
              onSettingUpdate({ action_email: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Bounces'
            description='Archive bounced emails from your inbox from contacts that Interseller has messaged.'
            inputId='archive_bounces'
            value={archiveBounces}
            onSave={(value) => {
              onSettingUpdate({ archive_bounces: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Auto Replies'
            description='Filter out automated emails from your inbox from contacts Interseller messages like vacation responders and pause future messages to the day they get back.'
            inputId='archive_auto'
            value={archiveAuto}
            onSave={(value) => {
              onSettingUpdate({ archive_auto: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Unsubscribe'
            description='Automatically unsubscribe users that reply with unsubscribe requests and archive the email request.'
            inputId='archive_unsubscribe'
            value={archivedUnsubscribed}
            onSave={(value) => {
              onSettingUpdate({ archive_unsubscribe: value })
            }}
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

Inbox.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func
}

export default Inbox
